import { useParams } from "react-router-dom";
import Layout from "../layout";
import Folder from "./Components/folder";
import style from "../styles/files.module.css";
import MobileUpload from "../Components/MobileUpload";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Sharing from "../Components/Sharing";
import Comments from "../Components/Comments";
import Activity from "../Components/Activity";
import {
  GetFolder,
  GetNextActivities,
  parentListener,
  renameFileService,
} from "../../Services/Document";
import { useEffect, useRef, useState } from "react";
import {
  Comment as CommentModel,
  FileData,
  Create as CreateModel,
  member,
  Activity as ActivityModel,
} from "../../Models/Document";
import { commentListener } from "../../Services/Comments";
import { Create } from "../../Services/Document";
import getBreadCrumb from "./BreadCrumb";
import { FileWithChecked } from "../Home/home";

export default function FileFolder() {
  const { id } = useParams<{ id: string }>();
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [folderName, setFolderName] = useState<string>("");
  const [members, setMembers] = useState<member[]>([]);
  const [activities, setActivities] = useState<{
    items: ActivityModel[];
    token: string;
  }>({
    items: [],
    token: "",
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const commentSubscriber = useRef<any>();
  const parentSubscriber = useRef<any>();
  const [folder, setFolder] = useState<{
    files: FileWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });
  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };
  const refresh = () => {
    setInitialLoad(true);
    GetFolder(id, 0)
      .then((files) => {
        if (files) {
          setFolder({
            files: files.files,
            token: files.token,
            loading: false,
          });
          setComments([...files.comments].reverse());
          setMembers(files.members);
          setActivities({
            items: files.activities,
            token: files.activitiesToken,
          });
          setBreadCrumbItems(getBreadCrumb(files.parent));
          setFolderName(files.name);
        }
      })
      .finally(() => {
        setInitialLoad(false);
      });
  };

  useEffect(() => {
    if (id) {
      GetFolder(id, 0)
        .then((files: any) => {
          setFolder({
            files: files.files,
            token: files.token,
            loading: false,
          });
          setComments(files.comments.reverse());
          setMembers(files.members);
          setActivities({
            items: files.activities,
            token: files.activitiesToken,
          });
          setBreadCrumbItems(getBreadCrumb(files.parent));
          setFolderName(files.name);
        })
        .finally(() => {
          setInitialLoad(false);
        });

      parentListener(id, (value: any) => {
        setFolder((folder) => ({
          ...folder,
          files: [value, ...folder.files.filter((x) => x.id !== value.id)],
        }));
      }).then((value) => {
        parentSubscriber.current = value;
      });
      commentListener(id, (value: any) => {
        setComments((comments) => [...comments, value]);
      }).then((value) => {
        commentSubscriber.current = value;
      });
    }
    return () => {
      if (commentSubscriber.current) {
        commentSubscriber.current.unsubscribe();
      }
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
    };
  }, [id]);

  const rename = async (file: FileData, name: string) => {
    await renameFileService(file.id, name);
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((f) =>
        f.id === file.id ? { ...f, name: name } : f
      ),
    }));
  };

  const move = (fileId: string, selected: string) => {
    if (selected !== id) {
      setFolder((folder) => ({
        ...folder,
        files: folder.files.filter((f) => f.id !== fileId),
      }));
    } else {
      return;
    }
  };
  const archive = (id: string) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.filter((f) => f.id !== id),
    }));
  };
  const createFile = async (fileId: string, file: File) => {
    let value: CreateModel = {
      id: fileId,
      name: file.name,
      parent: id,
      size: file.size,
      type: file.type,
      uploadUrl: "file",
    };
    await Create(value);
  };

  return (
    <Layout>
      <div className="d-lg-none h-100">
        <Tabs
          selectedTabClassName={style.tabSelected}
          className="d-flex flex-column h-100"
          selectedTabPanelClassName="d-flex  flex-fill flex-column"
        >
          <TabList className={style.tabs}>
            <Tab>Home</Tab>
            <Tab>Sharing</Tab>
            <Tab>Comments</Tab>
            <Tab>Activity</Tab>
          </TabList>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="files"
              uploadUrl="file"
            />
            <Folder
              comments={comments}
              files={folder.files}
              id={id}
              breadcrumbs={breadcrumbItems}
              name={folderName}
              rename={rename}
              move={move}
              onArchive={archive}
              createFile={createFile}
              members={members}
              refresh={refresh}
              token={folder.token}
              loading={folder.loading}
              onNext={(files, token) => {
                setFolder({
                  ...folder,
                  files: [...folder.files, ...files],
                  token: token,
                });
              }}
              onClickChecked={(val: string, data: boolean) =>
                handleChecked(val, data)
              }
              onFavorite={(fileId: any) => {
                setFolder((folder) => ({
                  ...folder,
                  files: folder.files.map((f) =>
                    f.id === fileId ? { ...f, favorites: true } : f
                  ),
                }));
              }}
              onUnfavorite={(fileId: any) => {
                setFolder((folder) => ({
                  ...folder,
                  files: folder.files.map((f) =>
                    f.id === fileId ? { ...f, favorites: false } : f
                  ),
                }));
              }}
              initialLoad={initialLoad}
            />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="files"
              uploadUrl="file"
            />
            <Sharing fileId={id} refresh={refresh} />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="files"
              uploadUrl="file"
            />
            <Comments comments={comments} fileId={id} />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="files"
              uploadUrl="file"
            />
            <Activity
              activities={activities.items}
              loadMore={() => {
                if (activities.token) {
                  GetNextActivities(id, activities.token).then((values) => {
                    if (values) {
                      setActivities((activities) => ({
                        items: activities.items.concat(values.activities),
                        token: values.token,
                      }));
                    }
                  });
                }
              }}
              hasMore={!!activities.token}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="d-none d-lg-block h-100">
        <Folder
          comments={comments}
          files={folder.files}
          id={id}
          rename={rename}
          move={move}
          onArchive={archive}
          onClickChecked={(val: string, data: boolean) =>
            handleChecked(val, data)
          }
          createFile={createFile}
          members={members}
          refresh={refresh}
          token={folder.token}
          loading={folder.loading}
          breadcrumbs={breadcrumbItems}
          onNext={(files, token) => {
            setFolder({
              ...folder,
              files: [...folder.files, ...files],
              token: token,
            });
          }}
          onFavorite={(fileId: any) => {
            setFolder((folder) => ({
              ...folder,
              files: folder.files.map((f) =>
                f.id === fileId ? { ...f, favorites: true } : f
              ),
            }));
          }}
          onUnfavorite={(fileId: any) => {
            setFolder((folder) => ({
              ...folder,
              files: folder.files.map((f) =>
                f.id === fileId ? { ...f, favorites: false } : f
              ),
            }));
          }}
          initialLoad={initialLoad}
          name={folderName}
        />
      </div>
    </Layout>
  );
}
