import { Link, NavLink } from "react-router-dom";
import style from "./navmenu.module.css";

export default function NavMenuMobile() {
  return (
    <div className="p-3 bg-white h-100">
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/" className="color-black">
            <img src="/images/Logo.svg" alt="logo" />
        </Link>
        <Link className="btn btn-radius-large btn-primary"  to="/registration-pricing" >
            Get Started
        </Link>
      </div>  
      <ul className={`font-weight-semibold list-unstyled mt-3`}>
        <li>
          <NavLink to="/" exact activeClassName={style.active} className="color-black text-decoration-none">
            Home
          </NavLink>
        </li>
        
        <li className="mt-2">
          <NavLink to="/about" activeClassName={style.active} className="color-black text-decoration-none">
            About
          </NavLink>
        </li>
        <li className="mt-2"> 
          <NavLink to="/faq" activeClassName={style.active} className="color-black text-decoration-none">
            FAQ
          </NavLink>
        </li>
        <li className="mt-2">
          <NavLink to="/contact" activeClassName={style.active} className="color-black text-decoration-none">
            Contact
          </NavLink>
        </li>
        <li className="mt-2">
          <NavLink to="/login" activeClassName={style.active} className="color-black text-decoration-none">
            Login
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
