import { useState } from "react";
import { KeyboardEventHandler, useEffect, useRef } from "react";
import Popup from "../popup";
import { SharedData } from "../../../Models/Document";
import Avatar from "../avatar";
import style from "../Row.module.css";
import { SharedWithChecked } from "../../Favorites/components/folder";
import AsyncModifiedDate from "./AsyncModifiedDate";
import DoubleClick from "./DoubleClick";

interface Props {
  file: SharedWithChecked;
  onClick: Function;
  onUnfavorite: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
  onClickChecked: Function;
}

const FavoriteRow: React.FC<Props> = ({
  file,
  onClick,
  onUnfavorite,
  handleKeyDown,
  tabIndex,
  previewImage,
  onClickChecked,
}) => {
  const timeoutRef = useRef<any>();
  const [lastClickTime, setLastClickTime] = useState(0);
  const [singleClickTimeout, setSingleClickTimeout] = useState<number | null>(
    null
  );
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const isVideoFile = (file: any) => {
    return file.type === "media";
  };
  return (
    <tr
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td className="align-middle">
        <input
          type="checkbox"
          onChange={(e) => onClickChecked(e.currentTarget.checked)}
          checked={file.checked}
          className="form-check-input"
        />
      </td>
      <td
        className="d-none d-lg-table-cell align-middle"
        style={{
          width: "20px",
        }}
      >
        <i className="fas fa-star color-orange"></i>
      </td>
      <td
        onClick={() => {
          DoubleClick({
            previewImage,
            timeoutRef,
            onClick,
            file,
            lastClickTime,
            setLastClickTime,
            singleClickTimeout,
            setSingleClickTimeout,
          });
        }}
        className="align-middle"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        <button
          className="d-flex btn align-items-center shadow-none px-0"
          // onClick={() => onClick(file.id)}
        >
          <span style={{ width: "32px" }}>
            <img
              src={`/logos/${file.type}.png`}
              alt={`${file.type} logo`}
              className="img-fluid"
            />
          </span>
          <span className="ms-2 d-none d-lg-inline">{file.name}</span>
        </button>
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        <span className={`text-center ${style.file}`}>{file.name}</span>
        <br />
        <span className={style.file2}>
          <span>
            <AsyncModifiedDate modified={file.modified} />
          </span>

          {/* {file.modified.toLocaleDateString()} */}
          <span className="color-dark-grey"> by {file.modifierName}</span>
        </span>
        {file.size}
        <br />
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} />
            ))}
        </span>
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          {/* {file.modified.toLocaleDateString()} */}
          <AsyncModifiedDate modified={file.modified} />
          <small className="color-dark-grey color-dark-grey">
            by {file.modifierName}
          </small>
        </div>
      </td>
      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.users?.length > 3 ? "+" + (file.users?.length - 3) : ""}
        </span>
      </td>
      <td className="text-end">
        <i className="fas fa-star color-orange d-lg-none"></i>
        <Popup
          trigger={
            <button
              className="btn btn-transparent color-dark-grey shadow-none"
              type="button"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
          }
          position="left center"
          nested
        >
          {(close: Function) => (
            <div
              className="card bg-white p-0"
              style={{
                filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                borderRadius: "10px",
              }}
            >
              <div className="card-body px-0">
                <ul className={`list-unstyled mb-0 ${style.menu}`}>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        onUnfavorite(file.id);
                        close();
                      }}
                    >
                      <img
                        src="/icons/Star Off.png"
                        alt="move"
                        className="img-fluid"
                      />{" "}
                      Remove from favorites
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        previewImage();
                      }}
                    >
                      <i className="fas fa-play"></i> Preview
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popup>
      </td>
    </tr>
  );
};

export default FavoriteRow;
