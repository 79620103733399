import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFile } from "../../../Context/FileContext";
import {
  Comment as CommentModel,
  SharedData,
  Activity as ActivityModel,
} from "../../../Models/Document";
import { DownloadFile, Replace } from "../../../Services/Document";
import Activity from "../../Components/Activity";
import Comment from "../../Components/Comment";
import ReplaceDropFiles from "../../Components/ReplaceDropFiles";
import Previewer from "../../Components/Preview";
import UploadSVG from "../../../Icons/Upload";
import CloseButton from "../../Components/closeButton";
import Popup from "../../Components/popup";
import { IoArrowBackCircle } from "react-icons/io5";

interface props {
  comments: CommentModel[];
  file?: SharedData;
  id: string;
  activities: {
    value: ActivityModel[];
    loading: boolean;
    disabled: boolean;
    loadMore: Function;
    hasMore: boolean;
  };
  refresh: Function;
  breadcrumbs: any[];
  previewFile: string;
}

export default function File({
  comments,
  file,
  id,
  activities,
  refresh,
  breadcrumbs,
  previewFile,
}: props) {
  const { addFiles } = useFile();
  const [preview, setPreview] = useState(false);
  const [prevId, setPrevId] = useState("/shared");
  const [firstId, setFirstId] = useState(null);

  const onDownload = async () => {
    if (file) {
      await DownloadFile(file.lastVersion);
    }
  };

  const openView = () => {
    setPreview(true);
  };
  const uploadFile = async (file: File) => {
    addFiles([
      {
        id: id,
        file: file,
        uploadUrl: "replace",
        save: async () => {
          let value = {
            id: id,
            size: file.size,
            type: file.type,
            name: file.name,
          };
          await Replace(value as any);
          refresh();
        },
      },
    ]);
  };

  breadcrumbs.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });
  useEffect(() => {
    breadcrumbs.map((x, index) => {
      if (breadcrumbs.length === 1) {
        setPrevId(`/shared/folder/${x.id}`);
      }
      if (breadcrumbs.length === 2) {
        setPrevId(`/shared/folder/${x.id}`);
      }
      if (breadcrumbs.length === 3) {
        setPrevId(`/shared/folder/${x.id}`);
      }
    });

    if (breadcrumbs.length === 0) {
      setPrevId("/shared");
    }
  }, [breadcrumbs]);

  return (
    <>
      <section className="flex-fill overflow-auto px-2 py-3 d-flex d-lg-none flex-column justify-content-between">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-3">
            <li className="breadcrumb-item">
              <Link to={`${prevId}`}>
                <IoArrowBackCircle style={{ width: "30px", height: "30px" }} />
              </Link>
              <Link
                className="text-decoration-none color-dark-grey"
                to="/shared"
              >
                Shared With Me
              </Link>
            </li>
            {breadcrumbs.map((x) => (
              <li className="breadcrumb-item">
                <Link
                  className="text-decoration-none color-dark-grey"
                  to={"/shared/folder/" + x.id}
                >
                  {x.name}
                </Link>
              </li>
            ))}
            <li className="breadcrumb-item">{file?.name}</li>
          </ol>
        </nav>
        <div>
          <div className="card card-radius mb-2">
            <div className="card-body d-flex align-items-center flex-column">
              <div
                onDoubleClick={() => {
                  openView();
                }}
                style={{
                  width: "100%",
                }}
              >
                <Previewer
                  src={previewFile}
                  fileName={file?.name ?? ""}
                  autoPlay={false}
                />
              </div>
              <p className="mb-0 mt-3">{file?.name}</p>
              <p className="text-muted mb-0">{file?.size}</p>
            </div>
            <button
              className="w-100 btn-primary btn card-button-footer"
              style={{
                border: "1px solid #DCE4F5",
              }}
              onClick={() => {
                onDownload();
              }}
            >
              Download
            </button>
          </div>
          <label
            className="btn  w-100"
            style={{
              backgroundColor: "#F2F6FF",
              color: "#3A77F3",
              border: "1px solid #3A77F3",
            }}
          >
            Take photo
            <input
              type="file"
              id="imageFile"
              capture="user"
              accept="image/*"
              className="d-none"
              onChange={(event) => {
                if (event.currentTarget.files)
                  uploadFile(event.currentTarget.files[0]);
              }}
            />
          </label>
          <p className="text-center mb-2">OR</p>
          <label
            className="btn w-100 mb-3"
            style={{
              backgroundColor: "#F2F6FF",
              color: "#3A77F3",
              border: "1px solid #3A77F3",
            }}
          >
            Upload file from library
            <input
              type="file"
              id="file"
              className="d-none"
              onChange={(event) => {
                if (event.currentTarget.files)
                  uploadFile(event.currentTarget.files[0]);
              }}
            />
          </label>
        </div>
      </section>

      <section className="h-100 d-none d-lg-flex flex-column">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-3">
            <li className="breadcrumb-item">
              <Link to={`${prevId}`}>
                <IoArrowBackCircle style={{ width: "30px", height: "30px" }} />
              </Link>
              <Link
                className="text-decoration-none color-dark-grey"
                to="/shared"
              >
                Shared With Me
              </Link>
            </li>
            {breadcrumbs.map((x) => (
              <li className="breadcrumb-item">
                <Link
                  className="text-decoration-none color-dark-grey"
                  to={"/shared/folder/" + x.id}
                >
                  {x.name}
                </Link>
              </li>
            ))}
            <li className="breadcrumb-item">{file?.name}</li>
          </ol>
        </nav>
        <div className="row flex-fill overflow-auto">
          <div className="col-4 d-flex justify-content-between flex-column overflow-auto h-100">
            <div>
              <div className="card card-radius mb-2">
                <div className="card-body d-flex align-items-center flex-column">
                  <div
                    onDoubleClick={() => {
                      openView();
                    }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Previewer
                      src={previewFile}
                      fileName={file?.name ?? ""}
                      autoPlay={false}
                    />
                  </div>
                  <div>
                    <Popup
                      open={preview}
                      closeOnDocumentClick
                      className="modalPopup"
                      onClose={() => setPreview(false)}
                    >
                      <div
                        style={{
                          height: "90vh",
                          width: "80vw",
                        }}
                      >
                        <CloseButton
                          preview={preview}
                          setPreview={setPreview}
                        />
                        <Previewer
                          src={previewFile}
                          fileName={file?.name ?? ""}
                          autoPlay={true}
                        />
                      </div>
                    </Popup>
                  </div>
                  <p className="mb-0 fw-bold text-center mt-3">{file?.name}</p>
                  <p
                    className="text-muted mb-0"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {file?.size}
                  </p>
                </div>
                <button
                  className="w-100 btn-primary btn card-button-footer"
                  style={{
                    border: "1px solid #DCE4F5",
                  }}
                  onClick={() => {
                    onDownload();
                  }}
                >
                  Download
                </button>
              </div>
              {/* <ReplaceDropFiles
                callback={(file: File) => {
                  uploadFile(file);
                }}
              />
              <p className="text-center my-2">OR</p> */}
              <label
                className="btn w-100 mb-3 fw-bold"
                style={{
                  backgroundColor: "#F2F6FF",
                  color: "#3A77F3",
                  border: "1px solid #3A77F3",
                }}
              >
                <div>
                  <span className="svgIcon d-inline-block">
                    <UploadSVG color="#ACB8D1" />
                  </span>
                  <br />
                  Upload file
                  <input
                    type="file"
                    id="file"
                    className="d-none"
                    onChange={(event) => {
                      if (event.currentTarget.files)
                        uploadFile(event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="col-4 h-100">
            <Activity
              activities={activities.value}
              loading={activities.loading}
              disabled={activities.disabled}
              loadMore={activities.loadMore}
              hasMore={activities.hasMore}
            />
          </div>
          <div className="col-4 h-100">
            <Comment
              fileId={id}
              comments={comments}
              next={function () {
                throw new Error("Function not implemented.");
              }}
              hasMore={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}
