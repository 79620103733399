import { Link } from "react-router-dom";
import Subscription from "../../Platform/Settings/Subscription";

export interface props {
  images: string;
  type: "Lite" | "Pro" | "Pro+";
  price?: number;
  info?: number;
  yearlySum?: number;
  duration?: "monthly" | "yearly";
  storage: number;
  url: number;
}

export default function PriceCard({
  images,
  type,
  price,
  info,
  yearlySum,
  duration,
  url,
  storage,
}: props) {
  return (
    <div
      className="card text-center border-0 card-radius h-100 pb-3"
      style={{
        boxShadow: "0px 6px 30px rgb(0,0,0,0.1)",
      }}
    >
      <div className="card-header bg-transparent py-4">
        <h4 className="font-weight-extrabold">{type}</h4>
        <img
          src={images}
          alt="price"
          className="my-3"
          style={{
            height: "74px",
            width: "60px",
          }}
        />
        <h2 className="font-weight-bold">
          {price ? (
            <>
              <span className="font-size-2x">£{price}</span>/month
            </>
          ) : (
            <>Free</>
          )}
        </h2>
        {info && (
          <p className="mb-0 color-dark-grey">
            {duration === "monthly" ? (
              <>Subscription costs per year £{info}</>
            ) : (
              <>
                {info}% <span>savings against monthly subscription</span>
              </>
            )}
          </p>
        )}
        {yearlySum && (
          <p className="font-size color-dark-grey">
            {" Costs per year £" + yearlySum}
          </p>
        )}
        {duration === "yearly" && <p>save by billing yearly</p>}
      </div>
      <div className="card-body py-4">
        <div className="d-flex justify-content-center">
          <img src="/icons/cloud-computing.svg" alt="storage logo" />
          <span className="ms-1">
            <p className="font-weight-bold mb-0 text-start">{storage} GB</p>
            <p className="mb-0 text-small text-start color-dark-grey">
              STORAGE
            </p>
          </span>
        </div>
        <Link
          className="btn btn-radius-large btn-primary mt-4 font-weight-semibold px-4 py-2"
          to={`registration/${url}`}
          style={{
            fontSize: "24px",
          }}
        >
          Get started
        </Link>
      </div>
    </div>
  );
}
