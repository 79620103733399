import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../Platform/Components/Loading";
import { Logout } from "../Services/Authenticate";
import { Login as LoginService } from "../Services/Authenticate";
import Layout from "./layout";

export default function Invite() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    try {
      Logout().then(async () => {
        const search = new URLSearchParams(location.search);
        const userName = search.get("username");
        const pwd = search.get("pwd");
        if (userName && pwd) {
          var result = await LoginService({
            Email: userName,
            Password: pwd,
          });
          if (result.success) {
            history.push("/platform");
          } else {
            let resultType = result.type as any;
            if (resultType === "NewPassword") {
              history.push("/complete-password/" + userName + "/" + pwd);
            } else {
              history.push("/login");
            }
          }
        } else {
          history.push("/login");
        }
      });
    } catch {
      history.push("/login");
    }
  }, []);
  return (
    <Layout display={true}>
      <div className="d-flex justify-content-center align-items-center pt-5">
        <Loading />
      </div>
    </Layout>
  );
}
