import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { SearchData } from "../../Models/Document";
import Files from "../Components/TableRows/Table";
import SearchButton from "../Components/SearchButton";
import { SearchService } from "../../Services/Search";
import { SearchRow } from "../Components/TableRows";
import Layout from "./Layout";
import { useOverlay } from "../../Context/OverlayContext";
import { Preview } from "../../Services/Document";
import Popup from "../Components/popup";
import Previewer from "../Components/Preview";
import CloseButton from "../Components/closeButton";

export default function Search() {
  const { term } = useParams<{ term: string }>();
  const [files, setFiles] = useState<SearchData[]>([]);
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { setLoading: setLoadingOverlay } = useOverlay();

  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");

  const previewImage = (file: SearchData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoadingOverlay(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoadingOverlay(false));
  };

  const handleKeyDown = (event: any, file: SearchData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const clickFile = (file: SearchData) => {
    let root = "files";
    if (file.shared) {
      root = "shared";
    } else if (file.root === 1) {
      root = "archived";
    } else if (
      !!file.activeDate &&
      file.activeDate.getTime() > new Date().getTime()
    ) {
      root = "time-lock";
    }
    history.push(
      `/${root}/${file.type === "folder" ? "folder" : "file"}/${file.id}`
    );
  };
  useEffect(() => {
    fetchFiles();
  }, [term]);
  const fetchFiles = () => {
    setLoading(true);
    SearchService(term, token)
      .then((result) => {
        if (result) {
          if (!!token) {
            setFiles(files.concat(result.files ?? []));
          } else {
            setFiles(result.files ?? []);
          }
          setToken(result.token);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Layout>
      <div className="d-flex h-100">
        <div className="flex-fill mw-100">
          <section className="h-100 d-flex flex-column">
            <h4>Search Result</h4>
            <div className="d-block d-lg-none">
              <SearchButton />
            </div>
            {files.length === 0 && !loading ? (
              <div className="d-flex flex-column align-items-center">
                <img
                  src="/images/Person.svg"
                  alt="Person searching"
                  className="img-fluid"
                />
                <h4>No search result</h4>
                <span className="text-center font-weight-semibold">
                  {" "}
                  Sorry, there are no results for
                  <br />
                  this search, Please try another word or phrase.
                </span>
                <Link to="/platform" className="fw-bold mt-2">
                  Return to home
                </Link>
              </div>
            ) : (
              <Files
                border={files.length !== 0}
                hasMore={!!token}
                next={() => {
                  if (!!token) fetchFiles();
                }}
                loading={loading}
              >
                {files.map((file, i) => (
                  <SearchRow
                    fileData={file}
                    onClick={() => clickFile(file)}
                    key={file.id}
                    onMove={() => {}}
                    tabIndex={i}
                    handleKeyDown={(e) => handleKeyDown(e, file)}
                    previewImage={() => previewImage(file)}
                  />
                ))}
              </Files>
            )}
          </section>
        </div>
      </div>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => setPreview(false)}
      >
        <div
          style={{
            height: "60vh",
            width: "50vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
        </div>
      </Popup>
    </Layout>
  );
}
