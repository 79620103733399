import { API } from "aws-amplify";
import { search } from "../graphql/searchQueries";
import GetFileSize from "../Helper/FileSizeExtractor";
export async function SearchService(term: string, token?: string) {
  try {
    const value = await (API.graphql({
      query: search,
      variables: { name: "*" + term + "*", nextToken: token },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.files.items.map((x: any) => {
      if (!!x.file) {
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          activeDate: new Date(x.file.activeDate),
          users: x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
          modifierName: x.file.uploader?.name,
          shared: true,
        };
      } else {
        return {
          ...x,
          size: GetFileSize(x.size, x.type),
          modified: new Date(x.modified),          
          activeDate: new Date(x.activeDate),
          users: x.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
          modifierName: x.uploader?.name,
          shared: false,
        };
      }
    });
    return { files, token: value.data.files.nextToken };
  } catch (error: any) {
    return undefined;
  }
}
