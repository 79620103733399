import { API, Auth } from "aws-amplify";
import { batchMoveFile, moveFile } from "../graphql/mutations";

export async function archive(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: moveFile,
      variables: { id: id, parent: "1", user: user.username },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function unarchive(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: moveFile,
      variables: { id: id, parent: "0", user: user.username },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function batchUnarchive(fileId: string[]) {
  try {
    await (API.graphql({
      query: batchMoveFile,
      variables: {
        ids: fileId.map((x) => x).join(","),
        parent: "0",
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function batchArchive(fileId: string[]) {
  try {
    let user = await Auth.currentAuthenticatedUser();

    await (API.graphql({
      query: batchMoveFile,
      variables: {
        ids: fileId.map((x) => x).join(","),
        parent: "1",
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}
