import { useEffect, useRef, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FileModel, useFile } from "../../Context/FileContext";
import { useOverlay } from "../../Context/OverlayContext";
import { archive } from "../../Services/Archive";
import Progress from "./Progress";
import { DateTime } from "luxon";
import { UserContext } from "../../Context/UserContext";
import { ModelIDInput } from "../../API";

interface props {
  file: FileModel;
  type: "large" | "small" | "group";
  onDetailsClick?: Function;
}

interface CustomFile extends File {
  lastModifiedDate: Date;
}
const getSize = (size: number) => {
  if (size < 1024) {
    return size + " B";
  } else if (size < 1024 * 1024) {
    return Math.ceil(size / 1024) + " KB";
  } else {
    return Math.ceil(size / (1024 * 1024)) + " MB";
  }
};
export default function UploadSingle({ file, type, onDetailsClick }: props) {
  const [percent, setPercent] = useState<number>(file.percentage);
  const [uploading, setUploading] = useState(!(file as any).paused);
  const { removeFile, removeFileById } = useFile();
  const { setLoading } = useOverlay();
  const firstRender = useRef<boolean>(false);
  const history = useHistory();
  const listener = (temp: number, id: string) => {
    setPercent(temp);
  };

  useEffect(() => {
    if (!firstRender.current) {
      (file as any).onProgressEvent.addListener(listener);
      firstRender.current = true;
    }
    return () => {
      (file as any).onProgressEvent.removeListener(listener);
    };
  }, []);

  const handleRemoveClick = (id: string) => {
    removeFileById(id);
  };

  const changeTimeZone = async (localTime: string, userTimezone: string) => {
    const localDateTime = DateTime.fromFormat(
      localTime,
      "MM/dd/yyyy, h:mm:ss a"
    );
    const targetDateTime = localDateTime.setZone(userTimezone);
    const newDateTime = targetDateTime.toFormat("MM/dd/yyyy, hh:mm:ss a");

    if (newDateTime === "Invalid DateTime") {
      return localTime;
    }

    return newDateTime;
  };

  const sharedValue = useContext(UserContext);

  const AsyncModifiedDate = () => {
    const currentDateTime = new Date();

    const modified = currentDateTime.toLocaleString();

    let formattedDate = "";

    try {
      const date = new Date(modified);

      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "numeric",
        hour12: true,
      };

      const formatter = new Intl.DateTimeFormat("en-US", options);
      formattedDate = formatter.format(date);
    } catch {}

    const [modifiedDate, setModifiedDate] = useState("");
    const userTimezone = sharedValue.user?.locale || "";
    useEffect(() => {
      changeTimeZone(formattedDate, userTimezone)
        .then((result) => {
          setModifiedDate(result);
        })
        .catch((error) => {
          console.error("Error fetching modified date:", error);
          setModifiedDate("");
        });
    }, [modified]);

    return <small>{modifiedDate}</small>;
  };

  return (
    <div className="border card-radius mb-2 py-2 px-3">
      <div className="d-flex justify-content-between align-items-start">
        {percent < 100 ? (
          <>
            <p className="mb-0 font-weight-medium">
              {percent < 60 && "Uploading file"}
              {percent >= 60 && percent <= 90 && "Inkmarking file"}
              {percent > 90 && "Completing upload"}
              <span className="color-orange ms-1">{percent.toFixed(2)}%</span>
            </p>
            <span>
              {uploading ? (
                <button
                  className="btn btn-transparent shadow-none p-0"
                  onClick={() => {
                    setUploading(false);
                    file.pause();
                  }}
                >
                  <i className="far fa-pause-circle color-dark-grey"></i>
                </button>
              ) : (
                <button
                  className="btn btn-transparent shadow-none p-0"
                  onClick={() => {
                    setUploading(true);
                    file.resume();
                  }}
                >
                  <i className="far fa-play-circle color-dark-grey"></i>
                </button>
              )}
              <button
                className="btn btn-transparent shadow-none px-1 py-0"
                onClick={() => removeFile(file)}
              >
                <i className="far fa-times-circle color-red"></i>
              </button>
            </span>
          </>
        ) : (
          <>
            {/* <p className="mb-0 fw-bold">Inkmark Operation Completed</p> */}
            <p
              className="mb-0 fw-bold"
              style={{
                fontSize: "16px",
              }}
            >
              {(file.upload?.file as File)?.name ?? ""}
            </p>

            <span>
              <Link
                to={`/${file.url}/file/${file.id}`}
                className="btn btn-transparent shadow-none px-0 py-0"
              >
                <div onClick={() => handleRemoveClick(file.id)}>
                  <i className="fas fa-eye color-blue"></i>
                </div>
              </Link>
              {file.url === "files" && (
                <button
                  className="btn btn-transparent shadow-none px-1 py-0"
                  onClick={() => {
                    setLoading(true);
                    archive(file.id)
                      .then(() => {
                        history.push("/archived");
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }}
                >
                  <i className="fas fa-archive color-blue"></i>
                </button>
              )}
            </span>
          </>
        )}
      </div>
      <div
        className="d-flex justify-content-between"
        style={{
          fontSize: "12px",
        }}
      >
        <p className="mb-1 text-break color-dark-grey">
          <AsyncModifiedDate />
        </p>
        <p className="mb-1">
          {getSize((file.upload?.file as File)?.size ?? 0)}
        </p>
      </div>
      <div className="position-relative">
        {type === "large" && (
          <i
            className="fas fa-file color-orange position-absolute bottom-0"
            style={{
              right: `calc(100% - ${percent}%)`,
            }}
          ></i>
        )}
        <Progress
          now={percent}
          color={type === "large" ? "#FFEFE1" : "#FDA758"}
        />
        {type === "large" && (
          <i className="fas fa-lock color-orange position-absolute end-0 bottom-0"></i>
        )}
      </div>
      {type === "group" && (
        <div className="text-center border-top">
          <button
            className="btn btn-link shadow-none"
            onClick={() => onDetailsClick && onDetailsClick()}
          >
            View Details
          </button>
        </div>
      )}
    </div>
  );
}
