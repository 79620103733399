import { Link, useLocation } from "react-router-dom";
import Layout from "./layout";
import style from "./layout.module.css";
import Countdown from "react-countdown";
import { useState } from "react";
import { ResendEmail } from "../Services/Authenticate";
import {Email} from "../Models/Authentication";

const renderer = ({ minutes, seconds }: any) => {
  return (
    <span>
      {minutes}:{seconds}
    </span>
  );
};
export default function EmailConfirm() {
  const [allowSend, setAllowSend] = useState(false);
  const { state } = useLocation<Email>();
  const [error, setError] = useState<string>();

  return (
    <Layout display={true}>
      <div className={`d-flex flex-column justify-content-between justify-content-lg-center align-items-center h-100 ${style["max-vh-100"]}`}>
        <p className={`text-end ${style.contentWidth}  ${style["fs-18"]} color-dark-grey d-none d-lg-block`}>
          <small>Already have an account?</small>
          <Link className="btn btn-outline-primary ms-3 shadow-none px-3 py-1" to="/login">Login</Link>
        </p>
        <div className={`text-center ${style.form} `}>
          <img src="/images/email confirm.svg" alt="email confirmation " />
          <h1 className={`${style.emailHeader} my-3`}>You're almost there!</h1>
          <p className={style.emailInfo}>Check your email to confirm your account and get started</p>
          <p>
            Didn't receive a verification email?{" "}
            <span className="font-weight-bold">
              {!allowSend && (
                <Countdown
                  date={Date.now() + 60000}
                  renderer={renderer}
                  onComplete={() => {
                    setAllowSend(true);
                  }}
                />
              )}
            </span>
          </p>
          <button
            className={`btn ${allowSend ? "btn-primary" : "btn-disabled"
              } w-100 shadow-none`}
            onClick={() => {
              ResendEmail({
                email: state.email
              }).then(() => {
                setAllowSend(false)
              }).catch((error) => {
                var errorType = error.response.data;
                setError(errorType.description)
              })
            }}
          >
            Resend Email
          </button>
          <small className="text-danger">
            {
              error
            }
          </small>           
        </div>
        <div className="d-block d-lg-none w-100 mt-5">
          <p className="mb-0 text-center">Already have an account?</p>
          <Link
            to="/login"
            className="btn btn-outline-primary w-100 shadow-none"
          >
            Login
          </Link>
        </div>
      </div>
    </Layout>
  );
}
