import { Link } from "react-router-dom";
import style from "./sitefooter.module.css";
import Terms from "./terms";
import Privacy from "./privacy";
import Cookies from "./cookies";

export default function Sitefooter() {
  return (
    <footer>
      <div className={`layout-section ${style.mobile} d-flex  py-4 row mx-0`}>
        <div className=" col-12 col-md-6 d-flex flex-column align-items-center ps-0 align-items-md-start text-small text-center text-md-start">
          <img src="/images/Logo.svg" alt="Logo" className="mb-2" />
          <p>
            Store, protect and secure your <br className="d-none d-lg-block" />{" "}
            digital treasure with Inkmark
          </p>
          <p className="d-flex d-md-none justify-content-center align-items-center">
            {/* <iframe
              title="Certificate"
              src="https://registry.blockmarktech.com/certificates/d398b489-09f7-4481-a2f6-818182629669/widget/?tooltip_position=corner&theme=transparent"
              style={{
                border: "none",
                height: "70px",
                width: "70px",
                marginLeft: "auto",
              }}
            ></iframe> */}
            <img
              style={{ marginTop: "-5px", marginRight: "10px" }}
              src="/logos/cyber.png"
              alt="MSDUK logo"
              width="40px"
              height="40px"
            />
            <img
              src="/logos/msduk.png"
              alt="MSDUK logo"
              width="40px"
              className="me-1"
            />
          </p>
          <p className="d-block d-md-none">
            &copy; 2021 Inkmark, All rights reserved
          </p>
          <p className="font-weight-medium mobile_visible d-block d-md-none ">
            <Privacy /> | <Terms />
          </p>
          <span>
            <a
              href="https://www.facebook.com/profile.php?id=61551885250824"
              target="_blank"
              className="me-2"
            >
              <img src="/images/facebook.svg" alt="facebook logo" />
            </a>
            <a
              href="https://www.instagram.com/inkmark_operations/"
              target="_blank"
              className="me-2"
            >
              <img src="/images/instagram.svg" alt="instagram logo" />
            </a>
            <a
              href="https://www.linkedin.com/in/inkmark-operations-aa7230270/"
              target="_blank"
              className="me-2"
            >
              <img src="/images/linkedin.svg" alt="linkedin logo" />
            </a>
            <a
              href="https://x.com/inkmark67?s=21&t=wL55ukQ65souzkgA3KO6Bg"
              target="_blank"
              className="me-2"
            >
              <img src="/images/twitter.svg" alt="twitter logo" />
            </a>
            <a
              href="https://www.tiktok.com/@inkmark_operations"
              target="_blank"
              className="me-2"
            >
              <img src="/images/tiktok.svg" alt="tiktok logo" />
            </a>
          </span>
        </div>
        <div
          className={`col-12 col-md-6 justify-content-between d-none d-md-flex ps-0 ${style.small}`}
        >
          <div>
            <h4>Navigation</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="color-black text-decoration-none">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/faq" className="color-black text-decoration-none">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/About" className="color-black text-decoration-none">
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="color-black text-decoration-none"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>Legal</h4>
            <ul className="list-unstyled text-small">
              <li>
                <Terms />
              </li>
              <li>
                <Privacy />
              </li>
              <li>
                <Cookies />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p className="bg-dark-grey py-3 layout-section color-white d-none d-md-flex mb-0">
        Copyright {new Date().getFullYear()} Inkmark. All rights reserved.
        <div style={{ marginLeft: "auto" }}>
          <img
            style={{
              marginTop: "-15px",
              marginRight: "10px",
            }}
            src="/logos/cyber.png"
            alt="MSDUK logo"
            width="90px"
            height="100px"
          />
          <img
            style={{ marginTop: "-5px" }}
            src="/logos/msduk.png"
            alt="MSDUK logo"
            width="100px"
            height="100px"
          />
        </div>
      </p>
    </footer>
  );
}
