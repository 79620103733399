import { API, Auth } from "aws-amplify";
import moment from "moment";
import { removeShared, updateShared } from "../graphql/customMutations";
import {
  fileShared,
  fileSharedRefresh,
  rootShared,
  userFileShared,
  userShared,
  userSharedNext,
} from "../graphql/customQueries";
import {
  batchUnshare,
  deleteShared,
  sendInvite,
  transferOwnership,
} from "../graphql/mutations";
import { onSharedCreate } from "../graphql/subscriptions";
import GetFileSize from "../Helper/FileSizeExtractor";

export async function invite(emails: string, fileId: string) {
  try {
    await (API.graphql({
      query: sendInvite,
      variables: { emails: emails, file: fileId },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function getSharedUsers(fileId: string, token?: string) {
  try {
    const value = await (API.graphql({
      query: fileShared,
      variables: { id: fileId, token: token },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.fileShared.items.map((x: any) => ({
      ...x.user,
      view: x.view,
    }));
    return { files, token: value.data.fileShared.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function refreshUsers(fileId: string) {
  try {
    const value = await (API.graphql({
      query: fileSharedRefresh,
      variables: { id: fileId },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.fileShared.items.map((x: any) => ({
      ...x.user,
    }));
    return files;
  } catch (error: any) {
    return undefined;
  }
}

export async function rootFiles(token?: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    const value = await (API.graphql({
      query: rootShared,
      variables: { id: user.username, date: date, token: token },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.userShared.items.map((x: any) => ({
      ...x.file,
      size: GetFileSize(x.file.size, x.file.type),
      modified: new Date(x.file.modified),
      users: x.file.shared.items.map((x: any) => ({
        id: x.user.id,
        name: x.user.name,
        identityId: x.user.identityId,
      })),
      modifierName: x.file.uploader?.name,
      favorites: x.file.favorites.items.length > 0,
    }));

    return { files, token: value.data.userShared.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function files(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userShared,
      variables: {
        id: user.username,
        parent: id,
        file: id + "_" + user.username,
      },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.files.items.map((x: any) => ({
      ...x.file,
      size: GetFileSize(x.file.size, x.file.type),
      modified: new Date(x.file.modified),
      users: x.file.shared.items.map((x: any) => ({
        id: x.user.id,
        name: x.user.name,
        identityId: x.user.identityId,
      })),
      modifierName: x.file.uploader?.name,
      favorites: x.file.favorites.items.length > 0,
    }));

    const comments = value.data.comments.items.map((x: any) => ({
      ...x,
      createdAt: new Date(x.createdAt),
      userComment: x.userId === user.username,
      name: x.user.name,
    }));
    return {
      files,
      comments,
      token: value.data.files.nextToken,
      view: value.data.shared.view,
      parent: value.data.shared.parentDet,
      name: value.data.shared.file.name,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function GetNextFiles(id: string, token?: String) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userSharedNext,
      variables: { id: user.username, parent: id, token: token },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.files.items.map((x: any) => ({
      ...x.file,
      size: GetFileSize(x.file.size, x.file.type),
      modified: new Date(x.file.modified),
      users: x.file.shared.items.map((x: any) => ({
        id: x.user.id,
        name: x.user.name,
        identityId: x.user.identityId,
      })),
      favorites: x.file.favorites.items.length > 0,
    }));
    return { files, token: value.data.files.nextToken };
  } catch (error: any) {
    return undefined;
  }
}
export async function getFile(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userFileShared,
      variables: { id: id + "_" + user.username },
    }) as Promise<{
      data: any;
    }>);
    const data = value.data.files.file;

    return {
      comments: data.comments.items.map((x: any) => ({
        ...x,
        createdAt: new Date(x.createdAt),
        userComment: x.userId === user.username,
        name: x.user.name,
      })),
      file: {
        createdAt: new Date(data.created),
        id: data.id,
        modified: new Date(data.modified),
        name: data.name,
        parent: data.parent,
        size: GetFileSize(data.size, data.type),
        type: data.type,
        userId: data.userId,
        lastVersion: data.versions.items[0].id,
      },
      view: value.data.files.view,
      parent: value.data.files.parentDet,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function unshare(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: deleteShared,
      variables: {
        input: {
          id: id + "_" + user.username,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function batchUnshareService(id: string[]) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: batchUnshare,
      variables: {
        ids: id.map((x) => x + "_" + user.username).join(","),
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function remove(id: string, userId: string) {
  try {
    await (API.graphql({
      query: removeShared,
      variables: {
        input: {
          id: id + "_" + userId,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function transfer(id: string, user: string, password: string) {
  try {
    await (API.graphql({
      query: transferOwnership,
      variables: { id: id, user: user, password: password },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    let errors = error.errors.map((x: any) => x.message);
    throw errors;
  }
}

export async function allowActivityView(id: string, user: string) {
  await (API.graphql({
    query: updateShared,
    variables: {
      input: {
        id: id + "_" + user,
        view: true,
      },
    },
  }) as Promise<{
    data: any;
  }>);
}

export async function preventActivityView(id: string, user: string) {
  await (API.graphql({
    query: updateShared,
    variables: {
      input: {
        id: id + "_" + user,
        view: false,
      },
    },
  }) as Promise<{
    data: any;
  }>);
}

export async function parentListener(parent: string, callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  let variables = {};
  variables = {
    uploaderId: user.username,
    parent: parent,
  };
  return (
    API.graphql({
      query: onSharedCreate,
      variables: variables,
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onSharedCreate;
      let data = {
        ...temp,
        size: GetFileSize(temp.size, temp.type),
        modified: new Date(temp.modified),
        modifierName: temp.uploader.name,
      };
      callBack(data);
    },
  });
}
