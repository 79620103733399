import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import NavMenuMobile from "./navmenu-mobile";
import style from "./navmenu.module.css";

export default function NavMenu() {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <header
      className={`d-flex justify-content-end w-100 ${style["layout-section"]} layout-section py-3 bg-white`}
    >
      <nav
        className={`${style.nav} d-flex justify-content-between align-items-center`}
      >
        <Link to="/" className="color-black">
          <img src="/images/Logo.svg" alt="logo" />
        </Link>
        <input
          type="checkbox"
          id="checkbox_toggle"
          className={style.checkbox}
          onChange={(e: any) => setMobileMenu(e.target.value)}
        />
        <label htmlFor="checkbox_toggle" className={style.hamburger}>
          &#9776;
        </label>
        <ul
          className={`list-unstyled font-weight-semibold ${style.display_flex} w-50 justify-content-around  mb-0 ${style.desktop}`}
        >
          <li>
            <NavLink
              to="/about"
              activeClassName={style.active}
              className="color-black text-decoration-none"
            >
              About
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/faq"
              activeClassName={style.active}
              className="color-black text-decoration-none"
            >
              FAQ
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/pricing"
              activeClassName={style.active}
              className="color-black text-decoration-none"
            >
              Pricing
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              activeClassName={style.active}
              className="color-black text-decoration-none"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <ul
          className={`list-unstyled ${style.display_flex} font-weight-extrabold mb-0 ${style.desktop}`}
        >
          <li>
            <Link
              className="btn btn-radius-large btn-primary shadow-none px-4"
              to="/registration-pricing"
            >
              Get Started
            </Link>
          </li>
          <li className="ms-5 d-flex align-items-center">
            <NavLink
              to="/login"
              activeClassName={style.active}
              className="color-black text-decoration-none"
            >
              Login
            </NavLink>
          </li>
        </ul>
      </nav>
      {mobileMenu ? (
        <div
          className="position-fixed d-inline-flex"
          style={{
            left: "0px",
            width: "100%",
            top: 0,
            backdropFilter: "blur(20px)",
            height: "calc( 100vh - 15px)",
            zIndex: 99,
          }}
        >
          <div
            style={{
              width: "85%",
              height: "100%",
            }}
          >
            <NavMenuMobile />
          </div>
          <button
            className="btn btn-radius-large btn-transparent align-self-start"
            style={{
              width: "15%",
              height: "68px",
            }}
            onClick={(e) => setMobileMenu(false)}
          >
            <img src="/images/cancel.svg" alt="cancel button" />
          </button>
        </div>
      ) : (
        ""
      )}
    </header>
  );
}
