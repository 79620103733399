import { ElementRef, useRef } from "react";
import { Link } from "react-router-dom";
import Popup from "./Components/popup";
import { useAuth } from "../Context/UserContext";
import Modal from "./Components/Modal";
import Profile from "./Components/Profile";
import SearchButton from "./Components/SearchButton";
import StorageSummary from "./Components/StorageSummary";
import SideMenu from "./sideMenu";

export default function TopMenu() {
  type modal = ElementRef<typeof Modal>;
  const sideMenuRef = useRef<modal>(null);
  const { user } = useAuth();

  return (
    <>
      <div className="d-none d-lg-flex justify-content-between">
        <div className="px-0">
          <h1 className="text-break">{user?.storage}</h1>
        </div>
        <div
          style={{
            height: "54px",
            width: "calc( 50% - 177px)",
          }}
        >
          <SearchButton />
        </div>
        <div
          style={{
            minWidth: "286px",
          }}
          className="ms-3"
        >
          <Profile />
        </div>
      </div>
      <div className="d-flex d-lg-none justify-content-between">
        <Popup
          trigger={
            <button className="btn btn-transparent shadow-none py-1">
              <i className="fas fa-bars fa-lg"></i>
            </button>
          }
          className="sideMenu"
          modal
          closeOnDocumentClick={true}
          nested
        >
          <SideMenu />
        </Popup>
        <Link to="/platform" className="color-black">
          <img
            src="/images/Logo.svg"
            alt="logo"
            style={{
              height: "26px",
            }}
          />
        </Link>
        <button
          className="btn btn-transparent shadow-none py-1"
          data-toggle="modal"
          data-target="#modal"
          type="button"
          onClick={() => sideMenuRef.current?.open()}
        >
          <i className="fas fa-ellipsis-v fa-lg"></i>
        </button>
      </div>
      <Modal type="sideMenu" ref={sideMenuRef}>
        <StorageSummary />
      </Modal>
    </>
  );
}
