import { Link } from "react-router-dom";
import Layout from "./../layout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import style from "./../layout.module.css";
import PaymentForm from "./form";
import { useAuth } from "../../Context/UserContext";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PRIMARY_KEY ?? ""
);

const plans = [
  {
    id: "1",
    price: 7.99,
    duration: "month",
    name: "Pro",
    size: 100,
    icon: "pro",
  },
  {
    id: "2",
    price: 9.99,
    duration: "month",
    name: "Pro+",
    size: 250,
    icon: "proplus",
  },
  {
    id: "3",
    price: 6.99,
    duration: "year",
    name: "Pro",
    size: 100,
    icon: "pro",
  },
  {
    id: "4",
    price: 8.99,
    duration: "year",
    name: "Pro+",
    size: 250,
    icon: "proplus",
  },
];

export default function Index() {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const { user, refresh } = useAuth();
  const [plan, setPlan] = useState<any>({});

  useEffect(() => {
    setPlan(plans.find((plan) => user?.priceId === plan.id));
  }, [user]);
  return (
    <Layout display={true}>
      <div
        className={`d-flex flex-column justify-content-start
         justify-content-lg-center align-items-center h-100 ${style.payment}`}
      >
        <div
          className={`d-flex mb-5 ${style.paymentDetails} align-items-center justify-content-between`}
        >
          <img
            src={`/icons/${plan?.icon}.svg`}
            className="img-fluid"
            alt={plan?.name}
          />
          <div className="border-end border-start px-3 d-flex flex-column text-break">
            <h4 className={style.paymentType}>{plan?.name}</h4>
            <p className="fw-bold mb-0">
              <span className={style.paymentPrice}>£{plan?.price}</span>/
              {plan?.duration}
            </p>
          </div>
          <div className="d-flex align-items-center flex-column flex-md-row">
            <img src="/icons/cloud-computing.svg" alt="storage logo" />
            <span className="ms-1 ">
              <p
                className={`font-weight-bold mb-0 text-start ${style.storageSize}`}
              >
                {plan?.size} GB
              </p>
              <p className="mb-0 text-small text-start color-dark-grey">
                STORAGE
              </p>
            </span>
          </div>
        </div>
        {!paymentSuccess ? (
          <Elements stripe={stripePromise}>
            <PaymentForm
              onComplete={() => {
                Auth.currentAuthenticatedUser().then((user) => {
                  Auth.updateUserAttributes(user, {
                    "custom:completedStripeSetup": "1",
                  }).then(() => {
                    refresh && refresh(() => setPaymentSuccess(true));
                  });
                });
              }}
            />
          </Elements>
        ) : (
          <div
            className={`d-flex flex-column align-items-center ${style.paymentSuccess}`}
          >
            <img src="/icons/credit-card.svg" alt="payment success" />
            <p
              className={`${style.paymentSuccessText} text-center mt-3 text-center`}
            >
              Payment successful. <br />
              You are subscribed to Pro plan.
            </p>
            <Link
              to="/platform"
              className="btn btn-primary w-100 mt-3 d-inline-block"
            >
              Continue to Inkmark
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
}
