import { useEffect, useState } from "react";
import style from "./Setting.module.css";
import { Link } from "react-router-dom";
import { GetSubscription } from "../../Services/Profile";
import { useAuth } from "../../Context/UserContext";
import Status from "./Subscriptions/Status";
import Cancel from "./Subscriptions/Cancel";
import Plans from "./Subscriptions/Plans";
import Payment from "./Subscriptions/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useOverlay } from "../../Context/OverlayContext";
import UpdateCard from "./Subscriptions/UpdateCard";
import Messages from "../Components/Messages";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PRIMARY_KEY ?? ""
);

export default function Subscription() {
  const { setLoading } = useOverlay();
  const [index, setIndex] = useState(0);
  const [refreshPlan, setRefreshPlan] = useState(false);
  const [message, setMessage] = useState("");
  const [state, setState] = useState({
    name: "",
    size: 0,
    loading: true,
    id: "0",
  });
  const [subscriptionState, setSubscrptionState] = useState<{
    card: string;
    dateEnd: string;
    status: string;
    price: number;
  }>();
  const [card, setCard] = useState<string>();

  const [nextPlan, setNextPlan] = useState<{
    clientSecret: string;
    subscriptionId: string;
  }>();

  const details = (type: string, price?: number, info?: number) => {
    // console.log("state", state);
    // console.log("type", type, "price", price, "info", info);
    return `You are about to upgrade from ${state.name} plan, do you wish to continue?`;
  };

  const { updateCount } = useAuth();
  const fetchSubscription = () => {
    setLoading(true);

    GetSubscription()
      .then((value) => {
        // console.log("value", value);
        setState({
          name: value?.plan.name ?? "free",
          size: value?.plan.size ?? 0,
          loading: false,
          id: value?.plan.id,
        });
        setSubscrptionState(value?.subscription);
        setCard(value?.card);
        // setMessage(
        //   `You are about to subscribe to ${state.name} plan, do you wish to continue?`
        // );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setTimeout(fetchSubscription, 5000);
  }, [refreshPlan]);

  useEffect(() => {
    fetchSubscription();
  }, []);

  const planMessage = (state: string) => {
    switch (state) {
      case "0":
        return <div>Payment successful. you are now on free plan</div>;
      case "1":
        return <div>Payment successful. you are now subcribed to pro plan</div>;
      case "2":
        return (
          <div>Payment successful. you are now subcribed to premium plan</div>
        );
      case "3":
        return <div>Payment successful. you are now subcribed to pro plan</div>;
      case "4":
        return (
          <div>Payment successful. you are now subcribed to premium plan</div>
        );
      default:
        return <div>Payment successful</div>;
    }
  };
  if (index === 0) {
    return (
      <Status
        id={state.id}
        name={state.name}
        size={state.size}
        changePlan={() => setIndex(2)}
        cancelPlan={() => setIndex(1)}
        subscription={subscriptionState}
        updateCard={() => setIndex(5)}
        card={card}
      />
    );
  }
  if (index === 1)
    return (
      <Cancel
        keep={() => setIndex(0)}
        onSuccess={() => {
          setIndex(0);
          updateCount && updateCount();
          fetchSubscription();
        }}
        onReload={() => {
          setRefreshPlan(!refreshPlan);
        }}
      />
    );
  if (index === 2)
    return (
      <Plans
        id={state.id}
        onSubscribe={(result: any) => {
          if (result.updated) {
            fetchSubscription();
            setIndex(0);
          } else {
            setNextPlan(result);
            setIndex(3);
          }
        }}
        onCancel={() => {
          setIndex(1);
        }}
        onReload={() => {
          setRefreshPlan(!refreshPlan);
        }}
      />
    );
  if (index === 3)
    return (
      <div className={style.payment}>
        <h4>Add credit information to start billing</h4>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: nextPlan?.clientSecret,
          }}
        >
          <Payment
            cancel={() => {
              setIndex(0);
              fetchSubscription();
            }}
            clientSecret={nextPlan?.clientSecret}
            details={details}
            message={message}
            onSuccess={(e: any) => {
              setIndex(4);
              updateCount && updateCount();
              fetchSubscription();
            }}
          />
          <div
            style={{
              backgroundColor: "transparent",
              display: "inline-block",
              padding: "10px",
            }}
          >
            {" "}
            <img
              style={{ width: "200px", backgroundColor: "transparent" }}
              src="/icons/stripe.jpeg"
              alt="stripe"
              className="mt-3 mt-xl-5 w-xl-20px"
            />
          </div>

          <p className="d-none d-xl-block">
            Powered by stripe payment solution. Inkmark does not hold
            confidential any information.
          </p>
        </Elements>
      </div>
    );
  if (index === 4)
    return (
      <div
        className={`${style.subscription}  d-flex flex-column align-items-center`}
      >
        <img src="/icons/credit-card.svg" alt="payment success" />
        <h4 className={`${style["fs-24"]} text-center mt-3`}>
          {planMessage(state.id)}
          {/* Payment successful. you are Now subcribed.1111 */}
          <br />
          <Link
            to="/platform"
            className="text-decoration-none mt-3 d-inline-block"
          >
            Back to home
          </Link>
        </h4>
      </div>
    );
  if (index === 5)
    return (
      <Elements stripe={stripePromise}>
        <UpdateCard
          onComplete={() => {
            setIndex(0);
            fetchSubscription();
          }}
        />

        <img
          style={{ width: "200px" }}
          src="/icons/stripe.jpeg"
          alt="stripe"
          className="mt-3 mt-xl-5"
        />
        <p className="d-none d-xl-block">
          Powered by stripe payment solution. Inkmark does not hold confidential
          any information...
        </p>
      </Elements>
    );
  else return <></>;
}
