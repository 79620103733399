import Dropzone from "react-dropzone";
import UploadSVG from "../../../Icons/Upload";
import { Create } from "../../../Services/Time-Lock";
import { Create as CreateModel } from "../../../Models/Document";

import style from "../timeLock.module.css";
import { useOverlay } from "../../../Context/OverlayContext";
import { useRef } from "react";

export default function Empty() {
  const { uploadFile } = useOverlay();
  const ref = useRef<HTMLInputElement>(null);
  const createFile = async (id: string, file: File) => {
    let value: CreateModel = {
      id: id,
      name: file.name,
      size: file.size,
      parent: "0",
      type: file.type,
      uploadUrl: "time-lock",
    };
    await Create(value);
  };
  return (
    <>
      <h1 className={`text-center ${style.emptyHeader}`}>
        Upload & Time-lock your files or folders
      </h1>
      <p
        className={`text-center color-dark-grey font-weight-semibold ${style.emptyInfo}`}
      >
        Add email address to an uploaded file and set time-lock frequency
      </p>
      <div
        className="w-100 d-flex justify-content-center d-lg-none flex-column align-items-center m-auto p-4"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23656E87FF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
          borderRadius: "10px",
          backgroundColor: "#F9FBFF",
        }}
      >
        <img src="/images/Final.svg" alt="Inkmark Pen" className="w-25" />

        <div className="row w-100 mb-3 mt-4 ">
          <div className="col-6 col-lg-12 text-center ps-0">
            <button
              onClick={() => {
                if (ref.current) {
                  ref.current.accept = "*";
                  ref.current.click();
                }
              }}
              className="btn btn-primary btn-radius-10 d-flex d-lg-inline h-100 align-items-center justify-content-center fw-bold"
            >
              <img src="/icons/upload(3).svg" alt="Upload" className="me-2" />
              Upload file
            </button>
            <input
              type="file"
              id="file"
              ref={ref}
              className="d-none"
              onChange={(event) => {
                if (event.target.files)
                  uploadFile(
                    Array.from(event.target.files).map((x) => ({
                      url: "time-lock",
                      file: x,
                      save: (id: string) => createFile(id, x),
                    })),
                    "0",
                    true,
                    "time-lock"
                  );
              }}
              multiple
            />
          </div>
          <div className="col-6 d-block d-lg-none pe-0">
            <label className="btn btn-primary btn-radius-10 d-flex h-100 align-items-center justify-content-center fw-bold">
              <i className="fas fa-camera color-white fa-x me-2"></i>
              Take photo
              <input
                type="file"
                id="imageFile"
                capture="user"
                accept="image/*"
                className="d-none"
                onChange={(event) => {
                  if (event.target.files)
                    uploadFile(
                      Array.from(event.target.files).map((x) => ({
                        url: "time-lock",
                        file: x,
                        save: (id: string) => createFile(id, x),
                      })),
                      "0",
                      true,
                      "time-lock"
                    );
                }}
                multiple
              />
            </label>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className={style.oneFifth}>
            <button
              className="btn d-inline-block p-0"
              onClick={() => {
                if (ref.current) {
                  ref.current.accept = "image/*";
                  ref.current.click();
                }
              }}
            >
              <img src="/logos/image.svg" alt="others" className="img-fluid" />
            </button>
          </div>
          <div className={style.oneFifth}>
            <button
              className="btn d-inline-block p-0"
              onClick={() => {
                if (ref.current) {
                  ref.current.accept = "audio/*";
                  ref.current.click();
                }
              }}
            >
              <img src="/logos/audio.svg" alt="audio" className="img-fluid" />
            </button>
          </div>
          <div className={style.oneFifth}>
            <button
              className="btn d-inline-block p-0"
              onClick={() => {
                if (ref.current) {
                  ref.current.accept = "video/*";
                  ref.current.click();
                }
              }}
            >
              <img src="/logos/media.svg" alt="video" className="img-fluid" />
            </button>
          </div>
          <div className={style.oneFifth}>
            <button
              className="btn d-inline-block p-0"
              onClick={() => {
                if (ref.current) {
                  ref.current.accept =
                    "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation";
                  ref.current.click();
                }
              }}
            >
              <img
                src="/logos/document.svg"
                alt="document"
                className="img-fluid"
              />
            </button>
          </div>
          <div className={style.oneFifth}>
            <button
              className="btn d-inline-block p-0"
              onClick={() => {
                if (ref.current) {
                  ref.current.accept = "*";
                  ref.current.click();
                }
              }}
            >
              <img src="/logos/other.svg" alt="others" className="img-fluid" />
            </button>
          </div>
        </div>
      </div>

      <Dropzone
        onDrop={(acceptedFiles) =>
          uploadFile(
            acceptedFiles.map((x) => ({
              url: "time-lock",
              file: x,
              save: (id: string) => createFile(id, x),
            })),
            "0",
            true,
            "time-lock"
          )
        }
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="w-75 d-none justify-content-center d-lg-flex flex-column align-items-center m-auto pt-5 pb-4"
            style={{
              maxWidth: "740px",
              backgroundColor: "#F9FBFF",
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23656E87FF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
            }}
          >
            <img src="/images/Final.svg" alt="Inkmark Pen" className="mt-5" />
            <p className={`my-3 ${style.emptyDropText}`}>
              Drop files here to upload
            </p>

            <button
              className="btn btn-primary btn-radius-10 mb-5 fw-bold px-4"
              onClick={() => {
                if (ref.current) {
                  ref.current.accept = "*";
                  ref.current.click();
                }
              }}
            >
              <span className="svgIcon d-inline-block">
                <UploadSVG color="white" />
              </span>
              Upload file
            </button>
            <input
              type="file"
              id="file"
              ref={ref}
              className="d-none"
              onChange={(event) => {
                if (event.target.files)
                  uploadFile(
                    Array.from(event.target.files).map((x) => ({
                      url: "time-lock",
                      file: x,
                      save: (id: string) => createFile(id, x),
                    })),
                    "0",
                    true,
                    "time-lock"
                  );
              }}
              multiple
            />
            <div className="w-100 row justify-content-between">
              <div className={style.oneFifth}>
                <button
                  className="btn d-inline-block p-0"
                  onClick={() => {
                    if (ref.current) {
                      ref.current.accept = "*";
                      ref.current.click();
                    }
                  }}
                >
                  <img
                    src="/logos/other.svg"
                    alt="others"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className={style.oneFifth}>
                <button
                  className="btn d-inline-block p-0"
                  onClick={() => {
                    if (ref.current) {
                      ref.current.accept = "video/*";
                      ref.current.click();
                    }
                  }}
                >
                  <img
                    src="/logos/media.svg"
                    alt="media"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className={style.oneFifth}>
                <button
                  className="btn d-inline-block p-0"
                  onClick={() => {
                    if (ref.current) {
                      ref.current.accept =
                        "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation";
                      ref.current.click();
                    }
                  }}
                >
                  <img
                    src="/logos/document.svg"
                    alt="document"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className={style.oneFifth}>
                <button
                  className="btn d-inline-block p-0"
                  onClick={() => {
                    if (ref.current) {
                      ref.current.accept = "image/*";
                      ref.current.click();
                    }
                  }}
                >
                  <img
                    src="/logos/image.svg"
                    alt="imageLogo"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className={style.oneFifth}>
                <button
                  className="btn d-inline-block p-0"
                  onClick={() => {
                    if (ref.current) {
                      ref.current.accept = "audio/*";
                      ref.current.click();
                    }
                  }}
                >
                  <img
                    src="/logos/audio.svg"
                    alt="audio"
                    className="img-fluid"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
}
