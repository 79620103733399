import InfiniteScroll from "react-infinite-scroller";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Context/UserContext";
import Avatar from "./avatar";

export default function Messages() {
  const { messages, removeMessage, loadMoreMessages, hasMoreMessages } =
    useAuth();

  return (
    <div className="h-100">
      <InfiniteScroll
        loader={<div className="text-center">Loading...</div>}
        useWindow={false}
        element="ul"
        loadMore={() => loadMoreMessages && loadMoreMessages()}
        initialLoad={true}
        hasMore={hasMoreMessages}
        className="list-unstyled mb-0"
      >
        {messages?.map((message) => (
          <li
            className="d-flex justify-content-between align-items-center border-top py-2"
            key={message.id}
          >
            <div>
              <Avatar name={message.userName} image={message.userId} />
            </div>
            <p
              onClick={() => removeMessage && removeMessage(message.id)}
              className="mb-0 ms-2 text-break"
            >
              {message.userName} messaged on.
              <NavLink
                to={`/files/file/${message.fileId}`}
                className="text-decoration-none mx-1"
              >
                {message.fileName}
              </NavLink>
              "{message.content}" to view
            </p>
            <button
              className="btn btn-transparent py-0 shadow-none"
              type="button"
              onClick={() => removeMessage && removeMessage(message.id)}
            >
              <i className="fas fa-times-circle text color-red"></i>
            </button>
          </li>
        ))}
        {}
      </InfiniteScroll>
      {!messages?.length && (
        <div style={{ textAlign: "center", paddingBottom: "10px" }}>
          No Messages
        </div>
      )}
    </div>
  );
}
