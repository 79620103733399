import React, { useState, useEffect } from "react";
import { useFile } from "../../Context/FileContext";
import DropFiles from "../Components/Dropfiles";
import Profile from "../Components/Profile";
import StorageSummary from "../Components/StorageSummary";
import UploadGroup from "../Components/UploadGroup";
import UploadSingle from "../Components/UploadSingle";
import SideMenu from "../sideMenu";
import style from "./Home.module.css";
import TopMenu from "./TopMenu";
import { Create as CreateModel } from "../../Models/Document";
import { Create } from "../../Services/Document";
import Loading from "../Components/Loading";

type Props = {
  children?: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const createFile = async (id: string, file: File) => {
    let value: CreateModel = {
      id: id,
      name: file.name,
      parent: "0",
      type: file.type,
      size: file.size,
      uploadUrl: "file",
    };
    await Create(value);
  };

  const { files, removeFile, removeFiles, removeFileById } = useFile();

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    const fetchData = async () => {
      // Simulate a delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const removedFiles = (file: any) => {
    setTimeout(() => removeFiles(file), 60000);
  };

  return (
    <>
      <div className={`d-none d-lg-block ${style.sideMenu} vh-100 border`}>
        <SideMenu />
      </div>
      <div className="position-relative overflow-auto h-100">
        <div className={`border-end d-flex flex-column ${style.mainElement}`}>
          <header
            className={`${style.topMenu} py-2 py-lg-3 px-lg-4 ${style["border-bottom"]}`}
          >
            <TopMenu />
          </header>
          <main
            className={`${style.center} pb-lg-2 px-lg-4 px-3 flex-fill mt-3 mt-lg-0`}
          >
            {children}
          </main>
        </div>
        <aside
          className={`px-lg-4 pb-3 d-none d-lg-flex border-start flex-column position-absolute top-0 end-0 ${style.aside}`}
        >
          <div
            style={{
              height: "120px",
            }}
            className="pt-3"
          >
            <Profile />
          </div>
          {files.length > 0 && <h4>Activity Log</h4>}

          {files.length > 0 ? (
            <>
              {removedFiles(files)}
              {files.map((file) => {
                return <UploadSingle file={file} type="small" key={file.id} />;
              })}
            </>
          ) : (
            <>
              <StorageSummary />
            </>
          )}
        </aside>
      </div>
    </>
  );
}
