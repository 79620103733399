import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../Context/UserContext";

export default function AdminRoute({ children, ...rest }: any) {
    const { isLoading, admin } = useAuth()
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isLoading) {
                    return <div>Loading</div>
                }
                else if (admin) {                    
                    return children
                } else {
                    return <Redirect
                        to={{
                            pathname: "/admin/login",
                            state: { from: location }
                        }}
                    />
                }
            }}
        />
    );
}