import ChangePassword from "../Platform/Settings/ChangePassword";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const moveFile = /* GraphQL */ `
  mutation MoveFile($id: ID!, $parent: ID!) {
    moveFile(id: $id, parent: $parent) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const sendTimeLockInvite = /* GraphQL */ `
  mutation SendTimeLockInvite(
    $email: String!
    $file: ID!
    $relationship: String
    $name: String
  ) {
    sendTimeLockInvite(
      email: $email
      file: $file
      relationship: $relationship
      name: $name
    ) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const transferOwnership = /* GraphQL */ `
  mutation TransferOwnership($id: ID!, $user: ID!, $password: String!) {
    transferOwnership(id: $id, user: $user, password: $password)
  }
`;
export const updateIdentityId = /* GraphQL */ `
  mutation UpdateIdentityId($id: ID) {
    updateIdentityId(id: $id)
  }
`;
export const clearNotifications = /* GraphQL */ `
  mutation ClearNotifications($id: ID!) {
    clearNotifications(id: $id)
  }
`;
export const clearActivityNotifications = /* GraphQL */ `
  mutation ClearActivityNotifications($id: ID!) {
    clearActivityNotifications(id: $id)
  }
`;
export const renameFile = /* GraphQL */ `
  mutation RenameFile($id: ID!, $name: String!) {
    renameFile(id: $id, name: $name)
  }
`;
export const preUploadFile = /* GraphQL */ `
  mutation PreUploadFile($files: String!, $parent: String, $locked: Boolean) {
    preUploadFile(files: $files, parent: $parent, locked: $locked) {
      id
      status
      type
      similarId
      __typename
    }
  }
`;
export const createStripeSubscription = /* GraphQL */ `
  mutation CreateStripeSubscription($planId: ID!) {
    createStripeSubscription(planId: $planId) {
      subscriptionId
      clientSecret
      updated
      size
      price
      __typename
    }
  }
`;
export const deleteStripeSubscription = /* GraphQL */ `
  mutation DeleteStripeSubscription($reason: String) {
    deleteStripeSubscription(reason: $reason)
  }
`;
export const multipartUpload = /* GraphQL */ `
  mutation MultipartUpload(
    $id: String!
    $uploadId: String
    $type: String
    $part: Int
    $sha: String
    $allParts: String
  ) {
    multipartUpload(
      id: $id
      uploadId: $uploadId
      type: $type
      part: $part
      sha: $sha
      allParts: $allParts
    ) {
      parts {
        partNumber
        signedUrl
        __typename
      }
      uploadId
      signedUrl
      fileKey
      __typename
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard($id: String!) {
    updateCard(id: $id)
  }
`;
export const completePayment = /* GraphQL */ `
  mutation CompletePayment {
    completePayment
  }
`;
export const batchRemoveFavorites = /* GraphQL */ `
  mutation BatchRemoveFavorites($ids: String!) {
    batchRemoveFavorites(ids: $ids)
  }
`;
export const batchUnshare = /* GraphQL */ `
  mutation BatchUnshare($ids: String!) {
    batchUnshare(ids: $ids)
  }
`;
export const batchMoveFile = /* GraphQL */ `
  mutation BatchMoveFile($ids: String!, $parent: ID!) {
    batchMoveFile(ids: $ids, parent: $parent)
  }
`;
export const batchLockFile = /* GraphQL */ `
  mutation BatchLockFile($ids: String!, $date: AWSDate!) {
    batchLockFile(ids: $ids, date: $date)
  }
`;
export const sendMailToInactiveUsers = /* GraphQL */ `
  mutation SendMailToInactiveUsers($temp: String) {
    sendMailToInactiveUsers(temp: $temp)
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser($enable: Boolean!, $user_id: String!) {
    updateCognitoUser(enable: $enable, user_id: $user_id)
  }
`;
export const createFileCronJob = /* GraphQL */ `
  mutation CreateFileCronJob(
    $input: CreateFileCronJobInput!
    $condition: ModelFileCronJobConditionInput
  ) {
    createFileCronJob(input: $input, condition: $condition) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFileCronJob = /* GraphQL */ `
  mutation UpdateFileCronJob(
    $input: UpdateFileCronJobInput!
    $condition: ModelFileCronJobConditionInput
  ) {
    updateFileCronJob(input: $input, condition: $condition) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFileCronJob = /* GraphQL */ `
  mutation DeleteFileCronJob(
    $input: DeleteFileCronJobInput!
    $condition: ModelFileCronJobConditionInput
  ) {
    deleteFileCronJob(input: $input, condition: $condition) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    createVersion(input: $input, condition: $condition) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const createShared = /* GraphQL */ `
  mutation CreateShared(
    $input: CreateSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    createShared(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const updateShared = /* GraphQL */ `
  mutation UpdateShared(
    $input: UpdateSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    updateShared(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const deleteShared = /* GraphQL */ `
  mutation DeleteShared(
    $input: DeleteSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    deleteShared(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const createTimelockShared = /* GraphQL */ `
  mutation CreateTimelockShared(
    $input: CreateTimelockSharedInput!
    $condition: ModelTimelockSharedConditionInput
  ) {
    createTimelockShared(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const updateTimelockShared = /* GraphQL */ `
  mutation UpdateTimelockShared(
    $input: UpdateTimelockSharedInput!
    $condition: ModelTimelockSharedConditionInput
  ) {
    updateTimelockShared(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const deleteTimelockShared = /* GraphQL */ `
  mutation DeleteTimelockShared(
    $input: DeleteTimelockSharedInput!
    $condition: ModelTimelockSharedConditionInput
  ) {
    deleteTimelockShared(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCancellation = /* GraphQL */ `
  mutation CreateCancellation(
    $input: CreateCancellationInput!
    $condition: ModelCancellationConditionInput
  ) {
    createCancellation(input: $input, condition: $condition) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCancellation = /* GraphQL */ `
  mutation UpdateCancellation(
    $input: UpdateCancellationInput!
    $condition: ModelCancellationConditionInput
  ) {
    updateCancellation(input: $input, condition: $condition) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCancellation = /* GraphQL */ `
  mutation DeleteCancellation(
    $input: DeleteCancellationInput!
    $condition: ModelCancellationConditionInput
  ) {
    deleteCancellation(input: $input, condition: $condition) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const addFile = /* GraphQL */ `
  mutation AddFile(
    $id: ID!
    $name: String!
    $parent: String!
    $size: String!
    $type: String!
    $checksum: String
    $userId: String
    $versionId: String
    $date: AWSDate
  ) {
    addFile(
      id: $id
      name: $name
      parent: $parent
      size: $size
      type: $type
      checksum: $checksum
      userId: $userId
      versionId: $versionId
      date: $date
    ) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const getInTouchMail = /* GraphQL */ `
  mutation GetInTouchMail(
    $name: String!
    $subject: String!
    $message: String!
    $from: String!
  ) {
    getInTouchMail(
      name: $name
      subject: $subject
      message: $message
      from: $from
    )
  }
`;

export const accountClosure = /* GraphQL */ `
  mutation AccountClosure(
    $name: String!
    $subject: String!
    $message: String!
    $from: String!
  ) {
    accountClosure(
      name: $name
      subject: $subject
      message: $message
      from: $from
    )
  }
`;

export const verifyRecaptcha = /*Graphql */ `
mutation VerifyRecaptcha(
  $recaptchaToken:String!
){
  verifyRecaptcha(
    recaptchaToken:$recaptchaToken
  )
}
`;

export const cancleNotification = /*Graphql*/ `
mutation CancleNotification(
  $userId:String!
  $activityId:String!
){
  cancleNotification(
    userId:$userId
    activityId:$activityId
  )
}
`;
export const changePasswordMail = /*Graphql */ `
mutation ChangePasswordMail(
  $name:String!
  $email:String!
){
  changePasswordMail(
    name: $name
    email: $email
  )
}
`;
export const replaceFile = /* GraphQL */ `
  mutation ReplaceFile(
    $id: ID!
    $size: String!
    $type: String!
    $name: String!
    $checksum: String!
    $userId: String!
    $versionId: String!
  ) {
    replaceFile(
      id: $id
      size: $size
      type: $type
      name: $name
      checksum: $checksum
      userId: $userId
      versionId: $versionId
    ) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const addFavoriteFile = /* GraphQL */ `
  mutation AddFavoriteFile(
    $fileId: ID!
    $name: String!
    $parent: String!
    $size: String!
    $type: String!
    $checksum: String
    $userId: String
    $versionId: String
    $date: AWSDate
  ) {
    addFavoriteFile(
      fileId: $fileId
      name: $name
      parent: $parent
      size: $size
      type: $type
      checksum: $checksum
      userId: $userId
      versionId: $versionId
      date: $date
    ) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const sendInvite = /* GraphQL */ `
  mutation SendInvite($emails: String!, $file: ID!) {
    sendInvite(emails: $emails, file: $file) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const signUp = /* GraphQL */ `
  mutation SignUp(
    $email: String!
    $password: String!
    $fullName: String!
    $PriceId: String
  ) {
    signUp(
      email: $email
      password: $password
      fullName: $fullName
      PriceId: $PriceId
    )
  }
`;
