import style from "../../styles/files.module.css";
import FileTable from "../../Components/TableRows/Table";
import MobileUpload from "../../Components/MobileUpload";
import { FileData } from "../../../Models/Document";
import { TimelockRow } from "../../Components/TableRows";
import { useHistory } from "react-router-dom";
import {
  batchUnlockService,
  Create,
  GetRoot,
} from "../../../Services/Time-Lock";
import { Create as CreateModel } from "../../../Models/Document";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import DesktopUpload from "../../Components/DesktopUpload";
import { useOverlay } from "../../../Context/OverlayContext";
import {
  batchLockService,
  DownloadFiles,
  Preview,
} from "../../../Services/Document";
import Popup from "../../Components/popup";
import Previewer from "../../Components/Preview";
import { FileWithChecked } from "../../Home/home";
import { useAlert } from "react-alert";
import CloseButton from "../../Components/closeButton";

type Props = {
  files: FileWithChecked[];
  token: string;
  onNext: (files: FileWithChecked[], token: string) => any;
  rename: Function;
  onClickChecked: Function;
  onUnlock: Function;
  refresh: Function;
};
export default function Index({
  files,
  token,
  onNext,
  rename,
  onClickChecked,
  onUnlock,
  refresh,
}: Props) {
  const history = useHistory();
  const [folderCreating, setFolderCreating] = useState(false);
  const [createFolder, setCreateFolder] = useState(false);

  const { setLoading: setLoadingOverlay } = useOverlay();
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const { setLoading } = useOverlay();
  const alert = useAlert();
  const previewImage = (file: FileData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoadingOverlay(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoadingOverlay(false));
  };

  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const clickFile = async (file: FileData) => {
    if (file.type === "folder") {
      history.push("/time-lock/folder/" + file.id);
    } else {
      history.push("/time-lock/file/" + file.id);
    }
  };

  const createFile = async (id: string, file: File) => {
    let value: CreateModel = {
      id: id,
      name: file.name,
      parent: "0",
      type: file.type,
      size: file.size,
      uploadUrl: "time-lock",
    };
    await Create(value);
  };
  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        parent: "0",
        type: "folder",
        size: 0,
        id: uuid(),
        uploadUrl: undefined,
      };
      await Create(document);
      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
    }
  };

  return (
    <section className="h-100 d-flex flex-column">
      <MobileUpload
        callback={createFile}
        parent="0"
        locked={true}
        url="time-lock"
        uploadUrl="time-lock"
      />
      <div className="d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-3">
            <li
              className="breadcrumb-item active text-black font-weight-semibold"
              aria-current="page"
            >
              Time-Lock
            </li>
          </ol>
        </nav>
        <DesktopUpload
          callback={createFile}
          parent="0"
          onCreateFolder={() => setCreateFolder(true)}
          locked={true}
          url="time-lock"
          uploadUrl="time-lock"
          disableMulti={
            files.filter((x) => x.checked).map((x) => x.id).length <= 0
          }
        />
      </div>
      <div className={`mb-lg-5 mb-0 ${style.table}`}>
        <FileTable
          isTimeLock
          border={files.length !== 0}
          hasMore={!!token}
          next={() => {
            if (!!token)
              GetRoot(token).then((files) => {
                if (files) onNext(files.files, files.token);
              });
          }}
          items={[
            {
              display: (
                <>
                  {files.filter((file) => file.checked).length !==
                  files.length ? (
                    <>
                      <i className="fas fa-check me-1"></i>
                      Select All
                    </>
                  ) : (
                    <>
                      <i className="far fa-window-close me-1"></i>
                      Deselect All
                    </>
                  )}
                </>
              ),
              action: () => {
                if (
                  files.filter((file) => file.checked).length == files.length
                ) {
                  for (let file of files) {
                    onClickChecked(file.id, false);
                  }
                } else {
                  for (let file of files) {
                    onClickChecked(file.id, true);
                  }
                }
              },
            },
            {
              display: (
                <>
                  <i className="fas fa-lock-open me-2"></i>
                  <span className="me-4">Lock File</span>
                </>
              ),
              action: () => {
                if (
                  files.filter((x) => x.checked).map((x) => x.id).length > 0
                ) {
                  setLoading(true);
                  batchUnlockService(
                    files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    refresh();
                    setLoading(false);
                  });
                } else {
                  alert.info("No file has been selected", { timeout: 5000 });
                }
              },
            },

            {
              display: (
                <>
                  <i className="fas fa-file-download me-1"></i>
                  Download
                </>
              ),
              action: async () => {
                setLoading(true);
                await DownloadFiles(
                  files.filter((x) => x.checked).map((x) => x.id)
                ).finally(() => {
                  setLoading(false);
                });
              },
            },
          ]}
        >
          {createFolder && (
            <tr>
              <td colSpan={7}>
                <div
                  className={`d-flex w-100 ${folderCreating && style.disabled}`}
                >
                  <img
                    src={`/logos/folder.png`}
                    alt={`folder logo`}
                    className="img-fluid"
                  />
                  <input
                    type="text"
                    className="form-control ms-2 shadow-none d-inline flex-fill"
                    onClick={(e: any) => e.stopPropagation()}
                    onKeyDown={handleFolderKeyEvent}
                    autoFocus
                    disabled={folderCreating}
                  />
                </div>
              </td>
            </tr>
          )}

          {files.map((file, i) => (
            <TimelockRow
              fileData={file}
              onUnlock={onUnlock}
              onClick={() => clickFile(file)}
              key={file.id}
              onRename={(value: string) => {
                rename(file.id, value);
              }}
              tabIndex={i}
              handleKeyDown={(e) => handleKeyDown(e, file)}
              previewImage={() => previewImage(file)}
              onClickChecked={(val: boolean) => onClickChecked(file.id, val)}
            />
          ))}
        </FileTable>
      </div>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => setPreview(false)}
      >
        <div
          style={{
            height: "90vh",
            width: "80vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
        </div>
      </Popup>
    </section>
  );
}
