import Comment from "./Comment";
import { Comment as CommentModel } from "../../Models/Document";

interface props{
    comments:CommentModel[],
    fileId:string
}
export default function Comments({comments,fileId}:props) {    
    return <div className="flex-fill overflow-auto" style={{
        backgroundColor: "#F5F6FC"
    }}>
        <Comment comments={comments} fileId={fileId} next={function () {
            throw new Error("Function not implemented.");
        } } hasMore={false}/>
    </div>


}