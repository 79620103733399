import { useEffect, useState } from "react";
import Empty from "./Empty";
import Upload from "./Upload";
import Layout from "./Layout";
import Index from "./Index";
import { useFile } from "../../Context/FileContext";
import {
  fileListener,
  Recent,
  renameFileService,
} from "../../Services/Document";
import { FileData } from "../../Models/Document";
import { useRef } from "react";
import Loading from "../Components/Loading";
import GetFileSize from "../../Helper/FileSizeExtractor";

export interface FileWithChecked extends FileData {
  checked: boolean;
}
export default function Home() {
  const subscriber = useRef<any>();
  const [recentFiles, setRecentFiles] = useState<FileWithChecked[]>([]);
  const [recentFavorites, setRecentFavorites] = useState<FileData[]>([]);
  const { files } = useFile();
  const [loadingFiles, setLoadingFiles] = useState(true);

  useEffect(() => {
    onPageRendered();
    return () => {
      if (subscriber.current) {
        subscriber.current.unsubscribe();
      }
    };
  }, []);
  const handleChecked = (fileId: string, checked: boolean) => {
    setRecentFiles((files) =>
      files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      )
    );
  };
  const refresh = async () => {
    setLoadingFiles(true);
    var value = await Recent();
    setRecentFiles(value?.files);
    setRecentFavorites(value?.favorites);
    setLoadingFiles(false);
  };
  const onPageRendered = async () => {
    await refresh();
    subscriber.current = await fileListener((value: any) => {
      if (value.type !== "folder") {
        setRecentFiles((recentFiles) => {
          return [
            { ...value, size: GetFileSize(value.size, value.type) },
            ...recentFiles.filter((x) => x.id !== value.id),
          ];
        });
      }
    });
  };

  const rename = async (file: FileData, name: string) => {
    await renameFileService(file.id, name);
    setRecentFiles((files) =>
      files.map((f) => (f.id === file.id ? { ...f, name: name } : f))
    );
  };

  return (
    <Layout>
      <div className="d-flex h-100">
        <div className="flex-fill mw-100">
          {loadingFiles ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : recentFiles && recentFiles.length !== 0 ? (
            <Index
              userFiles={recentFiles}
              rename={rename}
              userFavorites={recentFavorites}
              onClickChecked={(val: string, data: boolean) =>
                handleChecked(val, data)
              }
              refresh={refresh}
            />
          ) : files && files.length !== 0 ? (
            <Upload files={files as any} />
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Layout>
  );
}
