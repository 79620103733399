import style from "./help.module.css"

interface AccordionItem {
    title: string;
    body: any;
  }

interface Props {
    active: number | null;
    setActive: (index: number | null) => void;
    item: AccordionItem;
    index: number;
  }


  const Accordion = ({ active, setActive, item, index }: Props) => {
    const isActive = active === index;
  
    return (
      <div className={`${isActive ? style.accordionBorder : ''}`}>
        <button
        //   className="accordion-header"
        className={`d-flex btn shadow-none w-100 justify-content-between align-items-center ${style.accordionHeader}`}
          onClick={() => {
            setActive(isActive ? null : index);
          }}
        >
          <h4 className="mb-0 text-start">{item.title}</h4>
          {isActive ? <i className="fas fa-angle-up color-orange"></i> : <i className="fas fa-angle-down color-orange"></i>}
        </button>
        {isActive && (
          <div className={` ${style.accordionBody}`}>
            <p>{item.body}</p>
          </div>
        )}
      </div>
    );
  };
  
  export default Accordion;