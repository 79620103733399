import Index from "./Components/index";
import Empty from "./Components/Empty";
import { useEffect, useRef, useState } from "react";
import { GetRoot } from "../../Services/Time-Lock";
import { parentListener } from "../../Services/Document";
import moment from "moment";
import { renameFileService } from "../../Services/Document";
import { useOverlay } from "../../Context/OverlayContext";
import Loading from "../Components/Loading";
import { FileWithChecked } from "../Home/home";
import Layout from "../Home/Layout";

export default function TimeLock() {
  const { setLoading } = useOverlay();
  const parentSubscriber = useRef<any>();
  const [folder, setFolder] = useState<{
    files: FileWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });
  const [loadingFiles, setLoadingFiles] = useState(true);

  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };
  const refresh = () => {
    GetRoot()
      .then((files) => {
        if (files) {
          setFolder({
            loading: false,
            files: files.files,
            token: files.token,
          });
        }
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  };
  useEffect(() => {
    refresh();
    parentListener("0", (value: any) => {
      if (
        value.activeDate &&
        moment(value.activeDate, "YYYY-MM-DD").isAfter(moment())
      )
        setFolder((folder) => ({
          ...folder,
          files: [{ ...value, activeDate: new Date(value.activeDate) }].concat(
            folder.files.filter((x) => x.id !== value.id)
          ),
        }));
    }).then((value) => {
      parentSubscriber.current = value;
    });

    return () => {
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
    };
  }, []);

  const rename = async (id: string, name: string) => {
    // setLoading(true);
    await renameFileService(id, name);
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((f) => (f.id === id ? { ...f, name: name } : f)),
    }));
    // setLoading(false);
  };

  return (
    <Layout>
      {loadingFiles ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : folder.files.length !== 0 || folder.token ? (
        <Index
          onUnlock={(id: string) =>
            setFolder((folder) => ({
              ...folder,
              files: folder.files.filter((f) => f.id !== id),
            }))
          }
          refresh={refresh}
          files={folder.files}
          token={folder.token}
          onNext={(newFiles, token) => {
            setFolder({
              ...folder,
              files: folder.files.concat(newFiles),
              token: token,
            });
          }}
          onClickChecked={(val: string, data: boolean) =>
            handleChecked(val, data)
          }
          rename={rename}
        />
      ) : (
        <Empty />
      )}
    </Layout>
  );
}
