import { useParams } from "react-router";
import OwnershipTransfer from "./OwnershipTransfer";
import Layout from "../layout";
import { NavLink } from "react-router-dom";
import style from "../styles/files.module.css"

export default function Transfer() {
    const { id, type } = useParams<{ id?: string, type: "file" | "folder" }>();
    return <Layout>
        <section className="d-flex d-lg-none flex-column h-100" style={{
            marginLeft: "-1rem",
            marginRight: "-1rem"
        }}>
            <div className="d-flex color-blue">
                <NavLink to={`/files/${type}/${id}/sharing`}
                    activeClassName={style.active}
                    className="flex-fill py-2 text-center text-decoration-none"
                    style={{
                        border: "2px solid var(--blue)",
                    }}>Sharing</NavLink>
                <NavLink
                    activeClassName={style.active}
                    className="flex-fill py-2 text-center text-decoration-none"
                    to={`/files/${type}/${id}/comment`}
                    style={{
                        borderTop: "2px solid var(--blue)",
                        borderBottom: "2px solid var(--blue)"
                    }}>Comments</NavLink>

                <NavLink
                    activeClassName={style.active}
                    className="flex-fill py-2 text-center text-decoration-none"
                    to={`/files/${type}/${id}/activity`}
                    style={{
                        border: "2px solid var(--blue)",
                    }}>Activities</NavLink>
            </div>

            <div className="px-4 flex-fill overflow-auto pt-3 d-flex flex-column">
                <OwnershipTransfer id={""} user="" name={""} />
            </div>
        </section>
        <section className="h-100 row d-none d-lg-flex">
            {
                /*type !== "folder" ?
                    <File /> : <Folder />*/
            }
        </section>
    </Layout>
}