import { useParams } from "react-router-dom";
import Layout from "../layout";
import Folder, { SharedWithChecked } from "./components/folder";
import style from "../styles/files.module.css";
import MobileUpload from "../Components/MobileUpload";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Comments from "../Components/Comments";
import Activity from "../Components/Activity";
import { useEffect, useRef, useState } from "react";
import {
  Comment as CommentModel,
  SharedData,
  Create as CreateModel,
  Activity as ActivityModel,
} from "../../Models/Document";
import { commentListener } from "../../Services/Comments";
import {
  Create,
  folderListener,
  getFavorites,
  remove,
} from "../../Services/favorites";
import FetchActivity from "../../Services/Activity";
import { useOverlay } from "../../Context/OverlayContext";
import getBreadCrumb from "./BreadCrumb";

export default function FavoriteFolder() {
  const { id } = useParams<{ id: string }>();
  const parentSubscriber = useRef<any>();
  const commentSubscriber = useRef<any>();
  const { setLoading } = useOverlay();
  const [activities, setActivities] = useState<{
    items: ActivityModel[];
    token: string;
  }>({
    items: [],
    token: "",
  });
  const [viewActivity, setViewActivity] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [folderName, setFolderName] = useState("");
  const [folder, setFolder] = useState<{
    files: SharedWithChecked[];
    token: string;
    comments: CommentModel[];
    loading: boolean;
  }>({
    files: [],
    token: "",
    comments: [],
    loading: true,
  });

  const createFile = async (fileId: string, file: File) => {
    let value: CreateModel = {
      id: fileId,
      name: file.name,
      parent: id,
      size: file.size,
      type: file.type,
      uploadUrl: "favorite",
    };
    await Create(value);
  };
  const unfavourite = (id: string) => {
    setLoading(true);
    remove(id)
      .then(() =>
        setFolder({
          ...folder,
          files: folder.files.filter((x) => x.id !== id),
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };

  const refresh = () => {
    getFavorites(id)
      .then((files) => {
        if (files) {
          setFolder({
            comments: files.comments.reverse(),
            files: files.files.map((x: any) => ({
              ...x,
              checked: false,
            })),
            token: files.token,
            loading: false,
          });
          setBreadCrumbItems(getBreadCrumb(files.parent));
          setFolderName(files.name);
          setViewActivity(files.disabled && files.shared);
          if (!files.disabled || !files.shared) {
            FetchActivity(id).then((value) => {
              if (value) {
                setActivities({
                  items: value.activities,
                  token: value.token,
                });
                setLoadingActivity(false);
              }
            });
          } else {
            setLoadingActivity(false);
          }
        }
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  };
  useEffect(() => {
    refresh();
    folderListener(id, (value: any) => {
      setFolder((folder) => ({
        ...folder,
        files: [value, ...folder.files],
      }));
    }).then((value) => {
      parentSubscriber.current = value;
    });
    commentListener(id, (value: any) => {
      setFolder((folder) => ({
        ...folder,
        comments: folder.comments.concat(value),
      }));
    }).then((value) => {
      commentSubscriber.current = value;
    });

    return () => {
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
      if (commentSubscriber.current) {
        commentSubscriber.current.unsubscribe();
      }
    };
  }, [id]);

  const nextActivities = () => {
    if (activities.token) {
      FetchActivity(id, activities.token).then((values) => {
        if (values) {
          setActivities((activities) => ({
            items: activities.items.concat(values.activities),
            token: values.token,
          }));
        }
      });
    }
  };
  return (
    <Layout>
      <div className="d-lg-none h-100">
        <Tabs
          selectedTabClassName={style.tabSelected}
          className="d-flex flex-column h-100"
          selectedTabPanelClassName="d-flex  flex-fill flex-column"
        >
          <TabList className={style.tabs}>
            <Tab>Home</Tab>
            <Tab>Comments</Tab>
            <Tab>Activity</Tab>
          </TabList>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="favourites"
              uploadUrl="favorite"
            />
            <Folder
              comments={folder.comments}
              files={folder.files}
              folderName={folderName}
              id={id}
              createFile={createFile}
              unfavorite={unfavourite}
              token={folder.token}
              loading={folder.loading}
              onNext={(files, token) => {
                setFolder({
                  ...folder,
                  files: [...folder.files, ...files],
                });
              }}
              loadingFiles={loadingFiles}
              breadcrumbs={breadcrumbItems}
              onClickChecked={(val: string, data: boolean) =>
                handleChecked(val, data)
              }
              refresh={refresh}
            />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="favourites"
              uploadUrl="favorite"
            />
            <Comments comments={folder.comments} fileId={id} />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              url="favourites"
              uploadUrl="favorite"
            />
            <Activity
              activities={activities.items}
              loading={loadingActivity}
              disabled={viewActivity}
              loadMore={nextActivities}
              hasMore={!!activities.token}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="d-none d-lg-block h-100">
        <Folder
          comments={folder.comments}
          files={folder.files}
          id={id}
          createFile={createFile}
          unfavorite={unfavourite}
          token={folder.token}
          loading={folder.loading}
          onNext={(files, token) => {
            setFolder({
              ...folder,
              files: [...folder.files, ...files],
              token: token,
            });
          }}
          onClickChecked={(val: string, data: boolean) =>
            handleChecked(val, data)
          }
          loadingFiles={loadingFiles}
          folderName={folderName}
          breadcrumbs={breadcrumbItems}
          refresh={refresh}
        />
      </div>
    </Layout>
  );
}
