import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import Move from "../Move";
import Popup from "../popup";
import { FileData } from "../../../Models/Document";
import style from "../Row.module.css";
import Avatar from "../avatar";
import Share from "../Share";
import { refreshUsers } from "../../../Services/Shared";
import { FileWithChecked } from "../../Home/home";
import AsyncModifiedDate from "./AsyncModifiedDate";
import DoubleClick from "./DoubleClick";

interface Props {
  fileData: FileWithChecked;
  onClick: Function;
  onMove: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
  onClickChecked: Function;
}

const HomeRow: React.FC<Props> = ({
  fileData,
  onClick,
  onMove,
  handleKeyDown,
  tabIndex,
  previewImage,
  onClickChecked,
}) => {
  const [move, setMove] = useState(false);
  const [file, setFile] = useState<FileWithChecked>(fileData);
  const timeoutRef = useRef<any>();
  const [lastClickTime, setLastClickTime] = useState(0);
  const [singleClickTimeout, setSingleClickTimeout] = useState<number | null>(
    null
  );

  useEffect(() => {
    setFile(fileData);
  }, [fileData]);

  const refresh = () => {
    refreshUsers(file.id).then((data: any) => {
      setFile({
        ...file,
        users: data,
      });
    });
  };
  const moveFile = (fileId: string, selected: string) => {
    onMove(fileId, selected);
  };

  const isVideoFile = (file: any) => {
    return file.type === "media";
  };
  return (
    <tr
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td className="align-middle">
        <input
          type="checkbox"
          onChange={(e) => onClickChecked(e.currentTarget.checked)}
          checked={file.checked}
          className="form-check-input"
        />
      </td>
      <td
        onClick={() => {
          DoubleClick({
            previewImage,
            timeoutRef,
            onClick,
            file,
            lastClickTime,
            setLastClickTime,
            singleClickTimeout,
            setSingleClickTimeout,
          });
        }}
        className="align-middle"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        <button
          className="d-flex btn align-items-center shadow-none px-0"
          // onClick={() => onClick(file.id)}
        >
          <span style={{ width: "32px" }}>
            <img
              src={`/logos/${file.type}.png`}
              alt={`${file.type} logo`}
              className="img-fluid"
            />
          </span>
          <span className="ms-2 d-none d-lg-inline">{file.name}</span>
        </button>
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        <>
          <span className={`text-center ${style.file}`}>{file.name}</span>
          <br />
          <span className={style.file2}>
            <span>
              <AsyncModifiedDate modified={file.modified} />
            </span>

            {/* {file.modified.toLocaleDateString()} */}
            <span className="color-dark-grey"> by {file.modifierName}</span>
          </span>

          {/* <br /> */}
          {file.size}
          <br />
          <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
            {file.users &&
              file.users.map((user) => (
                <Avatar name={user.name} image={user.id} key={user.id} />
              ))}
            {file.sharedCount
              ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
              : ""}
          </span>
        </>
      </td>

      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          <AsyncModifiedDate modified={file.modified} />
          {/* {file.modified.toLocaleDateString()} */}
          <small className="color-dark-grey color-dark-grey">
            by {file.modifierName}
          </small>
        </div>
      </td>

      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars} `}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="text-end">
        <Popup
          trigger={
            <button
              className="btn btn-transparent color-dark-grey shadow-none"
              type="button"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
          }
          position="left center"
          nested
        >
          {(close: Function) => (
            <div
              className="card bg-white p-0"
              style={{
                filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                borderRadius: "10px",
              }}
            >
              <div className="card-body px-0">
                <ul className={`list-unstyled mb-0 ${style.menu}`}>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        setMove(true);
                      }}
                    >
                      <img
                        src="/icons/Move.png"
                        alt="move"
                        className="img-fluid"
                      />{" "}
                      Move to
                    </button>
                  </li>
                  <li>
                    <Popup
                      className="modalPopup"
                      trigger={
                        <button className="btn shadow-none d-none d-lg-inline">
                          <i className="fas fa-share-alt text-start"></i>
                          Manage Sharing
                        </button>
                      }
                      modal
                      closeOnDocumentClick={false}
                    >
                      {(closeShare: Function) => (
                        <div
                          className="bg-white p-3 d-flex flex-column"
                          style={{
                            width: "40vw",
                            height: "380px",
                            filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                          }}
                        >
                          <Share
                            close={() => {
                              close();
                              closeShare();
                            }}
                            fileId={file.id}
                            refresh={refresh}
                          />
                        </div>
                      )}
                    </Popup>
                    <Popup
                      className="modalPopup"
                      trigger={
                        <button className="btn shadow-none d-inline d-lg-none">
                          <i className="fas fa-share-alt text-start"></i>
                          Manage Sharing
                        </button>
                      }
                      modal
                      closeOnDocumentClick={false}
                    >
                      {(closeShare: Function) => (
                        <div
                          className="bg-white p-3 d-flex flex-column"
                          style={{
                            width: "90vw",
                            height: "380px",
                            filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                          }}
                        >
                          <Share
                            close={() => {
                              close();
                              closeShare();
                            }}
                            fileId={file.id}
                            refresh={refresh}
                          />
                        </div>
                      )}
                    </Popup>
                  </li>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        previewImage();
                      }}
                    >
                      <i className="fas fa-play"></i> Preview
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popup>
        <Popup
          open={move}
          closeOnDocumentClick={false}
          onClose={() => setMove(false)}
          closeOnEscape={false}
          className="menuPopup"
        >
          {(close: Function) => (
            <Move
              close={() => {
                close();
              }}
              fileId={file.id}
              onmove={moveFile}
              root={0}
            />
          )}
        </Popup>
      </td>
    </tr>
  );
};

export default HomeRow;
