import { useOverlay } from "../../Context/OverlayContext";
import UploadSVG from "../../Icons/Upload";

interface props {
  callback: Function;
  parent?: string;
  locked?: boolean;
  url: "shared" | "files" | "time-lock" | "archived" | "favourites";
  uploadUrl: "file" | "replace" | "favorite" | "time-lock";
}
export default function MobileUpload({
  callback,
  parent,
  locked = false,
  url,
  uploadUrl,
}: props) {
  const { uploadFile } = useOverlay();

  return (
    <div className="d-flex d-lg-none justify-content-between my-2">
      <label className="btn btn-primary btn-radius-10 h-100 py-2 flex-fill fw-bold">
        <span
          style={{
            width: "18px",
          }}
          className="d-inline-block me-1"
        >
          <UploadSVG color="white" />
        </span>
        Upload file
        <input
          type="file"
          id="file"
          className="d-none"
          multiple
          onChange={(event: any) => {
            uploadFile(
              Array.from(event.target.files).map((x) => ({
                url: url,
                file: x,
                save: (id: string) => callback(id, x),
              })),
              parent,
              locked,
              uploadUrl
            );
          }}
        />
      </label>
      <label className="btn btn-primary d-inline btn-radius-10 d-lg-none ms-2 py-2 flex-fill fw-bold">
        <img src="/icons/Camera.svg" alt="Upload" className="me-1" />
        Take photo
        <input
          type="file"
          id="imageFile"
          capture="user"
          accept="image/*"
          className="d-none"
          onChange={(event: any) => {
            uploadFile(
              Array.from(event.target.files).map((x) => ({
                file: x,
                save: (id: string) => callback(id, x),
              })),
              parent,
              locked
            );
          }}
          multiple
        />
      </label>
    </div>
  );
}
