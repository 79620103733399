import React from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "./layout";
import style from "./layout.module.css";
import { Mode, useForm } from "react-hook-form";
import { RegisterUser as Model } from "../Models/Authentication";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Register, VerifyRecaptcha } from "../Services/Authenticate";
import { ErrorMessage } from "@hookform/error-message";
import CustomError from "../Helper/CustomErrorMessage";
import Terms from "./terms";
import Privacy from "./privacy";
import { useOverlay } from "../Context/OverlayContext";
import { Auth } from "aws-amplify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
const siteKey = process.env.REACT_APP_SITE_KEY;

export default function Registration() {
  const recaptcha = useRef<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const history = useHistory();
  const { setLoading } = useOverlay();
  const { type } = useParams<{ type: string }>();
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrengthIndicator, setPasswordStrength] = useState(false);
  const [
    confirmPasswordStrengthIndicator,
    setConfirmPasswordStrengthIndicator,
  ] = useState(false);
  const [strength, setStrength] = useState(0);

  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const formSchema = Yup.object().shape({
    Password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain one uppercase, one lowercase, one number and one special case"
      ),
    ConfirmPassword: Yup.string().oneOf(
      [Yup.ref("Password")],
      "Passwords must match"
    ),
  });

  useEffect(() => {
    async function checkUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        setUser(null);
      }
    }

    checkUser();
  }, []);

  useEffect(() => {
    if (user) {
      history.push("/platform");
    }
  }, [user, history]);

  const validationOpt = {
    mode: "onBlur" as Mode,
    resolver: yupResolver(formSchema),
    defaultValues: {
      PriceId: type,
    },
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Model>(validationOpt);

  const passwordValue = watch("Password");
  const confirmPasswordValue = watch("ConfirmPassword");

  let passwordLength: number;
  let confirmPasswordLength: number;
  try {
    passwordLength = passwordValue.length;
  } catch {
    passwordLength = 0;
  }
  try {
    confirmPasswordLength = confirmPasswordValue.length;
  } catch {
    confirmPasswordLength = 0;
  }

  useEffect(() => {
    setPassword(passwordValue);
    setConfirmPassword(confirmPasswordValue);
    if (passwordLength > 0) {
      setPasswordStrength(true);
    }
    if (passwordLength == 0) {
      setPasswordStrength(false);
    }

    if (confirmPasswordLength > 0) {
      setConfirmPasswordStrengthIndicator(true);
    }
    if (confirmPasswordLength == 0) {
      setConfirmPasswordStrengthIndicator(false);
    }
  }, [passwordValue, confirmPasswordValue]);

  const [verifyOnce, setVerifyOnce] = useState(false);

  const submit = async (data: Model) => {
    // console.log("recaptoken", token);
    setLoading(true);
    let result;
    const recaptchaResult = await VerifyRecaptcha(token);

    if (recaptchaResult) {
      const res = recaptchaResult["data"]["verifyRecaptcha"];
      // console.log("res", res);
      if (res === true) {
        setRefreshReCaptcha(!refreshReCaptcha);
        setIsVerified(true);
        result = await Register(data);
        // console.log("result", result);
        if (result.success) {
          localStorage.setItem("ptcm", btoa(JSON.stringify(data)));
          history.push({
            pathname: "/email-confirm",
            state: {
              email: data.Email,
            },
          });
          setIsVerified(true);
        } else {
          setError("FullName", { message: result.message });
        }
        setLoading(false);
      } else {
        setRefreshReCaptcha(!refreshReCaptcha);
        setIsVerified(false);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const checkPasswordStrength = (password: string) => {
    // Check password length
    let strength = 0;

    // Check for presence of uppercase letters
    if (/[A-Z]/.test(password)) {
      strength += 25;
    }

    // Check for presence of lowercase letters
    if (/[a-z]/.test(password)) {
      strength += 25;
    }

    // Check for presence of numbers
    if (/[0-9]/.test(password)) {
      strength += 25;
    }

    // Check for presence of special characters
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      strength += 25;
    }

    return Math.min(Math.floor(strength), 100); // Cap strength at 100%
  };

  const PasswordStrengthIndicator: React.FC<{ password: string }> = ({
    password,
  }) => {
    const [strength, setStrength] = useState(0);

    React.useEffect(() => {
      setStrength(checkPasswordStrength(password));
    }, [password]);

    const getColor = (strength: number) => {
      if (strength === 25) {
        return { color: "danger", text: "week" };
      }
      if (strength === 50) {
        return { color: "danger", text: "week" };
      }
      if (strength === 75) {
        return { color: "warning", text: "medium" };
      }
      if (strength === 100) {
        return { color: "success", text: "strong" };
      } else {
        return { color: "danger", text: "week" };
      }
    };

    return (
      <div className={`${passwordStrengthIndicator ? "d-block" : "d-none"}`}>
        <div
          className={`progress-bar bg-${getColor(strength).color}`}
          role="progressbar"
          style={{
            height: "10px",
            border: "1px",
            borderRadius: "10px",
            width: `${strength}%`,
          }}
          aria-valuenow={strength}
        ></div>
        <div className={`text-${getColor(strength).color}`}>
          {getColor(strength).text}
        </div>
      </div>
    );
  };

  const ConfirmPasswordStrengthIndicator: React.FC<{
    confirmPassword: string;
  }> = ({ confirmPassword }) => {
    const [confirmPasswordStrength, setConfirmPasswordStrength] = useState(0);

    React.useEffect(() => {
      setConfirmPasswordStrength(checkPasswordStrength(confirmPassword));
    }, [confirmPassword]);

    const getColor = (confirmPasswordStrength: number) => {
      if (confirmPasswordStrength === 25) {
        return { color: "danger", text: "week" };
      }
      if (confirmPasswordStrength === 50) {
        return { color: "danger", text: "week" };
      }
      if (confirmPasswordStrength === 75) {
        return { color: "warning", text: "medium" };
      }
      if (confirmPasswordStrength === 100) {
        return { color: "success", text: "strong" };
      } else {
        return { color: "danger", text: "week" };
      }
    };

    return (
      <div
        className={`${confirmPasswordStrengthIndicator ? "d-block" : "d-none"}`}
      >
        <div
          className={`progress-bar bg-${
            getColor(confirmPasswordStrength).color
          }`}
          role="progressbar"
          style={{
            height: "10px",
            border: "1px",
            borderRadius: "10px",
            width: `${confirmPasswordStrength}%`,
          }}
          aria-valuenow={confirmPasswordStrength}
        ></div>
        <div className={`text-${getColor(confirmPasswordStrength).color}`}>
          {getColor(confirmPasswordStrength).text}
        </div>
      </div>
    );
  };

  const setTokenFunc = (getToken: any) => {
    setToken(getToken);
  };

  return (
    <Layout display={true}>
      <div
        className={`d-flex flex-column justify-content-between justify-content-lg-center align-items-center h-100 max-vh-100 ${style["max-vh-100"]}`}
      >
        <p
          className={`text-end ${style.contentWidth}  ${style["fs-18"]} color-dark-grey d-none d-lg-block mt-5`}
        >
          <small>Already have an account?</small>
          <Link
            className="btn btn-outline-primary ms-3 shadow-none px-3 py-1"
            to="/login"
          >
            Login
          </Link>
        </p>
        <form
          className={`text-center ${style.form} text-lg-start p-lg-4`}
          onSubmit={handleSubmit(submit)}
        >
          <h1 className={`${style.regHeader} mb-3`}>Sign up to Inkmark</h1>

          <ErrorMessage
            errors={errors}
            name="FullName"
            render={({ messages, message }) => (
              <CustomError
                messages={messages}
                messageSingle={"There's already a user with that email"}
              />
            )}
          />

          <input
            type="text"
            className="form-control shadow-none mb-3"
            placeholder="Full name"
            autoComplete="fullname"
            required
            {...register("FullName")}
          />
          <input type="hidden" {...register("PriceId")} />
          <ErrorMessage
            errors={errors}
            name="Email"
            render={({ messages, message }) => (
              <CustomError messages={messages} messageSingle={message} />
            )}
          />
          <input
            type="email"
            className="form-control mb-3 shadow-none"
            placeholder="Email"
            required
            {...register("Email")}
          />

          <ErrorMessage
            errors={errors}
            name="Password"
            render={({ messages, message }) => (
              <CustomError messages={messages} messageSingle={message} />
            )}
          />

          <div className="mb-3 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control shadow-none pr-4"
              placeholder="Password"
              required
              // onChange={(e) => handleChange(e)}
              minLength={8}
              {...register("Password", { required: true, minLength: 8 })}
            />
            <button
              type="button"
              className="btn btn-link position-absolute end-0 top-50 translate-middle-y"
              onClick={togglePasswordVisibility}
              style={{ zIndex: 1 }} // Ensure the button is above the input
            >
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </button>
          </div>
          <PasswordStrengthIndicator password={password} />

          <ErrorMessage
            errors={errors}
            name="ConfirmPassword"
            render={({ messages, message }) => (
              <CustomError messages={messages} messageSingle={message} />
            )}
          />

          <div className="mb-3 position-relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="form-control shadow-none pr-4"
              placeholder="Confirm Password"
              required
              minLength={8}
              {...register("ConfirmPassword")}
            />
            <button
              type="button"
              className="btn btn-link position-absolute end-0 top-50 translate-middle-y"
              onClick={toggleConfirmPasswordVisibility}
              style={{ zIndex: 1 }} // Ensure the button is above the input
            >
              {showConfirmPassword ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )}
            </button>
          </div>
          <ConfirmPasswordStrengthIndicator confirmPassword={confirmPassword} />

          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="termsAndPrivacyPolicy"
              required
            />
            <label
              className="form-check-label w-100 text-start"
              htmlFor="termsAndPrivacyPolicy"
            >
              I accept the <Terms /> and <Privacy />
            </label>
          </div>
          <button
            className="btn btn-primary btn-radius-10 mt-1 w-100 shadow-none"
            disabled={isSubmitting}
            type="submit"
          >
            Sign up now
          </button>
          <div className={`mt-2 d-flex justify-content-center`}>
            <div>
              {/* {siteKey && <ReCAPTCHA ref={recaptcha} sitekey={siteKey} />} */}

              {!isVerified && <p className="text-danger ">Recaptcha error.</p>}
            </div>
          </div>
        </form>
        <div className="d-block d-lg-none w-100 mt-5">
          <p className={`mb-1 text-center ${style["fs-18"]} color-dark-grey`}>
            Already have an account?
          </p>
          <Link
            className="btn btn-outline-primary w-100 shadow-none"
            to="/login"
          >
            Login
          </Link>
        </div>
      </div>
      {siteKey && (
        <GoogleReCaptchaProvider
          reCaptchaKey={`${siteKey}`}
          container={{
            // optional to render inside custom element
            element: "[required_id_or_htmlelement]",
            parameters: {
              badge: "bottomleft", // optional, default undefined
              theme: "light", // optional, default undefined
            },
          }}
        >
          <GoogleReCaptcha
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      )}
    </Layout>
  );
}
