import React from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import styles from "./index.module.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
// @ts-ignore
import AlertTemplate from "react-alert-template-basic";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const options = {
  position: positions.BOTTOM_LEFT,
  // timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

interface Style {
  [key: string]: string | number;
}

const CustomAlertTemplate = ({
  style,
  options,
  message,
  close,
}: {
  style: Style;
  options: any;
  message: any;
  close: () => void;
}) => (
  <div
    className={`${styles.alert}`}
    style={{
      ...style,
    }}
  >
    {options.type === "info" && (
      <img
        className={`${styles.options}`}
        src="/images/info.png"
        alt="Info Icon"
      />
    )}
    {options.type === "success" && (
      <img
        className={`${styles.options}`}
        src="/images/success.png"
        alt="Info Icon"
      />
    )}
    {options.type === "error" && (
      <img
        className={`${styles.options}`}
        src="/images/error.png"
        alt="Info Icon"
      />
    )}
    <div className={`${styles.message}`}>{message}</div>

    <div className="mx-3" onClick={close}>
      X
    </div>
  </div>
);

root.render(
  <AlertProvider {...options} template={CustomAlertTemplate}>
    <App />
  </AlertProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
