import { API, Auth } from "aws-amplify";
import {
  addFavoriteFile,
  batchRemoveFavorites,
  createFavorite,
  deleteFavorite,
} from "../graphql/mutations";
import GetFileSize from "../Helper/FileSizeExtractor";
import { Create as CreateModel } from "../Models/Document";
import {
  onFavoriteByFile,
  onFavoriteFile,
  onFavoriteFileByUser,
} from "../graphql/customSubscriptions";
import {
  rootFavorites,
  userFavorite,
  userFavorites,
  userNextFavorites,
} from "../graphql/customQueries";

export async function favorite(fileId: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: createFavorite,
      variables: {
        input: {
          fileId: fileId,
          userId: user.username,
          id: fileId + "_" + user.username,
          parent: "0",
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function getRootFavorites(token?: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: rootFavorites,
      variables: { id: user.username, token: token },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.favorites.items.map((x: any) => {
      const shared = x.file.user.id !== user.username;
      if (shared) {
        let users = [
          x.file.user,
          ...x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
        ];
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          users: users,
          modifierName: x.file.uploader?.name,
        };
      } else {
        let users = [
          ...x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
        ];
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          users: users,
          modifierName: x.file.uploader?.name,
        };
      }
    });
    return { files, token: value.data.favorites.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function getFavorites(parent: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userFavorites,
      variables: {
        id: user.username,
        parent: parent,
        favID: parent + "_" + user.username,
      },
    }) as Promise<{
      data: any;
    }>);
    const shared = !!value.data.shared;
    const disabled = !value.data.shared?.view;
    const files = value.data.favorites.items.map((x: any) => {
      if (shared) {
        let users = [
          x.file.user,
          ...x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
        ];
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          users: users,
          modifierName: x.file.uploader?.name,
        };
      } else {
        let users = [
          ...x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
        ];
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          users: users,
          modifierName: x.file.uploader?.name,
        };
      }
    });
    const comments = value.data.comments.items.map((x: any) => ({
      ...x,
      createdAt: new Date(x.createdAt),
      userComment: x.userId === user.username,
      name: x.user.name,
    }));
    return {
      files,
      comments,
      shared,
      token: value.data.favorites.nextToken,
      disabled,
      parent: value.data.info.parentDet,
      name: value.data.file.name,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function GetNextFavorites(parent: string, token?: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userNextFavorites,
      variables: { id: user.username, parent: parent, token: token },
    }) as Promise<{
      data: any;
    }>);
    const shared = value.data.file.userId !== user.username;
    const files = value.data.favorites.items.map((x: any) => {
      if (shared) {
        let users = [
          x.file.user,
          ...x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
        ];
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          users: users,
          modifierName: x.file.uploader?.name,
        };
      } else {
        let users = [
          ...x.file.shared.items.map((x: any) => ({
            id: x.user.id,
            name: x.user.name,
            identityId: x.user.identityId,
          })),
        ];
        return {
          ...x.file,
          size: GetFileSize(x.file.size, x.file.type),
          modified: new Date(x.file.modified),
          users: users,
          modifierName: x.file.uploader?.name,
        };
      }
    });
    return { files, shared, token: value.data.favorites.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function getFavorite(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userFavorite,
      variables: { id: id + "_" + user.username },
    }) as Promise<{
      data: any;
    }>);
    const data = value.data.files.file;

    return {
      comments: data.comments.items.map((x: any) => ({
        ...x,
        createdAt: new Date(x.createdAt),
        userComment: x.userId === user.username,
        name: x.user.name,
      })),
      file: {
        createdAt: new Date(data.created),
        id: data.id,
        modified: new Date(data.modified),
        name: data.name,
        parent: data.parent,
        size: GetFileSize(data.size, data.type),
        type: data.type,
        userId: data.userId,
        lastVersion: data.versions.items[0].id,
      },
      shared: !!value.data.shared,
      disabled: !value.data.shared?.view,
      parent: value.data.files.parentDet,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function Create(document: CreateModel) {
  try {
    await API.graphql({
      query: addFavoriteFile,
      variables: {
        fileId: document.id,
        name: document.name,
        parent: document.parent,
        size: document.size,
        type: document.type,
      },
    });
    return true;
  } catch (error: any) {
    // console.log("error", error);
    return false;
  }
}

export async function parentListener(parent: string, callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  let variables = {};
  variables = {
    userId: user.username,
    parent: parent,
  };
  return (
    API.graphql({
      query: onFavoriteFile,
      variables: variables,
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onFavoriteFile;
      let data = {
        ...temp,
        size: GetFileSize(temp.size, temp.type),
        modified: new Date(temp.modified),
        modifierName: temp.uploader.name,
      };
      callBack(data);
    },
  });
}

export async function folderListener(parent: string, callBack: Function) {
  let variables = {};
  variables = {
    parent: parent,
  };
  return (
    API.graphql({
      query: onFavoriteByFile,
      variables: variables,
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onFavoriteByFile;
      let data = {
        ...temp,
        size: GetFileSize(temp.size, temp.type),
        modified: new Date(temp.modified),
        modifierName: temp.uploader.name,
      };
      callBack(data);
    },
  });
}

export async function favoriteFileListener(callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  return (
    API.graphql({
      query: onFavoriteFileByUser,
      variables: { userId: user.username },
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onFavoriteFileByUser;
      let data = {
        ...temp,
        modified: new Date(temp.modified),
      };
      callBack(data);
    },
  });
}

export async function remove(fileId: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: deleteFavorite,
      variables: {
        input: {
          id: fileId + "_" + user.username,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function batchRemove(fileId: string[]) {
  try {
    let user = await Auth.currentAuthenticatedUser();

    await (API.graphql({
      query: batchRemoveFavorites,
      variables: {
        ids: fileId.map((x) => x + "_" + user.username).join(","),
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function batchAdd(fileId: string[]) {
  try {
    let user = await Auth.currentAuthenticatedUser();

    await (API.graphql({
      query: batchRemoveFavorites,
      variables: {
        ids: fileId.map((x) => x + "_" + user.username).join(","),
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}
