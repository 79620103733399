/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://radjr725evbwjjit3vgvp3ujbi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "stripe",
            "endpoint": "https://1eq11n46p9.execute-api.eu-west-2.amazonaws.com/main",
            "region": "eu-west-2"
        },
        {
            "name": "upload",
            "endpoint": "https://slq6gyzxgj.execute-api.eu-west-2.amazonaws.com/main",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:e000e23b-682e-438c-9acf-e844c24c4055",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_fxn4fDzWv",
    "aws_user_pools_web_client_id": "6rqua1f0qipfm2n1qfajh51elv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "inkmark-20230624130027-hostingbucket-main",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://d144hppeyjz1z2.cloudfront.net",
    "aws_user_files_s3_bucket": "inkmark-storage234650-main",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
