import React from "react";
import { Link } from "react-router-dom";
import NavMenu from "./components/navmenu";
import Sitefooter from "./components/sitefooter";
import style from "./Layout.module.css";
type Props = {
  children?: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div className={`h-100 overflow-auto ${style.all}`}>
      <NavMenu />
      <main className={style.main}>{children}</main>

      <section
        className="layout-section w-100 py-4 row mx-0 mt-5"
        style={{
          background: "linear-gradient(45deg,var(--violet),var(--blue))",
        }}
      >
        <div className="col-md-8 align-items-center  justify-content-center justify-content-md-start d-flex pb-3 pb-md-0 px-0">
          <h4
            className={`mb-0 color-white text-center text-md-start ${style.banner}`}
            style={{
              filter: "drop-shadow(0px 2px 2px rgb(0,0,0,0.25))",
            }}
          >
            {/* Get started with free 5Gb Storage space */}
            Get started with 2 month of 5gb space storage space trial
          </h4>
        </div>
        <div className="col-md-4 justify-content-center justify-content-md-end align-items-center d-flex px-0">
          <Link
            className="btn btn-radius-large btn-white btn-shadow px-4"
            to="/registration-pricing"
          >
            Get started
          </Link>
        </div>
      </section>

      <Sitefooter />
    </div>
  );
};
export default Layout;
