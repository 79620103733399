import Layout from "./Layout";
import style from "./Layout.module.css";
import React, { useState } from "react";
import { API, Auth } from "aws-amplify";
import { Email } from "../Models/Mail";
import { getInTouchMail } from "../graphql/mutations";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const alert = useAlert();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const emailData = {
      name: name,
      subject: "Get in touch",
      message: message,
      from: email,
    };
    if (name !== "" && email !== "" && message !== "") {
      setIsLoading(true);
      sendEmail(emailData);
    }
  };
  const sendEmail = async (emailData: Email) => {
    try {
      await Auth.currentAuthenticatedUser()
        .then(async () => {
          await API.graphql({
            query: getInTouchMail,
            variables: {
              name: emailData.name,
              subject: emailData.subject,
              message: emailData.message,
              from: emailData.from,
            },
          });
        })
        .catch(async () => {
          await API.graphql({
            query: getInTouchMail,
            variables: {
              name: emailData.name,
              subject: emailData.subject,
              message: emailData.message,
              from: emailData.from,
            },
            authMode: "AWS_IAM",
          });
        });

      alert.success("Mail Sent Successfully.", { timeout: 5000 });
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      alert.error("Mail not Sent", { timeout: 5000 });
    } finally {
      setIsLoading(false); // Stop loading (whether success or error)
    }
  };

  return (
    <Layout>
      <div
        className="layout-section text-center py-4"
        style={{
          boxShadow: "0px 4px 30px rgb(0,0,0,0.1)",
        }}
      >
        <h1 className={style.banner}>Get in touch!</h1>
        <p>
          We’d love to hear what you think about Inkmark. Share your thoughts
          and be part of our mission to make it easy for everyone to protect
          their digital treasure.
        </p>
      </div>

      <div className="row layout-section py-4 mx-0">
        <div className="col-12 col-md-6 pe-0 pe-md-5 ps-0">
          <h1 className="text-center text-md-start">Contact form</h1>
          <form
            onSubmit={handleSubmit}
            className={`text-center ${style.aboutForm}`}
          >
            <input
              type="text"
              className="form-control orange shadow-none py-3"
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Your name*"
              required
            />
            <input
              type="email"
              className="form-control my-3 orange shadow-none py-3"
              id="name"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Your email*"
              required
            />
            <textarea
              className="form-control orange shadow-none py-3"
              rows={10}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              placeholder="Your message"
            ></textarea>
            <button
              className="btn btn-radius-large btn-primary mt-4 px-4"
              disabled={isLoading}
            >
              {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
            </button>
          </form>
        </div>
        <div className="col-12 col-md-6 ps-0 ps-md-5 pe-0 pt-5 pt-md-0">
          <h1 className="text-center text-md-start">Contact Information</h1>
          <p className="text-center text-md-start">
            <span
              className="d-inline-block"
              style={{
                width: "60px",
              }}
            >
              <img src="/icons/mail.svg" className="me-3" alt="email" />
            </span>
            info@inkmark.io
          </p>
          <h4 className="text-center text-md-start mt-4">
            Have questions we haven’t answered here?
          </h4>
          <p className="text-center text-md-start">
            Our team is on hand to help. We endeavour to answer all questions
            within 24 hours... so ask away!
          </p>

          <h4 className="text-center text-md-start mt-4">Join our team!</h4>
          <p className="text-center text-md-start">
            The Inkmark team is hiring. Please share your CV if you’d like to be
            part of our journey.
          </p>
        </div>
      </div>
    </Layout>
  );
}
