import { useEffect, useState } from "react";
import { GetNotifications, SaveNotifications } from "../../Services/Profile";
import style from "./Setting.module.css";

export default function Notification() {
  const [state, setState] = useState({
    notifyAbout: false,
    emailNotifications: false,
    loading: true,
  });

  useEffect(() => {
    GetNotifications().then((value) =>
      setState({
        ...value,
        loading: false,
      })
    );
  }, []);
  function save() {
    setState({
      ...state,
      loading: true,
    });
    SaveNotifications(state.emailNotifications, state.notifyAbout)
      .then((result) => {})
      .finally(() => {
        setState({
          ...state,
          loading: false,
        });
      });
  }
  // console.log("state",state)
  return (
    <div className={style.notification}>
      <div className="d-flex justify-content-between align-items-end">
        <h5>Manage Notifications </h5>
        <button
          className="btn btn-primary d-none d-xl-inline shadow-none"
          disabled={state.loading}
          onClick={save}
        >
          Save
        </button>
      </div>
      <hr />
      <span className="fw-bold">Notify me about</span>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="notify"
          id="all"
          checked={state.notifyAbout}
          onChange={(event) =>
            setState({
              ...state,
              notifyAbout: event.target.checked,
            })
          }
        />
        <label className="form-check-label" htmlFor="all">
          All activity
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="notify"
          id="nothing"
          checked={!state.notifyAbout}
          onChange={(event) =>
            setState({
              ...state,
              notifyAbout: !event.target.checked,
            })
          }
        />
        <label className="form-check-label" htmlFor="nothing">
          Nothing
        </label>
      </div>
      <span className="fw-bold mt-2 d-inline-block">
        Email me notifications
      </span>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="email"
          id="yes"
          checked={state.emailNotifications}
          onChange={(event) => {
            setState({
              ...state,
              emailNotifications: event.target.checked,
            });
          }}
        />
        <label className="form-check-label" htmlFor="yes">
          Yes
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="email"
          id="no"
          checked={!state.emailNotifications}
          onChange={(event) =>
            setState({
              ...state,
              emailNotifications: !event.target.checked,
            })
          }
        />
        <label className="form-check-label" htmlFor="no">
          No
        </label>
      </div>
      <button
        className="btn btn-primary d-inline d-xl-none shadow-none mt-5"
        onClick={save}
        disabled={state.loading}
      >
        Save
      </button>
    </div>
  );
}
