export const createComment = /* GraphQL */ `
  mutation createComment($content: String!, $userId: ID!, $fileId: ID!) {
    createComment(
      input: { content: $content, fileId: $fileId, userId: $userId }
    ) {
      id
      fileId
      userId
      content
      createdAt
      user {
        name
      }
    }
  }
`;

export const removeShared = /* GraphQL */ `
  mutation removeShared(
    $input: DeleteSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    deleteShared(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateShared = /* GraphQL */ `
  mutation UpdateShared(
    $input: UpdateSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    updateShared(input: $input, condition: $condition) {
      id
      fileId
      userId
    }
  }
`;

export const uploadInit = /* GraphQL */ `
  mutation uploadInit($id: String!, $type: String) {
    multipartUpload(id: $id, type: $type) {
      uploadId
      fileKey
    }
  }
`;

export const uploadNext = /* GraphQL */ `
  mutation uploadNext(
    $id: String!
    $type: String
    $uploadId: String
    $part: Int
    $sha: String
  ) {
    multipartUpload(id: $id, type: $type, uploadId: $uploadId, part: $part, sha: $sha) {
      signedUrl
    }
  }
`;
