import React from "react";
import Layout from "../Home/Layout";
import Accordion from "./accordion";
import style from "./help.module.css"
import { useState } from "react";
import { FAQData } from "../../landing-page/components/faq/data";





export default function Help() {


    const [active, setActive] = useState<number | null>(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState(FAQData);

    const extractTextContent = (element: React.ReactNode): string => {
        if (typeof element === 'string') {
          return element;
        }
        if (React.isValidElement(element)) {
          return extractTextContent(element.props.children);
        }
        if (Array.isArray(element)) {
          return element.map((child) => extractTextContent(child)).join(' ');
        }
        return '';
      };
      const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchQuery(query);
        handleSearch()
      }
    const handleSearch = () => {
        const filtered = FAQData.filter((item) =>{
        const titleMatch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
        const contentText = extractTextContent(item.body).toLowerCase();
        const contentMatch = contentText.includes(searchQuery.toLowerCase());
        return titleMatch || contentMatch;
        
    });
        
        setFilteredItems(filtered);
      };

    return <Layout>
        <h4 className="text-center mt-3 mt-lg-0">Hello, May I help you?</h4>
        <div className="d-flex justify-content-center mt-3">
            <input
                type="search"
                value={searchQuery}
                onChange={handleSearchChange}
                className={`form-control shadow-none border-0 ${style.searchInput}`}
                placeholder="Type your question..." />
            <button onClick={handleSearch} className={`btn btn-primary my-2 shadow-none ${style.searchButton}`}>
                <i className="fas fa-search"></i>
            </button>
        </div>
        
        {/* <div className="my-3 text-start text-lg-center">
            <span className={style.suggestionTitle}>Suggestion :</span>
            <button className={`btn ${style.suggestion} px-2 mx-2 py-1 mb-1 shadow-none`}>Slider</button>
            <button className={`btn ${style.suggestion} px-2 me-2 py-1 mb-1 shadow-none`}>Tutorial</button>
            <button className={`btn ${style.suggestion} px-2 me-2 py-1 mb-1 shadow-none`}>HTML <sub>5</sub></button>
            <button className={`btn ${style.suggestion} px-2 me-2 py-1 mb-1 shadow-none`}>CSS <sub>3</sub></button>
            <button className={`btn ${style.suggestion} px-2 me-2 py-1 mb-1 shadow-none`}>Prototyping</button>
        </div> */}

        <ul className="px-0 pb-1">
      {filteredItems.map((item, index) => (
        <li key={index} className="list-unstyled my-3">
          <Accordion active={active} setActive={setActive} item={item} index={index} />
        </li>
      ))}
    </ul>
    </Layout>
}