import moment from "moment";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

interface Props {
  date: Date;
  handleChange: Function;
  disabled?: boolean;
}
export default function CustomSlider({ date, handleChange, disabled }: Props) {
  const { Handle } = Slider;

  return (
    <div>
      <div className="position-relative mx-3">
        <span
          className="d-inline-block position-absolute start-0 font-weight-medium"
          style={{
            top: "-25px",
            fontStyle: "12px",
          }}
        >
          30
        </span>
        <span
          className="d-inline-block position-absolute top-0 font-weight-medium"
          style={{
            height: "19px",
            width: "1px",
            left: "9px",
            backgroundColor: "#BDC8E0",
          }}
        ></span>
        <Slider
          onChange={(value) => {
            let date = moment().utc().add(value, "days").toDate();
            handleChange(date);
          }}
          min={30}
          max={365}
          included={false}
          disabled={disabled}
          style={{
            marginBottom: "30px",
            marginTop: "30px",
            marginLeft: "9px",
            marginRight: "9px",
            width: "calc(100% - 18px)",
          }}
          handleStyle={{
            width: "19px",
            height: "19px",
            border: "0",
            backgroundColor: "#578CF8",
            zIndex: 9,
          }}
          railStyle={{
            height: "1px",
            background: "#DCE4F5",
            top: "9px",
          }}
          handle={(e) => (
            <Handle {...e}>
              <span
                className="text-nowrap d-inline-block"
                style={{
                  marginTop: "18px",
                  marginLeft: "-80%",
                  color: "#578CF8",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {e.value} Days
              </span>
            </Handle>
          )}
          value={moment(date).diff(moment(), "days", false)}
        />
        <span
          className="d-inline-block position-absolute end-0 font-weight-medium"
          style={{
            top: "-25px",
            fontStyle: "12px",
          }}
        >
          365
        </span>
        <span
          className="d-inline-block position-absolute top-0 font-weight-medium"
          style={{
            height: "19px",
            width: "1px",
            right: "9px",
            backgroundColor: "#BDC8E0",
          }}
        ></span>
      </div>
    </div>
  );
}
