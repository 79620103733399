import { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Popup from "./Components/popup";
import { useAuth } from "../Context/UserContext";
import { useFile } from "../Context/FileContext";
import FolderSVG from "../Icons/FolderSVG";
import { Logout } from "../Services/Authenticate";
import Avatar from "./Components/avatar";
import ButtonBadge from "./Components/ButtonBadge";
import Messages from "./Components/Messages";
import Notifications from "./Components/Notifications";
import SearchButton from "./Components/SearchButton";
import style from "./sideMenu.module.css";

interface Props {
  close?: Function;
}
export default function SideMenu({ close }: Props) {
  const [openMessages, setOpenMessages] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const {
    user,
    messages,
    hasMoreNotifications,
    hasMoreMessages,
    notifications,
    clearNotifications,
    clearUserContext,
  } = useAuth();
  const { clearFileContext } = useFile();

  const history = useHistory();

  const handleLogout = () => {
    clearUserContext && clearUserContext();
    clearFileContext && clearFileContext();
    Logout();
  };

  return (
    <nav
      className={`h-100 bg-white d-flex flex-column justify-content-between ${style.container} py-3 pt-lg-0 pb-lg-3`}
    >
      <div>
        <div
          style={{
            height: "120px",
          }}
          className="d-none d-lg-flex align-items-center justify-content-center"
        >
          <Link to="/platform">
            <img
              src="/images/Logo.svg"
              alt="logo"
              className={`mb-3 ${style.item} ${style.image} w-100 d-lg-block d-none`}
            />
          </Link>
        </div>

        <div className={`${style.item} mb-1 d-lg-none d-block`}>
          <div className="mb-3 d-flex justify-content-between">
            <span
              className="font-weight-bold"
              style={{
                maxWidth: "50%",
              }}
            >
              <Avatar name={user?.name ?? ""} image={user?.id ?? ""} />
              <span
                className="d-inline-block text-truncate ms-1"
                style={{
                  maxWidth: "90%",
                }}
              >
                {user?.name}
              </span>
            </span>
            <ButtonBadge
              length={notifications?.length}
              hasValue={
                (notifications?.length ?? 0) > 0 ||
                (hasMoreNotifications ?? false)
              }
              onClick={() => setOpenNotifications(true)}
            >
              <img src="/icons/Bell.svg" alt="notifications" />
            </ButtonBadge>
            <Popup
              onClose={() => setOpenNotifications(false)}
              open={openNotifications}
              className="sideMenu"
              modal
            >
              {(close: Function) => (
                <div className="bg-white h-100">
                  <div
                    style={{
                      paddingLeft: "5%",
                      paddingTop: "5%",
                    }}
                    className="border-bottom mb-0"
                  >
                    <div className="fw-bold d-flex justify-content-between my-2 pe-2">
                      <span>
                        <button
                          className="btn btn-transparent ps-0 shadow-none"
                          type="button"
                          onClick={() => close()}
                        >
                          <i className="fas fa-chevron-left text-primary"></i>
                        </button>
                        Notifications
                      </span>
                      <button
                        className="btn btn-primary shadow-none"
                        type="button"
                        onClick={() =>
                          clearNotifications && clearNotifications()
                        }
                      >
                        Clear All
                      </button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 20px",
                      }}
                      className="overflow-auto"
                    >
                      <Notifications />
                    </div>
                  </div>
                </div>
              )}
            </Popup>

            <ButtonBadge
              length={messages?.length}
              hasValue={
                (messages?.length ?? 0) > 0 || (hasMoreMessages ?? false)
              }
              onClick={() => {
                setOpenMessages(true);
              }}
            >
              <img src="/icons/Chat Text.svg" alt="Chat" />
            </ButtonBadge>

            <Popup
              onClose={() => setOpenMessages(false)}
              open={openMessages}
              className="sideMenu"
              modal
            >
              {(close: Function) => (
                <div
                  className="bg-white h-100"
                  style={{
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "5%",
                      paddingTop: "5%",
                    }}
                    className="border-bottom mb-0"
                  >
                    <div className="fw-bold d-flex justify-content-between my-2 pe-2">
                      <span>
                        <button
                          className="btn btn-transparent ps-0 shadow-none"
                          type="button"
                          onClick={() => close()}
                        >
                          <i className="fas fa-chevron-left text-primary"></i>
                        </button>
                        Messages
                      </span>
                      <button
                        className="btn btn-primary shadow-none"
                        type="button"
                        onClick={() => {}}
                      >
                        Clear All
                      </button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 20px",
                      }}
                      className="overflow-auto"
                    >
                      <Messages />
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
          <SearchButton />
        </div>
        <ul className="list-unstyled w-100 mb-0">
          <li>
            <NavLink
              to="/platform"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img src="/icons/home.svg" alt="home" className={style.icon} />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/files"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <span className={`${style.icon} d-inline-block`}>
                <FolderSVG color="#3A77F3" />
              </span>
              My files
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/shared"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img src="/icons/share.svg" alt="share" className={style.icon} />
              Shared with me
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/favourites"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img src="/icons/Star.svg" alt="star" className={style.icon} />
              Favorites
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/archived"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img
                src="/icons/archive-filled-box.svg"
                alt="archive"
                className={style.icon}
              />
              Archived files
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/time-lock"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img src="/icons/time.svg" alt="time" className={style.icon} />
              Time-lock
            </NavLink>
          </li>

          <li className="d-block d-lg-none">
            <NavLink
              to="/help"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img
                src="/icons/Question Mark sq-fr.svg"
                alt="help"
                className={style.icon}
              />
              Help
            </NavLink>
          </li>
          <li className="d-block d-lg-none">
            <NavLink
              to="/settings"
              activeClassName={style.active}
              className={`color-black text-decoration-none font-weight-bold ${style.item} py-2 ps-lg-5`}
            >
              <img
                src="/icons/Settings.svg"
                alt="setting"
                className={style.icon}
              />
              Settings
            </NavLink>
          </li>
        </ul>
      </div>

      <ul className="list-unstyled w-100 mb-0">
        <li>
          <NavLink
            to="/help"
            activeClassName={style.active}
            className={`color-black text-decoration-none d-none d-lg-block font-weight-bold ${style.item} py-2 ps-lg-5`}
          >
            <img
              src="/icons/Question Mark sq-fr.svg"
              alt="help"
              className={style.icon}
            />
            Help
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/settings"
            activeClassName={style.active}
            className={`color-black text-decoration-none d-none d-lg-block font-weight-bold ${style.item} py-2 ps-lg-5`}
          >
            <img
              src="/icons/Settings.svg"
              alt="setting"
              className={style.icon}
            />
            Settings
          </NavLink>
        </li>
        <li>
          <Popup
            className="modalPopup"
            trigger={
              <button
                onClick={() => history.push("/")}
                className={`btn color-black shadow-none text-decoration-none d-lg-none d-block font-weight-bold ${style.item} py-2`}
              >
                <img
                  src="/icons/logout.svg"
                  alt="Logout"
                  className={`${style.icon} me-1`}
                />
                Logout
              </button>
            }
            modal
            closeOnDocumentClick={false}
          >
            {(close: Function) => (
              <div
                className="bg-white d-flex flex-column rounded text-center p-0 pt-3"
                style={{
                  filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                  width: "75vw",
                }}
              >
                <p
                  className={`py-3 text-center px-2 fw-bold ${style["fs-24"]}`}
                >
                  Are you sure you want to logout?
                </p>
                <div className="row border-top mx-0">
                  <div className="col-6 border-end">
                    <button
                      className={`btn px-2 py-3 w-100 shadow-none color-dark-grey fw-bold  ${style["fs-24"]}`}
                      onClick={() => close()}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className={`btn px-2 py-3 flex-fill color-blue shadow-none fw-bold ${style["fs-24"]}`}
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        </li>
      </ul>
    </nav>
  );
}
