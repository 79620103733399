import { Route, Switch, useHistory } from "react-router-dom";
import FaqCards from "./FAQGroups";
import FAQLists from "./FAQSingle";
import FAQSearch from "./FAQSearch";
import Layout from "./Layout";
import style from "./FAQ.module.css";

export default function Faq() {
  const history = useHistory();

  return (
    <Layout>
      <div
        className="layout-section text-center py-4 d-flex flex-column align-items-center"
        style={{
          boxShadow: "0px 4px 30px rgb(0,0,0,0.1)",
        }}
      >
        <h1 className={style.banner}>How can we help?</h1>
        <p>Select a topic or search below to get started.</p>

        <div className={`input-group ${style.search}`}>
          <button className="btn shadow-none p-0 ps-2" type="button">
            <i className="fas fa-search color-dark-grey"></i>
          </button>
          <input
            type="search"
            className="form-control orange rounded py-3"
            id="name"
            placeholder="Search help articles"
            required
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                history.push({
                  pathname: "/faq/search",
                  search: "?search=" + event.target.value,
                });
              }
            }}
          />
        </div>
      </div>

      <Switch>
        <Route path="/faq/search" component={FAQSearch} />
        <Route path="/faq/:group/:question?" component={FAQLists} />
        <Route path="/faq" component={FaqCards} />
      </Switch>

      <div className="layout-section">
        <h4
          className={`text-md-center text-center font-weight-bold ${style.homeH2}`}
        >
          What if my question isn't answered here?
        </h4>
        <p className="text-center text-md-center">
          Email us at{" "}
          <a
            href="mailto: info@inkmark.io"
            className="text-decoration-none color-blue"
          >
            info@inkmark.io
          </a>{" "}
          and we'll get back to you as soon as possible
        </p>
      </div>
    </Layout>
  );
}
