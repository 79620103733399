import style from "../styles/files.module.css";
import FileTable from "../Components/TableRows/Table";
import { SharedData } from "../../Models/Document";
import { useEffect, useState } from "react";
import { batchUnshareService, rootFiles } from "../../Services/Shared";
import { useHistory, useParams } from "react-router-dom";
import { SharedRow } from "../Components/TableRows";
import Popup from "../Components/popup";
import { DownloadFiles, Preview } from "../../Services/Document";
import { useOverlay } from "../../Context/OverlayContext";
import Previewer from "../Components/Preview";
import { SharedWithChecked } from "./folder";
import { useAlert } from "react-alert";
import Layout from "../Home/Layout";
import CloseButton from "../Components/closeButton";

export default function Shared() {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = useState(true);
  const [folder, setFolder] = useState<{
    files: SharedWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });
  const { setLoading: setLoadingOverlay } = useOverlay();

  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const alert = useAlert();

  const previewImage = (file: SharedData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoadingOverlay(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoadingOverlay(false));
  };

  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };
  const handleKeyDown = (event: any, file: SharedData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const refresh = () => {
    rootFiles()
      .then((result) => {
        if (result) {
          setFolder({
            loading: false,
            files: result.files,
            token: result.token,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    refresh();
  }, [id]);

  const clickFile = async (file: SharedData) => {
    if (file.type !== "folder") {
      history.push("/shared/file/" + file.id);
    } else {
      history.push("/shared/folder/" + file.id);
    }
  };

  return (
    <Layout>
      <section className="h-100 d-flex flex-column">
        <div className="d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-start mb-2">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3">
              <li
                className="breadcrumb-item active text-black font-weight-semibold"
                aria-current="page"
              >
                Shared with me
              </li>
            </ol>
          </nav>
        </div>
        <div className={`mb-0 ${style.table}`}>
          <FileTable
            border={folder.files.length !== 0}
            hasMore={!!folder.token}
            next={() => {
              if (!!folder.token)
                rootFiles(folder.token).then((values) => {
                  if (values)
                    setFolder((folder) => ({
                      ...folder,
                      files: folder.files.concat(values.files),
                      loading: false,
                      token: values.token,
                    }));
                });
            }}
            loading={loading}
            items={[
              {
                display: (
                  <>
                    {folder.files.filter((file) => file.checked).length !==
                    folder.files.length ? (
                      <>
                        <i className="fas fa-check me-1"></i>
                        Select All
                      </>
                    ) : (
                      <>
                        <i className="far fa-window-close me-1"></i>
                        Deselect All
                      </>
                    )}
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((file) => file.checked).length ==
                    folder.files.length
                  ) {
                    for (let file of folder.files) {
                      handleChecked(file.id, false);
                    }
                  } else {
                    for (let file of folder.files) {
                      handleChecked(file.id, true);
                    }
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-download me-1"></i>
                    Download
                  </>
                ),
                action: () => {
                  setLoading(true);
                  DownloadFiles(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    setLoading(false);
                  });
                },
              },
              {
                display: (
                  <>
                    <img
                      src="/icons/unshare.png"
                      alt="move"
                      className="img-fluid"
                    />{" "}
                    Remove yourself
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                      .length > 0
                  ) {
                    setLoading(true);
                    batchUnshareService(
                      folder.files.filter((x) => x.checked).map((x) => x.id)
                    ).finally(() => {
                      refresh();
                      setLoading(false);
                    });
                  } else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },
            ]}
          >
            {folder.files.map((file, i) => (
              <SharedRow
                onClickChecked={(data: boolean) => handleChecked(file.id, data)}
                file={file}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                onClick={() => clickFile(file)}
                key={file.id}
                onRemove={(id: string) => {
                  setFolder((folder) => ({
                    ...folder,
                    files: folder.files.filter((x) => x.id !== id),
                  }));
                }}
                previewImage={() => previewImage(file)}
                onFavorite={(fileId: any) => {
                  setFolder((folder) => ({
                    ...folder,
                    files: folder.files.map((f) =>
                      f.id === fileId ? { ...f, favorites: true } : f
                    ),
                  }));
                }}
                onUnfavorite={(fileId: any) => {
                  setFolder((folder) => ({
                    ...folder,
                    files: folder.files.map((f) =>
                      f.id === fileId ? { ...f, favorites: false } : f
                    ),
                  }));
                }}
              />
            ))}
          </FileTable>
        </div>
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => setPreview(false)}
        >
          <div
            style={{
              height: "90vh",
              width: "80vw",
            }}
          >
            <CloseButton preview={preview} setPreview={setPreview} />
            <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
          </div>
        </Popup>
      </section>
    </Layout>
  );
}
