import { API } from "aws-amplify";
import { fileActivities } from "../graphql/activityQuerites";

export default async function FetchActivity(id: string, token?: string) {
  try {
    const value = await (API.graphql({
      query: fileActivities,
      variables: {
        id: id,
        token: token
      },
    }) as Promise<{
      data: any;
    }>);
    const activities = value.data.fileActivities.items.map((x: any) => ({
      createdAt: new Date(x.createdAt),
      parameters: x.parameters ?? [],
      userId: x.user.id,
      userName: x.user.name,
      type: x.type,
      id: x.id,
    }));
    return {
      activities,
      token: value.data.fileActivities.nextToken
    };
  } catch (error: any) {
    return undefined;
  }
}
