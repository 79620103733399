import { Auth } from "aws-amplify";
import * as tus from "tus-js-client";
import simpleEvent from "./EventListener";
import { v4 as uuid } from "uuid";

export default class Uploader {
  upload?: tus.Upload;
  id: string;
  percentage: number = 0;
  url: string;
  onUpdatePercentage?: Function;
  paused: boolean = false;
  public onProgressEvent = simpleEvent();
  constructor(
    file: File,
    id: string,
    url: string,
    parent: string,
    uploadUrl: string,
    completeCallback: Function
  ) {
    this.id = id;
    var _this = this;
    this.url = url;
    Auth.currentSession().then((data) => {
      _this.upload = new tus.Upload(
        file,
        {
          // Endpoint is the upload creation URL from your tus server
          endpoint: process.env.REACT_APP_TUS_API,
          removeFingerprintOnSuccess: true,
          // Retry delays will enable tus-js-client to automatically retry on errors
          retryDelays: [0, 3000, 5000, 10000, 20000],
          storeFingerprintForResuming: false,

          // Attach additional meta data about the file for the server
          metadata: {
            id,
            filetype: file.type,
            originalFile: file.name,
            url: url,
            parent: parent,
            uploadUrl,
          },
          headers: {
            Authorization: data.getAccessToken().getJwtToken(),
          },
          // Callback for errors which cannot be fixed using retries
          onError: function (error) {
            console.log("Failed because: " + error);
          },
          // Callback for reporting upload progress
          onProgress: function (bytesUploaded, bytesTotal) {
            // console.log(bytesUploaded, bytesTotal);
            _this.percentage = (bytesUploaded / bytesTotal) * 100;
            _this.onProgressEvent.trigger(
              (bytesUploaded / bytesTotal) * 100,
              _this.id
            );
          },
          // Callback for once the upload is completed
          onSuccess: function () {
            completeCallback();
          },
        }
      );
      // this.startOrResumeUpload(_this.upload);
      _this.upload?.start();
    });
  }

  attachPercentageUpdateFunction(func: Function) {
    this.onUpdatePercentage = func;
  }
  pause() {
    this.upload?.abort();
    this.paused = true;
  }

  startOrResumeUpload(upload: any) {
    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads: any) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }
      upload.start();
    });
  }
  resume() {
    this.startOrResumeUpload(this.upload);
    this.paused = false;
  }

  stop() {
    if (this.upload?.url) tus.Upload.terminate(this.upload.url);
  }
}
