import { FileModel } from "../../Context/FileContext";
import UploadSingle from "../Components/UploadSingle";
interface Props {
  files: FileModel[];
}
export default function Upload({ files }: Props) {
  return (
    <>
      <h1 className="mb-4">Uploading your files to blockchain</h1>
      {files.map((file) => (
        <UploadSingle file={file} type="large" key={file.id} />
      ))}
    </>
  );
}
