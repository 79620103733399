import { useEffect, useRef, useState } from "react";
import ReactAvatar from "react-avatar";
import { GetPicture } from "../../Services/Document";
import Popup from "./popup";

interface Props {
  name: string;
  image: string;
}
export default function Avatar({ name, image }: Props) {
  const [imageSrc, setImageSrc] = useState<string>();
  const [openPopup, setOpenPopup] = useState(false);

  const innerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (
      innerRef.current &&
      !innerRef.current?.contains(event.target) &&
      openPopup
    ) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    GetPicture(image).then((value) => {
      if (value?.success && value.data) {
        setImageSrc(value.data);
      }
    });
  }, [image]);
  return (
    <span className="position-relative" ref={innerRef}>
      <ReactAvatar
        size="30"
        src={imageSrc}
        name={name}
        round={true}
        onClick={() => setOpenPopup(!openPopup)}
      />
      {openPopup && (
        <div
          className="position-absolute block bg-black color-white p-2 text-nowrap mt-2"
          style={{
            zIndex: 99,
          }}
        >
          {name}
        </div>
      )}
    </span>
  );
}
