import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface CloseButtonProps {
  preview: boolean;
  setPreview: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseButton = ({ preview, setPreview }: CloseButtonProps) => {
  const togglePreview = () => {
    setPreview(!preview);
  };

  return (
    <button
      onClick={togglePreview}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        background: "none",
        color: "red",
        border: "none",
        cursor: "pointer",
        zIndex: "1",
      }}
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );
};

export default CloseButton;
