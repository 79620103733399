import { useState, useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Activity as ActivityModel } from "../../Models/Document";
import { DownloadFile, Preview, ViewVersion } from "../../Services/Document";
import style from "../styles/files.module.css";
import Avatar from "./avatar";
import Loading from "./Loading";
import { UserContext } from "../../Context/UserContext";
import { useOverlay } from "../../Context/OverlayContext";
import { FileData, Create as CreateModel } from "../../Models/Document";
import Popup from "../Components/popup";
import Previewer from "../Components/Preview";
import { DateTime } from "luxon";
import CloseButton from "../Components/closeButton";

interface Props {
  activities: ActivityModel[];
  loading?: boolean;
  disabled?: boolean;
  loadMore?: Function;
  hasMore?: boolean;
}

export default function Activity({
  activities,
  loading,
  disabled,
  loadMore,
  hasMore,
}: Props) {
  return (
    <>
      <div className="flex-fill overflow-auto px-4 d-flex flex-column d-lg-none">
        <h4
          className="border-bottom border-2 py-3 mb-0"
          style={{
            fontSize: "20px",
          }}
        >
          Activity History
        </h4>
        <div className="pt-3 mt-3">
          <InfiniteScroll
            loader={
              <div className="text-center">
                <Loading />
              </div>
            }
            useWindow={true}
            loadMore={() => loadMore && loadMore()}
            initialLoad={true}
            hasMore={hasMore}
            className=""
          >
            {Content(loading ?? false, disabled ?? false, activities)}
          </InfiniteScroll>
        </div>
      </div>
      <div
        className="w-100 h-100 p-3 card-radius d-none flex-column border d-lg-flex"
        style={{
          fontSize: "20px",
        }}
      >
        <h4
          className="border-bottom border-2 py-2 mb-0"
          style={{
            fontSize: "20px",
          }}
        >
          Activity History
        </h4>
        <div
          style={{
            overflow: "auto",
            flex: 2,
          }}
          className="mt-2"
        >
          <InfiniteScroll
            loader={
              <div className="text-center">
                <Loading />
              </div>
            }
            useWindow={false}
            loadMore={() => loadMore && loadMore()}
            initialLoad={true}
            hasMore={hasMore}
            className=""
          >
            {Content(loading ?? false, disabled ?? false, activities)}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
}

function Content(
  loading: boolean,
  disabled: boolean,
  activities: ActivityModel[]
) {
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState<string>("");
  const [openMove, setOpenMove] = useState(false);
  const { setLoading } = useOverlay();

  const previewImage = (id: string) => {
    setLoading(true);
    ViewVersion(id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          if (value.type) {
            setFileType(value.type);
          }
        }
      })
      .finally(() => setLoading(false));
  };
  const sharedValue = useContext(UserContext);

  const changeTimeZone = async (localTime: string, userTimezone: string) => {
    const localDateTime = DateTime.fromFormat(
      localTime,
      "MM/dd/yyyy, h:mm:ss a"
    );
    const targetDateTime = localDateTime.setZone(userTimezone);
    const newDateTime = targetDateTime.toFormat("MM/dd/yyyy, hh:mm:ss a");

    if (newDateTime === "Invalid DateTime") {
      return localTime;
    }

    return newDateTime;
  };

  const AsyncModifiedDate = (modified: any) => {
    let formattedDate = "";
    try {
      const date = new Date(modified.modified);

      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "numeric",
        hour12: true,
      };

      const formatter = new Intl.DateTimeFormat("en-US", options);
      formattedDate = formatter.format(date);
    } catch {}
    const [modifiedDate, setModifiedDate] = useState("");
    const userTimezone = sharedValue.user?.locale || "";
    useEffect(() => {
      changeTimeZone(formattedDate, userTimezone)
        .then((result) => {
          setModifiedDate(result);
        })
        .catch((error) => {
          console.error("Error fetching modified date:", error);
          setModifiedDate("");
        });
    }, [modified]);

    return <small>{modifiedDate}</small>;
  };

  if (loading) {
    return <Loading />;
  } else if (disabled) {
    return <p>You don't have access</p>;
  } else {
    return (
      <>
        {activities.map((x) => (
          <div
            className={`d-flex align-items-center ${style.activity}`}
            key={x.id}
          >
            <div className="d-flex flex-column align-items-center align-self-stretch">
              <div
                className={`flex-fill ${style["image-line-up"]} image-line-up text-break`}
              >
                .
              </div>
              <Avatar name={x.userName} image={x.userId} />
              <div
                className={`flex-fill ${style["image-line-down"]} image-line-down  text-break`}
              >
                .
              </div>
            </div>

            <div className="d-flex flex-column ms-3 flex-fill mb-3">
              <span className="font-weight-semibold">{x.userName}</span>
              {GetValue(x.type, x.parameters)}
              {/* <small>{x.createdAt.toLocaleString()}</small> */}

              <AsyncModifiedDate modified={x.createdAt.toLocaleString()} />
            </div>

            {x.type.toUpperCase() === "CREATE" && (
              <button
                className="align-self-start color-blue fw-bold btn"
                onClick={() => {
                  // DownloadFile(x.parameters[0]);
                  previewImage(x.parameters[0]);
                }}
              >
                View version
              </button>
            )}
          </div>
        ))}
        {preview && (
          <Popup
            open={preview}
            closeOnDocumentClick
            className="modalPopup"
            onClose={() => setPreview(false)}
          >
            <div
              style={{
                height: "90vh",
                width: "80vw",
              }}
            >
              <CloseButton preview={preview} setPreview={setPreview} />
              <Previewer
                src={previewFile}
                fileName={fileType}
                autoPlay={false}
              />
            </div>
          </Popup>
        )}
      </>
    );
  }
}
function GetValue(type: string, parameters: string[]) {
  switch (type.toUpperCase()) {
    case "CREATE":
      return (
        <span className="color-orange  font-weight-semibold text-break">
          Uploaded this file
        </span>
      );
    case "NEWFILE":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Added {parameters.length > 0 ? parameters[1] : ""}
        </span>
      );
    case "RENAME":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Renamed file to {parameters.length > 0 ? parameters[0] : ""}
        </span>
      );
    case "MOVEDIN":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Moved {parameters.length > 0 ? parameters[1] : ""} into this folder
        </span>
      );
    case "MOVEDOUT":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Moved {parameters.length > 0 ? parameters[1] : ""} out of this folder
        </span>
      );
    case "MOVED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Moved file into {parameters.length > 0 ? parameters[1] : ""}
        </span>
      );
    case "ARCHIVED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Archived this file
        </span>
      );
    case "UNARCHIVED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Unarchived this file
        </span>
      );
    case "TRANSFERRED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Transferred ownership of file to{" "}
          {parameters.length > 0 ? parameters[1] : ""}
        </span>
      );
    default:
      return <></>;
  }
}
