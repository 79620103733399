import { ReactNode } from "react";
import { Group } from "./groups";
import style from "./Data.module.css";

export interface FAQDataType {
  title: string;
  body: ReactNode;
  groups: Group[];
}
export const FAQData: FAQDataType[] = [
  {
    title: "What is Inkmark?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Inkmark is a secure cloud service where you can secure your digital
            treasure.
          </p>
          <p>
            Inkmark provides a cloud-based environment where you can save your
            files and access them from anywhere. Like common file storage
            solutions, Inkmark provides a centralised place to securely store
            documents, manage data access and work on projects together in a
            team. You can easily share any file, even if it’s too large to
            email.
          </p>
          <p>
            Inkmark does more than your average file storage, however. It’s a
            unique solution that enables you to secure your files using
            blockchain technology, making changes to the files fully auditable
            and enabling you to Timelock access to them. In this way, Inkmark
            makes copyright protection easier and more accessible.
          </p>
          <p>Store, protect and secure your digital treasure with Inkmark.</p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "Who is Inkmark for?",
    body: (
      <>
        <div className={style.font}>
          <p>Anyone can use Inkmark, whether business or household users.</p>
          <p>We think Inkmark is especially useful for:</p>
          <h5>Creatives</h5>
          <ol>
            <li>Song writers</li>
            <li>Producers</li>
            <li>
              Scripts writers or pitching an idea to production and TV companies
            </li>
          </ol>
          <h5>Business industry</h5>
          <ol>
            <li>Lawyers - Contracts</li>
            <li>Confidential papers</li>
            <li>Wills</li>
            <li>Project managers</li>
          </ol>
          <span>** Any creative who needs to protect their ideas **</span>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "Why should I use Inkmark?",
    body: (
      <>
        <div className={style.font}>
          <p>There are plenty of reasons to use Inkmark:</p>
          <ul>
            <li>
              We go beyond file sharing to offer protection and encryption of
              all files uploaded on our platform.
            </li>
            <br />
            <li>
              Inkmark is not just another file storage: users can collaborate
              and share files. ** Inkmark is a file registration platform **
            </li>
            <br />
            <li>
              You can stay connected with co-workers, family and friends to keep
              projects moving forward in a safe and protected environment.{" "}
            </li>
            <br />
            <li>
              It has a clean, fresh design which is intuitive and easy to use.
            </li>
            <br />
            <li>
              Importantly, all files are time stamped with a digital signature
              and given a unique ID (hashtag). Files cannot be deleted or
              changed because all edits are recorded. All contributors to a
              piece of work will be recorded and credited.{" "}
            </li>
            <br />
            <li>With Inkmark, you can protect your digital treasure.</li>
          </ul>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "Why is Inkmark better than other secure digital storage?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Inkmark offers features which give our customers’ confidence that
            their files are protected, and they have control over who will see
            them, when the file can be viewed and accessed, through Inkmark’s
            Timelock feature.{" "}
          </p>
          <p>
            Inkmark uses Blockchain to lock in the date and ownership of your
            digital files, so you can demonstrate ownership at any time and have
            a complete audit trail.
          </p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "How does the Timelock feature work?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Timelock enables you to release a document or file at a certain
            time. Until then the file is locked.
          </p>
          <p>
            This is particularly useful if you want to share the release of a
            file with a group of users on a particular date and time. Timelock
            gives you the control to lock the file and then set a time and date
            for the file to be released.
          </p>
        </div>
      </>
    ),
    groups: [Group.Features, Group.About],
  },
  {
    title: "Where will my files be stored?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Files are stored in the cloud. Cloud storage has many advantages. It
            allows you to access your data from any location and doesn't require
            you to carry around an external hard drive to access your
            information. All your important files are securely and automatically
            backed up to the cloud.
          </p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "How do I know my files are secure?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Inkmark uses the latest encryption technology to ensure all your
            data is stored safely and securely.{" "}
          </p>
          <p>
            Each time a file is uploaded, it is registered on Blockchain,
            creating a new ledger. When any edits are made, information about
            each edit is recorded digitally.{" "}
          </p>
          <p>
            Blockchain secures through cryptography, which produces a signature
            that is encrypted through a private key. The digital signature in
            these certificates can never be forged without having access to the
            private key of the file owner.{" "}
          </p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "How do I access my files?",
    body: (
      <>
        <div className={style.font}>
          <p>An Inkmark account must first be created. </p>
          <p>
            Files are only accessed from the Inkmark platform. The account
            holder can share and invite users to view the files. Each file will
            have a unique ID so you can be confident there will be no duplicate
            records.
          </p>
        </div>
      </>
    ),
    groups: [Group.About, Group.Features],
  },
  {
    title: "How do I know no one else can access my files?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Using unhackable Blockchain, you have peace of mind that the work
            and documentation is secure. In order to access a file, the user
            must have the file Unique ID.{" "}
          </p>
          <p>
            Blockchains manage a large-scale record of transactions and
            additional data wrapped in several layers of data security. Although
            users can access details about transactions, they cannot access
            identifying information about the users making the transactions. In
            order to access data on a Blockchain, one must first become an
            authorised user of that system by having the hashtag ID of the file.
          </p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "Why do you use Blockchain?",
    body: (
      <>
        <div className={style.font}>
          <p>
            Blockchain is a system of recording information in a way that makes
            it difficult or impossible to change, hack or cheat the system. A
            Blockchain is essentially a digital ledger of transactions that is
            duplicated and distributed across the entire network of computer
            systems on the blockchain.
          </p>
          <p>
            {" "}
            Inkmark makes full use of the Blockchain because it increases trust
            with the user and offers depth of security. Each edit made to a file
            creates a new ledger and a hashtag, the Unique ID is created. If an
            edit is made, it is recorded on the Blockchain. The Blockchain
            increases trust, gives security, provides transparency and
            traceability when needing verification of data shared across the
            network.
          </p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "How much does Inkmark cost?",
    body: (
      <>
        <div className={style.font}>
          <p>We offer a Free Lite introductory account 5Gb</p>
          <dl>
            <dt>Monthly</dt>
            <dd>Pro £7.99 up to 100Gb</dd>
            <dd>Pro plus £9.99 up to 250Gb</dd>
            <dt>Yearly</dt>
            <dd>Pro £6.99 up to 100Gb</dd>
            <dd>Pro plus £8.99 up to 250Gb</dd>
          </dl>
          <p>
            If more space is required than the Pro plus package, feel free to
            contact us to discuss the options
          </p>
        </div>
      </>
    ),
    groups: [Group.About],
  },
  {
    title: "I’ve got more questions. How do I get in touch?",
    body: <>Info@inkmark.io</>,
    groups: [Group.About],
  },
];
