import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Profile from "./Profile";
import Notification from "./Notification";
import Invoices from "./Invoices";
import Subscription from "./Subscription";
import style from "./Setting.module.css";
import Layout from "../Home/Layout";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Index() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // Parse the URL path
    const pathSegments = location.pathname.split("/");
    // Get the last segment of the URL path
    const tabName = pathSegments[pathSegments.length - 1];

    // Define the tab index based on the tabParam value
    let tabIndex = 0;

    switch (tabName) {
      case "notification":
        tabIndex = 1;
        break;
      case "subscription":
        tabIndex = 2;
        break;
      case "invoices":
        tabIndex = 3;
        break;
      default:
        tabIndex = 0; // Default to profile tab
    }

    // Set the active tab index
    setActiveTab(tabIndex);
  }, [location.search]);

  return (
    <Layout>
      <Tabs
        selectedTabClassName={style.tabSelected}
        selectedIndex={activeTab}
        onSelect={(index) => setActiveTab(index)}
      >
        <TabList className={style.tabs}>
          <Tab>Profile</Tab>
          <Tab>Notification</Tab>
          <Tab>Subscription</Tab>
          <Tab>Invoices</Tab>
        </TabList>
        <TabPanel>
          <Profile />
        </TabPanel>
        <TabPanel>
          <Notification />
        </TabPanel>
        <TabPanel>
          <Subscription />
        </TabPanel>
        <TabPanel>
          <Invoices />
        </TabPanel>
      </Tabs>
    </Layout>
  );
}
