import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { PasswordChange } from "../../Models/Profile";
import style from "./Setting.module.css";
import { ChangePassword as ChangePasswordService } from "../../Services/Profile";
import { ErrorMessage } from "@hookform/error-message";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomError from "../../Helper/CustomErrorMessage";
import { useAlert } from "react-alert";
import { Logout } from "../../Services/Authenticate";

import { useAuth } from "../../Context/UserContext";
import { useFile } from "../../Context/FileContext";
import Popup from "../Components/popup";
import { useState } from "react";
import { changePasswordMail } from "../../graphql/mutations";
import { API, Auth } from "aws-amplify";

interface Props {
  close: Function;
  admin?: boolean;
}

export default function ChangePassword({ close, admin }: Props) {
  const { clearUserContext, user } = useAuth();
  const { clearFileContext } = useFile();
  const [preview, setPreview] = useState(false);
  const alert = useAlert();
  const formSchema = Yup.object().shape({
    NewPassword: Yup.string().required("Password is required"),
    ConfirmPassword: Yup.string().oneOf(
      [Yup.ref("NewPassword")],
      "Passwords must and should match"
    ),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<PasswordChange>(validationOpt);

  const handleLogout = () => {
    clearUserContext && clearUserContext();
    clearFileContext && clearFileContext();
    close();
    Logout();
  };

  const sendEmail = async (user: any) => {
    try {
      await Auth.currentAuthenticatedUser()
        .then(async () => {
          await API.graphql({
            query: changePasswordMail,
            variables: {
              name: user.name,
              email: user.email,
            },
          });
        })
        .catch(async (error) => {});
    } catch (error) {
      alert.error("Mail not Sent", { timeout: 5000 });
    } finally {
    }
  };

  const onSubmit = async (data: PasswordChange) => {
    var result = await ChangePasswordService(data);

    if (result.success) {
      sendEmail(user);
      setPreview(true);

      setTimeout(() => handleLogout(), 5000);
    } else {
      let resultType = result.type as any;
      setError(resultType, { message: result.message });
    }
  };

  return (
    <form className={style.changePassword} onSubmit={handleSubmit(onSubmit)}>
      <h5>
        Password{" "}
        <button
          className="btn float-end color-red p-0 shadow-none"
          onClick={() => close()}
        >
          <i className="fas fa-times-circle color-red"></i>
        </button>
      </h5>
      <p>
        Set a strong password to prevent unauthorized access to your account
      </p>
      <hr />
      <div className="row">
        <div className="col-12 col-xl-8 pe-0">
          <label className="form-label" htmlFor="oldPassword">
            Current Password
          </label>
          <input
            className="form-control shadow-none"
            type="password"
            {...register("OldPassword")}
            required
            minLength={8}
          />
          <ErrorMessage
            errors={errors}
            name="OldPassword"
            render={({ messages, message }) => (
              <CustomError messages={messages} messageSingle={message} />
            )}
          />
        </div>
        {admin ? (
          <div className="col-12 col-xl-4 d-flex align-items-end justify-content-xl-end">
            <Link to="forgot-password text-decoration-none text-end">
              Forgot Password?
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={style.formItem}>
        <label className="form-label" htmlFor="newPassword">
          New Password
        </label>
        <input
          className="form-control shadow-none"
          type="password"
          {...register("NewPassword")}
          required
          minLength={8}
        />
        <ErrorMessage
          errors={errors}
          name="NewPassword"
          render={({ messages, message }) => (
            <CustomError messages={messages} messageSingle={message} />
          )}
        />
        <small>Include at least 8 characters</small>
      </div>
      <div className={style.formItem}>
        <label className="form-label" htmlFor="confirmPassword">
          Confirm New Password
        </label>
        <input
          className="form-control shadow-none"
          type="password"
          {...register("ConfirmPassword")}
          required
          minLength={8}
        />
        <ErrorMessage
          errors={errors}
          name="ConfirmPassword"
          render={({ messages, message }) => (
            <CustomError messages={messages} messageSingle={message} />
          )}
        />
      </div>
      <button className="btn btn-faded-primary mt-3 shadow-none">
        Change Password
      </button>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => {
          console.log("close");
          setPreview(false);
        }}
      >
        <div
          className="bg-white p-1  d-flex "
          style={{
            width: "300px",
            height: "170px",
            filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
            borderRadius: "10px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: "35px",
            }}
          >
            <p>
              Your password has been successfully changed. You will be required
              to log in after password reset
            </p>
          </div>
        </div>
      </Popup>
    </form>
  );
}
