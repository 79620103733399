import FAQCard from "./components/faqcard";
import { groups } from "./components/faq/groups";

export default function FaqCards() {
  return (
    <section className="layout-section py-4">
      <div className="row">
        {groups.map((x, i) => (
          <div
            className="col-12 col-md-6 mb-3 d-flex justify-content-center align-items-center"
            key={i}
          >
            <FAQCard header={x.header} text={x.description} id={x.group} />
          </div>
        ))}
      </div>
    </section>
  );
}
