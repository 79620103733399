import { useEffect } from "react";
import style from "../../styles/files.module.css";
import FileTable from "../../Components/TableRows/Table";
import Comment from "../../Components/Comment";
import { Link, useHistory } from "react-router-dom";
import DropFiles from "../../Components/Dropfiles";
import {
  Comment as CommentModel,
  SharedData,
  Create as CreateModel,
} from "../../../Models/Document";
import { useState } from "react";
import { Create, DownloadFiles, Preview } from "../../../Services/Document";
import { v4 as uuid } from "uuid";
import { SharedRow } from "../../Components/TableRows";
import { batchUnshareService, GetNextFiles } from "../../../Services/Shared";
import DesktopUpload from "../../Components/DesktopUpload";
import Popup from "../../Components/popup";
import Previewer from "../../Components/Preview";
import { SharedWithChecked } from "../folder";
import { useOverlay } from "../../../Context/OverlayContext";
import { useAlert } from "react-alert";
import CloseButton from "../../Components/closeButton";
import { IoArrowBackCircle } from "react-icons/io5";

interface props {
  comments: CommentModel[];
  files: SharedWithChecked[];
  id: string;
  createFile: Function;
  unshare: Function;
  token: string;
  onNext: (files: SharedWithChecked[], token: string) => any;
  loading: boolean;
  loadingFiles: boolean;
  breadcrumbs: any[];
  folderName: string;
  onFavorite: Function;
  onClickChecked: Function;
  onUnfavorite: Function;
  refresh: Function;
}
export default function Folder({
  comments,
  files,
  id,
  createFile,
  unshare,
  token,
  onNext,
  loadingFiles,
  breadcrumbs,
  folderName,
  onFavorite,
  onUnfavorite,
  onClickChecked,
  refresh,
}: props) {
  const [createFolder, setCreateFolder] = useState(false);
  const history = useHistory();

  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const [folderCreating, setFolderCreating] = useState(false);
  const { setLoading } = useOverlay();
  const [firstId, setFirstId] = useState(null);
  const [prevId, setPrevId] = useState("/shared");
  const alert = useAlert();
  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        type: "folder",
        parent: id,
        size: 0,
        id: uuid(),
        uploadUrl: undefined,
      };
      await Create(document);
      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
    }
  };

  const previewImage = (file: SharedData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    Preview(file.id).then((value) => {
      if (value?.success && value.data) {
        setPreviewFile(value.data);
        setPreview(true);
        setFileType(fileType());
      }
    });
  };

  const handleKeyDown = (event: any, file: SharedData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const clickFile = async (file: SharedData) => {
    if (file.type !== "folder") {
      history.push("/shared/file/" + file.id);
    } else {
      history.push("/shared/folder/" + file.id);
    }
  };

  breadcrumbs.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });

  useEffect(() => {
    breadcrumbs.map((x, index) => {
      if (breadcrumbs.length === 1) {
        setPrevId(x.id);
      }
      if (breadcrumbs.length === 2) {
        setPrevId(x.id);
      }
      if (breadcrumbs.length === 3) {
        setPrevId(x.id);
      }
    });

    if (breadcrumbs.length === 0) {
      setPrevId("/shared");
    }
  }, [breadcrumbs]);
  return (
    <section className="h-100 row">
      <div
        className={`col-12 h-100 d-flex flex-column ${
          id ? "col-lg-8" : "col-lg-12"
        }`}
      >
        <section className="h-100 d-flex flex-column">
          <DropFiles
            callback={createFile}
            parent={id}
            url="shared"
            uploadUrl="file"
          />
          <div className="mt-4 d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-start">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb my-3">
                <li className="breadcrumb-item">
                  <Link to={`${prevId}`}>
                    <IoArrowBackCircle
                      style={{ width: "30px", height: "30px" }}
                    />
                  </Link>
                  <Link
                    className="text-decoration-none color-dark-grey"
                    to="/shared"
                  >
                    Shared With Me
                  </Link>
                </li>
                {breadcrumbs.map((x) => (
                  <li className="breadcrumb-item">
                    <Link
                      className="text-decoration-none color-dark-grey"
                      to={"/shared/folder/" + x.id}
                    >
                      {x.name}
                    </Link>
                  </li>
                ))}
                <li className="breadcrumb-item">{folderName}</li>
              </ol>
            </nav>
            <DesktopUpload
              callback={createFile}
              parent={id}
              onCreateFolder={() => setCreateFolder(true)}
              url="shared"
              uploadUrl="file"
              disableMulti={
                files.filter((x) => x.checked).map((x) => x.id).length <= 0
              }
            />
          </div>
          <div className={`mb-lg-5 mb-0 ${style.table}`}>
            <FileTable
              border={files.length !== 0}
              hasMore={!!token}
              next={() => {
                if (!!token)
                  GetNextFiles(id, token).then((values) => {
                    if (values) onNext(values.files, values.token);
                  });
              }}
              loading={loadingFiles}
              items={[
                {
                  display: (
                    <>
                      {files.filter((file) => file.checked).length !==
                      files.length ? (
                        <>
                          <i className="fas fa-check me-1"></i>
                          Select All
                        </>
                      ) : (
                        <>
                          <i className="far fa-window-close me-1"></i>
                          Deselect All
                        </>
                      )}
                    </>
                  ),
                  action: () => {
                    if (
                      files.filter((file) => file.checked).length ==
                      files.length
                    ) {
                      for (let file of files) {
                        onClickChecked(file.id, false);
                      }
                    } else {
                      for (let file of files) {
                        onClickChecked(file.id, true);
                      }
                    }
                  },
                },
                {
                  display: (
                    <>
                      <img
                        src="/icons/unshare.png"
                        alt="move"
                        className="img-fluid"
                      />{" "}
                      Remove yourself
                    </>
                  ),
                  action: () => {
                    if (
                      files.filter((x) => x.checked).map((x) => x.id).length > 0
                    ) {
                      setLoading(true);
                      batchUnshareService(
                        files.filter((x) => x.checked).map((x) => x.id)
                      ).finally(() => {
                        refresh();
                        setLoading(false);
                      });
                    } else {
                      alert.info("No file has been selected", {
                        timeout: 5000,
                      });
                    }
                  },
                },
                {
                  display: (
                    <>
                      <i className="fas fa-file-download me-1"></i>
                      Download
                    </>
                  ),
                  action: async () => {
                    setLoading(true);
                    await DownloadFiles(
                      files.filter((x) => x.checked).map((x) => x.id)
                    ).finally(() => {
                      setLoading(false);
                    });
                  },
                },
              ]}
            >
              {createFolder && (
                <tr>
                  <td colSpan={5}>
                    <div
                      className={`d-flex w-100 ${
                        folderCreating && style.disabled
                      }`}
                    >
                      <img
                        src={`/logos/folder.png`}
                        alt={`folder logo`}
                        className="img-fluid"
                      />
                      <input
                        type="text"
                        className="form-control ms-2 shadow-none d-none d-lg-inline flex-fill"
                        onClick={(e: any) => e.stopPropagation()}
                        onKeyDown={handleFolderKeyEvent}
                        autoFocus
                        disabled={folderCreating}
                      />
                    </div>
                  </td>
                </tr>
              )}

              {files.map((file, i) => (
                <SharedRow
                  file={file}
                  onClick={() => {
                    clickFile(file);
                  }}
                  tabIndex={i}
                  previewImage={() => previewImage(file)}
                  key={file.id}
                  onRemove={unshare}
                  onFavorite={onFavorite}
                  onUnfavorite={onUnfavorite}
                  handleKeyDown={(e) => handleKeyDown(e, file)}
                  onClickChecked={(val: boolean) =>
                    onClickChecked(file.id, val)
                  }
                />
              ))}
            </FileTable>
          </div>
        </section>
      </div>

      <div className="d-none col-lg-4  d-lg-flex h-100 flex-column">
        <div
          className="h-100"
          style={{
            flex: 2,
            overflow: "auto",
          }}
        >
          <Comment
            fileId={id}
            comments={comments}
            next={function () {
              throw new Error("Function not implemented.");
            }}
            hasMore={false}
          />
        </div>
      </div>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => setPreview(false)}
      >
        <div
          style={{
            height: "90vh",
            width: "80vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
        </div>
      </Popup>
    </section>
  );
}
