import style from "../styles/files.module.css";
import FileTable from "../Components/TableRows/Table";
import DropFiles from "../Components/Dropfiles";
import MobileUpload from "../Components/MobileUpload";
import { useEffect, useRef, useState } from "react";
import FavoriteRow from "../Components/TableRows/Favorites";
import {
  batchRemove,
  Create,
  getRootFavorites,
  parentListener,
  remove,
} from "../../Services/favorites";
import { SharedData, Create as CreateModel } from "../../Models/Document";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { useOverlay } from "../../Context/OverlayContext";
import DesktopUpload from "../Components/DesktopUpload";
import { DownloadFiles, Preview } from "../../Services/Document";
import Popup from "../Components/popup";
import Previewer from "../Components/Preview";
import { SharedWithChecked } from "./components/folder";
import Layout from "../Home/Layout";
import CloseButton from "../Components/closeButton";
// import { useParams } from "react-router-dom";

export default function Favorites() {
  // const { id } = useParams<{ id: string }>();
  const [createFolder, setCreateFolder] = useState(false);
  const [folderCreating, setFolderCreating] = useState(false);
  const parentSubscriber = useRef<any>();
  const history = useHistory();
  const { setLoading } = useOverlay();
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [folder, setFolder] = useState<{
    files: SharedWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });

  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");

  const previewImage = (file: SharedData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoading(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoading(false));
  };

  const handleKeyDown = (event: any, file: SharedData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        type: "folder",
        parent: "0",
        id: uuid(),
        size: 0,
        uploadUrl: undefined,
      };
      const out = await Create(document);

      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
    }
  };

  const createFile = async (fileId: string, file: File) => {
    let value: CreateModel = {
      id: fileId,
      name: file.name,
      type: file.type,
      size: file.size,
      parent: "0",
      uploadUrl: "favorite",
    };
    await Create(value);
  };

  const clickFile = async (file: SharedData) => {
    if (file.type !== "folder") {
      history.push("/favourites/file/" + file.id);
    } else {
      history.push("/favourites/folder/" + file.id);
    }
  };
  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };

  const refresh = () => {
    getRootFavorites()
      .then((files) => {
        if (files) {
          setFolder((folder) => ({
            loading: false,
            files: files.files,
            token: files.token,
          }));
        }
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  };
  useEffect(() => {
    refresh();
    parentListener("0", (value: any) => {
      setFolder((folder) => ({
        ...folder,
        files: [value].concat(folder.files),
      }));
    }).then((value) => {
      parentSubscriber.current = value;
    });

    return () => {
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
    };
  }, []);

  return (
    <Layout>
      <MobileUpload
        callback={createFile}
        parent="0"
        url="favourites"
        uploadUrl="favorite"
      />
      <section className="h-100 d-flex flex-column">
        <DropFiles
          callback={createFile}
          parent="0"
          url="favourites"
          uploadUrl="favorite"
        />
        <div className="mt-4 d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-center">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3">
              <li
                className="breadcrumb-item active text-black font-weight-semibold"
                aria-current="page"
              >
                Favorites
              </li>
            </ol>
          </nav>
          <DesktopUpload
            callback={createFile}
            parent="0"
            onCreateFolder={() => setCreateFolder(true)}
            url="favourites"
            uploadUrl="favorite"
            disableMulti={
              folder.files.filter((x) => x.checked).map((x) => x.id).length <= 0
            }
          />
        </div>
        <div className={`mb-lg-5 mb-0 ${style.table}`}>
          <FileTable
            border={folder.files.length !== 0}
            hasMore={!!folder.token}
            next={() => {
              if (!!folder.token)
                getRootFavorites(folder.token).then((value) => {
                  if (value) {
                    setFolder((folder) => ({
                      ...folder,
                      files: folder.files.concat(value.files),
                      loading: false,
                      token: value.token,
                    }));
                  }
                });
            }}
            items={[
              {
                display: (
                  <>
                    {folder.files.filter((file) => file.checked).length !==
                    folder.files.length ? (
                      <>
                        <i className="fas fa-check me-1"></i>
                        Select All
                      </>
                    ) : (
                      <>
                        <i className="far fa-window-close me-1"></i>
                        Deselect All
                      </>
                    )}
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((file) => file.checked).length ==
                    folder.files.length
                  ) {
                    for (let file of folder.files) {
                      handleChecked(file.id, false);
                    }
                  } else {
                    for (let file of folder.files) {
                      handleChecked(file.id, true);
                    }
                  }
                },
              },
              {
                display: (
                  <>
                    <img
                      src="/icons/Star Off.png"
                      alt="move"
                      className="img-fluid"
                    />{" "}
                    Remove from favorites
                  </>
                ),
                action: () => {
                  setLoading(true);
                  batchRemove(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    refresh();
                  });
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-download me-1"></i>
                    Download
                  </>
                ),
                action: async () => {
                  setLoading(true);
                  await DownloadFiles(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    setLoading(false);
                  });
                },
              },
            ]}
            favorites={true}
            loading={loadingFiles}
          >
            {createFolder && (
              <tr>
                <td colSpan={7}>
                  <div
                    className={`d-flex w-100 ${
                      folderCreating && style.disabled
                    }`}
                  >
                    <img
                      src={`/logos/folder.png`}
                      alt={`folder logo`}
                      className="img-fluid"
                    />
                    <input
                      type="text"
                      className="form-control ms-2 shadow-none d-none d-lg-inline flex-fill"
                      onClick={(e: any) => e.stopPropagation()}
                      onKeyDown={handleFolderKeyEvent}
                      autoFocus
                      disabled={folderCreating}
                    />
                  </div>
                </td>
              </tr>
            )}

            {folder.files.map((file, i) => (
              <FavoriteRow
                file={file}
                onClick={() => {
                  clickFile(file);
                }}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                key={file.id}
                onUnfavorite={() => {
                  setLoading(true);
                  remove(file.id)
                    .then(() => {
                      setFolder({
                        ...folder,
                        files: folder.files.filter((x) => x.id !== file.id),
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
                onClickChecked={(data: boolean) => handleChecked(file.id, data)}
              />
            ))}
          </FileTable>
        </div>
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => setPreview(false)}
        >
          <div
            style={{
              height: "90vh",
              width: "80vw",
            }}
          >
            <CloseButton preview={preview} setPreview={setPreview} />
            <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
          </div>
        </Popup>
      </section>
    </Layout>
  );
}
