export default function UploadSVG({
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) {
    return <svg width="100%" height="100%" viewBox="0 0 18 18">
        <path id="upload_3_"
            data-name="upload(3)"
            d="M18,16.875A1.125,1.125,0,0,1,16.875,18H1.125a1.125,1.125,0,1,1,0-2.25h15.75A1.125,1.125,0,0,1,18,16.875ZM5.014,6.236a1.121,1.121,0,0,0,.8-.33L7.875,3.841v8.675a1.125,1.125,0,0,0,2.25,0V3.841L12.19,5.907a1.125,1.125,0,0,0,1.591-1.591L9.8.33A1.125,1.125,0,0,0,8.2.33L4.219,4.316a1.125,1.125,0,0,0,.8,1.921Z"
            transform="translate(0 0)"
            fill={color} />
    </svg>

}