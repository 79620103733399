import style from "./ButtonBadge.module.css";
interface Props {
  children: React.ReactNode;
  hasValue: boolean;
  onClick: Function;
  classList?: string;
  length: number | undefined;
}
export default function ButtonBadge({
  children,
  length,
  hasValue,
  onClick,
  classList,
}: Props) {
  return (
    <button
      type="button"
      className={`btn p-0 position-relative shadow-none ${classList}`}
      onClick={() => onClick()}
    >
      {children}
      {hasValue && (
        <span
          className={`position-absolute top-0 start-100 ${style.translate} badge rounded-pill bg-orange`}
        >
          {/* <i className="fas fa-plus"></i> */}
          {length}
          <span className="visually-hidden">unread messages</span>
        </span>
      )}
    </button>
  );
}
