import { useEffect, useRef, useState } from "react";
import { FileModel } from "../../Context/FileContext";
import Progress from "./Progress";
interface props {
  files: FileModel[];
  onDetailsClick: Function;
}

export default function UploadGroup({ files, onDetailsClick }: props) {
  const [percentStore, setPercentStore] = useState<{ string?: number }>(
    Object.fromEntries(files.map((x) => [x.id, x.percentage as any]))
  );
  const firstRender = useRef<boolean>(false);

  const listener = (temp: number, id: string) =>
    setPercentStore((percentStore: any) => ({
      ...percentStore,
      ...{ [id]: temp },
    }));
  useEffect(() => {
    if (!firstRender.current) {
      files.map((x) => (x as any).onProgressEvent.addListener(listener));
      firstRender.current = true;
    }
    return () => {
      files.map((x) => (x as any).onProgressEvent.removeListener(listener));
    };
  }, [files]);

  return (
    <div className="border card-radius mb-2 py-2 px-3">
      <div className="d-flex justify-content-between align-items-start">
        {Object.values(percentStore).reduce((sum, a) => sum + a, 0) /
          Object.values(percentStore).length <
        100 ? (
          <>
            <p className="mb-0 font-weight-medium">
              Uploading{" "}
              {Object.values(percentStore).filter((x) => x < 100).length} files
              <span className="color-orange ms-1">
                {(
                  Object.values(percentStore).reduce((sum, a) => sum + a, 0) /
                  Object.values(percentStore).length
                ).toFixed(2)}
                %
              </span>
            </p>

            <span>
              <button
                className="btn btn-transparent shadow-none p-0"
                onClick={() => {}}
              >
                <i className="far fa-pause-circle color-dark-grey"></i>
              </button>
              <button
                className="btn btn-transparent shadow-none px-1 py-0"
                onClick={() => {}}
              >
                <i className="far fa-times-circle color-red"></i>
              </button>
            </span>
          </>
        ) : (
          <>
            <p className="mb-0 fw-bold">Uploaded</p>
          </>
        )}
      </div>
      <div className="position-relative">
        <Progress
          now={
            Object.values(percentStore).reduce((sum, a) => sum + a, 0) /
            Object.values(percentStore).length
          }
          color="#FDA758"
        />
      </div>
      <div className="text-center border-top">
        <button
          className="btn btn-link shadow-none"
          onClick={() => onDetailsClick && onDetailsClick()}
        >
          View Details
        </button>
      </div>
    </div>
  );
}
