import Layout from "../layout";
import style from "./timeLock.module.css";
import { useParams } from "react-router-dom";
import MobileUpload from "../Components/MobileUpload";
import { FileData, member } from "../../Models/Document";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChangeDate, GetFolder } from "../../Services/Time-Lock";
import { Create as CreateModel } from "../../Models/Document";
import { Create } from "../../Services/Time-Lock";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Sharing from "./Components/Sharing";
import Inactive from "./Components/Inactive";
import Folder from "./Components/folder";
import debounce from "../../Helper/Debounce";
import { parentListener } from "../../Services/Document";
import { useOverlay } from "../../Context/OverlayContext";
import { renameFileService } from "../../Services/Document";
import getBreadCrumb from "../File/BreadCrumb";
import { FileWithChecked } from "../Home/home";
import { useAlert } from "react-alert";
import { files } from "../../Services/Shared";

export default function TimeLockFolder() {
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useOverlay();
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [folderName, setFolderName] = useState<string>("");
  const [members, setMembers] = useState<member[]>([]);
  const [folderChildrenIds, setFolderChildrenIds] = useState<[]>([]);
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [disabled, setDisabled] = useState(false);
  const changeDate = useRef<boolean>(false);
  const parentSubscriber = useRef<any>();
  const [folder, setFolder] = useState<{
    files: FileWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });
  const [loadingFiles, setLoadingFiles] = useState(true);
  const alert = useAlert();
  const rename = async (id: string, name: string) => {
    // setLoading(true);
    await renameFileService(id, name);
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((f) => (f.id === id ? { ...f, name: name } : f)),
    }));
    // setLoading(false);
  };

  useEffect(() => {
    GetFolder(id)
      .then((files: any) => {
        setFolder({
          files: files.files,
          token: files.token,
          loading: false,
        });
        setMembers(files.members);
        setActiveDate(files.date);
        setBreadCrumbItems(getBreadCrumb(files.parent));
        setFolderName(files.name);
        setFolderChildrenIds(files.files);
      })
      .finally(() => {
        setLoadingFiles(false);
      });
    parentListener(id, (value: any) => {
      setFolder((folder) => ({
        ...folder,
        files: [
          { ...value, activeDate: new Date(value.activeDate) },
          ...folder.files.filter((x) => x.id !== value.id),
        ],
      }));
    }).then((value) => {
      parentSubscriber.current = value;
    });

    return () => {
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
    };
  }, [id]);

  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };
  const dateChangeCallback = useCallback(
    debounce(700, (date: Date, id: string) => {
      setDisabled(true);
      ChangeDate(date, id)
        .then((val) => {
          if (val) {
            alert.success("Timelock has been updated successfully", {
              timeout: 5000,
            });
          } else {
            alert.error("Error updating timelock", { timeout: 5000 });
          }
        })
        .finally(() => setDisabled(false));
    }),
    []
  );

  const refresh = () => {
    GetFolder(id).then((files: any) => {
      setFolder((folder) => ({
        ...folder,
        files: files.files,
        token: files.token,
      }));
      setMembers(files.members);
      setActiveDate(files.date);
      setBreadCrumbItems(getBreadCrumb(files.parent));
      setFolderName(files.name);
    });
  };

  useEffect(() => {
    if (changeDate.current) {
      dateChangeCallback(activeDate, id);
    }
  }, [activeDate, id, dateChangeCallback]);

  const createFile = async (fileId: string, file: File) => {
    let value: CreateModel = {
      id: fileId,
      name: file.name,
      parent: id,
      size: file.size,
      type: file.type,
      uploadUrl: "file",
    };
    await Create(value);
  };

  return (
    <Layout>
      <div className="d-lg-none h-100">
        <Tabs
          selectedTabClassName={style.tabSelected}
          className="d-flex flex-column h-100"
          selectedTabPanelClassName="d-flex  flex-fill flex-column"
        >
          <TabList className={style.tabs}>
            <Tab>Home</Tab>
            <Tab>Sharing</Tab>
            <Tab>Inactivity Period</Tab>
          </TabList>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              locked={true}
              url="time-lock"
              uploadUrl="time-lock"
            />
            <Folder
              files={folder.files}
              id={id}
              members={members}
              refresh={refresh}
              folderChildrenIds={folderChildrenIds}
              date={activeDate}
              handleDateChange={(date: Date) => {
                setActiveDate(date);
                changeDate.current = true;
              }}
              createFile={createFile}
              name={folderName}
              onClickChecked={(val: string, data: boolean) =>
                handleChecked(val, data)
              }
              token={folder.token}
              onUnlock={(id: string) =>
                setFolder((folder) => ({
                  ...folder,
                  files: folder.files.filter((f) => f.id !== id),
                }))
              }
              onNext={(files, token) => {
                setFolder({
                  ...folder,
                  files: [...folder.files, ...files],
                  token: token,
                });
              }}
              rename={rename}
              loadingFiles={loadingFiles}
              breadcrumbs={breadcrumbItems}
            />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              locked={true}
              url="time-lock"
              uploadUrl="time-lock"
            />
            <Sharing
              fileId={id}
              members={members}
              refresh={refresh}
              folderChildrenIds={folderChildrenIds}
            />
          </TabPanel>
          <TabPanel>
            <MobileUpload
              callback={createFile}
              parent={id}
              locked={true}
              url="time-lock"
              uploadUrl="time-lock"
            />
            <Inactive
              date={activeDate}
              handleChange={setActiveDate}
              disabled={disabled}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="d-none d-lg-block h-100">
        <Folder
          files={folder.files}
          id={id}
          members={members}
          folderChildrenIds={folderChildrenIds}
          refresh={refresh}
          date={activeDate}
          handleDateChange={(date: Date) => {
            setActiveDate(date);
            changeDate.current = true;
          }}
          createFile={createFile}
          token={folder.token}
          onUnlock={(id: string) =>
            setFolder((folder) => ({
              ...folder,
              files: folder.files.filter((f) => f.id !== id),
            }))
          }
          breadcrumbs={breadcrumbItems}
          name={folderName}
          onClickChecked={(val: string, data: boolean) =>
            handleChecked(val, data)
          }
          onNext={(files, token) => {
            setFolder({
              ...folder,
              files: [...folder.files, ...files],
              token: token,
            });
          }}
          rename={rename}
          loadingFiles={loadingFiles}
          disabled={disabled}
        />
      </div>
    </Layout>
  );
}
