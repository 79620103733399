import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Login as Model } from "../Models/Authentication";
import Layout from "./layout";
import style from "./layout.module.css";
import { Login as LoginService } from "../Services/Authenticate";
import { ErrorMessage } from "@hookform/error-message";
import CustomError from "../Helper/CustomErrorMessage";
import { useOverlay } from "../Context/OverlayContext";
// import { FiEye, FiEyeOff } from "react-icons/fi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const { setLoading } = useOverlay();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Model>();
  const { state } = useLocation<{ reset: boolean }>();

  const handleLogin = async (data: Model) => {
    setLoading(true);
    var result = await LoginService(data);
    if (result.success) {
      if (!!result.pay) {
        history.push("/payment");
      } else history.push("/platform");
    } else {
      let resultType = result.type as any;
      if (resultType === "NewPassword") {
        history.push("/complete-password/" + data.Email + "/" + data.Password);
      }
      setError(resultType, { message: result.message });
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout display={true}>
      <div
        className={`d-flex flex-column justify-content-between justify-content-lg-center align-items-center h-100 ${style["max-vh-100"]}`}
      >
        <p
          className={`text-end ${style.contentWidth}  ${style["fs-18"]} color-dark-grey d-none d-lg-block`}
        >
          <small>New to Inkmark?</small>
          <Link
            className="btn btn-outline-primary ms-3 btn-radius-10 shadow-none px-3 py-1"
            to="/registration"
          >
            Create Account
          </Link>
        </p>
        <div
          className={`justify-content-center d-flex flex-column ${style.form}`}
        >
          <form
            className="text-center text-lg-start "
            onSubmit={handleSubmit(handleLogin)}
          >
            <h1 className={`${style.loginHeader} mb-3`}>Sign in to Inkmark</h1>
            {state != null && state.reset ? (
              <div className="alert alert-warning" role="alert">
                The Password has successfully been reset
              </div>
            ) : (
              ""
            )}

            <ErrorMessage
              errors={errors}
              name="Email"
              render={({ messages, message }) => (
                <CustomError messages={messages} messageSingle={message} />
              )}
            />
            <input
              type="email"
              className="form-control mb-3 shadow-none"
              placeholder="Email"
              required
              {...register("Email")}
            />
            <ErrorMessage
              errors={errors}
              name="Password"
              render={({ messages, message }) => (
                <CustomError messages={messages} messageSingle={message} />
              )}
            />

            <div className="mb-3 position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control shadow-none pr-4"
                placeholder="Password"
                required
                {...register("Password")}
              />
              <button
                type="button"
                className="btn btn-link position-absolute end-0 top-50 translate-middle-y"
                onClick={togglePasswordVisibility}
                style={{ zIndex: 1 }} // Ensure the button is above the input
              >
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
            </div>

            <button
              className="btn  btn-primary btn-radius-10 shadow-none w-100"
              disabled={isSubmitting}
              type="submit"
            >
              Sign in
            </button>
          </form>

          <p
            className={`text-end ${style.contentWidth} mt-2 ${style["fs-18"]} color-dark-grey d-lg-none w-100 d-flex justify-content-between`}
          >
            <small>Forgot your password?</small>
            <Link
              className="btn btn-outline-primary ms-3 btn-radius-10 shadow-none px-3 py-1"
              to="/registration"
            >
              Reset
            </Link>
          </p>
        </div>
        <p
          className={`text-end ${style.contentWidth} mt-3 ${style["fs-18"]} color-dark-grey d-none d-lg-block`}
        >
          <small>Forgot your password?</small>
          <Link
            className="btn btn-outline-primary ms-3 shadow-none px-3 py-1"
            to="/reset-password"
          >
            Reset password
          </Link>
        </p>
        <div className="d-block d-lg-none w-100 mt-5">
          <p className={`mb-1 text-center ${style["fs-18"]} color-dark-grey`}>
            New to Inkmark?
          </p>
          <Link
            className="btn btn-outline-primary btn-radius-10 w-100"
            to="/registration"
          >
            Create Account
          </Link>
        </div>
      </div>
    </Layout>
  );
}
