import { ReactNode } from "react";
import { useOverlay } from "../../Context/OverlayContext";
import NewFolderSVG from "../../Icons/NewFolderSVG";
import style from "../styles/files.module.css";
import Popup from "./popup";
interface props {
  callback: Function;
  parent: string;
  onCreateFolder?: Function;
  locked?: boolean;
  url: "shared" | "files" | "time-lock" | "archived" | "favourites";
  uploadUrl: "file" | "replace" | "favorite" | "time-lock";
  items?: {
    display: ReactNode;
    action: Function;
  }[];
  disableMulti?: boolean;
}
export default function DesktopUpload({
  callback,
  parent,
  onCreateFolder,
  locked = false,
  url,
  uploadUrl,
  items,
  disableMulti,
}: props) {
  const { uploadFile } = useOverlay();
  return (
    <span
      className="d-flex justify-content-end"
      style={{
        minWidth: "295px",
      }}
    >
      <label className="btn btn-primary btn-radius-10 h-100 d-none d-lg-inline py-2 px-3 fw-bold">
        <img src="/icons/upload(3).svg" alt="Upload" className="me-1" />
        Upload file
        <input
          type="file"
          id="file"
          className="d-none"
          multiple
          onChange={(event: any) => {
            // console.log("event", event);

            uploadFile(
              Array.from(event.target.files).map((x) => ({
                url: url,
                file: x,
                save: (id: string) => callback(id, x),
              })),
              parent,
              locked,
              uploadUrl
            );
          }}
        />
      </label>
      {onCreateFolder && (
        <button
          className={`btn btn-outline-primary btn-radius-10 ${style.newFolderButton} py-2 px-3 fw-bold mx-2`}
          onClick={() => onCreateFolder(true)}
        >
          <span className="d-inline-block svgIcon me-1">
            <NewFolderSVG color="#3A77F3" />
          </span>
          New Folder
        </button>
      )}
    </span>
  );
}
