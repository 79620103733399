import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { SearchData } from "../../Models/Document";
import { SearchService } from "../../Services/Search";
import debounce from "../../Helper/Debounce";
import Loading from "./Loading";
import { Link } from "react-router-dom";

export default function SearchButton() {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<SearchData[]>([]);
  const [open, setOpen] = useState(false);

  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (innerRef.current && !innerRef.current?.contains(event.target) && open) {
      setOpen(false);
    }
  };

  const fileUrl = (file: SearchData) => {
    let root = "files";
    if (file.shared) {
      root = "shared";
    } else if (file.root === 1) {
      root = "archived";
    } else if (
      !!file.activeDate &&
      file.activeDate.getTime() > new Date().getTime()
    ) {
      root = "time-lock";
    }

    return `/${root}/${file.type === "folder" ? "folder" : "file"}/${file.id}`;
  };

  const fetchFiles = (term: string) => {
    setLoading(true);
    SearchService(term, undefined)
      .then((result) => {
        if (result) {
          // console.log(result);
          setFiles(
            result.files.map((file: any) => ({
              ...file,
              url: fileUrl(file),
            })) ?? []
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const nameChangeCallback = useCallback(
    debounce(300, (term) => {
      fetchFiles(term);
    }),
    []
  );
  return (
    <div className="position-relative" ref={innerRef}>
      <div className="input-group h-100">
        <button
          className="btn btn-radius-large btn-outline-secondary bg-transparent border-0 shadow-none"
          type="button"
          style={{
            zIndex: 9,
            marginRight: "-40px",
            padding: "0px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <i className="fas fa-search color-dark-grey"></i>
        </button>
        <input
          type="search"
          className="form-control rounded shadow-none"
          id="name"
          placeholder="Search for file"
          required
          style={{
            paddingLeft: "40px",
          }}
          onKeyUp={(event: any) => {
            nameChangeCallback(event.target.value);
          }}
          onBeforeInput={() => setOpen(true)}
        />
      </div>
      {open && (
        <div
          className="bg-white start-0 end-0 shadow-lg position-absolute rounded p-3 mt-2"
          style={{
            zIndex: 99,
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          {loading && (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          )}
          {!loading && files.length === 0 && (
            <div className="d-flex justify-content-center align-items-center flex-column">
              {" "}
              <img
                src="/images/Person.svg"
                alt="Person searching"
                className="img-fluid mb-3 w-50"
              />
              <h4 className="text-center">No search result</h4>
              <span className="text-center font-weight-semibold">
                {" "}
                Sorry, there are no results for
                <br />
                this search, Please try another word or phrase.
              </span>
            </div>
          )}
          {!loading && files.length !== 0 && (
            <ul className="list-unstyled">
              {files.map((file) => (
                <li key={file.id} className="border-bottom py-2">
                  <Link to={file?.url}>{file.name}</Link>
                  <br />
                  <small>by {file.uploader.name}</small>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
