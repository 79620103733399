import { createContext, FC, ReactNode, useContext, useState } from "react";
import Loading from "../Platform/Components/Loading";
import { PreUpload } from "../Services/Document";
import { v4 as uuid } from "uuid";
import { useFile } from "./FileContext";
import style from "./context.module.css";

export interface Model {
  uploadFile: Function;
  setLoading: Function;
}

export const OverlayContext = createContext<Model>({
  uploadFile: () => {},
  setLoading: (value: boolean) => {},
});

export const OverlayProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { addFiles } = useFile();

  const [loading, setLoading] = useState(false);
  const [filesInError, setFilesInError] = useState<{
    large: any[];
    name: any[];
  }>({
    large: [],
    name: [],
  });

  const uploadFile = (
    acceptedFiles: { file: File; save: Function; url: string }[],
    parent: string,
    locked: boolean = false,
    uploadUrl: string = "file"
  ) => {
    setLoading(true);
    let files = acceptedFiles.map((x) => ({
      name: x.file.name,
      size: x.file.size,
      id: uuid(),
      file: x.file,
      save: x.save,
      url: x.url,
    }));
    // console.log(files)
    PreUpload(
      JSON.stringify(
        files.map((x) => ({
          name: x.name,
          size: x.size,
          id: x.id,
        }))
      ),
      parent,
      locked
    ).then((result) => {
      if (result) {
        let successIds = result
          .filter((x: any) => x.status === "accepted")
          .map((x: any) => x.id) as string[];
        let successFiles = files
          .filter((x) => successIds.includes(x.id))
          .map((x) => ({
            file: x.file,
            id: x.id,
            save: x.save,
            url: x.url,
            parent: parent,
            uploadUrl,
          }));
        let largeIds = result
          .filter((x: any) => x.status === "unaccepted" && x.type === "large")
          .map((x: any) => x.id) as string[];
        let largeFiles = files
          .filter((x) => largeIds.includes(x.id))
          .map((x) => ({
            file: x.file,
            id: x.id,
          }));
        let nameIds = result
          .filter((x: any) => x.status === "unaccepted" && x.type === "name")
          .map((x: any) => x.id) as string[];
        let nameFiles = files
          .filter((x) => nameIds.includes(x.id))
          .map((x) => ({
            file: x.file,
            id: x.id,
            save: x.save,
            parent,
            overwriteId: result.find((y: any) => x.id === y.id).similarId,
            url: x.url,
            uploadUrl,
          }));
        addFiles(successFiles);
        setLoading(false);
        setFilesInError({
          large: largeFiles,
          name: nameFiles,
        });
      }
    });
  };

  return (
    <OverlayContext.Provider
      value={{
        uploadFile: uploadFile,
        setLoading: setLoading,
      }}
    >
      {(loading || filesInError.name.length > 0) && (
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            backgroundColor: "gray",
            zIndex: 99,
            opacity: 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <Loading />}
        </div>
      )}
      {filesInError.name.length > 0 && (
        <div className={style.error}>
          <div
            style={{
              zIndex: 99,
            }}
            className="h-50 w-50 bg-white text-center p-3"
          >
            <div className={`d-flex justify-content-between ${style.prompt}`}>
              <h5>
                Upload has started on cleared files, how will you like to handle
                files with the same names?
              </h5>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setFilesInError({
                    large: [],
                    name: [],
                  });
                }}
              >
                Cancel Upload
              </button>
            </div>

            <table className="table mt-3">
              <thead>
                <tr>
                  <td colSpan={2} align="right">
                    Overwrite?
                  </td>
                </tr>
              </thead>
              <tbody>
                {filesInError.name.map((x) => (
                  <tr className={style.fileNameDirection} key={x.id}>
                    <td className={style.fileName} align="left">
                      {x.file.name}
                    </td>
                    <td className={style.btnDisplay} align="right">
                      <button
                        className={`btn btn-primary  ${style.btn}`}
                        onClick={() => {
                          addFiles([
                            {
                              id: x.overwriteId,
                              file: x.file,
                              save: x.save,
                              url: x.url,
                              parent: x.parent,
                              uploadUrl: x.uploadUrl,
                            },
                          ]);
                          setFilesInError((files) => ({
                            large: files.large,
                            name: files.name.filter((y) => y.id !== x.id),
                          }));
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className={`btn btn-secondary ms-1  ${style.btn}`}
                        onClick={() => {
                          addFiles([
                            {
                              id: x.id,
                              file: x.file,
                              save: x.save,
                              url: x.url,
                              parent: x.parent,
                              uploadUrl: x.uploadUrl,
                            },
                          ]);
                          setFilesInError((files) => ({
                            large: files.large,
                            name: files.name.filter((y) => y.id !== x.id),
                          }));
                        }}
                      >
                        No
                      </button>
                      <button
                        className={`btn btn-secondary ms-1 ${style.btn}`}
                        onClick={() => {
                          // Handle skip logic here
                          // console.log("File skipped.");
                          // Remove the current file from the list without adding it
                          setFilesInError((files) => ({
                            large: files.large,
                            name: files.name.filter((y) => y.id !== x.id),
                          }));
                        }}
                      >
                        Skip
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => useContext(OverlayContext);
