import style from "./styles/files.module.css";
import FileTable from "./Components/TableRows/Table";
import MobileUpload from "./Components/MobileUpload";
import DropFiles from "./Components/Dropfiles";
import { FileData, Create as CreateModel } from "../Models/Document";
import { useEffect, useRef, useState } from "react";
import {
  DownloadFiles,
  GetFolder,
  GetNextFolder,
  parentListener,
  Preview,
} from "../Services/Document";
import { Link, useHistory, useParams } from "react-router-dom";
import { Create } from "../Services/Document";
import { ArchivedRow } from "./Components/TableRows";
import { v4 as uuid } from "uuid";
import DesktopUpload from "./Components/DesktopUpload";
import getBreadCrumb from "./File/BreadCrumb";
import Popup from "./Components/popup";
import { useOverlay } from "../Context/OverlayContext";
import Previewer from "./Components/Preview";
import { FileWithChecked } from "./Home/home";
import { batchUnarchive } from "../Services/Archive";
import Move from "./Components/Move";
import { useAlert } from "react-alert";
import Layout from "./Home/Layout";
import CloseButton from "./Components/closeButton";
import { IoArrowBackCircle } from "react-icons/io5";

export default function Archived() {
  const [createFolder, setCreateFolder] = useState(false);
  const [folderCreating, setFolderCreating] = useState(false);
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [folderName, setFolderName] = useState("");
  const parentSubscriber = useRef<any>();
  const [firstId, setFirstId] = useState(null);
  const [prevId, setPrevId] = useState("/archived");
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [folder, setFolder] = useState<{
    files: FileWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });
  const { setLoading: setOverlayLoading } = useOverlay();
  const [loading, setLoading] = useState(true);

  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const [move, setMove] = useState(false);
  const alert = useAlert();
  const previewImage = (file: FileData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setOverlayLoading(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => {
        setOverlayLoading(false);
      });
  };

  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };
  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        type: "folder",
        parent: id ?? "1",
        size: 0,
        id: uuid(),
        uploadUrl: undefined,
      };
      await Create(document);
      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
    }
  };

  const createFile = async (fileId: string, file: File) => {
    let value: CreateModel = {
      id: fileId,
      name: file.name,
      type: file.type,
      size: file.size,
      parent: id ?? "1",
      uploadUrl: "file",
    };
    await Create(value);
  };

  const refresh = () => {
    GetFolder(id ?? "1", 1)
      .then((files: any) => {
        setFolder({
          loading: false,
          files: files.files,
          token: files.token,
        });
        setFolderName(files.name);
        setBreadCrumbItems(getBreadCrumb(files.parent));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    refresh();

    parentListener(id ?? "1", (value: any) => {
      setFolder((folder) => ({
        ...folder,
        files: [value].concat(folder.files),
      }));
    }).then((value) => {
      parentSubscriber.current = value;
    });

    return () => {
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
    };
  }, [id]);

  const clickFile = async (file: FileData) => {
    history.push("/archived/" + file.id);
  };

  const unarchiveFile = (id: string) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.filter((f) => f.id !== id),
    }));
  };

  breadcrumbItems.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });

  useEffect(() => {
    breadcrumbItems.map((x, index) => {
      if (breadcrumbItems.length === 1) {
        setPrevId(x.id);
      }
      if (breadcrumbItems.length === 2) {
        setPrevId(x.id);
      }
      if (breadcrumbItems.length === 3) {
        setPrevId(x.id);
      }
    });

    if (breadcrumbItems.length === 0) {
      setPrevId("/archived");
    }
  }, [breadcrumbItems]);

  return (
    <Layout>
      <MobileUpload
        callback={createFile}
        parent={id ?? "1"}
        url="archived"
        uploadUrl="file"
      />
      <section className="h-100 d-flex flex-column">
        <DropFiles
          callback={createFile}
          parent={id ?? "1"}
          url="archived"
          uploadUrl="file"
        />
        <div className="mt-4 d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-start">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3">
              <li className="breadcrumb-item">
                <Link to={`${prevId}`}>
                  <IoArrowBackCircle
                    style={{ width: "30px", height: "30px" }}
                  />
                </Link>
                <Link
                  className="text-decoration-none color-dark-grey"
                  to="/archived"
                >
                  Archived
                </Link>
              </li>
              {breadcrumbItems.length === 3 &&
                !breadcrumbItems.some((item) => item.id === firstId) && (
                  <li className="breadcrumb-item">
                    {" "}
                    <i className="fas fa-ellipsis-h"></i>{" "}
                  </li>
                )}
              {breadcrumbItems.map((x) => (
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none color-dark-grey"
                    to={"/archived/" + x.id}
                  >
                    {x.name}
                  </Link>
                </li>
              ))}
              <li className="breadcrumb-item">{folderName}</li>
            </ol>
          </nav>
          <DesktopUpload
            callback={createFile}
            parent={id ?? "1"}
            onCreateFolder={() => setCreateFolder(true)}
            url="archived"
            disableMulti={
              folder.files.filter((x) => x.checked).map((x) => x.id).length <= 0
            }
            uploadUrl="file"
          />
        </div>
        <div className={`mb-lg-5 mb-0 ${style.table}`}>
          <FileTable
            border={folder.files.length !== 0}
            hasMore={!!folder.token}
            next={() => {
              if (!!folder.token)
                if (id) {
                  GetNextFolder(id, 1, folder.token).then((values) => {
                    if (values)
                      setFolder((folder) => ({
                        files: folder.files.concat(values.files),
                        loading: false,
                        token: values.token,
                      }));
                  });
                } else {
                  GetFolder("1", 1, folder.token).then((files) => {
                    if (!!files) {
                      setFolder((folder) => ({
                        files: folder.files.concat(files.files),
                        loading: false,
                        token: files.token,
                      }));
                    }
                  });
                }
            }}
            loading={loading}
            items={[
              {
                display: (
                  <>
                    {folder.files.filter((file) => file.checked).length !==
                    folder.files.length ? (
                      <>
                        <i className="fas fa-check me-1"></i>
                        Select All
                      </>
                    ) : (
                      <>
                        <i className="far fa-window-close me-1"></i>
                        Deselect All
                      </>
                    )}
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((file) => file.checked).length ==
                    folder.files.length
                  ) {
                    for (let file of folder.files) {
                      handleChecked(file.id, false);
                    }
                  } else {
                    for (let file of folder.files) {
                      handleChecked(file.id, true);
                    }
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-archive text-start me-1"></i>
                    Unarchive
                  </>
                ),
                action: () => {
                  setOverlayLoading(true);
                  batchUnarchive(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    refresh();
                    setOverlayLoading(false);
                  });
                },
              },
              {
                display: (
                  <>
                    <img
                      src="/icons/Move.png"
                      alt="move"
                      className="img-fluid"
                    />{" "}
                    Move to
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                      .length > 0
                  )
                    setMove(true);
                  else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },

              {
                display: (
                  <>
                    <i className="fas fa-file-download me-1"></i>
                    Download
                  </>
                ),
                action: async () => {
                  setLoading(true);
                  await DownloadFiles(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    setLoading(false);
                  });
                },
              },
            ]}
          >
            {createFolder && (
              <tr>
                <td colSpan={6}>
                  <div
                    className={`d-flex w-100 ${
                      folderCreating && style.disabled
                    }`}
                  >
                    <img
                      src={`/logos/folder.png`}
                      alt={`folder logo`}
                      className="img-fluid"
                    />
                    <input
                      type="text"
                      className="form-control ms-2 shadow-none d-none d-lg-inline flex-fill"
                      onClick={(e: any) => e.stopPropagation()}
                      onKeyDown={handleFolderKeyEvent}
                      autoFocus
                      disabled={folderCreating}
                    />
                  </div>
                </td>
              </tr>
            )}

            {folder.files.map((file, i) => (
              <ArchivedRow
                file={file}
                onClick={() => clickFile(file)}
                key={file.id}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                onMove={(fileId: string, selected: string) => {
                  if (selected !== id ?? "1") {
                    setFolder((folder) => ({
                      ...folder,
                      files: folder.files.filter((f) => f.id !== fileId),
                    }));
                  }
                }}
                onUnarchive={(id: string) => {
                  unarchiveFile(id);
                }}
                onClickChecked={(val: boolean) => handleChecked(file.id, val)}
              />
            ))}
          </FileTable>
        </div>
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => setPreview(false)}
        >
          <div
            style={{
              height: "90vh",
              width: "80vw",
            }}
          >
            <CloseButton preview={preview} setPreview={setPreview} />
            <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
          </div>
        </Popup>
        <Popup
          open={move}
          closeOnDocumentClick={false}
          onClose={() => setMove(false)}
          closeOnEscape={false}
          className="menuPopup"
        >
          {(close: Function) => (
            <Move
              close={() => {
                close();
              }}
              ids={folder.files.filter((x) => x.checked).map((x) => x.id)}
              onmove={() => {
                refresh();
              }}
              root={1}
            />
          )}
        </Popup>
      </section>
    </Layout>
  );
}
