import { API, Auth } from "aws-amplify";
import { createComment } from "../graphql/customMutations";
import { onFileCommentCreate } from "../graphql/customSubscriptions";

export async function sendComment(file: string, content: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await API.graphql({
      query: createComment,
      variables: {
        content: content,
        fileId: file,
        userId: user.username,
      },
    });
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function commentListener(fileId: string, callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  return (
    API.graphql({
      query: onFileCommentCreate,
      variables: { fileId: fileId },
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onFileCommentCreate;
      let data = {
        ...temp,
        createdAt: new Date(temp.createdAt),
        userComment: temp.userId === user.username,
        name: temp.user.name,
      };
      callBack(data);
    },
  });
}
