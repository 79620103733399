/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchFilesAndSharedFiles = /* GraphQL */ `
  query SearchFilesAndSharedFiles(
    $name: String
    $userId: String
    $nextToken: String
    $limit: Int
  ) {
    searchFilesAndSharedFiles(
      name: $name
      userId: $userId
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        ... on File {
          id
          name
          type
          size
          userId
          uploaderId
          parent
          modified
          created
          root
          sharedCount
          lastUploadType
          activeDate
          folderOrFile
          groups
        }
        ... on Shared {
          id
          fileId
          userId
          modified
          created
          email
          view
          archived
          count
          relationship
          name
          activeDate
          fileOwner
          groups
          parentShared
          parent
        }
      }
      nextToken
      total
      __typename
    }
  }
`;
export const autoCompleteName = /* GraphQL */ `
  query AutoCompleteName(
    $name: String
    $userId: String
    $nextToken: String
    $limit: Int
  ) {
    autoCompleteName(
      name: $name
      userId: $userId
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        ... on File {
          id
          name
          type
          size
          userId
          uploaderId
          parent
          modified
          created
          root
          sharedCount
          lastUploadType
          activeDate
          folderOrFile
          groups
        }
        ... on Shared {
          id
          fileId
          userId
          modified
          created
          email
          view
          archived
          count
          relationship
          name
          activeDate
          fileOwner
          groups
          parentShared
          parent
        }
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getSignedUrl = /* GraphQL */ `
  query GetSignedUrl($id: String!) {
    getSignedUrl(id: $id)
  }
`;

// export const getSignedUrl = /* GraphQL */ `
//   query GetSignedUrl($id: String!) {
//     getSignedUrl(sortDirection: DESC) {
//       item {
//         id
//       }
//     }
//   }
// `;

export const getSignedUrlByFileId = /* GraphQL */ `
  query GetSignedUrlByFileId($fileId: String!) {
    getSignedUrlByFileId(fileId: $fileId)
  }
`;
export const getPreviewUrl = /* GraphQL */ `
  query GetPreviewUrl($id: String!) {
    getPreviewUrl(id: $id)
  }
`;

// export const getPreviewUrl = /* GraphQL */ `
//   query GetPreviewUrl($id: String!) {
//     getPreviewUrl(sortDirection: DESC) {
//       item {
//         id
//       }
//     }
//   }
// `;
export const getSubscription = /* GraphQL */ `
  query GetSubscription($id: String) {
    getSubscription(id: $id) {
      status
      card
      dateEnd
      price
      __typename
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod {
    getPaymentMethod
  }
`;
export const getInvoices = /* GraphQL */ `
  query GetInvoices($pageNo: String!, $type: String!) {
    getInvoices(pageNo: $pageNo, type: $type) {
      invoices {
        id
        status
        total
        created
        url
        name
        __typename
      }
      hasMore
      __typename
    }
  }
`;
export const getCognitoUsers = /* GraphQL */ `
  query GetCognitoUsers($nextToken: String, $status: String, $search: String) {
    getCognitoUsers(nextToken: $nextToken, status: $status, search: $search) {
      users {
        Username
        UserCreateDate
        Enabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchInvoices = /* GraphQL */ `
  query SearchInvoices(
    $status: String
    $start_date: AWSDateTime
    $end_date: AWSDateTime
  ) {
    searchInvoices(
      status: $status
      start_date: $start_date
      end_date: $end_date
    )
  }
`;
export const getFileCronJob = /* GraphQL */ `
  query GetFileCronJob($id: ID!) {
    getFileCronJob(id: $id) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFileCronJobs = /* GraphQL */ `
  query ListFileCronJobs(
    $filter: ModelFileCronJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileCronJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        callTime
        cronSyntax
        percentage
        planId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cron_userPlan = /* GraphQL */ `
  query Cron_userPlan(
    $planId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFileCronJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cron_userPlan(
      planId: $planId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        callTime
        cronSyntax
        percentage
        planId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileNameParent = /* GraphQL */ `
  query FileNameParent(
    $name: String!
    $parent: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileNameParent(
      name: $name
      parent: $parent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileByType = /* GraphQL */ `
  query FileByType(
    $type: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileByType(
      type: $type
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileByUser = /* GraphQL */ `
  query FileByUser(
    $userId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileByUser(
      userId: $userId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileByUploader = /* GraphQL */ `
  query FileByUploader(
    $uploaderId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileByUploader(
      uploaderId: $uploaderId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const childrenFiles = /* GraphQL */ `
  query ChildrenFiles(
    $parent: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    childrenFiles(
      parent: $parent
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const folders = /* GraphQL */ `
  query Folders(
    $folderOrFile: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    folders(
      folderOrFile: $folderOrFile
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchFiles = /* GraphQL */ `
  query SearchFiles(
    $filter: SearchableFileFilterInput
    $sort: [SearchableFileSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFileAggregationInput]
  ) {
    searchFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getVersion = /* GraphQL */ `
  query GetVersion($id: ID!) {
    getVersion(id: $id) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const listVersions = /* GraphQL */ `
  query ListVersions(
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileId
        modified
        created
        hash
        userId
        name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileVersion = /* GraphQL */ `
  query FileVersion(
    $fileId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileVersion(
      fileId: $fileId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        modified
        created
        hash
        userId
        name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const versionByHash = /* GraphQL */ `
  query VersionByHash(
    $hash: String!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    versionByHash(
      hash: $hash
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        modified
        created
        hash
        userId
        name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userVersion = /* GraphQL */ `
  query UserVersion(
    $userId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userVersion(
      userId: $userId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        modified
        created
        hash
        userId
        name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShared = /* GraphQL */ `
  query GetShared($id: ID!) {
    getShared(id: $id) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const listShareds = /* GraphQL */ `
  query ListShareds(
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileShared = /* GraphQL */ `
  query FileShared(
    $fileId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileShared(
      fileId: $fileId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileUserShared = /* GraphQL */ `
  query FileUserShared(
    $fileId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileUserShared(
      fileId: $fileId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userShared = /* GraphQL */ `
  query UserShared(
    $userId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userShared(
      userId: $userId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const childrenShared = /* GraphQL */ `
  query ChildrenShared(
    $parentShared: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    childrenShared(
      parentShared: $parentShared
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const parentShared = /* GraphQL */ `
  query ParentShared(
    $parent: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    parentShared(
      parent: $parent
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const parentUserShared = /* GraphQL */ `
  query ParentUserShared(
    $parent: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    parentUserShared(
      parent: $parent
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchShareds = /* GraphQL */ `
  query SearchShareds(
    $filter: SearchableSharedFilterInput
    $sort: [SearchableSharedSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSharedAggregationInput]
  ) {
    searchShareds(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTimelockShared = /* GraphQL */ `
  query GetTimelockShared($id: ID!) {
    getTimelockShared(id: $id) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const listTimelockShareds = /* GraphQL */ `
  query ListTimelockShareds(
    $filter: ModelTimelockSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelockShareds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileId
        userId
        fileOwner
        schedulerId
        parentId
        modified
        created
        email
        view
        name
        relationship
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileTimelockShared = /* GraphQL */ `
  query FileTimelockShared(
    $fileId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelockSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileTimelockShared(
      fileId: $fileId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        fileOwner
        schedulerId
        parentId
        modified
        created
        email
        view
        name
        relationship
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileUserTimelockShared = /* GraphQL */ `
  query FileUserTimelockShared(
    $fileId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelockSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileUserTimelockShared(
      fileId: $fileId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        fileOwner
        schedulerId
        parentId
        modified
        created
        email
        view
        name
        relationship
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userTimelockShared = /* GraphQL */ `
  query UserTimelockShared(
    $userId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelockSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTimelockShared(
      userId: $userId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        fileOwner
        schedulerId
        parentId
        modified
        created
        email
        view
        name
        relationship
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const parentlockShared = /* GraphQL */ `
  query ParentlockShared(
    $parentId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelockSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    parentlockShared(
      parentId: $parentId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        fileOwner
        schedulerId
        parentId
        modified
        created
        email
        view
        name
        relationship
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileFavorites = /* GraphQL */ `
  query FileFavorites(
    $fileId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileFavorites(
      fileId: $fileId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFavorites = /* GraphQL */ `
  query UserFavorites(
    $userId: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFavorites(
      userId: $userId
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const childrenFavorites = /* GraphQL */ `
  query ChildrenFavorites(
    $parentFav: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    childrenFavorites(
      parentFav: $parentFav
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const parentFavoritesByUser = /* GraphQL */ `
  query ParentFavoritesByUser(
    $parent: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    parentFavoritesByUser(
      parent: $parent
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const parentFavorites = /* GraphQL */ `
  query ParentFavorites(
    $parent: ID!
    $modified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    parentFavorites(
      parent: $parent
      modified: $modified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userPlan = /* GraphQL */ `
  query UserPlan(
    $planId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPlan(
      planId: $planId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stripeCustomerId = /* GraphQL */ `
  query StripeCustomerId(
    $stripeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripeCustomerId(
      stripeId: $stripeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stripePriceId = /* GraphQL */ `
  query StripePriceId(
    $stripePriceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripePriceId(
      stripePriceId: $stripePriceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCancellation = /* GraphQL */ `
  query GetCancellation($id: ID!) {
    getCancellation(id: $id) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCancellations = /* GraphQL */ `
  query ListCancellations(
    $filter: ModelCancellationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCancellations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reason
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileActivities = /* GraphQL */ `
  query FileActivities(
    $fileId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileActivities(
      fileId: $fileId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userActivities = /* GraphQL */ `
  query UserActivities(
    $userId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userActivities(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityId
        userId
        read
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activityNotifications = /* GraphQL */ `
  query ActivityNotifications(
    $activityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityNotifications(
      activityId: $activityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityId
        userId
        read
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userNotifications = /* GraphQL */ `
  query UserNotifications(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotifications(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityId
        userId
        read
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileComments = /* GraphQL */ `
  query FileComments(
    $fileId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileComments(
      fileId: $fileId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userComments = /* GraphQL */ `
  query UserComments(
    $userId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userComments(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commentId
        userId
        read
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentMessages = /* GraphQL */ `
  query CommentMessages(
    $commentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentMessages(
      commentId: $commentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        userId
        read
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userMessages = /* GraphQL */ `
  query UserMessages(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMessages(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        userId
        read
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
