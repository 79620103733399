export const getNotificationSettings = /* GraphQL */ `
  query GetNotificationSettings($id: ID!) {
    getUser(id: $id) {
      notifyAbout
      emailNotifications
    }
  }
`;
export const getSubscriptionSettings = /* GraphQL */ `
  query GetSubscriptionSettings($id: ID!) {
    getUser(id: $id) {
      plan {
        id
        name
        size
      }
    }
    getSubscription {
      card
      dateEnd
      status
      price
    }
    getPaymentMethod
  }
`;

export const getSubscription = /* GraphQL */ `
  query GetSubscription($id: String) {
    getSubscription(id: $id) {
      status
    }
  }
`;

export const cancelPremium = /* GraphQL */ `
  mutation CancelPremium($reason: String!, $id: ID!) {
    createCancellation(input: { reason: $reason, userId: $id }) {
      id
      reason
    }
    updateUser(input: { id: $id, planId: "0" }) {
      plan {
        id
        name
        size
      }
    }
  }
`;
