export default function getBreadCrumb(details:any){    
    const list = []
    while(details){
        list.push({
          id: details.fileId,
          name: details.file.name,  
        })
        details = details.parentDet        
    }
    list.reverse()
    return list
}