export default function Loading() {
    return <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading</span>
    </div>
}

// export default function Loading() {
//     return( <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
//     <div className="spinner-border text-primary" role="status">
//       <span className="visually-hidden">Loading</span>
//     </div>
//   </div>)
// }

