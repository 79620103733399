import React, { useEffect, useRef, useState } from "react";
import Style from "./Modal.module.css";
interface Props {
  type: "mainMenu" | "sideMenu" | "";
  children?: React.ReactNode;
}
interface Handle {
  open: () => void;
  close: () => void;
}
export const Modal = React.forwardRef<Handle, Props>((props, ref) => {
  const [openModal, setOpenModal] = useState(false);

  const innerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (
      innerRef.current &&
      !innerRef.current?.contains(event.target) &&
      openModal
    ) {
      close();
    }
  };
  const open = () => {
    setOpenModal(true);
  };
  const close = () => {
    setOpenModal(false);
  };
  React.useImperativeHandle(ref, () => ({
    open: () => open(),
    close: () => close(),
  }));

  return (
    <>
      <div
        className={`modal fade ${openModal && "show d-block"} ${
          props.type === "sideMenu" && Style.sideMenu
        } ${props.type === "mainMenu" && Style.mainMenu} `}
        id="modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog pe-none`}>
          <div className="modal-content">
            <div className="modal-body" ref={innerRef}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default Modal;
