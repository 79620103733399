import { useEffect, useState } from "react";
export interface ToggleItems {
  itemA: string;
  itemB: string;
  onSelected:Function
}
export default function Toggle({ itemA, itemB,onSelected }: ToggleItems) {
  const [selectedItem, setSelectedItem] = useState(itemA);

  useEffect(()=>{
    onSelected(selectedItem.toLocaleLowerCase())
  },[selectedItem,onSelected])
  return (
    <span className="bg-light-orange d-inline-block btn btn-radius-large">
      <button
        className={`btn btn-radius-large shadow-none me-4 font-weight-bold ${
          selectedItem === itemA ? "btn-orange" : "btn-transparent"
        }`}
        onClick={() => setSelectedItem(itemA)}
      >
        {itemA}
      </button>
      <button
        className={`btn btn-radius-large shadow-none me-2 font-weight-bold ${
          selectedItem === itemB ? "btn-orange" : "btn-transparent"
        }`}
        onClick={() => setSelectedItem(itemB)}
      >
        {itemB}
      </button>
    </span>
  );
}
