import { KeyboardEventHandler, useRef, useState } from "react";
import Popup from "../popup";
import { SharedData } from "../../../Models/Document";
import style from "../Row.module.css";
import { favorite, remove } from "../../../Services/favorites";
import Avatar from "../avatar";
import { useOverlay } from "../../../Context/OverlayContext";
import { unshare as unshareService } from "../../../Services/Shared";
import { SharedWithChecked } from "../../Shared/folder";
import AsyncModifiedDate from "./AsyncModifiedDate";
import DoubleClick from "./DoubleClick";

interface Props {
  file: SharedWithChecked;
  onClick: Function;
  onRemove: Function;
  onFavorite: Function;
  onUnfavorite: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
  onClickChecked: Function;
}

const SharedRow: React.FC<Props> = ({
  file,
  onClick,
  onRemove,
  onFavorite,
  onUnfavorite,
  handleKeyDown,
  tabIndex,
  previewImage,
  onClickChecked,
}) => {
  const [unshare, setUnshare] = useState(false);
  const { setLoading } = useOverlay();
  const timeoutRef = useRef<any>();
  const [lastClickTime, setLastClickTime] = useState(0);
  const [singleClickTimeout, setSingleClickTimeout] = useState<number | null>(
    null
  );

  const [unsharing, setUnsharing] = useState(false);
  const favoriteFile = (fileId: string) => {
    setLoading(true);
    favorite(fileId)
      .then(() => onFavorite(fileId))
      .finally(() => {
        setLoading(false);
      });
  };
  const unfavourite = (id: string) => {
    setLoading(true);
    remove(id)
      .then(() => onUnfavorite(id))
      .finally(() => {
        setLoading(false);
      });
  };
  const isVideoFile = (file: any) => {
    return file.type === "media";
  };

  return (
    <tr
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td className="align-middle">
        <input
          type="checkbox"
          onChange={(e) => onClickChecked(e.currentTarget.checked)}
          checked={file.checked}
          className="form-check-input"
        />
      </td>
      <td
        onClick={() => {
          DoubleClick({
            previewImage,
            timeoutRef,
            onClick,
            file,
            lastClickTime,
            setLastClickTime,
            singleClickTimeout,
            setSingleClickTimeout,
          });
        }}
        className="align-middle"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        <button
          className="d-flex btn align-items-center shadow-none px-0"
          // onClick={() => onClick(file.id)}
        >
          <span style={{ width: "32px" }}>
            <img
              src={`/logos/${file.type}.png`}
              alt={`${file.type} logo`}
              className="img-fluid"
            />
          </span>
          <span className="ms-2 d-none d-lg-inline">{file.name}</span>
        </button>
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        <span className={`text-center ${style.file}`}>{file.name}</span>
        <br />
        <span className={style.file2}>
          <span>
            <AsyncModifiedDate modified={file.modified} />
          </span>

          {/* {file.modified.toLocaleDateString()} */}
          <span className="color-dark-grey"> by {file.modifierName}</span>
        </span>
        {file.size}
        <br />
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          <Avatar name={file.user.name} image={file.user.id} />
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount &&
            file.sharedCount > 3 &&
            "+" + (file.sharedCount - 3)}
        </span>
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          {/* {file.modified.toLocaleDateString()} */}
          <AsyncModifiedDate modified={file.modified} />
          <small className="color-dark-grey">by {file.modifierName}</small>
        </div>
      </td>
      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          <Avatar name={file.user.name} image={file.user.id} />
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="text-end">
        <div className="d-flex">
          {!file.favorites ? (
            <button
              className="btn shadow-none"
              onClick={() => {
                favoriteFile(file.id);
              }}
              title="favorite"
            >
              <i className="far fa-star color-blue"></i>
            </button>
          ) : (
            <button
              className="btn shadow-none"
              onClick={() => {
                unfavourite(file.id);
              }}
              title="unfavorite"
            >
              <i className="fas fa-star color-blue"></i>
            </button>
          )}
          <Popup
            trigger={
              <button
                className="btn btn-transparent color-dark-grey shadow-none"
                type="button"
              >
                <i className="fas fa-ellipsis-v"></i>
              </button>
            }
            position="left center"
            nested
          >
            {(close: Function) => (
              <div
                className="card bg-white p-0"
                style={{
                  filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                  borderRadius: "10px",
                }}
              >
                <div className="card-body px-0">
                  <ul className={`list-unstyled mb-0 ${style.menu}`}>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          close();
                          setUnshare(true);
                        }}
                      >
                        <img
                          src="/icons/unshare.png"
                          alt="move"
                          className="img-fluid"
                        />{" "}
                        Remove yourself
                      </button>
                    </li>
                    {!file.favorites ? (
                      <li>
                        <button
                          className="btn shadow-none"
                          onClick={() => {
                            favoriteFile(file.id);
                            close();
                          }}
                        >
                          <i className="fas fa-star text-start"></i>
                          Add to Favorites
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          className="btn shadow-none"
                          onClick={() => {
                            unfavourite(file.id);
                            close();
                          }}
                        >
                          <img
                            src="/icons/Star Off.png"
                            alt="move"
                            className="img-fluid"
                          />{" "}
                          Remove from favorites
                        </button>
                      </li>
                    )}
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          close();
                          previewImage();
                        }}
                      >
                        <i className="fas fa-play"></i> Preview
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </Popup>
          <Popup
            className="modalPopup"
            open={unshare}
            onClose={() => setUnshare(false)}
            modal
            closeOnDocumentClick={false}
          >
            {(close: Function) => (
              <div
                className={`bg-white d-flex flex-column rounded 
            text-center p-0 pt-3 ${style.removeYourself}`}
              >
                <h4 className="px-5 fw-bold roboto">
                  Are you sure you want to remove yourself from sharing?
                </h4>
                <p className="px-5 roboto">
                  You will not be able to get access to this file
                </p>
                <div className="row border-top mx-0">
                  <div className="col-6 border-end">
                    <button
                      className="btn p-3 w-100 shadow-none color-dark-grey fw-bold"
                      onClick={() => close()}
                      disabled={unsharing}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn p-3 flex-fill color-blue shadow-none fw-bold"
                      onClick={() => {
                        setUnsharing(true);
                        unshareService(file.id)
                          .then(() => {
                            onRemove(file.id);
                            close();
                          })
                          .finally(() => {
                            setUnsharing(false);
                          });
                      }}
                      disabled={unsharing}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </td>
    </tr>
  );
};

export default SharedRow;
