import { useEffect, useRef, useState } from "react";
import style from "./Comment.module.css";
import { Comment as CommentModel } from "../../Models/Document";
import { sendComment } from "../../Services/Comments";
import InfiniteScroll from "react-infinite-scroller";
import AsyncModifiedTime from "./AsyncModifiedTime";
import Avatar from "./avatar";
export interface props {
  fileId?: string;
  comments: CommentModel[];
  next: () => any;
  hasMore: boolean;
}
export default function Comment({ fileId, comments, next, hasMore }: props) {
  const [content, setContent] = useState<string>();
  const [sending, setSending] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divRef.current) divRef.current.scrollIntoView({ behavior: "smooth" });
  });
  return (
    <div
      className={`w-100 px-3 card-radius d-flex flex-column ${style.mobileHeight}`}
      style={{
        backgroundColor: "#F5F6FC",
      }}
    >
      <h4
        className="border-bottom border-2 py-2 mb-0"
        style={{
          fontSize: "20px",
        }}
      >
        Comments
      </h4>
      <div
        className="mb-2 mt-0"
        style={{
          overflow: "auto",
          flex: 2,
        }}
        ref={divRef}
      >
        <InfiniteScroll
          loader={<div className="text-center">Loading...</div>}
          useWindow={false}
          loadMore={next}
          initialLoad={true}
          hasMore={hasMore}
          isReverse={true}
        >
          {comments.map((x) =>
            !x.userComment ? (
              <div className="d-flex align-items-top my-1 " key={x.id}>
                <span>
                  <Avatar name={x.name} image={x.userId} />
                </span>
                <p className="ms-2 me-5">
                  <span
                    className={`px-3 py-2 ${style.chat} text-break openSans d-inline-block`}
                  >
                    {x.content}
                  </span>
                  <br />
                  {/* <small className="openSans">
                    {x.createdAt.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </small> */}
                  <AsyncModifiedTime modified={x.createdAt} />
                </p>
              </div>
            ) : (
              <div className="d-flex align-items-top justify-content-end my-1 ">
                <p className="me-2 text-end ms-5 openSans">
                  <span
                    className={`px-3 py-2 d-inline-block ${style.chatAuthor} text-break`}
                  >
                    {x.content}
                  </span>
                  <br />
                  {/* <small className="openSans">
                    {x.createdAt.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </small> */}
                  <AsyncModifiedTime modified={x.createdAt} />
                </p>
                <span>
                  <Avatar name={x.name} image={x.userId} />
                </span>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>

      <div
        className={`d-flex align-items-center ${style.input}`}
        style={{
          opacity: sending ? 0.4 : 1,
        }}
      >
        <textarea
          placeholder="Add your comment"
          className="form-control py-3 rounded openSans"
          style={{
            paddingRight: "80px",
          }}
          onChange={(e) => setContent(e.currentTarget.value)}
          value={content}
          disabled={sending}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              setSending(true);
              if (fileId && content) {
                await sendComment(fileId, content);
              }
              setSending(false);
              setContent("");
            }
          }}
        />
        <button
          className="btn btn-primary rounded"
          style={{
            marginLeft: "-80px",
          }}
          onClick={async () => {
            setSending(true);
            if (fileId && content) {
              await sendComment(fileId, content);
            }
            setSending(false);
            setContent("");
          }}
          disabled={sending}
        >
          Save
        </button>
      </div>
    </div>
  );
}
