import { KeyboardEventHandler, useRef, useState } from "react";
import Move from "../Move";
import Popup from "../popup";
import { FileData } from "../../../Models/Document";
import style from "../Row.module.css";
import { unarchive } from "../../../Services/Archive";
import Avatar from "../avatar";
import { useOverlay } from "../../../Context/OverlayContext";
import { FileWithChecked } from "../../Home/home";
import AsyncModifiedDate from "./AsyncModifiedDate";

interface Props {
  file: FileWithChecked;
  onClick: Function;
  onMove: Function;
  onUnarchive: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
  onClickChecked: Function;
}

const ArchivedRow: React.FC<Props> = ({
  file,
  onClick,
  onMove,
  onUnarchive,
  handleKeyDown,
  tabIndex,
  previewImage,
  onClickChecked,
}) => {
  const [move, setMove] = useState(false);
  const timeoutRef = useRef<any>();
  const { setLoading } = useOverlay();

  const moveFile = (fileId: string, selected: string) => {
    onMove(fileId, selected);
  };
  const isVideoFile = (file: any) => {
    return file.type === "media";
  };
  const handleDoubleClick = (file: any) => {
    previewImage(file);
  };

  return (
    <tr
      onDoubleClick={() => {
        handleDoubleClick(file);
      }}
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td className="align-middle">
        <input
          type="checkbox"
          onChange={(e) => onClickChecked(e.currentTarget.checked)}
          checked={file.checked}
          className="form-check-input"
        />
      </td>
      <td
        className="align-middle"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        {file.type === "folder" ? (
          <button
            className="d-flex btn align-items-center shadow-none px-0"
            onClick={() => onClick(file.id)}
          >
            <span style={{ width: "32px" }}>
              <img
                src={`/logos/${file.type}.png`}
                alt={`${file.type} logo`}
                className="img-fluid"
              />
            </span>
            <span className="ms-2 d-none d-lg-inline">{file.name}</span>
          </button>
        ) : (
          <div className="d-flex align-items-center shadow-none px-0">
            <span style={{ width: "32px" }}>
              <img
                src={`/logos/${file.type}.png`}
                alt={`${file.type} logo`}
                className="img-fluid"
              />
            </span>
            <span className="ms-2 d-none d-lg-inline">{file.name}</span>
          </div>
        )}
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        <span className={`text-center ${style.file}`}>{file.name}</span>
        <br />
        <span className={style.file2}>
          <span>
            <AsyncModifiedDate modified={file.modified} />
          </span>

          {/* {file.modified.toLocaleDateString()} */}
          <span className="color-dark-grey"> by {file.modifierName}</span>
        </span>

        {file.size}
        <br />
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          {/* {file.modified.toLocaleDateString()} */}
          <AsyncModifiedDate modified={file.modified} />
          <small className="color-dark-grey color-dark-grey">
            by {file.modifierName}
          </small>
        </div>
      </td>
      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="text-end">
        <Popup
          trigger={
            <button
              className="btn btn-transparent color-dark-grey shadow-none"
              type="button"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
          }
          position="left center"
          nested
        >
          {(close: Function) => (
            <div
              className="card bg-white p-0"
              style={{
                filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                borderRadius: "10px",
              }}
            >
              <div className="card-body px-0">
                <ul className={`list-unstyled mb-0 ${style.menu}`}>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        setLoading(true);
                        unarchive(file.id)
                          .then(() => {
                            close();
                            onUnarchive(file.id);
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      }}
                    >
                      <i className="fas fa-file-archive text-start"></i>{" "}
                      Unarchive
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        setMove(true);
                      }}
                    >
                      <img
                        src="/icons/Move.png"
                        alt="move"
                        className="img-fluid"
                      />{" "}
                      Move to
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        previewImage();
                      }}
                    >
                      <i className="fas fa-play"></i> Preview
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popup>
        <Popup
          open={move}
          closeOnDocumentClick={false}
          onClose={() => setMove(false)}
          closeOnEscape={false}
          className="menuPopup"
        >
          {(close: Function) => (
            <Move
              close={() => {
                close();
              }}
              fileId={file.id}
              onmove={moveFile}
              root={1}
            />
          )}
        </Popup>
      </td>
    </tr>
  );
};

export default ArchivedRow;
