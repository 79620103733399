import React from "react";
import { Link } from "react-router-dom";
import style from "./layout.module.css";

type Props = {
  children?: React.ReactNode;
  display?: boolean;
};

const Layout = ({ children, display }: Props) => {
  return (
    <div className="h-100 overflow-auto">
      <div className="d-flex align-items-center justify-content-center bg-white d-lg-none py-3">
        <Link to="/" className="color-black">
          <img src="/images/Logo.svg" alt="logo" />
        </Link>
      </div>
      <div className={`row mx-0 p-3 ${style.container}`}>
        <div
          className={`col-12 col-lg-6 bg-blue text-white p-lg-5 p-3 mb-3 mb-lg-0 ${
            style.layoutLeft
          } ${display && "d-none d-lg-block"}`}
        >
          <Link to="/" className="color-black">
            <img
              style={{ width: "200px" }}
              src="/images/Logo---White.svg"
              alt="logo"
              className="d-none d-lg-inline"
            />
          </Link>
          <h1 className={`text-white ${style.largeDisplay}`}>
            Everything <br /> is inkmarked
          </h1>
          <div className={style.textDisplay}>
            <p>
              Inkmark makes it easy to store, share and access your files from
              just about anywhere.
            </p>
            <p>
              Best of all, Inkmark protects your privacy with end-to-end
              encryption — ensuring that your data in the cloud is safe, secure
              and 100% private.
            </p>
          </div>
          <Link
            className={`btn btn-outline-white ${style.layoutButton} mt-4`}
            to="/"
          >
            Back to website
          </Link>
        </div>
        <div className="col-12 col-lg-6 p-0">
          <main className="h-100">{children}</main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
