import { API, Auth } from "aws-amplify";
import moment from "moment";
import {
  addFile,
  batchLockFile,
  sendTimeLockInvite,
  updateFile,
  updateTimelockShared,
  deleteTimelockShared,
} from "../graphql/mutations";
import {
  file,
  folder,
  nextFolder,
  rootFolders,
} from "../graphql/timelockQueries";
import GetFileSize from "../Helper/FileSizeExtractor";
import { Create as CreateModel } from "../Models/Document";

export async function Create(document: CreateModel) {
  try {
    let date = moment().utc().add(1, "months").format("YYYY-MM-DD");
    await API.graphql({
      query: addFile,
      variables: {
        id: document.id,
        name: document.name,
        parent: document.parent,
        size: document.size,
        type: document.type,
        date: date,
      },
    });
  } catch (error: any) {
    return false;
  }
}

export async function GetRoot(token?: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    const value = await (API.graphql({
      query: rootFolders,
      variables: { id: user.username, date: date, token: token },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.folders.items.map((x: any) => ({
      ...x,
      size: GetFileSize(x.size, x.type),
      modified: new Date(x.modified),
      activeDate: new Date(x.activeDate),
      users: x.timelockShared.items,
      modifierName: x.uploader?.name,
    }));
    return { files, token: value.data.folders.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function GetFolder(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    const value = await (API.graphql({
      query: folder,
      variables: {
        id: id,
        root: 0,
        userId: user.username,
        date: date,
      },
    }) as Promise<{
      data: any;
    }>);
    const folders = value.data.files;

    const members = folders.timelockShared.items;
    const files = folders.children.items.map((x: any) => ({
      ...x,
      activeDate: new Date(x.activeDate),
      size: GetFileSize(x.size, x.type),
      modified: new Date(x.modified),
      users: x.timelockShared.items,
      modifierName: x.uploader?.name,
    }));

    return {
      files,
      members,
      date: new Date(folders.activeDate),
      token: folders.children.nextToken,
      parent: folders.parentDet,
      name: folders.name,
    };
  } catch (error: any) {
    return undefined;
  }
}
export async function GetNextFolder(id: string, token?: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    const value = await (API.graphql({
      query: nextFolder,
      variables: {
        id: id,
        root: 0,
        userId: user.username,
        date: date,
        token: token,
      },
    }) as Promise<{
      data: any;
    }>);
    const folders = value.data.files;
    const files = folders.children.items.map((x: any) => ({
      ...x,
      size: GetFileSize(x.size, x.type),
      modified: new Date(x.modified),
      activeDate: new Date(x.activeDate),
      users: x.timelockShared.items,
      modifierName: x.uploader?.name,
    }));
    return { files, token: folders.children.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function getFile(id: string) {
  try {
    const value = await (API.graphql({
      query: file,
      variables: { id: id },
    }) as Promise<{
      data: any;
    }>);
    const data = value.data.files;
    return {
      file: {
        createdAt: new Date(data.created),
        id: data.id,
        modified: new Date(data.modified),
        name: data.name,
        parent: data.parent,
        size: GetFileSize(data.size, data.type),
        type: data.type,
        userId: data.userId,
        lastVersion: data.versions.items[0].id,
      },
      parent: data.parentDet,
      members: data.timelockShared.items,
      date: new Date(data.activeDate),
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function ChangeDate(date: Date, id: string) {
  try {
    await (API.graphql({
      query: updateFile,
      variables: {
        input: { id: id, activeDate: moment(date).format("YYYY-MM-DD") },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function batchUnlockService(fileId: string[]) {
  try {
    await (API.graphql({
      query: batchLockFile,
      variables: {
        ids: fileId.map((x) => x).join(","),
        date: moment().utc().format("YYYY-MM-DD"),
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function invite(
  email: string,
  fileId: string,
  relationship?: string,
  name?: string
) {
  try {
    await (API.graphql({
      query: sendTimeLockInvite,
      variables: {
        email: email,
        file: fileId,
        relationship: relationship,
        name: name,
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function allowActivityView(id: string) {
  await (API.graphql({
    query: updateTimelockShared,
    variables: {
      input: {
        id: id,
        view: true,
      },
    },
  }) as Promise<{
    data: any;
  }>);
}

export async function preventActivityView(id: string) {
  await (API.graphql({
    query: updateTimelockShared,
    variables: {
      input: {
        id: id,
        view: false,
      },
    },
  }) as Promise<{
    data: any;
  }>);
}

export async function remove(id: string) {
  try {
    await (API.graphql({
      query: deleteTimelockShared,
      variables: {
        input: {
          id: id,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}
