import CustomSlider from "../../Components/Slider";
import CustomDatePicker from "../../Components/DatePicker";

interface Props {
  date: Date;
  handleChange: Function;
  disabled?: boolean;
}
export default function Inactive({ date, handleChange, disabled }: Props) {
  return (
    <>
      <h5>Manage Inactivity</h5>
      <span>
        We will share the folders with the invited members after set period of
        inactivity
      </span>
      <hr />

      <div className="d-flex justify-content-between">
        <span className="font-weight-semibold">Choose Transfer Date</span>
        <CustomDatePicker
          date={date}
          handleChange={handleChange}
          disabled={disabled}
        />
      </div>
      <div className="row my-1">
        <div className="col">
          <hr
            style={{
              backgroundColor: "#DCE4F5",
              height: "2px",
            }}
          />
        </div>
        <div
          className="col-auto d-flex align-items-center justify-content-center"
          style={{
            color: "#DCE4F5",
          }}
        >
          OR
        </div>
        <div className="col">
          <hr
            style={{
              backgroundColor: "#DCE4F5",
              height: "2px",
            }}
          />
        </div>
      </div>
      <span className="font-weight-semibold">Select inactivity period</span>
      <CustomSlider
        date={date}
        handleChange={handleChange}
        disabled={disabled}
      />
    </>
  );
}
