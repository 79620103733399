import { createContext, FC, ReactNode, useContext, useState } from "react";
import { useAlert } from "react-alert";
import Uploader from "../Platform/Services/Uploader";
import * as tus from "tus-js-client";
import { useAuth } from "./UserContext";

interface FileContextModel {
  files: FileModel[];
  addFiles: Function;
  removeFile: Function;
  removeFiles: Function;
  removeFileById: Function;
  clearFileContext: Function;
}
export interface FileModel {
  pause: Function;
  resume: Function;
  upload: tus.Upload;
  id: string;
  percentage: number;
  url: string;
}

export const FileContext = createContext<FileContextModel>({
  files: [],
  addFiles: () => {},
  removeFile: () => {},
  removeFiles: () => {},
  removeFileById: () => {},
  clearFileContext: () => {},
});

export const FileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { refresh } = useAuth();
  const [files, setFiles] = useState<FileModel[]>([]);
  const alert = useAlert();

  const addFiles = (
    newFiles: {
      file: File;
      id: string;
      save: Function;
      url: string;
      parent: string;
      uploadUrl: string;
    }[]
  ) => {
    if (newFiles.length > 1) {
      const results: any[] = [];
      for (let file of newFiles) {
        let id = file.id;
        if (files.filter((x) => x.id === id).length > 0) {
          alert.error(file.file.name + "is currently uploading", {
            timeout: 5000,
          });
          return;
        }

        results.push(
          new Uploader(
            file.file,
            id,
            file.url,
            file.parent,
            file.uploadUrl,
            () => {}
          )
        );
      }
      alert.info("Files have started uploading", { timeout: 5000 });
      setTimeout(function () {
        alert.success("Files have been uploaded", { timeout: 5000 });

        refresh && refresh();
      }, 5000);

      setFiles([...files, ...results]);
    }

    if (newFiles.length === 1) {
      const results: any[] = [];
      for (let file of newFiles) {
        let id = file.id;
        // if (files.filter((x) => x.id === id).length > 0) {
        //   alert.error(file.file.name + "is currently uploading", {
        //     timeout: 5000,
        //   });
        //   return;
        // }

        alert.info(file.file.name + " has started upload", { timeout: 5000 });
        results.push(
          new Uploader(
            file.file,
            id,
            file.url,
            file.parent,
            file.uploadUrl,
            () => {
              alert.success(file.file.name + " has been uploaded", {
                timeout: 5000,
              });

              refresh && refresh();
            }
          )
        );
      }
      setFiles([...files, ...results]);
    }
  };

  const removeFile = (file: FileModel) => {
    file.pause();
    setFiles(files.filter((x) => x.id !== file.id));
  };

  const removeFiles = (files: any) => {
    const idsToRemove = files.map((file: any) => file.id);

    setFiles((prevFiles) =>
      prevFiles.filter((file) => !idsToRemove.includes(file.id))
    );
  };

  const removeFileById = (id: string) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const clearFileContext = () => {
    setFiles([]);
  };

  return (
    <FileContext.Provider
      value={{
        files: files,
        addFiles: addFiles,
        removeFile: removeFile,
        removeFiles: removeFiles,
        removeFileById: removeFileById,
        clearFileContext: clearFileContext,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export const useFile = () => useContext(FileContext);
