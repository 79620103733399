import Dropzone from "react-dropzone";
import { useOverlay } from "../../Context/OverlayContext";
import UploadSVG from "../../Icons/Upload";

interface Props {
  callback: Function;
  parent: String;
  locked?: boolean;
  url: "shared" | "files" | "time-lock" | "archived" | "favourites";
  uploadUrl: "file" | "replace" | "favorite" | "time-lock";
}
export default function DropFiles({
  callback,
  parent,
  locked = false,
  url,
  uploadUrl,
}: Props) {
  const { uploadFile } = useOverlay();

  return (
    <Dropzone
      onDrop={(acceptedFiles) =>
        uploadFile(
          acceptedFiles.map((x) => ({
            url: url,
            file: x,
            save: (id: string) => callback(id, x),
          })),
          parent,
          locked,
          uploadUrl
        )
      }
    >
      {({ getRootProps, getInputProps }) => (
        <div className="w-100 d-none d-lg-block" {...getRootProps()}>
          <input {...getInputProps()} />
          <label
            className="d-flex flex-column font-weight-semibold btn-radius-10 text-center rounded py-3 align-items-center justify-content-center"
            style={{
              backgroundColor: "#F9FBFF",
              color: "#ACB8D1",
              fontSize: "18px",
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23656E87FF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
            }}
          >
            <span className="svgIcon d-inline-block">
              <UploadSVG color="#ACB8D1" />
            </span>
            Upload files here to Inkmark
          </label>
        </div>
      )}
    </Dropzone>
  );
}
