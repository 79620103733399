export default function MoveSVG({
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) {
    return <svg width="100%" height="100%" viewBox="0 0 22 18">
        <g id="Folder_Export_ou-lc" data-name="Folder Export ou-lc" transform="translate(-2 -4)">
            <path
                id="Path_22019"
                data-name="Path 22019"
                d="M19.293,16.293a1,1,0,0,1,1.414,0l2,2a1,1,0,0,1,0,1.414l-2,2a1,1,0,0,1-1.414-1.414L19.586,20H16a1,1,0,0,1,0-2h3.586l-.293-.293A1,1,0,0,1,19.293,16.293Z"
                transform="translate(1)"
                fill={color}
                fill-rule="evenodd" />
            <path
                id="Path_22020"
                data-name="Path 22020"
                d="M10.146,4a5.21,5.21,0,0,1,1.17.083,3.239,3.239,0,0,1,.911.359,4.913,4.913,0,0,1,.889.729l.87.829h4.646c.553,0,1.031,0,1.424.031a3.337,3.337,0,0,1,1.225.3,3.079,3.079,0,0,1,1.377,1.311,2.938,2.938,0,0,1,.311,1.167C23,9.18,23,9.634,23,10.161V12.2a1.187,1.187,0,0,1-.057.527.513.513,0,0,1-.229.218A1.365,1.365,0,0,1,22.16,13H19.85a6.156,6.156,0,0,0-6.3,6v.2a1.187,1.187,0,0,1-.057.527.513.513,0,0,1-.229.219A1.366,1.366,0,0,1,12.71,20H6.369c-.553,0-1.031,0-1.424-.031a3.336,3.336,0,0,1-1.225-.3,3.079,3.079,0,0,1-1.377-1.311A2.939,2.939,0,0,1,2.032,17.2C2,16.821,2,16.366,2,15.839V8.162c0-.527,0-.982.032-1.356a2.939,2.939,0,0,1,.311-1.167A3.079,3.079,0,0,1,3.72,4.327a3.337,3.337,0,0,1,1.225-.3C5.339,4,5.816,4,6.37,4Z"
                transform="translate(0)"
                fill={color} />
        </g>
    </svg>

}