import { useState } from "react";
import { ChangeEvent } from "react";
import Avatar from "react-avatar-edit"
import { UploadPicture as UploadService } from "../../Services/Profile";

interface Props {
    close: Function
    onUpload:Function
}
export default function UploadPicture({ close,onUpload }: Props) {
    const [preview, setPreview] = useState<string | null>(null)
    const [error, setError] = useState<string>()

    const onBeforeFileLoad = (elem: ChangeEvent<HTMLInputElement>) => {
        if (elem.target.files !== null && elem.target.files[0].size > 71680000) {
            setError("File too large");
            elem.target.value = "";
        };
    }

    const upload = () => {
        if (preview) {
            UploadService(preview).then((value) => {
                if (value.success) {    
                    onUpload(preview)                
                    close()
                } else {
                    setError("An error occurred during file upload")
                }
            })
        }
    }

    return <div className="bg-white p-3 text-center">
        <h5>Picture
            <button className="btn float-end color-red p-0 shadow-none" onClick={() => close()}>
                <i className="fas fa-times-circle color-red"></i>
            </button>
        </h5>
        {
            error && <small className="text-danger"> 
                {error}
            </small>
        }
        <Avatar width={250} height={250}
            onBeforeFileLoad={onBeforeFileLoad}
            onCrop={(preview) => setPreview(preview)}
            onClose={() => {
                setPreview(null)
            }}
            cropColor="#3a77f3"
            closeIconColor="#ef3f3f"
        />
        <button className="btn btn-primary mt-3 shadow-none" disabled={preview === null} onClick={() => upload()}>Upload</button>
    </div>
}