import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./DatePicker.module.css";

interface Props {
  date: Date;
  handleChange: Function;
  disabled?: boolean;
}

export default function CustomDatePicker({
  date,
  handleChange,
  disabled,
}: Props) {
  return (
    <div className="position-relative">
      <DatePicker
        selected={date}
        onChange={(date: any) => {
          handleChange(date);
        }}
        placeholderText="MM/DD/YYYY"
        popperClassName={style.datePickerPopper}
        popperPlacement="auto"
        wrapperClassName={style.datePickerWrapper}
        disabled={disabled}
      />
    </div>
  );
}
