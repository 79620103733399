import { Link } from "react-router-dom";
import Subscription from "../Platform/Settings/Subscription";

export interface props {
  images: string;
  type: "Lite" | "Pro" | "Pro+";
  price?: number;
  info?: number;
  duration?: "monthly" | "yearly";
  storage: number;
  url: number;
}

export default function PriceCard({
  images,
  type,
  price,
  info,
  duration,
  url,
  storage,
}: props) {
  return (
    <div
      className="card text-center border-0 card-radius h-100 "
      style={{
        boxShadow: "0px 6px 30px rgb(0,0,0,0.1)",
      }}
    >
      <div className="card-header bg-transparent pt-2">
        <h5 className="font-weight-extrabold">{type}</h5>
        <img
          src={images}
          alt="price"
          className="my-1"
          style={{
            width: "65px",
            height: "80px",
          }}
        />
        <p className="font-weight-bold">
          {price ? (
            <>
              <span style={{ fontSize: "20px" }}>£{price}</span>/month
            </>
          ) : (
            <>Free</>
          )}
        </p>
        {info && (
          <p className="mb-0 color-dark-grey">
            {duration === "monthly" ? (
              <>Subscription costs per year £{info}</>
            ) : (
              <>
                {info}% <span>savings against monthly subscription</span>
              </>
            )}
          </p>
        )}
        {duration === "yearly" && <p>save by billing yearly</p>}
      </div>
      <div className="card-body py-4 d-flex flex-column align-items-center justify-content-end">
        <div className="d-flex justify-content-center mb-4">
          <img src="/icons/cloud-computing.svg" alt="storage logo" />
          <span className="ms-1">
            <p className="font-weight-bold mb-0 text-start">{storage} GB</p>
            <p className="mb-0 text-small text-start">STORAGE</p>
          </span>
        </div>
        <Link
          className="btn btn-primary fw-bold btn-radius-10 "
          to={`/Settings/subscription`}
        >
          Get started
        </Link>
      </div>
    </div>
  );
}
