import style from "./teammembercard.module.css";
import React from "react";
export interface TeamMember {
  name: string;
  description: React.ReactNode;
  color: "yellow" | "blue";
  src: string;
}

export default function TeamMemberCard({
  name,
  description,
  color,
  src,
}: TeamMember) {
  return (
    <div
      className={`p-4  ${style.card} ${
        color === "blue" ? "bg-light-blue" : "bg-light-orange"
      } rounded text-center`}
    >
      <img
        alt="team member"
        src={src}
        className={`img-fluid mb-2 ${style.img}`}
      />
      <h4 className="font-weight-bold text-start">{name}</h4>
      <p
        className="text-start"
        style={{
          fontSize: "16px",
        }}
      >
        {description}
      </p>
    </div>
  );
}
