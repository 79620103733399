import { useLocation } from "react-router-dom";
import style from "./FAQ.module.css";
import { FAQData, FAQDataType } from "./components/faq/data";
import { ReactNode } from "react";
import { filter, onlyText } from "react-children-utilities";
import React from "react";

export default function FAQSearch() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("search");

  // Function to highlight the search query within the text
  const highlightText = (text: any, query: any) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, "gi");
    return text.split(regex).map((part: any, index: any) => {
      if (index % 2 === 0) return part;
      return <span style={{ backgroundColor: "yellow" }}>{part}</span>;
    });
  };

  const highlightTextBpdy = (element: any, query: any) => {
    if (!query) return element;

    const regex = new RegExp(`(${query})`, "gi");

    // Helper function to recursively process children
    const highlightChildren = (children: React.ReactNode): React.ReactNode => {
      return React.Children.map(children, (child) => {
        if (typeof child === "string") {
          // If child is a string, split it by the query and wrap the query with a span
          return child.split(regex).map((part, index) => {
            return index % 2 === 0 ? (
              part
            ) : (
              <span style={{ backgroundColor: "yellow" }}>{part}</span>
            );
          });
        } else if (React.isValidElement<any>(child)) {
          // If child is a valid element, recursively process its children
          return React.cloneElement(child, {
            children: highlightChildren(child.props.children),
          });
        } else {
          return child;
        }
      });
    };

    // Highlight the element's children recursively
    return highlightChildren(element.props.children);
  };
  function filterChildren(tag: FAQDataType) {
    const query = queryParams.get("search")?.toLowerCase() ?? "";
    if (tag.title.toLowerCase().includes(query)) {
      return true;
    } else if (
      filter(tag.body, (child) => onlyText(child).toLowerCase().includes(query))
        .length > 0
    ) {
      return true;
    }
  }

  return (
    <section className="layout-section mb-5 pb-3">
      {FAQData.filter(filterChildren).map((x, i) => (
        <div
          className={`card text-start card-radius my-4 p-4 ${style.search_card}`}
          style={{
            boxShadow: "0px 6px 30px rgb(0,0,0,0.1)",
          }}
        >
          {/* <h4>{x.title}</h4> */}
          <h4>{highlightText(x.title, searchQuery)}</h4>
          <div className={`${style["faq-item"]} color-dark-grey`}>
            {highlightTextBpdy(x.body, searchQuery)}
          </div>
        </div>
      ))}
    </section>
  );
}
