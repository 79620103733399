import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import CustomError from "../../Helper/CustomErrorMessage";
import { EmailChange } from "../../Services/Profile";
import style from "./Setting.module.css";

interface Props {
  close: Function;
  email: string | undefined;
  submit: Function;
}
export default function ConfirmEmailChange({ close, email, submit }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<{ Code: string }>();

  const handleEmailVerify = async (data: any) => {
    var result = await EmailChange(data);
    if (result.success) {
      close();
    } else {
      let resultType = result.type as any;
      setError(resultType, { message: result.message });
    }
  };
  return (
    <form
      className={style.changePassword}
      onSubmit={handleSubmit(handleEmailVerify)}
    >
      <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
        Email Address Change
        <button
          className="btn float-end color-red p-0 shadow-none"
          onClick={() => close()}
        >
          <i className="fas fa-times-circle color-red"></i>
        </button>
      </h5>
      <p>
        An Email Change Confirmation Code has been sent to{" "}
        <span style={{ color: "grey" }}>{email}</span>
      </p>
      {/* <hr /> */}
      <div className="row">
        <div className="col-12 col-xl-8 pe-0">
          <label className="form-label" htmlFor="oldPassword">
            Code
          </label>
          <input
            className="form-control shadow-none"
            type="text"
            {...register("Code")}
            required
          />
          <ErrorMessage
            errors={errors}
            name="Code"
            render={({ messages, message }) => (
              <CustomError messages={messages} messageSingle={message} />
            )}
          />
        </div>
      </div>
      <button
        className="btn btn-faded-primary mt-3 shadow-none"
        type="submit"
        disabled={isSubmitting}
      >
        Confirm
      </button>
    </form>
  );
}
