import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroller";
import Popup from "./popup";
import { useAuth } from "../../Context/UserContext";
import {
  allowActivityView,
  getSharedUsers,
  invite,
  preventActivityView,
  remove,
} from "../../Services/Shared";
import Avatar from "./avatar";
import Loading from "./Loading";
import OwnershipTransfer from "./OwnershipTransfer";

interface Props {
  close?: Function;
  removeOverflow?: boolean;
  fileId: string;
  refresh: Function;
}
export default function Share({
  close,
  removeOverflow,
  fileId,
  refresh,
}: Props) {
  const [token, setToken] = useState<string>();
  const [open, setOpen] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [inviting, setInviting] = useState(false);
  const { register, handleSubmit } = useForm<{
    email: string;
  }>();
  const [users, setUsers] = useState<
    {
      id: string;
      name: string;
      view: boolean;
    }[]
  >([]);
  const { user } = useAuth();

  const sendInvite = async (data: any) => {
    setInviting(true);
    await invite(data.email, fileId);
    refreshUsers();
    setInviting(false);
  };
  useEffect(() => {
    getSharedUsers(fileId).then((value) => {
      setUsers(value?.files);
      setToken(value?.token);
    });
  }, [fileId]);
  const loadMore = () => {
    setLoading(true);
    if (!!token && !loading)
      getSharedUsers(fileId, token)
        .then((value) => {
          setUsers(users.concat(value?.files));
          setToken(value?.token);
        })
        .finally(() => {
          setLoading(false);
        });
  };
  const refreshUsers = () => {
    getSharedUsers(fileId).then((value) => {
      setUsers(value?.files);
      setToken(value?.token);
    });
    refresh();
  };
  return (
    <>
      <h5>
        Manage Sharing
        {close && (
          <button
            className="btn color-red float-end py-0 shadow-none"
            onClick={() => close()}
          >
            <i className="far fa-times-circle "></i>
          </button>
        )}
      </h5>
      <p
        style={{
          borderBottom: "1px solid #dddddd",
        }}
      >
        Share or un-share files and folders with anyone
      </p>
      <form
        className="d-flex mb-2 align-items-center"
        onSubmit={handleSubmit(sendInvite)}
      >
        <input
          type="text"
          className="form-control shadow-none"
          placeholder="Enter email, comma separated"
          {...register("email")}
        />
        <button
          className="btn btn-primary ms-2 shadow-none text-nowrap"
          disabled={inviting}
        >
          {" "}
          Send Invite
        </button>
      </form>

      <p
        style={{
          borderBottom: "1px solid #dddddd",
        }}
        className="mb-0 mt-2"
      >
        Shared with
      </p>
      <ul className={`list-unstyled ${!removeOverflow ? "overflow-auto" : ""}`}>
        <InfiniteScroll
          loader={
            <li className="text-center">
              <Loading />
            </li>
          }
          useWindow={false}
          loadMore={loadMore}
          initialLoad={true}
          hasMore={!!token}
          element="ul"
          className={`list-unstyled`}
        >
          <li className="d-flex align-items-top my-3">
            <Avatar name={user?.name ?? ""} image={user?.id ?? ""} />
            <div className="text-break flex-fill ms-1">{user?.name} (You)</div>
            <span className="me-3">Owner</span>
          </li>
          {users.map((x) => (
            <li className="d-flex align-items-center my-3" key={x.id}>
              <span className="me-1">
                <Avatar name={x.name} image={x.id} />
              </span>
              <div className="text-break flex-fill">{x.name}</div>
              <Popup
                offsetX={-45}
                position="center center"
                trigger={
                  <button className="btn shadow-none py-0 d-flex align-items-center">
                    {x.view ? "Can view activity" : "Cannot view activity"}
                    {open === x.id ? (
                      <i className="fas fa-angle-down color-orange ps-2"></i>
                    ) : (
                      <i className="fas fa-angle-up color-orange ps-2"></i>
                    )}
                  </button>
                }
                onOpen={() => setOpen(x.id)}
                onClose={() => setOpen(undefined)}
                nested
              >
                {(close: any) => (
                  <ul
                    className="list-unstyled bg-white card-radius mb-0 p-2"
                    style={{
                      filter: "drop-shadow(0px 2px 6px rgb(0,0,0,0.16))",
                    }}
                  >
                    <li>
                      <button
                        className="btn shadow-none "
                        onClick={() => {
                          allowActivityView(fileId, x.id).then(async () => {
                            refreshUsers();
                            close();
                          });
                        }}
                      >
                        Can view activity
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          preventActivityView(fileId, x.id).then(async () => {
                            refreshUsers();
                            close();
                          });
                        }}
                      >
                        Cannot view activity
                      </button>
                    </li>
                    <li className="d-none d-lg-inline">
                      <Popup
                        className="modalPopup"
                        trigger={
                          <button className="btn shadow-none">
                            Transfer ownership
                          </button>
                        }
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(closeTransfer: Function) => (
                          <div
                            className="bg-white p-3 d-flex flex-column"
                            style={{
                              width: "40vw",
                              minHeight: "380px",
                              filter:
                                "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                            }}
                          >
                            <OwnershipTransfer
                              close={() => {
                                close && close();
                                closeTransfer();
                              }}
                              name={x.name}
                              id={fileId}
                              user={x.id}
                            />
                          </div>
                        )}
                      </Popup>
                    </li>
                    <li className="d-inline d-lg-none">
                      <Popup
                        className="modalPopup"
                        trigger={
                          <button className="btn shadow-none">
                            Transfer ownership
                          </button>
                        }
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(closeTransfer: Function) => (
                          <div
                            className="bg-white p-3 d-flex flex-column"
                            style={{
                              width: "90vw",
                              minHeight: "380px",
                              filter:
                                "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                            }}
                          >
                            <OwnershipTransfer
                              close={() => {
                                close && close();
                                closeTransfer();
                              }}
                              name={x.name}
                              id={fileId}
                              user={x.id}
                            />
                          </div>
                        )}
                      </Popup>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          remove(fileId, x.id).then(async () => {
                            refreshUsers();
                          });
                        }}
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                )}
              </Popup>
            </li>
          ))}
        </InfiniteScroll>
      </ul>
    </>
  );
}
