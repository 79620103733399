import Popup from "../Components/popup";
import { useState } from "react";
export interface props {
  images: string;
  type: "Lite" | "Pro" | "Premium";
  defaultId: string;
  current: string;
  price?: number;
  info?: number;
  duration: "monthly" | "yearly";
  click: Function;
  subscribed: boolean;
  size: string;
}

export default function PriceCard({
  images,
  type,
  defaultId,
  current,
  price,
  info,
  duration,
  click,
  subscribed,
  size,
}: props) {
  const [preview, setPreview] = useState(false);
  const [planInfo, setPlanInfo] = useState("");

  const intDefaultId = parseInt(defaultId);
  const intCurrentId = parseInt(current);

  // console.log("defaultId", defaultId, "current", current);

  const details = (type: string, price?: number, info?: number) => {
    // console.log("types", type);
    if (price) {
      if (intDefaultId > intCurrentId) {
        setPlanInfo(
          `Do you wish to continue to upgrade to ${type} plan for £${price}`
        );
      }

      if (intDefaultId < intCurrentId) {
        setPlanInfo(
          `Do you wish to continue to downgrade to ${type} plan for £${price}`
        );
      }
    }
    if (price === undefined) {
      setPlanInfo(`Do you wish to continue to downgrade to ${type} free plan`);
    }
    setPreview(true);
  };

  const planButton = () => {
    // console.log(intCurrentId, intDefaultId);
    if (intCurrentId > intDefaultId && intDefaultId === 0) {
      return <>Downgrade</>;
    }

    if (intCurrentId > intDefaultId && intDefaultId === 1) {
      return <>Downgrade</>;
    }

    if (intCurrentId > intDefaultId && intDefaultId === 2) {
      return <>Downgrade</>;
    }

    if (intCurrentId > intDefaultId && intDefaultId === 3) {
      return <>Downgrade</>;
    }

    if (intDefaultId > intCurrentId) {
      return <>Upgrade</>;
    }
    if (intDefaultId < intCurrentId) {
      return <>Subscribe</>;
    }
  };

  return (
    <div
      className="card text-center border-0 card-radius h-100"
      style={{
        boxShadow: "0px 6px 30px rgb(0,0,0,0.1)",
        backgroundColor: subscribed ? "#E3EAF9" : "",
      }}
    >
      <div className="card-header bg-transparent py-4 flex-grow-1">
        <h5 className="font-weight-extrabold">{type}</h5>
        <img src={images} alt="price" className="my-3" />
        <p className="font-weight-bold">
          {price ? (
            <>
              <span className="font-size-2x">£{price}</span>/month
            </>
          ) : (
            <>Free</>
          )}
        </p>
        {info && (
          <p className="mb-0 color-dark-grey">
            {duration === "monthly" ? (
              <>Subscription costs per year £{info}</>
            ) : (
              <>
                {info}% <span>savings against monthly subscription</span>
              </>
            )}
          </p>
        )}
        {duration === "yearly" && <p>save by billing yearly</p>}
      </div>
      <div
        className="card-body py-4 d-flex flex-column align-items-center"
        style={{ flex: "none" }}
      >
        <div className="flex-fill"></div>
        <div className="d-flex justify-content-center flex-wrap mb-2">
          <img src="/icons/cloud-computing.svg" alt="storage logo" />
          <span className="ms-1 ">
            <p className="font-weight-bold mb-0 text-start">{size}</p>
            <p className="mb-0 text-small text-start">STORAGE</p>
          </span>
        </div>
        {subscribed ? (
          <div className="btn  btn-outline-primary btn-radius-10 mt-2">
            {" "}
            Subscribed{" "}
          </div>
        ) : (
          <button
            className="btn btn-primary btn-radius-10 shadow-none"
            onClick={() => {
              details(type, price, info);
            }}
          >
            {planButton()}
          </button>
        )}
      </div>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => {
          setPreview(false);
        }}
      >
        <div
          className="bg-white p-1  d-flex "
          style={{
            width: "300px",
            height: "150px",
            filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
            borderRadius: "10px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div>
            <p className="mt-3 text-md text-align-center justify-content-center">
              {planInfo}
            </p>
            <div className="container mt-2">
              <button
                className="btn btn-primary btn-sm ms-2 me-2 mb-2"
                type="submit"
                onClick={() => {
                  click();
                }}
              >
                Confirm
              </button>
              <button
                className="btn btn-danger  btn-sm mb-2  text-white"
                onClick={() => setPreview(false)}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}
