import { useState } from "react";
import { useFile } from "../../Context/FileContext";
import NewFolderSVG from "../../Icons/NewFolderSVG";
import Files from "../Components/TableRows/Table";
import MobileUpload from "../Components/MobileUpload";
import UploadSingle from "../Components/UploadSingle";
import UploadGroup from "../Components/UploadGroup";
import style from "./Home.module.css";
import { Create as CreateModel, FileData } from "../../Models/Document";
import {
  batchLockService,
  Create,
  DownloadFiles,
  Preview,
} from "../../Services/Document";
import { Link, useHistory } from "react-router-dom";
import { HomeRow } from "../Components/TableRows";
import DesktopUpload from "../Components/DesktopUpload";
import Popup from "../Components/popup";
import { useOverlay } from "../../Context/OverlayContext";
import Previewer from "../Components/Preview";
import { FileWithChecked } from "./home";
import { batchArchive } from "../../Services/Archive";
import { useAlert } from "react-alert";
import Move from "../Components/Move";
import DropFiles from "../Components/Dropfiles";
import CloseButton from "../Components/closeButton";

interface Prop {
  userFiles: FileWithChecked[];
  userFavorites: FileData[];
  rename: Function;
  onClickChecked: Function;
  refresh: Function;
}
export default function Index({
  userFiles,
  userFavorites,
  onClickChecked,
  refresh,
}: Prop) {
  const [details, setDetails] = useState(false);
  const { files } = useFile();
  const history = useHistory();
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const { setLoading } = useOverlay();
  const [openMove, setOpenMove] = useState(false);
  const alert = useAlert();

  const previewImage = (file: FileData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoading(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoading(false));
  };
  //  console.log("----------------------")
  //  console.log(Files)
  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        history.push("/files/file/" + file.id);
        break;
      default:
        break;
    }
  };
  const createFile = async (id: string, file: File) => {
    let value: CreateModel = {
      id: id,
      name: file.name,
      size: file.size,
      parent: "0",
      type: file.type,
      uploadUrl: "file",
    };
    await Create(value);
  };

  return (
    <>
      <div className={`${details ? "d-block" : "d-none"} d-lg-none`}>
        <div className="d-flex">
          <button
            className="btn btn-transparent"
            type="button"
            onClick={() => setDetails(false)}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <h4>Uploading your files to blockchain</h4>
        </div>

        {files.map((file) => (
          <UploadSingle file={file} type="large" key={file.id} />
        ))}
      </div>

      <div className={`${details ? "d-none" : "d-block"} d-lg-block`}>
        <section>
          <MobileUpload
            callback={createFile}
            parent="0"
            url="files"
            uploadUrl="file"
          />
          {files.length !== 0 && (
            <div className="d-block d-lg-none">
              <UploadGroup
                files={files}
                onDetailsClick={() => setDetails(true)}
              />
            </div>
          )}
          {userFiles.length !== 0 && (
            <div className={`mb-4`}>
              <DropFiles
                callback={createFile}
                parent="0"
                url="files"
                uploadUrl="file"
              />
            </div>
          )}
          <div className="d-flex mb-2 align-items-center">
            <h4 className="mb-0 flex-grow-1">Favorites</h4>
            <div className="d-lg-block d-none">
              <DesktopUpload
                callback={createFile}
                parent="0"
                onCreateFolder={undefined}
                url="files"
                uploadUrl="file"
              />
            </div>
          </div>
          <div
            className={`d-flex flex-nowrap justify-content-start px-0 ${style.container} mb-5`}
          >
            {userFavorites.map((x) => (
              <div className={style.file} key={x.id}>
                <div className="p-4 d-flex align-items-center justify-items-center flex-column h-100">
                  <div>
                    <img
                      src={`/logos/${x.type}.png`}
                      alt={`${x.type} logo`}
                      className="img-fluid"
                      style={{
                        minHeight: "60px",
                        minWidth: "60px",
                      }}
                    />
                  </div>
                  <p className="text-truncate mb-0 fw-bold text-center container-fluid">
                    {x.name}
                  </p>
                  <small className="mb-0 color-dark-grey">{x.size}</small>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section>
          <div className="d-flex mb-2 align-items-center">
            <h4 className="mb-0">Recent Files</h4>

            <button className="btn btn-outline-primary py-1 btn-radius-10 fw-bold px-3 ms-auto">
              <Link style={{ textDecoration: "none" }} to="/files">
                View All
              </Link>
            </button>

            <Popup
              trigger={
                <button
                  className={`btn ${
                    userFiles.filter((x) => x.checked).length <= 0
                      ? "btn-outline-secondary  color-dark-grey shadow-none ms-2 py-1"
                      : "btn-outline-primary color-dark-grey shadow-none ms-2 py-1"
                  }`}
                  // className="btn btn-outline-primary color-dark-grey shadow-none ms-2 py-1"
                  type="button"
                  disabled={
                    userFiles.filter((x) => x.checked).map((x) => x.id)
                      .length <= 0
                  }
                >
                  <i className="fas fa-caret-down"></i>
                </button>
              }
              position="bottom right"
              nested
              disabled={
                userFiles.filter((x) => x.checked).map((x) => x.id).length <= 0
              }
            >
              {(close: Function) => (
                <div
                  className="card bg-white p-0 ms-2"
                  style={{
                    filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                    borderRadius: "10px",
                  }}
                >
                  <div className="card-body px-0">
                    <ul className={`list-unstyled mb-0 ${style.menu}`}>
                      <li>
                        <button
                          className="btn shadow-none px-3 "
                          onClick={() => {
                            if (
                              userFiles
                                .filter((x) => x.checked)
                                .map((x) => x.id).length > 0
                            ) {
                              setLoading(true);
                              batchLockService(
                                userFiles
                                  .filter((x) => x.checked)
                                  .map((x) => x.id)
                              ).finally(() => {
                                refresh();
                                setLoading(false);
                              });
                            } else {
                              alert.info("No file has been selected", {
                                timeout: 5000,
                              });
                            }
                            close();
                          }}
                        >
                          <i className="fas fa-lock me-1"></i>
                          Lock File
                        </button>
                      </li>
                      <li>
                        <button
                          className="btn shadow-none px-3 "
                          onClick={() => {
                            setLoading(true);
                            batchArchive(
                              userFiles
                                .filter((x) => x.checked)
                                .map((x) => x.id)
                            ).finally(() => {
                              refresh();
                              setLoading(false);
                            });
                          }}
                        >
                          <i className="fas fa-file-archive text-start me-1"></i>
                          Archive
                        </button>
                      </li>
                      <li>
                        <button
                          className="btn shadow-none px-3 "
                          onClick={() => {
                            if (
                              userFiles
                                .filter((x) => x.checked)
                                .map((x) => x.id).length > 0
                            )
                              setOpenMove(true);
                            else {
                              alert.info("No file has been selected", {
                                timeout: 5000,
                              });
                            }
                          }}
                        >
                          <img
                            src="/icons/Move.png"
                            alt="move"
                            className="img-fluid"
                          />{" "}
                          Move to
                        </button>
                      </li>

                      <li>
                        <button
                          className="btn shadow-none px-3 "
                          onClick={() => {
                            setLoading(true);
                            DownloadFiles(
                              userFiles
                                .filter((x) => x.checked)
                                .map((x) => x.id)
                            ).finally(() => {
                              setLoading(false);
                            });
                          }}
                        >
                          <i className="fas fa-file-download me-1"></i>
                          Download
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </Popup>
          </div>
          <Files
            border={userFiles.length !== 0}
            next={function () {
              throw new Error("Function not implemented.");
            }}
            hasMore={false}
          >
            {userFiles.slice(0, 5).map((file, i) => (
              <HomeRow
                fileData={file}
                onClick={() => history.push("/files/file/" + file.id)}
                key={file.id}
                onMove={() => {}}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                onClickChecked={(val: boolean) => onClickChecked(file.id, val)}
              />
            ))}
          </Files>
        </section>
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => setPreview(false)}
        >
          <div
            style={{
              height: "90vh",
              width: "80vw",
            }}
          >
            <CloseButton preview={preview} setPreview={setPreview} />
            <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
          </div>
        </Popup>
        <Popup
          open={openMove}
          closeOnDocumentClick={false}
          onClose={() => setOpenMove(false)}
          closeOnEscape={false}
          className="menuPopup"
        >
          {(close: Function) => (
            <Move
              close={() => {
                close();
              }}
              ids={userFiles.filter((x) => x.checked).map((x) => x.id)}
              onmove={() => {
                refresh();
              }}
              root={0}
            />
          )}
        </Popup>
      </div>
    </>
  );
}
