import SideMenu from "./sideMenu";
import style from "./layout.module.css";
import TopMenu from "./topMenu";

type Props = {
  children?: React.ReactNode;
  scrollAuto?: boolean //For allowing comments box to be fixed
};
export default function Layout({ children, scrollAuto }: Props) {
  return (
    <>
      <div className={`d-none d-lg-block ${style.sideMenu} vh-100 border`}>
        <SideMenu />
      </div>
      <div className={`d-flex flex-column h-100 overflow-auto ${style.main}`}>
        <header className={`${style.topMenu} py-lg-3  py-2 px-lg-4 ${style["border-bottom"]}`}>
          <TopMenu />
        </header>
        <main className={`${style.center} pb-lg-2 px-lg-4 px-3 flex-lg-fill overflow-lg-auto h-100  mt-0
          ${false && "overflow-auto"} `}>{children}</main>
      </div>
    </>
  );
}
