import { useState } from "react";
import style from "../timeLock.module.css";
import Sharing from "./Sharing";
import Inactive from "./Inactive";
import { FileData, member } from "../../../Models/Document";
import { useFile } from "../../../Context/FileContext";
import { DownloadFile, Replace } from "../../../Services/Document";
import ReplaceDropFiles from "../../Components/ReplaceDropFiles";
import { useOverlay } from "../../../Context/OverlayContext";
import { ChangeDate } from "../../../Services/Time-Lock";
import { useHistory } from "react-router-dom";
import Previewer from "../../Components/Preview";
import UploadSVG from "../../../Icons/Upload";
import CloseButton from "../../Components/closeButton";
import Popup from "../../Components/popup";

interface props {
  file?: FileData;
  id: string;
  members: member[];
  refresh: Function;
  date: Date;
  handleDateChange: Function;
  disabled?: boolean;
  previewFile: string;
}

export default function File({
  file,
  id,
  date,
  handleDateChange,
  members,
  refresh,
  disabled,
  previewFile,
}: props) {
  const { addFiles } = useFile();
  const { setLoading } = useOverlay();
  const [preview, setPreview] = useState(false);
  const history = useHistory();
  const onDownload = async () => {
    if (file) {
      await DownloadFile(file.lastVersion);
    }
  };

  const openView = () => {
    setPreview(true);
  };
  const uploadFile = async (file: File) => {
    addFiles([
      {
        id: id,
        file: file,
        uploadUrl: "replace",
        save: async () => {
          let value = {
            id: id,
            size: file.size,
            type: file.type,
            name: file.name,
          };
          await Replace(value as any);
          refresh();
        },
      },
    ]);
  };
  const unlock = async () => {
    setLoading(true);
    await ChangeDate(new Date(), id);
    history.push("/files/file/" + id);
    setLoading(false);
  };
  return (
    <section className="h-100 d-flex">
      <div className="flex-fill d-flex justify-content-between flex-column overflow-auto h-100">
        <div>
          <div className="card card-radius mb-2">
            <div className="card-body d-flex align-items-center flex-column">
              <div
                onDoubleClick={() => {
                  openView();
                }}
                style={{
                  width: "100%",
                }}
              >
                <Previewer
                  src={previewFile}
                  fileName={file?.name ?? ""}
                  autoPlay={false}
                />
              </div>
              <div>
                <Popup
                  open={preview}
                  closeOnDocumentClick
                  className="modalPopup"
                  onClose={() => setPreview(false)}
                >
                  <div
                    style={{
                      height: "90vh",
                      width: "80vw",
                    }}
                  >
                    <CloseButton preview={preview} setPreview={setPreview} />
                    <Previewer
                      src={previewFile}
                      fileName={file?.name ?? ""}
                      autoPlay={true}
                    />
                  </div>
                </Popup>
              </div>

              <p className="mb-0 fw-bold text-center mt-3">{file?.name}</p>
              <p
                className="text-muted mb-0"
                style={{
                  fontSize: "14px",
                }}
              >
                {file?.size}
              </p>
            </div>
            <button
              className="w-100 btn-primary btn card-button-footer"
              style={{
                border: "1px solid #DCE4F5",
              }}
              onClick={() => {
                onDownload();
              }}
            >
              Download
            </button>
          </div>
          {/* <ReplaceDropFiles
            callback={(file: File) => {
              uploadFile(file);
            }}
          />
          <p className="text-center my-2">OR</p> */}
          <label
            className="btn w-100 mb-3 fw-bold"
            style={{
              backgroundColor: "#F2F6FF",
              color: "#3A77F3",
              border: "1px solid #3A77F3",
            }}
          >
            <div>
              <span className="svgIcon d-inline-block">
                <UploadSVG color="#ACB8D1" />
              </span>
              <br />
              Upload file
              <input
                type="file"
                id="file"
                className="d-none"
                onChange={(event) => {
                  if (event.currentTarget.files)
                    uploadFile(event.currentTarget.files[0]);
                }}
              />
            </div>
          </label>
          <button
            className="btn w-100 mb-3 shadow-none"
            style={{
              border: "1px solid #FDA758",
              backgroundColor: "#FFF6EF",
              color: "#FDA758",
            }}
            onClick={() => unlock()}
          >
            Unlock File
          </button>
        </div>
      </div>
      <div
        className="ms-3 mb-lg-3 d-none d-lg-block"
        style={{
          minWidth: "450px",
          width: "35%",
        }}
      >
        <section className="h-100 d-flex flex-column">
          <div className={`${style.selectedCard} p-3 overflow-auto bg-white`}>
            <Sharing fileId={id} members={members} refresh={refresh} />
          </div>
          <div className={`${style.selectedCard} p-3 mt-3 bg-white`}>
            <Inactive
              date={date}
              handleChange={handleDateChange}
              disabled={disabled}
            />
          </div>
        </section>
      </div>
    </section>
  );
}
