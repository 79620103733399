import FileViewer from "@codesmith-99/react-file-preview";

interface Props {
  src: string;
  fileName: string;
  autoPlay: boolean;
}
export default function Previewer({ src, fileName, autoPlay = true }: Props) {
  return (
    <FileViewer
      src={src}
      fileName={fileName}
      loader={undefined}
      autoPlay={autoPlay}
    />
  );
}
