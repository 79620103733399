const simpleEvent = <T extends Function>(context = null) => {
  let cbs: T[] = [];
  return {
    get: () => {
      console.log(cbs);
    },
    addListener: (cb: T) => {
      cbs.push(cb);
    },
    removeListener: (cb: T) => {
      let i = cbs.indexOf(cb);
      cbs.splice(i, Math.max(i, 0));
    },
    trigger: <T>(
      (((...args: any) => cbs.forEach((cb) => cb.apply(context, args))) as any)
    ),
  };
};

export default simpleEvent;
