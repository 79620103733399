export default function FolderSVG({
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) {
    return <svg width="100%" height="100%" viewBox="0 0 25.492 20">
        <g id="folder" transform="translate(0 -50.447)">
            <path
                id="Path_22108"
                data-name="Path 22108"
                d="M1.607,50.447H7.7a1.116,1.116,0,0,1,.967.559l1,1.371a1.116,1.116,0,0,0,.967.559h14.85v15.65A1.488,1.488,0,0,1,24,70.075H1.488A1.488,1.488,0,0,1,0,68.587V54.672a11.168,11.168,0,0,1,.527-3.39h0A1.116,1.116,0,0,1,1.607,50.447Z"
                transform="translate(0 0)"
                fill={color} />
            <rect
                id="Rectangle_18"
                data-name="Rectangle 18"
                width="19.595"
                height="8.278"
                transform="translate(2.32 52.68)"
                fill="#ebf0f3" />
            <path 
                id="Path_22109"
                data-name="Path 22109" 
                d="M24.376,64.117H18.232a1.116,1.116,0,0,0-1.015.652l-1.051,2.3a1.116,1.116,0,0,1-1.015.652H0V81.885a1.488,1.488,0,0,0,1.488,1.488H24a1.488,1.488,0,0,0,1.488-1.488V65.233A1.116,1.116,0,0,0,24.376,64.117Z"
                 transform="translate(0 -12.926)"
                fill={color} />
        </g>
    </svg>

}