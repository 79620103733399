import { useState } from "react";
import style from "../FAQ.module.css";
import { useHistory } from "react-router-dom";

export interface item {
  id: number;
  value: string;
}

export interface props {
  items: item[];
  group?: string;
}
export default function FAQQuestionList({ items, group }: props) {
  const [current, setCurrent] = useState(-1);
  const history = useHistory()
  return (
    <ul className="list-group list-group-flush px-0 px-lg-2">
      {items.map((item) => (
        <li
          className={`list-group-item d-flex justify-content-between px-0 py-3 ${
            current === item.id && style.active
          }`}
          key={item.id}
          style={{
            cursor: "pointer",
            borderColor: "var(--light-blue)",
            fontSize:"14px"
          }}
          onClick={() => {
            history.push(`/faq/${group}/${item.id}`);
            setCurrent(item.id)
          }}
        >
          {item.value}
          <img
            src="/icons/right-arrow.svg"
            alt="right arrow"
            className="ms-1"
          />
        </li>
      ))}
    </ul>
  );
}
