import { Auth, Hub } from "aws-amplify";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FileType, NotificationModel } from "../Models/Document";
import { FileSummary } from "../Models/Profile";
import { fileListener, GetFileCount } from "../Services/Document";
import { favoriteFileListener } from "../Services/favorites";
import {
  clearAllMessages,
  clearAllNotifications,
  fetchNewMessages,
  fetchNewNotifications,
  GetPicture,
  getSubscriptionStatus,
  messageListener,
  notificationListener,
  RemoveMessage,
  RemoveNotification,
} from "../Services/Profile";

interface Message {
  id: string;
  userName: string;
  fileName: string;
  fileId: string;
  content: string;
  userId: string;
}
interface UserContextModel {
  isLoading: boolean;
  user: {
    email: string;
    name: string;
    locale: string;
    storage: string;
    id: string;
    completedStripeSetup: number;
    priceId?: string;
  };
  image: string;
  refresh: Function;
  summary: FileSummary;
  updateCount: Function;
  messages: Message[];
  removeMessage: Function;
  loadMoreMessages: Function;
  hasMoreMessages: boolean;
  clearMessages: Function;
  clearNotifications: Function;
  notifications: NotificationModel[];
  hasMoreNotifications: boolean;
  removeNotification: Function;
  loadMoreNotifications: Function;
  subscriptionStatus: string;
  clearUserContext: Function;
  admin: boolean;
}
export const UserContext = createContext<Partial<UserContextModel>>({
  isLoading: true,
});

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<
    | {
        email: string;
        name: string;
        locale: string;
        storage: string;
        id: string;
        completedStripeSetup: number;
        priceId?: string;
      }
    | undefined
  >(undefined);
  const [image, setImage] = useState<string>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [admin, setAdmin] = useState(false);
  const [nextMessagesToken, setNextMessagesToken] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [notifications, setNotifications] = useState<{
    items: NotificationModel[];
    token: "";
  }>({
    items: [],
    token: "",
  });
  const [fileSummary, setFileSummary] = useState<FileSummary>({
    image: {
      size: 0,
      count: 0,
    },
    media: {
      size: 0,
      count: 0,
    },
    other: {
      size: 0,
      count: 0,
    },
    document: {
      size: 0,
      count: 0,
    },
    total: 0,
  });
  const subscriber = useRef<any>();
  const favSubscriber = useRef<any>();
  const messageSubscriber = useRef<any>();
  const notificationSubscriber = useRef<any>();
  const fileListeners = () => {
    favoriteFileListener(handleListener).then((result) => {
      subscriber.current = result;
    });
    fileListener(handleListener).then((result) => {
      favSubscriber.current = result;
    });
    messageListener((value: any) => {
      setMessages((messages) => [value, ...messages]);
    }).then((result) => {
      messageSubscriber.current = result;
    });
    notificationListener((value: any) => {
      setNotifications((notifications) => ({
        items: [value, ...notifications.items],
        token: notifications.token,
      }));
    }).then((result) => {
      notificationSubscriber.current = result;
    });
  };

  const removeMessage = (id: string) => {
    RemoveMessage(id).then((result) => {
      if (result?.success) {
        setMessages(messages.filter((x) => x.id !== id));
      }
    });
  };
  // const removeNotification = (id: string) => {
  //   RemoveNotification(id).then((result) => {
  //     if (result?.success) {
  //       setNotifications({
  //         items: notifications.items.filter((x) => x.id !== id),
  //         token: notifications.token,
  //       });
  //     }
  //   });
  // };

  const removeNotification = (id: string, user: string) => {
    RemoveNotification(id, user).then((result) => {
      if (result?.success) {
        setNotifications({
          items: notifications.items.filter((x) => x.id !== id),
          token: notifications.token,
        });
      }
    });
  };
  const handleListener = (value: any) => {
    switch (value.type as FileType) {
      case "image":
        setFileSummary((fileSummary) => {
          return {
            ...fileSummary,
            image: {
              count: fileSummary.image.count + Number(!value.lastUploadType),
              size: fileSummary.image.size + value.size,
            },
          };
        });
        break;
      case "audio":
      case "video":
        setFileSummary((fileSummary) => {
          return {
            ...fileSummary,
            media: {
              count: fileSummary.media.count + Number(!value.lastUploadType),
              size: fileSummary.media.size + value.size,
            },
          };
        });
        break;
      case "document":
        setFileSummary((fileSummary) => {
          return {
            ...fileSummary,
            document: {
              count: fileSummary.document.count + Number(!value.lastUploadType),
              size: fileSummary.document.size + value.size,
            },
          };
        });
        break;
      case "other":
      default:
        setFileSummary((fileSummary) => {
          return {
            ...fileSummary,
            other: {
              count: fileSummary.other.count + Number(!value.lastUploadType),
              size: fileSummary.other.size + value.size,
            },
          };
        });
        break;
    }
  };

  // Get the time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleAuth = ({ payload }: any) => {
    switch (payload.event) {
      case "signIn":
        fileListeners();
        GetFileCount().then((value) => {
          if (value) {
            setFileSummary({
              image: value.image,
              media: value.media,
              other: value.other,
              document: value.document,
              total: value.total,
            });
            setMessages(value.messages);
            setNextMessagesToken(value.messageToken);
            setNotifications({
              items: value.notifications,
              token: value.notificationsToken,
            });
          }
        });

        return setUser({
          email: payload.data.attributes.email,
          name: payload.data.attributes.name,

          locale: payload.data.attributes.locale ?? timeZone,
          storage:
            payload.data.attributes["custom:storageName"] ?? "My Content..",
          id: payload.data.username,
          completedStripeSetup:
            payload.data.attributes["custom:completedStripeSetup"],
          priceId: payload.data.attributes["custom:priceId"],
        });
      case "signOut":
        return setUser(undefined);
      default:
    }
  };

  const updateCount = () => {
    GetFileCount().then((value) => {
      if (value) setFileSummary(value);
    });
  };
  const loadMoreMessages = () => {
    if (!!nextMessagesToken && !messageLoading) {
      setMessageLoading(true);
      fetchNewMessages(nextMessagesToken)
        .then((value) => {
          setMessages((messages) =>
            messages.concat(value?.notifications ?? [])
          );
          setNextMessagesToken(value?.messageToken);
        })
        .finally(() => {
          setNotificationsLoading(false);
        });
    }
  };

  const loadMoreNotifications = () => {
    if (!!notifications.token && !notificationsLoading) {
      setNotificationsLoading(true);
      fetchNewNotifications(notifications.token)
        .then((value) => {
          if (value) {
            setNotifications((notifications) => ({
              items: notifications.items.concat(value.notifications),
              token: value.messageToken,
            }));
          }
        })
        .finally(() => {
          setNotificationsLoading(false);
        });
    }
  };
  const refresh = (callback: Function) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((value) => {
        // console.log("value///", value);
        setAdmin(
          value.signInUserSession.accessToken.payload[
            "cognito:groups"
          ]?.includes("InkmarkAdmin")
        );
        setUser({
          email: value.attributes.email,
          name: value.attributes.name,
          locale: value.attributes.locale ?? timeZone,
          storage: value.attributes["custom:storageName"] ?? "My Content",
          id: value.username,
          completedStripeSetup: value.attributes["custom:completedStripeSetup"],
          priceId: value.attributes["custom:priceId"],
        });
        callback();
      })
      .catch((err) => {
        console.error(err);
      });
    GetPicture().then((result) => setImage(result.data));
  };
  const clearMessages = () => {
    setMessages([]);
    clearAllMessages().then(() => {});
  };
  const clearNotifications = () => {
    setNotifications({
      items: [],
      token: "",
    });
    clearAllNotifications().then(() => {});
  };
  useEffect(() => {
    Hub.listen("auth", handleAuth);
    Auth.currentAuthenticatedUser()
      .then(async (value) => {
        setAdmin(
          value.signInUserSession.accessToken.payload[
            "cognito:groups"
          ]?.includes("InkmarkAdmin")
        );

        setUser({
          email: value.attributes.email,
          name: value.attributes.name,
          locale: value.attributes.locale ?? timeZone,
          storage: value.attributes["custom:storageName"] ?? "My Content",
          id: value.username,
          completedStripeSetup: value.attributes["custom:completedStripeSetup"],
          priceId: value.attributes["custom:priceId"],
        });
        await GetFileCount().then((value) => {
          // console.log("value", value);
          if (value) {
            setFileSummary({
              image: value.image,
              media: value.media,
              other: value.other,
              document: value.document,
              total: value.total,
            });
            setMessages(value.messages);
            setNextMessagesToken(value.messageToken);
            setNotifications({
              items: value.notifications,
              token: value.notificationsToken,
            });
          }
        });
        const subscription = await getSubscriptionStatus();
        if (subscription.data) {
          setSubscriptionStatus(subscription.data);
        }
        fileListeners();
      })
      .catch((err) => {
        // console.log("error");
        console.error(err);
      })
      .then(() => setIsLoading(false));

    return () => {
      subscriber.current?.unsubscribe();
      Hub.remove("auth", handleAuth);
    };
  }, []);

  useEffect(() => {
    if (user) {
      GetPicture().then((result) => setImage(result.data));
    }
  }, [user]);

  const clearUserContext = () => {
    setUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{
        isLoading: isLoading,
        user: user,
        refresh: refresh,
        subscriptionStatus,
        image: image,
        summary: fileSummary,
        updateCount: updateCount,
        messages: messages,
        removeMessage: removeMessage,
        loadMoreMessages: loadMoreMessages,
        hasMoreMessages: !!nextMessagesToken,
        clearMessages: clearMessages,
        notifications: notifications.items,
        hasMoreNotifications: !!notifications.token,
        removeNotification: removeNotification,
        loadMoreNotifications: loadMoreNotifications,
        clearNotifications: clearNotifications,
        clearUserContext: clearUserContext,
        admin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);
