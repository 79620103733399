import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../Context/UserContext";
import Loading from "../Platform/Components/Loading";

export default function PrivateRoute({ children, ...rest }: any) {
  const { isLoading, user } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Loading />
            </div>
          );
        } else if (user) {
          // if (!user.completedStripeSetup) {
          //     return <Redirect
          //         to={{
          //             pathname: "/payment",
          //             state: { from: location }
          //         }}
          //     />
          // }
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}
