import InfiniteScroll from "react-infinite-scroller";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Context/UserContext";
import Avatar from "./avatar";
import AsyncModifiedDate from "./TableRows/AsyncModifiedDate";

export default function Notifications() {
  const {
    notifications,
    removeNotification,
    loadMoreNotifications,
    hasMoreNotifications,
    user,
  } = useAuth();

  return (
    <div className="h-100">
      <InfiniteScroll
        loader={<div className="text-center">Loading...</div>}
        useWindow={false}
        element="ul"
        loadMore={() => loadMoreNotifications && loadMoreNotifications()}
        initialLoad={true}
        hasMore={hasMoreNotifications}
        className="list-unstyled mb-0"
      >
        {notifications?.map((message) => (
          <li
            className="d-flex justify-content-between align-items-center border-top py-2"
            key={message.id}
          >
            <div>
              <Avatar name={message.userName} image={message.userId} />
            </div>
            <p
              onClick={() =>
                removeNotification && removeNotification(message.id, user?.id)
              }
              className="mb-0 ms-2 text-break"
            >
              {message.userName + " "}
              {GetValue(
                message.type,
                message.parameters,
                message.fileId,
                message.fileName
              )}
            </p>
            <button
              className="btn btn-transparent py-0 shadow-none"
              type="button"
              onClick={() => {
                removeNotification && removeNotification(message.id, user?.id);
              }}
            >
              <i className="fas fa-times-circle text color-red"></i>
            </button>
          </li>
        ))}
      </InfiniteScroll>
      {!notifications?.length && (
        <div style={{ textAlign: "center", paddingBottom: "10px" }}>
          No Notifications
        </div>
      )}
    </div>
  );
}
function GetValue(
  type: string,
  parameters: string[],
  fileId: string,
  fileName: string
) {
  switch (type.toUpperCase()) {
    case "CREATE":
      return (
        <span className="color-orange  font-weight-semibold text-break">
          added this
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>
          to a folder you have access to.
        </span>
      );
    case "NEWFILE":
      return (
        <span className="color-orange  font-weight-semibold text-break">
          added a file to
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>
        </span>
      );
    case "RENAME":
      return (
        <span className="color-orange font-weight-semibold text-break">
          renamed{" "}
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {parameters[0]}
          </NavLink>
        </span>
      );
    case "MOVED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          moved{" "}
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>
        </span>
      );
    case "ARCHIVED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          archived{" "}
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>
        </span>
      );

    case "SHARED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          Just added you to{" "}
          <NavLink
            to={`/shared/${parameters[0]}/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>
          file
        </span>
      );

    case "TIMELOCKSHARED":
      return (
        <small className="color-orange font-weight-semibold text-break">
          Added you to a time lock file, access will be given on{" "}
          <AsyncModifiedDate modified={parameters[0]} />
        </small>
      );

    case "UPDATETIMELOCKSHARED":
      return (
        <small className="color-orange font-weight-semibold text-break">
          Updated the time lock file date to{" "}
          <AsyncModifiedDate modified={parameters[0]} />
        </small>
      );

    case "UNARCHIVED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          unarchived{" "}
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>
        </span>
      );
    case "TRANSFERRED":
      return (
        <span className="color-orange font-weight-semibold text-break">
          transferred ownership of{" "}
          <NavLink
            to={`/files/file/${fileId}`}
            className="text-decoration-none mx-1"
          >
            {fileName}
          </NavLink>{" "}
          to
          {parameters.length > 0 ? parameters[1] : ""}
        </span>
      );
    default:
      return <span></span>;
  }
}
