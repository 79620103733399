import { useParams } from "react-router-dom";
import Layout from "../layout";
import File from "./Components/File";
import style from "../styles/files.module.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Sharing from "../Components/Sharing";
import Comments from "../Components/Comments";
import Activity from "../Components/Activity";
import { getFile, GetNextActivities, Preview } from "../../Services/Document";
import { useEffect, useRef, useState } from "react";
import {
  Comment as CommentModel,
  FileData,
  member,
  Activity as ActivityModel,
} from "../../Models/Document";
import { commentListener } from "../../Services/Comments";
import getBreadCrumb from "./BreadCrumb";

export default function FilesFile() {
  const { id } = useParams<{ id: string }>();
  const [file, setFile] = useState<FileData>();
  const [previewFile, setPreviewFile] = useState("");
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [activities, setActivities] = useState<{
    items: ActivityModel[];
    token: string;
  }>({
    items: [],
    token: "",
  });
  const [members, setMembers] = useState<member[]>([]);
  const commentSubscriber = useRef<any>();

  const refresh = () => {
    getFile(id).then((value: any) => {
      setFile(value.file);
      setComments(value.comments.reverse());
      setMembers(value.members);
      setActivities({
        items: value.activities,
        token: value.activitiesToken,
      });
      setBreadCrumbItems(getBreadCrumb(value.parent));
    });
    Preview(id).then((value) => {
      if (value?.success && value.data) {
        setPreviewFile(value.data);
      }
    });
  };

  useEffect(() => {
    if (id) {
      getFile(id).then((value: any) => {
        setFile(value.file);
        setComments(value.comments.reverse());
        setMembers(value.members);
        setActivities({
          items: value.activities,
          token: value.activitiesToken,
        });
        setBreadCrumbItems(getBreadCrumb(value.parent));
      });
      commentListener(id, (value: any) => {
        setComments((comments) => [...comments, value]);
      }).then((value) => {
        commentSubscriber.current = value;
      });

      Preview(id).then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
        }
      });
    }
    return () => {
      if (commentSubscriber.current) {
        commentSubscriber.current.unsubscribe();
      }
    };
  }, [id]);

  const nextActivities = () => {
    if (activities.token) {
      GetNextActivities(id, activities.token).then((values) => {
        if (values) {
          setActivities((activities) => ({
            items: activities.items.concat(values.activities),
            token: values.token,
          }));
        }
      });
    }
  };
  return (
    <Layout>
      <div className="d-lg-none h-100">
        <Tabs
          selectedTabClassName={style.tabSelected}
          className="d-flex flex-column h-100"
          selectedTabPanelClassName="d-flex  flex-fill flex-column"
        >
          <TabList className={style.tabs}>
            <Tab>Home</Tab>
            <Tab>Sharing</Tab>
            <Tab>Comments</Tab>
            <Tab>Activity</Tab>
          </TabList>
          <TabPanel>
            <File
              comments={comments}
              file={file}
              id={id}
              members={members}
              refresh={refresh}
              activities={activities.items}
              loadMoreActivities={nextActivities}
              hasMoreActivities={!!activities.token}
              breadcrumbs={breadcrumbItems}
              previewFile={previewFile}
            />
          </TabPanel>
          <TabPanel>
            <Sharing fileId={id} refresh={refresh} />
          </TabPanel>
          <TabPanel>
            <Comments comments={comments} fileId={id} />
          </TabPanel>
          <TabPanel>
            <Activity
              activities={activities.items}
              loadMore={nextActivities}
              hasMore={!!activities.token}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="d-none d-lg-block h-100">
        <File
          comments={comments}
          file={file}
          id={id}
          members={members}
          refresh={refresh}
          activities={activities.items}
          loadMoreActivities={nextActivities}
          hasMoreActivities={!!activities.token}
          breadcrumbs={breadcrumbItems}
          previewFile={previewFile}
        />
      </div>
    </Layout>
  );
}
