import Layout from "../layout";
import style from "../styles/files.module.css";
import FileTable from "../Components/TableRows/Table";
import { useHistory, useParams } from "react-router-dom";
import Progress from "../Components/Progress";
import { useAuth } from "../../Context/UserContext";
import { FileDetail } from "../../Models/Profile";
import GetFileSize from "../../Helper/FileSizeExtractor";
import { useEffect } from "react";
import {
  batchLockService,
  DownloadFiles,
  GetFilesByType,
  Preview,
  renameFileService,
} from "../../Services/Document";
import { useState } from "react";
import { FileData } from "../../Models/Document";
import { FileRow } from "../Components/TableRows";
import Popup from "../Components/popup";
import { useOverlay } from "../../Context/OverlayContext";
import Previewer from "../Components/Preview";
import { FileWithChecked } from "./home";
import { useAlert } from "react-alert";
import { batchArchive } from "../../Services/Archive";
import Move from "../Components/Move";
import CloseButton from "../Components/closeButton";

const colors = {
  image: "#3A77F3",
  document: "#FDA758",
  media: "#AC9CFF",
  other: "#FCC62D",
};
function getHeader(
  data: FileDetail,
  total: number,
  type: "image" | "document" | "media" | "other"
) {
  if (type !== undefined) {
    let logo: string;
    logo = type;
    var name = type.charAt(0).toUpperCase() + type.slice(1) + "s";
    return (
      <>
        <img src={`/logos/${logo}.png`} alt="All images" className="me-2 " />
        <div className="row flex-fill">
          <div className="col-12 col-lg-4">
            <h5>{name}</h5>
            <Progress now={(data.size / total) * 100} color={colors[type]} />
            <div className="mb-0 d-flex justify-content-between">
              {data.count} Files <span>{GetFileSize(data.size, type)}</span>{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default function All() {
  const { type } = useParams<{
    type: "image" | "document" | "media" | "other";
  }>();
  const [state, setState] = useState<{
    files: FileWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: false,
  });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { summary } = useAuth();
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const [openMove, setOpenMove] = useState(false);
  const { setLoading: setLoadingOverlay } = useOverlay();
  const alert = useAlert();
  const previewImage = (file: FileData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoadingOverlay(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoadingOverlay(false));
  };

  const handleChecked = (fileId: string, checked: boolean) => {
    setState((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };
  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        history.push("/files/file/" + file.id);
        break;
      default:
        break;
    }
  };
  const refresh = () => {
    GetFilesByType(type, null)
      .then((value) => {
        setState({
          files: value?.files,
          token: value?.token,
          loading: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    refresh();
  }, [type]);

  const rename = async (file: FileData, name: string) => {
    await renameFileService(file.id, name);
    setState({
      ...state,
      files:
        state?.files.map((f) =>
          f.id === file.id ? { ...f, name: name } : f
        ) ?? [],
    });
  };

  return (
    <Layout>
      <section className="h-100 mt-2 d-flex flex-column">
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn btn-transparent ps-0 shadow-none"
            type="button"
            onClick={() => history.goBack()}
            style={{
              fontSize: "24px",
            }}
          >
            <i className="fas fa-chevron-left text-primary"></i>
            <span className="d-none d-lg-inline ms-1">Back</span>
          </button>
          {summary && type && getHeader(summary[type], summary.total, type)}

          <Popup
            trigger={
              <button
                className={`btn ${
                  state.files.filter((x) => x.checked).map((x) => x.id)
                    .length <= 0
                    ? "btn-outline-none"
                    : "btn-outline-primary"
                } color-dark-grey shadow-none`}
                type="button"
                disabled={
                  state.files.filter((x) => x.checked).map((x) => x.id)
                    .length <= 0
                }
              >
                {state.files.filter((x) => x.checked).map((x) => x.id).length >
                  0 && <i className="fas fa-caret-down"></i>}
              </button>
            }
            position="bottom right"
            nested
            disabled={
              state.files.filter((x) => x.checked).map((x) => x.id).length <= 0
            }
          >
            {(close: Function) => (
              <div
                className="card bg-white p-0 ms-auto"
                style={{
                  filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                  borderRadius: "10px",
                }}
              >
                <div className="card-body px-0">
                  <ul className={`list-unstyled mb-0 ${style.menu}`}>
                    <li>
                      <button
                        className="btn shadow-none px-3 "
                        onClick={() => {
                          if (
                            state.files
                              .filter((x) => x.checked)
                              .map((x) => x.id).length > 0
                          ) {
                            setLoading(true);
                            batchLockService(
                              state.files
                                .filter((x) => x.checked)
                                .map((x) => x.id)
                            ).finally(() => {
                              refresh();
                              setLoading(false);
                            });
                          } else {
                            alert.info("No file has been selected", {
                              timeout: 5000,
                            });
                          }
                          close();
                        }}
                      >
                        <i className="fas fa-lock me-1"></i>
                        Lock File
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none px-3 "
                        onClick={() => {
                          setLoading(true);
                          batchArchive(
                            state.files
                              .filter((x) => x.checked)
                              .map((x) => x.id)
                          ).finally(() => {
                            refresh();
                            setLoading(false);
                          });
                        }}
                      >
                        <i className="fas fa-file-archive text-start me-1"></i>
                        Archive
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none px-3 "
                        onClick={() => {
                          if (
                            state.files
                              .filter((x) => x.checked)
                              .map((x) => x.id).length > 0
                          )
                            setOpenMove(true);
                          else {
                            alert.info("No file has been selected", {
                              timeout: 5000,
                            });
                          }
                        }}
                      >
                        <img
                          src="/icons/Move.png"
                          alt="move"
                          className="img-fluid"
                        />{" "}
                        Move to
                      </button>
                    </li>

                    <li>
                      <button
                        className="btn shadow-none px-3 "
                        onClick={() => {
                          setLoading(true);
                          DownloadFiles(
                            state.files
                              .filter((x) => x.checked)
                              .map((x) => x.id)
                          ).finally(() => {
                            setLoading(false);
                          });
                        }}
                      >
                        <i className="fas fa-file-download me-1"></i>
                        Download
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </Popup>
        </div>

        <div className={`mb-0 ${style.table}`}>
          <FileTable
            border={state?.files.length !== 0}
            hasMore={!!state.token}
            next={() => {
              if (!!state.token)
                if (type)
                  GetFilesByType(type, state?.token ?? null).then((value) => {
                    setState({
                      ...state,
                      token: value?.token ?? "",
                      files: (state?.files ?? []).concat(value?.files),
                    });
                  });
            }}
            loading={loading}
          >
            {state?.files.map((file, i) => (
              <FileRow
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                fileData={file}
                onClickChecked={(data: boolean) => handleChecked(file.id, data)}
                onClick={() => history.push("/files/file/" + file.id)}
                key={file.id}
                onRename={(value: string) => rename(file, value)}
                onMove={() => {}}
                onArchive={() => {}}
                onLock={() => history.push("/time-lock/file/" + file.id)}
                onFavorite={(fileId: any) => {
                  setState({
                    ...state,
                    files:
                      state?.files.map((f) =>
                        f.id === file.id ? { ...f, favorites: true } : f
                      ) ?? [],
                  });
                }}
                onUnfavorite={(fileId: any) => {
                  setState({
                    ...state,
                    files:
                      state?.files.map((f) =>
                        f.id === file.id ? { ...f, favorites: false } : f
                      ) ?? [],
                  });
                }}
              />
            ))}
          </FileTable>
        </div>
      </section>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => setPreview(false)}
      >
        <div
          style={{
            height: "90vh",
            width: "80vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
        </div>
      </Popup>
      <Popup
        open={openMove}
        closeOnDocumentClick={false}
        onClose={() => setOpenMove(false)}
        closeOnEscape={false}
        className="menuPopup"
      >
        {(close: Function) => (
          <Move
            close={() => {
              close();
            }}
            ids={state.files.filter((x) => x.checked).map((x) => x.id)}
            onmove={() => {
              refresh();
            }}
            root={0}
          />
        )}
      </Popup>
    </Layout>
  );
}
