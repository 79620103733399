import { Create as CreateModel } from "../Models/Document";
import { API, Auth, Storage } from "aws-amplify";
import {
  allFilesByType,
  folder,
  fileCounts,
  homePage,
  file,
  subfolders,
  rootfolders,
  rootFolders,
  nextFolder,
  subfoldersList,
  rootfoldersList,
} from "../graphql/customQueries";
import GetFileSize from "../Helper/FileSizeExtractor";
import {
  addFile,
  batchLockFile,
  batchMoveFile,
  moveFile as move,
  preUploadFile,
  renameFile,
  replaceFile,
  updateFile,
} from "../graphql/mutations";
import {
  onFileByUser,
  onFileByUserByParent,
} from "../graphql/customSubscriptions";
import moment from "moment";
import { nextFileActivities } from "../graphql/activityQuerites";
import {
  getPreviewUrl,
  getSignedUrl,
  getSignedUrlByFileId,
} from "../graphql/queries";

export async function PreUpload(files: string, parent: string, locked = false) {
  try {
    var value: any = await API.graphql({
      query: preUploadFile,
      variables: {
        files: files,
        parent: parent,
        locked: locked,
      },
    });

    return value.data.preUploadFile.map((x: any) => ({
      id: x.id,
      status: x.status,
      type: x.type,
      similarId: x.similarId,
    }));
  } catch (error: any) {
    return false;
  }
}
export async function Create(document: CreateModel) {
  try {
    var value: any = await API.graphql({
      query: addFile,
      variables: {
        id: document.id,
        name: document.name,
        parent: document.parent,
        size: document.size,
        type: document.type,
      },
    });

    return {
      id: value.data.addFile.id,
      name: value.data.addFile.name,
      type: value.data.addFile.type,
      created: new Date(value.data.addFile.created),
      modified: new Date(value.data.addFile.modified),
      size: GetFileSize(value.data.addFile.size, value.data.addFile.type),
      owner: value.data.addFile.userId,
      parent: value.data.addFile.parent,
    } as any;
  } catch (error: any) {
    return false;
  }
}

export async function Replace(document: CreateModel) {
  try {
    var value: any = await API.graphql({
      query: replaceFile,
      variables: {
        id: document.id,
        size: document.size,
        type: document.type,
        name: document.name,
      },
    });

    return {
      id: value.data.replaceFile.id,
      name: value.data.replaceFile.name,
      type: value.data.replaceFile.type,
      created: new Date(value.data.replaceFile.created),
      modified: new Date(value.data.replaceFile.modified),
      size: GetFileSize(
        value.data.replaceFile.size,
        value.data.replaceFile.type
      ),
      owner: value.data.replaceFile.userId,
      parent: value.data.replaceFile.parent,
    } as any;
  } catch (error: any) {
    return false;
  }
}

export async function Recent() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    const value = await (API.graphql({
      query: homePage,
      variables: { user: user.username, date: date },
    }) as Promise<{
      data: any;
    }>);
    const files = value.data.files.items.map((x: any) => ({
      ...x,
      size: GetFileSize(x.size, x.type),
      modified: new Date(x.modified),
      users: x.shared.items.map((x: any) => ({
        id: x.user.id,
        name: x.user.name,
        identityId: x.user.identityId,
      })),
      modifierName: x.uploader?.name,
    }));
    const favorites = value.data.favorites.items.map((x: any) => ({
      ...x.file,
      size: GetFileSize(x.file.size, x.file.type),
      users: x.file.shared.items.map((x: any) => ({
        id: x.user.id,
        name: x.user.name,
        identityId: x.user.identityId,
      })),
    }));
    return { files, favorites };
  } catch (error: any) {
    return undefined;
  }
}

export async function GetFileCount() {
  try {
    let userId = await Auth.currentAuthenticatedUser();

    const value = await (API.graphql({
      query: fileCounts,
      variables: { id: userId.username },
    }) as Promise<{
      data: any;
    }>);

    let user = value.data.getUser;
    const image = {
      count: user.imageCount,
      size: user.imageSize,
    };
    const document = {
      count: user.documentCount,
      size: user.documentSize,
    };
    const media = {
      count: user.mediaCount,
      size: user.mediaSize,
    };
    const other = {
      count: user.otherCount,
      size: user.otherSize,
    };
    const total = user.plan.size;
    const messages = user.messages.items.map((x: any) => ({
      userName: x.comment.user.name,
      fileName: x.comment.file.name,
      fileId: x.comment.file.id,
      content: x.comment.content,
      id: x.id,
      userId: x.comment.user.id,
    }));
    const notifications = user.notifications.items.map((x: any) => ({
      createdAt: new Date(x.activity.createdAt),
      parameters: x.activity.parameters ?? [],
      userId: x.activity.user.id,
      userName: x.activity.user.name,
      type: x.activity.type,
      id: x.id,
      fileName: x.activity.file.name,
      fileId: x.activity.file.id,
    }));
    return {
      image,
      document,
      media,
      other,
      total,
      messages,
      messageToken: user.messages.nextToken,
      notificationsToken: user.notifications.nextToken,
      notifications,
    };
  } catch (error: any) {
    // console.log("error", error);
    return undefined;
  }
}

export async function GetFilesByType(
  type: "image" | "document" | "media" | "other",
  token: string | null
) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: allFilesByType,
      variables: { type: type, id: user.username, token: token },
    }) as Promise<{
      data: any;
    }>);
    const result = value.data.files;
    const files = result.items.map((x: any) => ({
      ...x,
      size: GetFileSize(x.size, x.type),
      modified: new Date(x.modified),
      modifierName: x.uploader?.name,
    }));
    return { files, token: result.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function fileListener(callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  return (
    API.graphql({
      query: onFileByUser,
      variables: { userId: user.username },
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onFileByUser;
      let data = {
        ...temp,
        modified: new Date(temp.modified),
        size: temp.size,
      };
      callBack(data);
    },
  });
}

export async function parentListener(parent: string, callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  return (
    API.graphql({
      query: onFileByUserByParent,
      variables: { userId: user.username, parent: parent },
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onFileByUserByParent;
      let data = {
        ...temp,
        size: GetFileSize(temp.size, temp.type),
        modified: new Date(temp.modified),
        modifierName: temp.uploader.name,
      };
      callBack(data);
    },
  });
}

export async function GetFolder(
  parent: string,
  root: number,
  token?: string | null
) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    if (parent === root.toString()) {
      const value = await (API.graphql({
        query: rootFolders,
        variables: {
          parent: parent,
          id: user.username,
          date: date,
          token: token,
        },
      }) as Promise<{
        data: any;
      }>);
      const files = value.data.folders.items.map((x: any) => ({
        ...x,
        size: GetFileSize(x.size, x.type),
        modified: new Date(x.modified),
        users: x.shared.items.map((x: any) => ({
          id: x.user?.id,
          name: x.user?.name,
          identityId: x.user?.identityId,
        })),
        modifierName: x.uploader?.name,
        favorites: x.favorites.items.length > 0,
      }));
      return { files, token: value.data.folders.nextToken };
    } else {
      const value = await (API.graphql({
        query: folder,
        variables: {
          id: parent,
          root: root,
          userId: user.username,
          date: date,
        },
      }) as Promise<{
        data: any;
      }>);
      const folders = value.data.files;
      const members = folders.shared.items.map((x: any) => ({
        ...x.user,
      }));
      const files = folders.children.items.map((x: any) => ({
        ...x,
        size: GetFileSize(x.size, x.type),
        modified: new Date(x.modified),
        users: x.shared.items.map((x: any) => ({
          id: x.user?.id,
          name: x.user?.name,
          identityId: x.user?.identityId,
        })),
        modifierName: x.uploader?.name,
        favorites: x.favorites.items.length > 0,
      }));
      const comments = folders.comments.items.map((x: any) => ({
        ...x,
        createdAt: new Date(x.createdAt),
        userComment: x.userId === user.username,
      }));
      const activities = folders.activities.items.map((x: any) => ({
        createdAt: new Date(x.createdAt),
        parameters: x.parameters ?? [],
        userId: x.user.id,
        userName: x.user.name,
        type: x.type,
        id: x.id,
      }));

      return {
        files,
        comments,
        members,
        token: folders.children.nextToken,
        activities,
        activitiesToken: folders.activities.nextToken,
        parent: folders.parentDet,
        name: folders.name,
      };
    }
  } catch (error: any) {
    return undefined;
  }
}

export async function GetNextFolder(
  parent: string,
  root: number,
  token?: string | null
) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    const value = await (API.graphql({
      query: nextFolder,
      variables: {
        id: parent,
        root: root,
        userId: user.username,
        date: date,
        token: token,
      },
    }) as Promise<{
      data: any;
    }>);
    const folders = value.data.files;

    const files = folders.children.items.map((x: any) => ({
      ...x,
      size: GetFileSize(x.size, x.type),
      modified: new Date(x.modified),
      users: x.shared.items.map((x: any) => ({
        id: x.user.id,
        name: x.user.name,
        identityId: x.user.identityId,
      })),
      modifierName: x.uploader?.name,
      favorites: x.favorites.items.length > 0,
    }));
    return { files, token: folders.children.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function getFile(id: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: file,
      variables: { id: id },
    }) as Promise<{
      data: any;
    }>);
    const data = value.data.files;

    return {
      comments: data.comments.items.map((x: any) => ({
        ...x,
        createdAt: new Date(x.createdAt),
        userComment: x.userId === user.username,
        name: x.user.name,
      })),
      file: {
        createdAt: new Date(data.created),
        id: data.id,
        modified: new Date(data.modified),
        name: data.name,
        parent: data.parent,
        size: GetFileSize(data.size, data.type),
        type: data.type,
        userId: data.userId,
        lastVersion: data.versions.items[0].id,
      },
      members: data.shared.items.map((x: any) => ({
        ...x.user,
      })),
      activities: data.activities.items.map((x: any) => ({
        createdAt: new Date(x.createdAt),
        parameters: x.parameters ?? [],
        userId: x.user.id,
        userName: x.user.name,
        type: x.type,
        id: x.id,
      })),
      activitiesToken: data.activities.nextToken,
      parent: data.parentDet,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function DownloadFile(id?: string) {
  const value = await (API.graphql({
    query: getSignedUrl,
    variables: { id: id },
  }) as Promise<{
    data: any;
  }>);
  const url = value.data.getSignedUrl;

  const a = document.createElement("a");
  a.href = url;
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

export async function DownloadFiles(ids: string[]) {
  for (const id of ids) {
    const value = await (API.graphql({
      query: getSignedUrlByFileId,
      variables: { fileId: id },
    }) as Promise<{
      data: any;
    }>);
    const url = value.data.getSignedUrlByFileId;
    const frame = document.createElement("iframe") as any;
    frame.src = url;
    frame.download = 1;
    document.body.appendChild(frame);
  }
}

export async function subFolders(
  fileId: string | undefined,
  ids: string[] | undefined,
  root: number,
  id?: string,
  nextToken?: string
) {
  try {
    let files: any[] = [];
    let parent: any = {};
    let date = moment(new Date(new Date().toUTCString())).format("YYYY-MM-DD");
    let token;
    if (id !== root.toString()) {
      if (fileId) {
        const value = await (API.graphql({
          query: subfolders,
          variables: {
            id: id,
            fileId: fileId,
            root: root,
            parent: root.toString(),
            date: date,
            token: nextToken,
          },
        }) as Promise<{
          data: any;
        }>);

        const data = value.data.files;
        files = data.children.items;
        parent = {
          parent: data.parent,
          name: data.name,
          id: data.id,
        };
        token = data.children.nextToken;
      } else if (ids) {
        const value = await (API.graphql({
          query: subfoldersList,
          variables: {
            id: id,
            filter: {
              and: ids.map((id) => ({
                or: [
                  {
                    folderOrFile: { eq: "folder" },
                    id: { ne: id },
                    root: { eq: root },
                    activeDate: { attributeExists: false },
                  },
                  {
                    folderOrFile: { eq: "folder" },
                    id: { ne: id },
                    root: { eq: root },
                    activeDate: { attributeExists: true, le: date },
                  },
                  {
                    folderOrFile: { eq: "folder" },
                    id: { ne: id },
                    root: { eq: root },
                    activeDate: { eq: null },
                  },
                ],
              })),
            },
            token: nextToken,
          },
        }) as Promise<{
          data: any;
        }>);
        const data = value.data.files;
        files = data.children.items;
        parent = {
          parent: data.parent,
          name: data.name,
          id: data.id,
        };
        token = data.children.nextToken;
      }
    } else {
      let user = await Auth.currentAuthenticatedUser();
      if (fileId) {
        const value = await (API.graphql({
          query: rootfolders,
          variables: {
            fileId: fileId,
            id: user.username,
            parent: root.toString(),
            date: date,
            token: nextToken,
          },
        }) as Promise<{
          data: any;
        }>);
        files = value.data.folders.items;
        parent = {
          parent: undefined,
          name: user.attributes["custom:storageName"],
          id: root.toString(),
        };
        token = value.data.folders.nextToken;
      } else if (ids) {
        const value = await (API.graphql({
          query: rootfoldersList,
          variables: {
            filter: {
              and: ids.map((id) => ({
                or: [
                  {
                    parent: { eq: root.toString() },
                    type: { eq: "folder" },
                    id: { ne: id },
                    activeDate: { attributeExists: false },
                  },
                  {
                    parent: { eq: root.toString() },
                    type: { eq: "folder" },
                    id: { ne: id },
                    activeDate: { attributeExists: true, le: date },
                  },
                  {
                    parent: { eq: root.toString() },
                    type: { eq: "folder" },
                    id: { ne: id },
                    activeDate: { eq: null },
                  },
                ],
              })),
            },
            token: nextToken,
            id: user.username,
          },
        }) as Promise<{
          data: any;
        }>);
        files = value.data.folders.items;
        parent = {
          parent: undefined,
          name: user.attributes["custom:storageName"],
          id: root.toString(),
        };
        token = value.data.folders.nextToken;
      }
    }
    return { files, parent, token };
  } catch (error: any) {
    return undefined;
  }
}

export async function moveFile(id: string, newParent: string) {
  try {
    await (API.graphql({
      query: move,
      variables: { id: id, parent: newParent },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function moveFileBatch(fileId: string[], newParent: string) {
  try {
    await (API.graphql({
      query: batchMoveFile,
      variables: {
        ids: fileId.map((x) => x).join(","),
        parent: newParent,
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function renameFileService(id: string, name: string) {
  try {
    await (API.graphql({
      query: renameFile,
      variables: { id: id, name: name },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function lockFileService(id: string) {
  try {
    await (API.graphql({
      query: updateFile,
      variables: {
        input: {
          id: id,
          activeDate: moment().utc().add(1, "months").format("YYYY-MM-DD"),
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return undefined;
  }
}

export async function batchLockService(fileId: string[]) {
  try {
    await (API.graphql({
      query: batchLockFile,
      variables: {
        ids: fileId.map((x) => x).join(","),
        date: moment().utc().add(1, "months").format("YYYY-MM-DD"),
      },
    }) as Promise<{
      data: any;
    }>);
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function GetPicture(id: string) {
  try {
    if (!!id) {
      const result = await Storage.get(id, {
        download: true,
      });
      if (result && result.Body) {
        var value = await new Response(result.Body as Blob).text();
        return {
          success: true,
          data: value,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function GetNextActivities(id: string, token: string) {
  try {
    const value = await (API.graphql({
      query: nextFileActivities,
      variables: {
        id: id,
        token: token,
      },
    }) as Promise<{
      data: any;
    }>);
    let data = value.data.files.activities;
    const activities = data.items.map((x: any) => ({
      createdAt: new Date(x.createdAt),
      parameters: x.parameters ?? [],
      userId: x.user.id,
      userName: x.user.name,
      type: x.type,
      id: x.id,
    }));
    return { activities, token: data.nextToken };
  } catch (error: any) {
    return undefined;
  }
}

export async function Preview(id: string) {
  try {
    const value = await (API.graphql({
      query: getPreviewUrl,
      variables: { id: id },
    }) as Promise<{
      data: any;
    }>);

    return {
      success: true,
      data: value.data.getPreviewUrl,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function ViewVersion(id?: string) {
  const value = await (API.graphql({
    query: getSignedUrl,
    variables: { id: id },
  }) as Promise<{
    data: any;
  }>);
  const url = value.data.getSignedUrl;

  const urlObject = new URL(url);
  const contentDisposition = urlObject.searchParams.get(
    "response-content-disposition"
  );

  // Extract filename from content disposition
  const filenameMatch = contentDisposition?.match(/filename=([^;]+)/);
  const filename = filenameMatch ? filenameMatch[1] : null;
  // const fileType = filename ? filename.split(".").pop() : null;

  return {
    success: true,
    data: url,
    type: filename,
  };
}
