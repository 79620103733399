import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../Context/UserContext";

export default function RedirectRoute({ children, ...rest }: any) {
    const { isLoading, user, subscriptionStatus } = useAuth()
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isLoading) {
                    return <div>Loading</div>
                }        
                else if (user) {
                    if(subscriptionStatus==="incomplete"){
                        return <Redirect
                        to={{
                            pathname: "/payment",
                            state: { from: location }
                        }}
                    />
                    }
                    return <Redirect
                        to={{
                            pathname: "/platform",
                            state: { from: location }
                        }}
                    />
                } else {
                    return children
                }
            }}
        />
    );
}