import style from "../../styles/files.module.css";
import FileTable from "../../Components/TableRows/Table";
import Comment from "../../Components/Comment";
import { Link, useHistory } from "react-router-dom";
import DropFiles from "../../Components/Dropfiles";
import Popup from "../../Components/popup";
import Share from "../../Components/Share";
import {
  Comment as CommentModel,
  FileData,
  Create as CreateModel,
  member,
} from "../../../Models/Document";
import { useEffect, useState } from "react";
import { FileRow } from "../../Components/TableRows";
import {
  batchLockService,
  Create,
  DownloadFiles,
  GetNextFolder,
  Preview,
} from "../../../Services/Document";
import { v4 as uuid } from "uuid";
import Avatar from "../../Components/avatar";
import DesktopUpload from "../../Components/DesktopUpload";
import { useOverlay } from "../../../Context/OverlayContext";
import Previewer from "../../Components/Preview";
import { FileWithChecked } from "../../Home/home";
import { batchArchive } from "../../../Services/Archive";
import { useAlert } from "react-alert";
import Move from "../../Components/Move";
import CloseButton from "../../Components/closeButton";
import { IoArrowBackCircle } from "react-icons/io5";

interface props {
  comments: CommentModel[];
  files: FileWithChecked[];
  id: string;
  rename: Function;
  move: Function;
  onArchive: Function;
  createFile: Function;
  members: member[];
  refresh: Function;
  token: string;
  loading: boolean;
  onNext: (files: FileWithChecked[], token: string) => any;
  initialLoad: boolean;
  breadcrumbs: any[];
  name: string;
  onFavorite: Function;
  onUnfavorite: Function;
  onClickChecked: Function;
}
export default function Folder({
  comments,
  files,
  id,
  rename,
  move,
  onArchive,
  createFile,
  members,
  refresh,
  token,
  onNext,
  initialLoad,
  breadcrumbs,
  name,
  onFavorite,
  onUnfavorite,
  onClickChecked,
}: props) {
  const [createFolder, setCreateFolder] = useState(false);
  const history = useHistory();
  const [folderCreating, setFolderCreating] = useState(false);
  const { setLoading } = useOverlay();
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const [openMove, setOpenMove] = useState(false);
  const [firstId, setFirstId] = useState(null);
  const [prevId, setPrevId] = useState("/files");
  const alert = useAlert();
  const previewImage = (file: FileData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoading(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };
  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        type: "folder",
        parent: id,
        size: 0,
        id: uuid(),
        uploadUrl: "file",
      };
      await Create(document);
      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
    }
  };

  const clickFile = async (file: FileData) => {
    if (file.type !== "folder") {
      history.push("/files/file/" + file.id);
    } else {
      history.push("/files/folder/" + file.id);
    }
  };

  const onLock = async (file: FileData) => {
    if (file.type !== "folder") {
      history.push("/time-lock/file/" + file.id);
    } else {
      history.push("/time-lock/folder/" + file.id);
    }
  };

  breadcrumbs.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });

  useEffect(() => {
    breadcrumbs.map((x, index) => {
      if (breadcrumbs.length === 1) {
        setPrevId(x.id);
      }
      if (breadcrumbs.length === 2) {
        setPrevId(x.id);
      }
      if (breadcrumbs.length === 3) {
        setPrevId(x.id);
      }
    });

    if (breadcrumbs.length === 0) {
      setPrevId("/files");
    }
  }, [breadcrumbs]);

  return (
    <section className="h-100 row">
      <div
        className={`col-12 h-100 d-flex flex-column ${
          id ? "col-lg-8" : "col-lg-12"
        }`}
      >
        <DropFiles
          callback={createFile}
          parent={id}
          url="files"
          uploadUrl="file"
        />
        <div className="d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-start mt-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3">
              <li className="breadcrumb-item">
                <Link to={`${prevId}`}>
                  <IoArrowBackCircle
                    style={{ width: "30px", height: "30px" }}
                  />
                </Link>
                <Link
                  className="text-decoration-none color-dark-grey"
                  to="/files"
                >
                  My files
                </Link>
              </li>
              {breadcrumbs.length === 3 &&
                !breadcrumbs.some((item) => item.id === firstId) && (
                  <li className="breadcrumb-item">
                    {" "}
                    <i className="fas fa-ellipsis-h"></i>{" "}
                  </li>
                )}
              {breadcrumbs.map((x) => (
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none color-dark-grey"
                    to={"/files/folder/" + x.id}
                  >
                    {x.name}
                  </Link>
                </li>
              ))}

              <li className="breadcrumb-item">{name}</li>
            </ol>
          </nav>
          <DesktopUpload
            callback={createFile}
            parent={id}
            onCreateFolder={() => setCreateFolder(true)}
            url="files"
            uploadUrl="file"
            disableMulti={
              files.filter((x) => x.checked).map((x) => x.id).length <= 0
            }
          />
        </div>
        <div className={`mb-lg-5 mb-0 ${style.table}`}>
          <FileTable
            border={files.length !== 0}
            hasMore={!!token}
            next={() => {
              if (!!token)
                GetNextFolder(id, 0, token).then((values) => {
                  if (values) onNext(values.files, values.token);
                });
            }}
            loading={initialLoad}
            items={[
              {
                display: (
                  <>
                    {files.filter((file) => file.checked).length !==
                    files.length ? (
                      <>
                        <i className="fas fa-check me-1"></i>
                        Select All
                      </>
                    ) : (
                      <>
                        <i className="far fa-window-close me-1"></i>
                        Deselect All
                      </>
                    )}
                  </>
                ),
                action: () => {
                  if (
                    files.filter((file) => file.checked).length == files.length
                  ) {
                    for (let file of files) {
                      onClickChecked(file.id, false);
                    }
                  } else {
                    for (let file of files) {
                      onClickChecked(file.id, true);
                    }
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-archive text-start me-1"></i>
                    Archive
                  </>
                ),
                action: () => {
                  setLoading(true);
                  batchArchive(
                    files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    refresh();
                    setLoading(false);
                  });
                },
              },
              {
                display: (
                  <>
                    <img
                      src="/icons/Move.png"
                      alt="move"
                      className="img-fluid"
                    />{" "}
                    Move to
                  </>
                ),
                action: () => {
                  if (
                    files.filter((x) => x.checked).map((x) => x.id).length > 0
                  )
                    setOpenMove(true);
                  else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-lock me-1"></i>
                    Lock File
                  </>
                ),
                action: () => {
                  if (
                    files.filter((x) => x.checked).map((x) => x.id).length > 0
                  ) {
                    setLoading(true);
                    batchLockService(
                      files.filter((x) => x.checked).map((x) => x.id)
                    ).finally(() => {
                      refresh();
                      setLoading(false);
                    });
                  } else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },

              {
                display: (
                  <>
                    <i className="fas fa-file-download me-1"></i>
                    Download
                  </>
                ),
                action: async () => {
                  setLoading(true);
                  await DownloadFiles(
                    files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    setLoading(false);
                  });
                },
              },
            ]}
          >
            {createFolder && (
              <tr>
                <td colSpan={5}>
                  <div
                    className={`d-flex w-100 ${
                      folderCreating && style.disabled
                    }`}
                  >
                    <img
                      src={`/logos/folder.png`}
                      alt={`folder logo`}
                      className="img-fluid"
                    />
                    <input
                      type="text"
                      className="form-control ms-2 shadow-none d-none d-lg-inline flex-fill"
                      onClick={(e: any) => e.stopPropagation()}
                      onKeyDown={handleFolderKeyEvent}
                      autoFocus
                      disabled={folderCreating}
                    />
                  </div>
                </td>
              </tr>
            )}

            {files.map((file, i) => (
              <FileRow
                fileData={file}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                onClick={() => clickFile(file)}
                key={file.id}
                onRename={(value: string) => rename(file, value)}
                onMove={move}
                onArchive={onArchive}
                onFavorite={onFavorite}
                onUnfavorite={onUnfavorite}
                onLock={() => onLock(file)}
                onClickChecked={(val: boolean) => onClickChecked(file.id, val)}
              />
            ))}
          </FileTable>
        </div>
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => setPreview(false)}
        >
          <div
            style={{
              height: "90vh",
              width: "80vw",
            }}
          >
            <CloseButton preview={preview} setPreview={setPreview} />
            <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
          </div>
        </Popup>
      </div>

      <div className="d-none col-lg-4  d-lg-flex h-100 flex-column">
        <div
          className="card card-radius mb-3 "
          style={{
            borderColor: "#ECECF2",
          }}
        >
          <div className="card-body px-0 pb-0 d-flex flex-column justify-content-center align-items-center">
            <p className="text-center fw-bold">Shared with</p>
            <span>
              {members.map((user, i) => (
                <Avatar name={user.name} image={user.id} key={user.id} />
              ))}
            </span>
            <Popup
              className="modalPopup"
              trigger={
                <button
                  className="btn py-3 fw-bold color-dark-grey shadow-none w-100 mt-2 card-radius text-center"
                  style={{
                    backgroundColor: "#ECECF2",
                    borderColor: "#ECECF2",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  Manage Sharing
                </button>
              }
              modal
              closeOnDocumentClick={false}
            >
              {(close: Function) => (
                <div
                  className="bg-white p-3 d-flex flex-column"
                  style={{
                    width: "40vw",
                    height: "60vh",
                    filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                  }}
                >
                  <Share close={() => close()} fileId={id} refresh={refresh} />
                </div>
              )}
            </Popup>
          </div>
        </div>
        <div
          className="h-100"
          style={{
            flex: 2,
            overflow: "auto",
          }}
        >
          <Comment
            fileId={id}
            comments={comments}
            next={function () {
              throw new Error("Function not implemented.");
            }}
            hasMore={false}
          />
        </div>
      </div>

      <Popup
        open={openMove}
        closeOnDocumentClick={false}
        onClose={() => setOpenMove(false)}
        closeOnEscape={false}
        className="menuPopup"
      >
        {(close: Function) => (
          <Move
            close={() => {
              close();
            }}
            ids={files.filter((x) => x.checked).map((x) => x.id)}
            onmove={() => {
              refresh();
            }}
            root={0}
          />
        )}
      </Popup>
    </section>
  );
}
