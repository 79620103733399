import { ResponsivePie } from "@nivo/pie";
import GetFileSize from "../../Helper/FileSizeExtractor";
import { FileSummary } from "../../Models/Profile";

interface props {
  summary?: FileSummary;
}

export default function Pie({ summary }: props) {
  // console.log("summary", summary);

  const CenteredMetric = ({ dataWithArc, centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "32px",
            fontWeight: 700,
          }}
        >
          {GetFileSize(
            summary
              ? summary.other.size +
                  summary.media.size +
                  summary.document.size +
                  summary.image.size
              : 0,
            "file"
          )}
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "20px",
            fill: "#656E87",
          }}
        >
          USED OF {GetFileSize(summary?.total ?? 0, "file")}
        </text>
      </>
    );
  };

  return (
    <ResponsivePie
      data={[
        {
          id: "images",
          label: "images",
          value: summary?.image.size,
        },
        {
          id: "documents",
          label: "documents",
          value: summary?.document.size,
        },
        {
          id: "media",
          label: "media",
          value: summary?.media.size,
        },
        {
          id: "others",
          label: "others",
          value: summary?.other.size,
        },
        {
          id: "empty",
          label: "empty",
          value: summary
            ? summary.total -
              (summary.other.size +
                summary.media.size +
                summary.document.size +
                summary.image.size)
            : 0,
        },
      ]}
      innerRadius={0.85}
      colors={["#3A77F3", "#FDA758", "#AC9CFF", "#FCC62D", "#F5F6FC"]}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      layers={["arcs", CenteredMetric]}
    />
  );
}
