interface DoubleClickProps {
  previewImage: Function;
  rename?: Boolean | null;
  timeoutRef: any;
  onClick: Function;
  file: any;
  lastClickTime: number;
  setLastClickTime: Function;
  singleClickTimeout: number | null;
  setSingleClickTimeout: Function;
}

function DoubleClick({
  previewImage,
  rename,
  timeoutRef,
  onClick,
  file,
  lastClickTime,
  setLastClickTime,
  singleClickTimeout,
  setSingleClickTimeout,
}: DoubleClickProps) {
  const currentTime = new Date().getTime();
  const delay = 300;

  if (currentTime - lastClickTime < delay) {
    // Double-click logic
    previewImage(file);
    // Clear the single-click timeout if it exists
    if (singleClickTimeout !== null) {
      clearTimeout(singleClickTimeout);
      setSingleClickTimeout(null); // Reset singleClickTimeout
    }
    // Reset the last click time to prevent subsequent single click
    setLastClickTime(0);
  } else {
    // Single-click logic
    // Clear any existing single-click timeout
    if (singleClickTimeout !== null) {
      clearTimeout(singleClickTimeout);
    }
    // Set a new single-click timeout
    const timeoutId = window.setTimeout(() => {
      // Execute single-click logic after the delay
      clearTimeout(timeoutRef.current);
      !rename && onClick(file.id);

      // Reset the last click time
      setLastClickTime(0);
      setSingleClickTimeout(null); // Reset singleClickTimeout
    }, delay);

    // Store the timeout ID
    setSingleClickTimeout(timeoutId);

    // Update the last click time
    setLastClickTime(currentTime);
  }
}

export default DoubleClick;
