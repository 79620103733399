import { useHistory } from "react-router-dom";
import style from "../FAQ.module.css";

export interface Props {
  header: string;
  text?: string;
  id: number;
  active?: boolean;
  single?: boolean;
}
export default function FAQCard({ header, text, id, active, single }: Props) {
  const history = useHistory();
  return (
    <button
      className={`card card-radius  p-0 w-100 ${
        active && style.group_card_active
      } h-100`}
      style={{
        border: "1px solid var(--blue)",
        boxShadow: "0px 4px 15px rgb(0,0,0,0.07)",
      }}
      onClick={() => {
        history.push(`/faq/${id}`);
      }}
    >
      <div className="card-body   w-100 text-center">
        <h4
          className={`${style.groupCardHeader} mb-0 ${single && "text-center"}`}
          style={{
            fontSize: single ? "14px" : "",
          }}
        >
          {header}
        </h4>
        {text && <p className={`mb-0 mt-1 ${style.groupCard}`}>{text}</p>}
      </div>
    </button>
  );
}
