import { useOverlay } from "../../../Context/OverlayContext";
import { useAuth } from "../../../Context/UserContext";
import style from "../Setting.module.css";
import { completePaymentFunc } from "../../../Services/stripe";
import { useAlert } from "react-alert";
import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { accountClosure } from "../../../graphql/mutations";
import { Email } from "../../../Models/Mail";
import Subscription from "../Subscription";
import Popup from "../../Components/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CloseButton from "../../Components/closeButton";
interface Props {
  id: string;
  name: string;
  size: number;
  changePlan: Function;
  cancelPlan: Function;
  subscription?: {
    dateEnd: string;
    status: string;
    price: number;
  };
  updateCard: Function;
  card?: string;
}
function GetFileSize(size: number) {
  if (size < 1024) {
    return { value: size, unit: "B" };
  } else if (size < 1024 * 1024) {
    return { value: Math.ceil(size / 1024), unit: "KB" };
  } else if (size < Math.pow(1024, 3)) {
    return { value: Math.ceil(size / (1024 * 1024)), unit: "MB" };
  } else if (size < Math.pow(1024, 4)) {
    return { value: Math.ceil(size / Math.pow(1024, 3)), unit: "GB" };
  } else {
    return { value: Math.ceil(size / Math.pow(1024, 4)), unit: "TB" };
  }
}
const GetPlanType = (id: string) => {
  switch (id) {
    case "0":
      return "cloud-computing.svg";
    case "1":
    case "5":
      return "lite.svg";
    case "2":
    case "6":
      return "pro.svg";
    case "3":
    case "7":
    case "4":
      return "proplus.svg";
  }
};

function StripeStatus(subscription?: {
  card?: string;
  dateEnd?: string;
  status?: string;
  price?: number;
  updateCard?: Function;
}) {
  const { setLoading } = useOverlay();
  const alert = useAlert();

  const completePayment = () => {
    setLoading(true);
    completePaymentFunc()
      .then(() => {
        alert.success("Successfully completed payment", { timeout: 5000 });
      })
      .catch((err) => {
        alert.error(err.errors[0].message, { timeout: 5000 });
      })
      .finally(() => setLoading(false));
  };

  switch (subscription?.status) {
    case "active":
      return (
        <p className="color-dark-grey">
          Your next payment is{" "}
          <span className="text-black fw-bold">
            £{subscription?.price ?? 0}
          </span>
          , to be charged on
          <span className="text-black fw-bold">
            {" "}
            {new Date(subscription?.dateEnd ?? "").toDateString()}
          </span>
          <br />
          Your payment will be automatically renewed each year.
        </p>
      );
    case "canceled":
      return (
        <p className="color-dark-grey">
          Your subscription has been canceled
          <br />
          <small>
            Refresh the page after a while if the plan hasn't changed.
          </small>
        </p>
      );
    case "incomplete":
      return (
        <>
          <p className="color-dark-grey">
            Your payment is incomplete,{"  "}
            <button
              className="btn mx-0 px-0 text-decoration-underline text-primary"
              onClick={() => completePayment()}
            >
              Complete your payment.
            </button>
          </p>
        </>
      );
    case "incomplete_expired":
    case "unpaid":
      return <p>You failed to make the payment.</p>;
    case "past_due":
      return (
        <p>
          Your payment is past due.{" "}
          <button
            className="btn mx-0 px-0 text-decoration-underline text-primary"
            onClick={() => completePayment()}
          >
            Complete your payment.
          </button>
        </p>
      );
    default:
      return <></>;
  }
}
export default function Status({
  id,
  name,
  size,
  changePlan,
  cancelPlan,
  subscription,
  updateCard,
  card,
}: Props) {
  const { user } = useAuth();
  const alert = useAlert();

  const [preview, setPreview] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  interface Data {
    name: String | undefined;
    subject: String | undefined;
    message: String | undefined;
    from: String | undefined;
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const emailData = {
      name: user?.name,
      subject: "Account Closure Form",
      message: message,
      from: user?.email,
    };
    if (message !== "") {
      setIsLoading(true);
      sendEmail(emailData);
    }
  };

  const sendEmail = async (emailData: Data) => {
    try {
      await Auth.currentAuthenticatedUser().then(async () => {
        await API.graphql({
          query: accountClosure,
          variables: {
            name: emailData.name,
            subject: emailData.subject,
            message: emailData.message,
            from: emailData.from,
          },
        });
      });

      alert.success("Mail Sent Successfully.", { timeout: 5000 });

      setMessage("");
    } catch (error) {
      alert.error("Mail not Sent", { timeout: 5000 });
    } finally {
      setIsLoading(false); // Stop loading (whether success or error)
      setPreviewForm(false);
    }
  };

  return (
    <>
      <div className={style.subscription}>
        <div className="d-flex justify-content-around">
          <img src={`/icons/${GetPlanType(id)}`} alt="Pro" />
          <div className="ms-3">
            <h4 className="mb-0">{name.toUpperCase()} PLAN</h4>
            {name !== "free" ? (
              <>
                <span className="font-weight-medium">Premium Account</span>
                <br />
                <span className="font-weight-semibold color-dark-grey">
                  Annual Plan
                </span>
                <br />
              </>
            ) : (
              <></>
            )}
            <span className="font-weight-bold font-size-2x">
              {GetFileSize(size).value}
            </span>
            <span className="font-weight-bold">
              {GetFileSize(size).unit.toUpperCase()}
            </span>
          </div>
          <div className="flex-fill d-none d-lg-block"></div>
          <div className="flex-column justify-content-around d-none d-lg-flex">
            <button className="btn btn-primary" onClick={() => changePlan(2)}>
              Change Plan
            </button>
            {id !== "0" && (
              <button
                className="btn bg-dark-grey color-white"
                onClick={() => cancelPlan(1)}
              >
                Cancel Plan
              </button>
            )}
          </div>
        </div>
        <hr />
        <StripeStatus {...subscription} updateCard={updateCard} card={card} />
        <small>
          {card ? (
            <>
              Your default card ends with{" "}
              <span className="text-black fw-bold">{card}</span> .
              <br />
              <small>
                Update your{" "}
                <button
                  className="btn mx-0 px-0 text-decoration-underline text-primary"
                  onClick={() => subscription && updateCard && updateCard()}
                >
                  payment details
                </button>
              </small>
            </>
          ) : (
            <>
              You do not have a card attached
              <br />
              <small>
                Update your{" "}
                <button
                  className="btn mx-0 px-0 text-decoration-underline text-primary"
                  onClick={() => subscription && updateCard && updateCard()}
                >
                  payment details
                </button>
              </small>
            </>
          )}
        </small>
        <div className="d-flex d-lg-none d-xl-none">
          <button
            className="btn btn-primary flex-fill me-2"
            onClick={() => changePlan(2)}
          >
            Change Plan
          </button>
          <button
            className="btn bg-dark-grey color-white flex-fill ms-2"
            onClick={() => cancelPlan(1)}
          >
            Cancel Plan
          </button>
        </div>

        {/* <div className="d-flex  justify-content-center flex-column  text-align-center mt-3 d-lg-none d-xl-none ">
          <div className=" d-flex font-weight-medium justify-content-center text-align-center">
            Do you wish to close your account
          </div>
          <div className="d-flex mt-2 justify-content-center">
            <button
              className=" btn btn-danger me-2"
              onClick={() => setPreview(true)}
            >
              Close Account
            </button>
          </div>
        </div> */}
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => {
            setPreview(false);
          }}
        >
          <div
            className="bg-white p-1  d-flex "
            style={{
              width: "300px",
              height: "150px",
              filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
              borderRadius: "10px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div className="pe-2 ps-2">
              <p className="mt-3 text-sm">
                Are you sure you want to close your account
              </p>
              <div className="container mt-2">
                <button
                  className="btn btn-primary btn-sm ms-2 me-2 mb-2"
                  type="submit"
                  onClick={() => {
                    setPreview(false);
                    setPreviewForm(true);
                  }}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger  btn-sm mb-2  p-2.5  text-white"
                  onClick={() => setPreview(false)}
                  type="button"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          open={previewForm}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => {
            setPreviewForm(false);
          }}
        >
          <div
            className="d-flex bg-white p-1   "
            style={{
              borderRadius: "10px",
              filter: "drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.16))",
              padding: "10px",
              height: "450px",
              maxHeight: "90vh",
              width: "350px",
            }}
          >
            <CloseButton preview={previewForm} setPreview={setPreviewForm} />

            <div className="col-12  pe-4  ps-4">
              <h1 className="d-flex fs-6 text-align-center justify-content-center mt-3">
                Account Closure Form
              </h1>
              <form
                onSubmit={handleSubmit}
                className={`text-center ${style.aboutForm}`}
              >
                <input
                  type="text"
                  className="form-control orange shadow-none py-3"
                  id="name"
                  value={user?.name}
                  disabled
                  placeholder="Your name*"
                  required
                />
                <input
                  type="email"
                  className="form-control my-3 orange shadow-none py-3"
                  id="name"
                  value={user?.email}
                  disabled
                  placeholder="Your email*"
                  required
                />
                <textarea
                  className="form-control orange shadow-none py-3"
                  // rows={1}
                  value={message}
                  required
                  rows={5}
                  cols={40}
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="Reason for closing account"
                ></textarea>
                <button
                  className="btn btn-radius-large btn-primary mt-4 px-4"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </Popup>
      </div>
      <div className={style.subscription} style={{ marginTop: "100px" }}>
        <div className="d-flex flex-row justify-content-between d-none d-lg-flex ">
          <div className="">Do you wish to close your account</div>

          <div className="  d-none d-lg-flex">
            <button className="btn btn-danger" onClick={() => setPreview(true)}>
              Close Account
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex  justify-content-center flex-column  text-align-center mt-3 d-lg-none d-xl-none ">
        <div className=" d-flex font-weight-medium justify-content-center text-align-center">
          Do you wish to close your account
        </div>
        <div className="d-flex mt-2 justify-content-center">
          <button
            className=" btn btn-danger me-2"
            onClick={() => setPreview(true)}
          >
            Close Account
          </button>
        </div>
      </div>
    </>
  );
}
