import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import HomeSlide from "./components/homeSlide";
import Layout from "./Layout";
import Pricing from "./pricing";
import style from "./Layout.module.css";

const slides = [
  {
    title: "Jessica, a graphic designer",
    firstImage: "/images/home video 1.gif",
    secondImage: "/images/home video 2.gif",
  },
  {
    title: "Adam, a Small Business Owner",
    firstImage: "/images/home video 3.gif",
    secondImage: "/images/home video 4.gif",
  },
  {
    title: "Mariah, a Lawyer",
    firstImage: "/images/home video 5.gif",
    secondImage: "/images/home video 6.gif",
  },
  {
    title: "John, a Musician",
    firstImage: "/images/home video 7.gif",
    secondImage: "/images/home video 8.gif",
  },
];

export default function Home() {
  const [activeSlide, setActiveSlide] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((activeSlide + 1) % 4);
    }, 4000);
    return () => clearInterval(interval);
  });

  const handleViewChange = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.intersectionRatio > 0.5) {
          if (videoRef.current) {
            videoRef.current.play();
          }
        } else {
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }
      }
    },
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleViewChange, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });
    setObserver(observer);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [handleViewChange]);

  return (
    <Layout>
      <div
        className="layout-section py-4 text-center"
        style={{
          background:
            "linear-gradient(180deg, rgba(243, 243, 243, 0.43) 0%, #F4F4F4 115.93%)",
        }}
      >
        {/* <p className="font-weight-light">Get 10GB free &#128293;</p> */}
        <h1 className={`color-blue ${style.banner}`}>
          Secure your digital treasure with Inkmark
        </h1>
        <p className="mb-md-5">
          Protected content storage with verifiable proof of ownership: the
          perfect solution for safeguarding legal and time-sensitive documents,
          creative works and intellectual property through encryption.
        </p>
        <HomeSlide
          title={slides[activeSlide].title}
          firstImage={slides[activeSlide].firstImage}
          secondImage={slides[activeSlide].secondImage}
          // className={`slide-transition ${activeSlide === 0 ? 'active-slide' : ''}`}
        />

        <div className="d-flex justify-content-center pt-5 align-items-center">
          <Link
            className="btn btn-radius-large btn-primary font-weight-semibold px-3 px-md-4"
            to="/registration-pricing"
            style={{
              filter: "drop-shadow(0px 8px 20px rgb(0, 0, 0, 0.15))",
            }}
          >
            Get started
          </Link>
        </div>
      </div>

      <div
        className={`${style.logo} layout-section py-4 d-flex justify-content-center justify-content-md-end  justify-content-lg-end align-items-center}`}
      >
        <div
          style={{
            display: "flex",
          }}
          className="align-items-center"
        >
          {/* <iframe
            title="Certificate"
            src="https://registry.blockmarktech.com/certificates/d398b489-09f7-4481-a2f6-818182629669/widget/?tooltip_position=corner&theme=transparent"
            style={{
              border: "none",
              height: "110px",
              width: "110px",
              marginLeft: "auto",
            }}
          ></iframe> */}
          <img
            style={{ marginTop: "-30px", marginRight: "10px" }}
            src="/logos/cyber.png"
            alt="MSDUK logo"
            width="90px"
            height="90px"
          />
          <img
            style={{ marginTop: "-20px" }}
            src="/logos/msduk.png"
            alt="MSDUK logo"
            width="100px"
            height="100px"
          />
        </div>
      </div>

      <div className="layout-section mb-lg-5">
        <div className="row py-4 flex-row-reverse">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h2 className={`text-center text-md-start ${style.homeH2}`}>
              Securely store your most important files
            </h2>
            <p className="text-center text-md-start">
              Safeguard your digital intellectual property (IP) using
              tamperproof, auditable and encrypted storage for your protected
              content. Easily obtain time-stamped evidence of ownership,
              securely recorded in unhackable Blockchain-based logs.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img
              // src="/images/home image 1.svg"
              src="/images/GROUP 1.png"
              alt="home "
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row py-4">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h2 className={`text-center text-md-start ${style.homeH2}`}>
              Collaborate, share and comment on assets
            </h2>
            <p className="text-center text-md-start">
              Manage data access and work on projects as a team. You can easily
              share files of any size, with time-restricted access options.
              Collaborate and comment seamlessly, with all changes recorded in
              the Blockchain.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img
              // src="/images/home image 2.svg"
              src="/images/GROUP 3.png"
              alt="home "
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row py-4 flex-row-reverse">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h2 className={`text-center text-md-start ${style.homeH2}`}>
              Access files on any device
            </h2>
            <p className="text-center text-md-start">
              Simple to get started and simple to use. Register today, save your
              digital treasures securely with encryption then access from
              anywhere via a browser. Anyone can get started quickly and easily.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img
              // src="/images/home image 3.svg"
              src="/images/GROUP 2.png"
              alt="home "
              className="img-fluid"
            />
          </div>
        </div>
        <article className="text-center text-md-start py-4">
          <h1 className={`text-center ${style.homeH2}`}>What is Inkmark?</h1>
          <p>
            Inkmark offers a better way to store the digital content that’s
            important to you. Inkmark uses Blockchain to lock in the date and
            ownership of your digital files, so you can demonstrate authorship
            at any time. This makes Inkmark the perfect place to store your
            legal or time-sensitive documents, creative work and intellectual
            property (IP).
          </p>
          <p>
            Once your files are saved on the platform, it’s easy to share and
            control access. Work collaboratively as a team. Share with
            time-restricted access. Record all changes for full auditability.
          </p>
          <p>
            This makes Inkmark’s encrypted file storage with tamperproof proof
            of ownership the ideal way to protect your digital treasure.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <video ref={videoRef} width="90%" controls autoPlay={false} muted>
              <source src="/images/Inkmark_Final.MP4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </article>
      </div>
      <div className="d-block d-md-none">
        <Pricing />
      </div>
    </Layout>
  );
}
