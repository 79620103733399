import { useState, useContext } from "react";
import Popup from "../Components/popup";
import ChangePassword from "./ChangePassword";
import ConfirmEmailChange from "./ConfirmEmail";
import style from "./Setting.module.css";
import { useEffect } from "react";
import {
  GetPicture,
  Profile as ProfileService,
  ProfileChange,
} from "../../Services/Profile";
import { useForm } from "react-hook-form";
import { Profile as ProfileModel } from "../../Models/Profile";
import { ErrorMessage } from "@hookform/error-message";
import CustomError from "../../Helper/CustomErrorMessage";
import UploadPicture from "./UploadPicture";
// import { timezones } from "./TimeZones";
import { useAuth } from "../../Context/UserContext";
import Avatar from "react-avatar";
import { UserContext } from "../../Context/UserContext";
import { DateTime } from "luxon";
import { Auth } from "aws-amplify";
import Select from "react-select";
import timezones from "timezones-list";

export default function Profile() {
  const sharedValue = useContext(UserContext);
  const [edit, setEdit] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [value, setValue] = useState<ProfileModel>();

  const [selectedTimzone, setSelectedTimeZone] = useState("");

  const handleOptionChange = (selectedOption: any) => {
    setSelectedTimeZone(selectedOption.tzCode);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<ProfileModel>();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    GetPicture().then((image) => {
      setImage(image.data);
    });
  }, []);

  const { refresh } = useAuth();

  useEffect(() => {
    refreshProfile();
  }, [reset]);

  const refreshProfile = () => {
    ProfileService().then((value) => {
      if (value?.success) {
        setValue(value.data);
        reset(value.data);
      }
    });
  };

  const handleProfileChange = async (data: ProfileModel) => {
    if (selectedTimzone !== null && selectedTimzone !== "") {
      data.TimeZone = selectedTimzone;
    }

    ProfileChange(data).then((value) => {
      if (value.success) {
        if (value.emailChanged) {
          setEmailChanged(true);
        }
        setValue(data);
        setEdit(false);

        refresh && refresh();
      } else {
        let resultType = value.type as any;
        setError(resultType, { message: value.message });
      }
    });
  };
  const onUpload = (value: string) => {
    setImage(value);
    refresh && refresh();
  };

  const changeTimeZone = async (localTime: string, userTimezone: string) => {
    const localDateTime = DateTime.fromFormat(
      localTime,
      "MM/dd/yyyy, h:mm:ss a",
      { zone: "utc" }
    );
    const targetDateTime = localDateTime.setZone(userTimezone);
    const newDateTime = targetDateTime.toFormat("MM/dd/yyyy hh:mm:ss a");
    if (newDateTime === "Invalid DateTime") {
      return localTime;
    }
    return newDateTime;
  };

  const AsyncModifiedDate = (modified: any) => {
    const [modifiedDate, setModifiedDate] = useState("");
    const userTimezone = sharedValue.user?.locale || "";

    useEffect(() => {
      changeTimeZone(modified.modified, userTimezone)
        .then((result) => {
          setModifiedDate(result);
        })
        .catch((error) => {
          setModifiedDate("");
        });
    }, [modified]);

    return <span>{modifiedDate}</span>;
  };
  return (
    <div className={style.profile}>
      <div className="d-flex align-items-center">
        <Avatar size="40" src={image} name={value?.UserName} round={true} />
        <Popup
          className="modalPopup"
          modal
          disabled={isSubmitting}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="btn btn-primary ms-2 shadow-none me-auto"
              disabled={isSubmitting}
            >
              Change Picture
            </button>
          }
        >
          {(close: Function) => (
            <UploadPicture
              close={close}
              onUpload={(value: string) => onUpload(value)}
            />
          )}
        </Popup>

        {!edit ? (
          <button
            className="btn btn-primary ms-2 d-none d-xl-inline shadow-none"
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        ) : (
          <></>
        )}
      </div>
      {edit ? (
        <form className="d-flex justify-content-between flex-column h-100">
          <div className="row mt-3">
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label" htmlFor="name">
                Full Name
              </label>
              <input
                className="form-control shadow-none"
                type="text"
                required
                minLength={1}
                {...register("UserName")}
              />
              <ErrorMessage
                errors={errors}
                name="UserName"
                render={({ messages, message }) => (
                  <CustomError messages={messages} messageSingle={message} />
                )}
              />
            </div>
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label" htmlFor="email">
                Email Address
              </label>
              <input
                className="form-control shadow-none"
                type="email"
                required
                {...register("Email")}
              />
              <ErrorMessage
                errors={errors}
                name="Email"
                render={({ messages, message }) => (
                  <CustomError messages={messages} messageSingle={message} />
                )}
              />
            </div>
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label" htmlFor="storage">
                Storage name
              </label>
              <input
                className="form-control shadow-none"
                type="text"
                minLength={1}
                {...register("StorageName")}
              />
              <ErrorMessage
                errors={errors}
                name="StorageName"
                render={({ messages, message }) => (
                  <CustomError messages={messages} messageSingle={message} />
                )}
              />
            </div>
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label" htmlFor="tomezone">
                Timezone
              </label>

              <Select options={timezones} onChange={handleOptionChange} />
            </div>
          </div>

          <div className={`d-flex my-3 ${style.profileFormButtons}`}>
            <button
              className="btn btn-primary"
              onClick={handleSubmit(handleProfileChange)}
              disabled={isSubmitting}
            >
              Save
            </button>
            <button
              className="btn bg-dark-grey color-white ms-2 shadow-none"
              onClick={() => setEdit(false)}
              disabled={isSubmitting}
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <>
          <div className="row mt-2">
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label fw-normal" htmlFor="name">
                Full Name
              </label>
              <p className="text-break">{value?.UserName}</p>
            </div>
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label fw-normal" htmlFor="email">
                Email Address
              </label>
              <p className="text-break">{value?.Email}</p>
            </div>
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label fw-normal" htmlFor="storage">
                Storage name
              </label>
              <p className="text-break">{value?.StorageName}</p>
            </div>
            <div className="col-xl-6 my-2 col-12">
              <label className="form-label fw-normal" htmlFor="tomezone">
                Timezone
              </label>
              <p className="text-break">
                {value?.TimeZone}
                {/* {timezones.find((x) => x.IANA === value?.TimeZone)?.IANA} */}
              </p>
            </div>
          </div>
          <button
            className="btn btn-primary mt-2 d-inline d-xl-none shadow-none"
            onClick={() => {
              reset(value);
              setEdit(true);
            }}
          >
            Edit
          </button>
        </>
      )}
      <div
        className={`mt-5 mb-5  mb-xl-0 ${
          edit ? "d-none" : "d-block"
        } d-xl-block`}
      >
        <h5>Password</h5>
        <span>
          Change your account password often to prevent unauthorized access to
          your account.
        </span>
        <hr />
        <Popup
          className="modalPopup"
          modal
          disabled={isSubmitting}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="btn btn-primary shadow-none"
              disabled={isSubmitting}
            >
              Change Password
            </button>
          }
        >
          {(close: Function) => (
            <ChangePassword
              close={() => {
                close();
                refreshProfile();
              }}
            />
          )}
        </Popup>
        <br />

        {/* <small>Last modified {value?.Date.toLocaleString()}</small> */}
        <small>
          Last modified{" "}
          <AsyncModifiedDate modified={value?.Date.toLocaleString()} />
        </small>
      </div>
      <Popup
        className="modalPopup"
        open={emailChanged}
        modal
        closeOnDocumentClick={false}
        onClose={() => {
          setEdit(false);
          setEmailChanged(false);
        }}
      >
        {(close: Function) => (
          <ConfirmEmailChange
            close={close}
            email={value?.Email}
            submit={() => {
              setEdit(false);
              setEmailChanged(false);
            }}
          />
        )}
      </Popup>
    </div>
  );
}
