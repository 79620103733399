import Layout from "../layout";
import style from "./timeLock.module.css";
import { Link, useParams } from "react-router-dom";
import { FileData, member } from "../../Models/Document";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChangeDate, getFile } from "../../Services/Time-Lock";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Sharing from "./Components/Sharing";
import Inactive from "./Components/Inactive";
import debounce from "../../Helper/Debounce";
import File from "./Components/file";
import getBreadCrumb from "../File/BreadCrumb";
import { useAlert } from "react-alert";
import { Preview } from "../../Services/Document";
import { IoArrowBackCircle } from "react-icons/io5";

export default function TimeLockFile() {
  const { id } = useParams<{ id: string }>();
  const [members, setMembers] = useState<member[]>([]);
  const [previewFile, setPreviewFile] = useState("");
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [file, setFile] = useState<FileData>();
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [disabled, setDisabled] = useState(false);
  const changeDate = useRef<boolean>(false);
  const [prevId, setPrevId] = useState("/time-lock");
  const [firstId, setFirstId] = useState(null);
  const alert = useAlert();

  useEffect(() => {
    getFile(id).then((value: any) => {
      setFile(value.file);
      setMembers(value.members);

      setBreadCrumbItems(getBreadCrumb(value.parent));
      setActiveDate(value.date);
    });

    Preview(id).then((value) => {
      if (value?.success && value.data) {
        setPreviewFile(value.data);
      }
    });
  }, [id]);

  const dateChangeCallback = useCallback(
    debounce(700, (date: Date, id: string) => {
      setDisabled(true);
      ChangeDate(date, id)
        .then((val) => {
          if (val) {
            alert.success("Timelock has been updated successfully", {
              timeout: 5000,
            });
          } else {
            alert.error("Error updating timelock", { timeout: 5000 });
          }
        })
        .finally(() => setDisabled(false));
    }),
    []
  );

  const refresh = (callback?: Function) => {
    getFile(id)
      .then((value: any) => {
        setFile(value.file);
        setMembers(value.members);
        setActiveDate(value.date);
      })
      .finally(() => {
        callback && callback();
      });
  };

  useEffect(() => {
    if (changeDate.current) {
      dateChangeCallback(activeDate, id);
    }
  }, [activeDate, id, dateChangeCallback]);

  breadcrumbItems.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });
  useEffect(() => {
    breadcrumbItems.map((x, index) => {
      if (breadcrumbItems.length === 1) {
        setPrevId(`/time-lock/folder/${x.id}`);
      }
      if (breadcrumbItems.length === 2) {
        setPrevId(`/time-lock/folder/${x.id}`);
      }
      if (breadcrumbItems.length === 3) {
        setPrevId(`/time-lock/folder/${x.id}`);
      }
    });

    if (breadcrumbItems.length === 0) {
      setPrevId("/time-lock");
    }
  }, [breadcrumbItems]);

  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={`${prevId}`}>
              <IoArrowBackCircle style={{ width: "30px", height: "30px" }} />
            </Link>
            <Link
              className="text-decoration-none color-dark-grey"
              to="/time-lock"
            >
              Timelock
            </Link>
          </li>
          {breadcrumbItems.length === 3 &&
            !breadcrumbItems.some((item) => item.id === firstId) && (
              <li className="breadcrumb-item">
                {" "}
                <i className="fas fa-ellipsis-h"></i>{" "}
              </li>
            )}
          {breadcrumbItems.map((x) => (
            <li className="breadcrumb-item">
              <Link
                className="text-decoration-none color-dark-grey"
                to={"/time-lock/folder/" + x.id}
              >
                {x.name}
              </Link>
            </li>
          ))}
          <li className="breadcrumb-item">{file?.name}</li>
        </ol>
      </nav>
      <div className="d-lg-none h-100">
        <Tabs
          selectedTabClassName={style.tabSelected}
          className="d-flex flex-column h-100"
          selectedTabPanelClassName="d-flex  flex-fill flex-column"
        >
          <TabList className={style.tabs}>
            <Tab>Home</Tab>
            <Tab>Sharing</Tab>
            <Tab>Inactivity Period</Tab>
          </TabList>
          <TabPanel>
            <File
              file={file}
              id={id}
              members={members}
              refresh={refresh}
              date={activeDate}
              handleDateChange={(date: Date) => {
                setActiveDate(date);
                changeDate.current = true;
              }}
              previewFile={previewFile}
            />
          </TabPanel>
          <TabPanel>
            <Sharing fileId={id} members={members} refresh={refresh} />
          </TabPanel>
          <TabPanel>
            <Inactive
              date={activeDate}
              handleChange={setActiveDate}
              disabled={disabled}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="d-none d-lg-block h-100">
        <File
          file={file}
          id={id}
          members={members}
          refresh={refresh}
          date={activeDate}
          handleDateChange={(date: Date) => {
            setActiveDate(date);
            changeDate.current = true;
          }}
          disabled={disabled}
          previewFile={previewFile}
        />
      </div>
    </Layout>
  );
}
