export default function GetFileSize(size:number,folder: string){
    if(folder === "folder"){
        return size +" files"
    }
    else if(size <1024){
        return size+" B"
    }
    else if(size < 1024 * 1024){
        return Math.ceil(size/1024) +" KB"
    }
    else if(size < Math.pow(1024,3)){
        return Math.ceil(size/(1024*1024)) +" MB"
    }
    else if(size <Math.pow(1024,4)){
        return Math.ceil(size/Math.pow(1024,3)) +" GB"
    }
    else{
        return Math.ceil(size/Math.pow(1024,4)) +" TB"
    }
}