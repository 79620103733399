export enum Group{
    About = 1,
    Account,
    Features
}

export const groups = [
    {
        group: Group.About,
        header: "About Inkmark",
        description: "Information about your accounts"
    },
    {
        group: Group.Features,
        header: "Using Inkmark",
        description: 'Information on using inkmark'
    }
]