import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import Move from "../Move";
import Popup from "../popup";
import { FileData } from "../../../Models/Document";
import style from "../Row.module.css";
import { archive } from "../../../Services/Archive";
import { favorite, remove } from "../../../Services/favorites";
import Avatar from "../avatar";
import Share from "../Share";
import { refreshUsers } from "../../../Services/Shared";
import { useOverlay } from "../../../Context/OverlayContext";
import { lockFileService } from "../../../Services/Document";
import { FileWithChecked } from "../../Home/home";
import AsyncModifiedDate from "./AsyncModifiedDate";
import DoubleClick from "./DoubleClick";

interface Props {
  fileData: FileWithChecked;
  onClick: Function;
  onRename: Function;
  onMove: Function;
  onArchive: Function;
  onFavorite: Function;
  onUnfavorite: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
  onLock: Function;
  onClickChecked: Function;
}
const splitName = (name: string) => {
  let pos = name.lastIndexOf(".");
  if (pos === -1) {
    return {
      name: name,
      extension: "",
    };
  } else {
    let nameOnly = name.substring(0, pos);
    let extensionOnly = name.substring(pos);
    return {
      name: nameOnly,
      extension: extensionOnly,
    };
  }
};
const FileRow: React.FC<Props> = ({
  fileData,
  onClick,
  onRename,
  onMove,
  onArchive,
  onFavorite,
  onUnfavorite,
  handleKeyDown,
  tabIndex,
  previewImage,
  onLock,
  onClickChecked,
}) => {
  const [rename, setRename] = useState(false);
  const [move, setMove] = useState(false);
  const [action, setAction] = useState(false);
  const [file, setFile] = useState<FileWithChecked>(fileData);
  const timeoutRef = useRef<any>();
  const { setLoading } = useOverlay();
  const [lastClickTime, setLastClickTime] = useState(0);
  const [singleClickTimeout, setSingleClickTimeout] = useState<number | null>(
    null
  );

  useEffect(() => {
    setFile(fileData);
  }, [fileData]);

  const refresh = () => {
    refreshUsers(file.id).then((data: any) => {
      setFile({
        ...file,
        users: data,
      });
    });
  };

  const renameClick = async (event: any) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      // setLoading(true);
      setAction(true);
      await onRename(
        event.currentTarget.value + splitName(file.name).extension
      );
      // setLoading(false);
      setRename(false);
      setAction(false);
    } else if (event.key === "Escape") {
      setRename(false);
    }
  };
  const moveFile = (fileId: string, selected: string) => {
    onMove(fileId, selected);
  };
  const favoriteFile = (fileId: string) => {
    // setLoading(true);
    favorite(fileId)
      .then(() => {
        onFavorite(fileId);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const unfavourite = (id: string) => {
    // setLoading(true);
    remove(id)
      .then(() => onUnfavorite(id))
      .finally(() => {
        // setLoading(false);
      });
  };
  const lock = (id: string) => {
    setLoading(true);
    lockFileService(id)
      .then(() => onLock(id))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const isVideoFile = (file: any) => {
    return file.type === "media";
  };

  return (
    <tr
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td className="align-middle">
        <input
          type="checkbox"
          onChange={(e) => onClickChecked(e.currentTarget.checked)}
          checked={file.checked}
          className="form-check-input"
        />
      </td>
      <td
        onClick={() => {
          DoubleClick({
            previewImage,
            rename,
            timeoutRef,
            onClick,
            file,
            lastClickTime,
            setLastClickTime,
            singleClickTimeout,
            setSingleClickTimeout,
          });
        }}
        className="align-middle"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        <button className="d-flex btn align-items-center shadow-none px-0">
          <span style={{ width: "32px" }}>
            <img
              src={`/logos/${file.type}.png`}
              alt={`${file.type} logo`}
              className="img-fluid"
            />
          </span>
          {rename ? (
            <>
              <input
                type="text"
                className="form-control ms-2 shadow-none d-none d-lg-inline"
                defaultValue={splitName(file.name).name}
                onClick={(e: any) => e.stopPropagation()}
                onKeyDown={renameClick}
                autoFocus
                disabled={action}
              />
              <span className="text-nowrap d-none d-lg-inline">
                {splitName(file.name).extension}
              </span>
            </>
          ) : (
            <span className="ms-2 d-none d-lg-inline">{file.name}</span>
          )}
        </button>
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        {rename ? (
          <div className="d-flex align-items-center">
            <input
              type="text"
              className="form-control shadow-none"
              defaultValue={splitName(file.name).name}
              onKeyPress={renameClick}
              autoFocus
              disabled={action}
            />
            <span className="text-nowrap">
              {splitName(file.name).extension}
            </span>
          </div>
        ) : (
          <>
            <span className={`text-center ${style.file}`}>{file.name}</span>
            <br />

            <span className={style.file2}>
              <span>
                <AsyncModifiedDate modified={file.modified} />
              </span>

              {/* {file.modified.toLocaleDateString()} */}
              <span className="color-dark-grey"> by {file.modifierName}</span>
            </span>

            {file.size}
            <br />
            <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
              {file.users &&
                file.users.map((user, i) => (
                  <Avatar name={user.name} image={user.id} key={user.id} />
                ))}
              {file.sharedCount
                ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
                : ""}
            </span>
          </>
        )}
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          {/* {file.modified.toLocaleDateString()} */}
          <small>
            <AsyncModifiedDate modified={file.modified} />
          </small>

          <small className="color-dark-grey color-dark-grey">
            by {file.modifierName}
          </small>
        </div>
      </td>
      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="text-end">
        <div className="d-flex">
          {!file.favorites ? (
            <button
              className="btn shadow-none"
              onClick={() => {
                favoriteFile(file.id);
              }}
              title="favorite"
            >
              <i className="far fa-star color-blue"></i>
            </button>
          ) : (
            <button
              className="btn shadow-none"
              onClick={() => {
                unfavourite(file.id);
              }}
              title="unfavorite"
            >
              <i className="fas fa-star color-blue"></i>
            </button>
          )}
          <Popup
            trigger={
              <button
                className="btn btn-transparent color-dark-grey shadow-none"
                type="button"
              >
                <i className="fas fa-ellipsis-v"></i>
              </button>
            }
            position="left center"
            nested
          >
            {(close: Function) => (
              <div
                className="card bg-white p-0"
                style={{
                  filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                  borderRadius: "10px",
                }}
              >
                <div className="card-body px-0">
                  <ul className={`list-unstyled mb-0 ${style.menu}`}>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          close();
                          setRename(true);
                        }}
                      >
                        <img
                          src="/icons/Rename.png"
                          alt="move"
                          className="img-fluid"
                        />{" "}
                        Rename
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          setLoading(true);
                          archive(file.id)
                            .then(() => {
                              close();
                              onArchive(file.id);
                            })
                            .finally(() => {
                              setLoading(false);
                            });
                        }}
                      >
                        <i className="fas fa-file-archive text-start"></i>{" "}
                        Archive
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          close();
                          setMove(true);
                        }}
                      >
                        <img
                          src="/icons/Move.png"
                          alt="move"
                          className="img-fluid"
                        />{" "}
                        Move to
                      </button>
                    </li>
                    {!file.favorites ? (
                      <li>
                        <button
                          className="btn shadow-none"
                          onClick={() => {
                            favoriteFile(file.id);
                            close();
                          }}
                        >
                          <i className="fas fa-star text-start"></i>
                          Add to Favorites
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          className="btn shadow-none"
                          onClick={() => {
                            unfavourite(file.id);
                            close();
                          }}
                        >
                          <img
                            src="/icons/Star Off.png"
                            alt="move"
                            className="img-fluid"
                          />{" "}
                          Remove from favorites
                        </button>
                      </li>
                    )}
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          lock(file.id);
                          close();
                        }}
                      >
                        <i className="fas fa-lock"></i>
                        Lock File
                      </button>
                    </li>
                    <li>
                      <Popup
                        className="modalPopup"
                        trigger={
                          <button className="btn shadow-none d-none d-lg-inline">
                            <i className="fas fa-share-alt text-start"></i>
                            Manage Sharing
                          </button>
                        }
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(closeShare: Function) => (
                          <div
                            className="bg-white p-3 d-flex flex-column"
                            style={{
                              width: "40vw",
                              height: "380px",
                              filter:
                                "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                            }}
                          >
                            <Share
                              close={() => {
                                close();
                                closeShare();
                              }}
                              fileId={file.id}
                              refresh={refresh}
                            />
                          </div>
                        )}
                      </Popup>
                      <Popup
                        className="modalPopup"
                        trigger={
                          <button className="btn shadow-none d-inline d-lg-none">
                            <i className="fas fa-share-alt text-start"></i>
                            Manage Sharing
                          </button>
                        }
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(closeShare: Function) => (
                          <div
                            className="bg-white p-3 d-flex flex-column"
                            style={{
                              width: "90vw",
                              height: "380px",
                              filter:
                                "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                            }}
                          >
                            <Share
                              close={() => {
                                close();
                                closeShare();
                              }}
                              fileId={file.id}
                              refresh={refresh}
                            />
                          </div>
                        )}
                      </Popup>
                    </li>
                    <li>
                      <button
                        className="btn shadow-none"
                        onClick={() => {
                          close();
                          previewImage();
                        }}
                      >
                        <i className="fas fa-play"></i> Preview
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </Popup>
          <Popup
            open={move}
            closeOnDocumentClick={false}
            onClose={() => setMove(false)}
            closeOnEscape={false}
            className="menuPopup"
          >
            {(close: Function) => (
              <Move
                close={() => {
                  close();
                }}
                fileId={file.id}
                onmove={moveFile}
                root={0}
              />
            )}
          </Popup>
        </div>
      </td>
    </tr>
  );
};

export default FileRow;
