import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./Helper/PrivateRoute";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { UserProvider } from "./Context/UserContext";
import { FileProvider } from "./Context/FileContext";
import RedirectRoute from "./Helper/RedirectRoute";
import { OverlayProvider } from "./Context/OverlayContext";
import Loading from "./Platform/Components/Loading";
import Home from "./landing-page/home";
import Contact from "./landing-page/contact";
import Pricing from "./landing-page/pricing";
import FAQ from "./landing-page/FAQ";
import About from "./landing-page/about";
import Layout from "./landing-page/Layout";

import Login from "./Authentication/login";
import Registration from "./Authentication/registration";
import EmailConfirm from "./Authentication/email-confirm";
import PostConfirm from "./Authentication/post-confirm";
import RegistrationPricing from "./Authentication/pricing";
import ResetPassword from "./Authentication/forgotpassword";
import Invite from "./Authentication/invite";
import CompletePassword from "./Authentication/complete-password";

import Platform from "./Platform/Home/home";
import Shared from "./Platform/Shared/shared";
import Favourites from "./Platform/Favorites/favourites";
import Archived from "./Platform/archived";
import TimeLock from "./Platform/TimeLock/time-lock";
import Help from "./Platform//Help/help";
import All from "./Platform/Home/All";
import Settings from "./Platform/Settings/Index";
import PaymentForm from "./Authentication/Payment";
import Files from "./Platform/File/Files";
import Transfer from "./Platform/Components/Transfer";
import FavoriteFolder from "./Platform/Favorites/folder";
import FavoriteFile from "./Platform/Favorites/File";
import FileFolder from "./Platform/File/folder";
import FilesFile from "./Platform/File/File";
import SharedFolder from "./Platform/Shared/folder";
import SharedFile from "./Platform/Shared/File";
import TimeLockFolder from "./Platform/TimeLock/folder";
import TimeLockFile from "./Platform/TimeLock/file";
import Search from "./Platform/Home/Search";
import AdminRoute from "./Helper/AdminRoute";
// import ReCAPTCHA from "react-google-recaptcha";

const AdminLogin = lazy(() => import("./Admin/Authentication/Login"));
const ForgotPassword = lazy(
  () => import("./Admin/Authentication/ForgotPassword")
);
const Dashboard = lazy(() => import("./Admin/Platform/Dashboard"));
const Users = lazy(() => import("./Admin/Platform/Users"));
const Transaction = lazy(() => import("./Admin/Platform/Transaction"));
const AdminSettings = lazy(() => import("./Admin/Platform/Settings"));
const Mail = lazy(() => import("./Admin/Platform/Mail/Mail"));

const NotFound = lazy(() => import("./Error404"));

Amplify.configure(awsconfig);

function App() {
  return (
    <UserProvider>
      <FileProvider>
        <OverlayProvider>
          <div className="App d-flex flex-column">
            <Router>
              <Suspense
                fallback={
                  <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
                    <Loading />
                  </div>
                }
              >
                <Switch>
                  <Route path="/" exact component={Home} />

                  <Route path="/contact" component={Contact} />

                  <Route path="/pricing">
                    <Layout>
                      <Pricing />
                    </Layout>
                  </Route>

                  <Route path="/faq" component={FAQ} />
                  <Route path="/about" component={About} />

                  <RedirectRoute path="/login/:username?/:password?">
                    <Login />
                  </RedirectRoute>
                  <Route
                    path="/complete-password/:email/:password"
                    component={CompletePassword}
                  />
                  <Route path="/registration/:type?" component={Registration} />
                  <Route path="/email-confirm" component={EmailConfirm} />
                  <Route path="/invite" component={Invite} />
                  <Route path="/post-confirm" component={PostConfirm} />
                  <Route
                    path="/registration-pricing"
                    component={RegistrationPricing}
                  />
                  <Route path="/payment" component={PaymentForm} />
                  <Route
                    path="/reset-password/:email?/:code?"
                    component={ResetPassword}
                  />

                  <PrivateRoute path="/platform" exact>
                    <Platform />
                  </PrivateRoute>
                  <PrivateRoute path="/platform/:type" exact>
                    <All />
                  </PrivateRoute>
                  <PrivateRoute path="/platform/search/:term">
                    <Search />
                  </PrivateRoute>
                  <PrivateRoute path="/shared" exact>
                    <Shared />
                  </PrivateRoute>
                  <PrivateRoute path="/shared/folder/:id" exact>
                    <SharedFolder />
                  </PrivateRoute>
                  <PrivateRoute path="/shared/file/:id" exact>
                    <SharedFile />
                  </PrivateRoute>
                  <PrivateRoute path="/favourites" exact>
                    <Favourites />
                  </PrivateRoute>
                  <PrivateRoute path="/favourites/folder/:id" exact>
                    <FavoriteFolder />
                  </PrivateRoute>
                  <PrivateRoute path="/favourites/file/:id" exact>
                    <FavoriteFile />
                  </PrivateRoute>
                  <PrivateRoute path="/archived" exact>
                    <Archived />
                  </PrivateRoute>
                  <PrivateRoute path="/archived/:id">
                    <Archived />
                  </PrivateRoute>

                  <PrivateRoute path="/time-lock" exact>
                    <TimeLock />
                  </PrivateRoute>
                  <PrivateRoute path="/time-lock/folder/:id">
                    <TimeLockFolder />
                  </PrivateRoute>
                  <PrivateRoute path="/time-lock/file/:id">
                    <TimeLockFile />
                  </PrivateRoute>
                  <PrivateRoute path="/help">
                    <Help />
                  </PrivateRoute>
                  <PrivateRoute path="/Settings">
                    <Settings />
                  </PrivateRoute>

                  <PrivateRoute path="/files" exact>
                    <Files />
                  </PrivateRoute>
                  <PrivateRoute path="/files/folder/:id" exact>
                    <FileFolder />
                  </PrivateRoute>
                  <PrivateRoute path="/files/file/:id" exact>
                    <FilesFile />
                  </PrivateRoute>
                  <PrivateRoute path="/files/:type(file|folder)/:id/transfer">
                    <Transfer />
                  </PrivateRoute>
                  <Route path="/admin/login" component={AdminLogin} />
                  <Route path="/admin/forgotPassword" component={AdminLogin} />
                  <AdminRoute path="/admin/dashboard">
                    <Dashboard />
                  </AdminRoute>
                  <AdminRoute path="/admin/users">
                    <Users />
                  </AdminRoute>
                  <AdminRoute path="/admin/transactions">
                    <Transaction />
                  </AdminRoute>
                  <AdminRoute path="/admin/settings">
                    <AdminSettings />
                  </AdminRoute>
                  <AdminRoute path="/admin/mail" exact>
                    <Mail />
                  </AdminRoute>
                  <AdminRoute path="/admin/mail/:type(sent|draft|deleted)">
                    <Mail />
                  </AdminRoute>
                  <Route path="*" component={NotFound} />
                </Switch>
              </Suspense>
            </Router>
          </div>
        </OverlayProvider>
      </FileProvider>
    </UserProvider>
  );
}

export default App;
