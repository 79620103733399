import { ReactNode } from "react";
import Popup from "../Platform/Components/popup";
import style from "./layout.module.css";
import Privacy from "./privacy";
import { useState } from "react";
import CloseButton from "../Platform/Components/closeButton";

export default function Terms({ main = true }: { main?: boolean }) {
  const [preview, setPreview] = useState(false);
  return (
    <>
      <button
        className="btn btn-none text-primary p-0"
        type="button"
        onClick={() => setPreview(true)}
      >
        Terms
      </button>
      <Popup
        className="modalPopup"
        open={preview}
        closeOnDocumentClick
        onClose={() => {
          setPreview(false);
        }}
      >
        <div
          className="bg-white overflow-auto"
          style={{
            borderRadius: "40px",
            padding: "10px",
            height: "90vh",
            maxHeight: "90vh",
            width: "75vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <div
            style={{
              overflow: "auto",
              height: "100%",
              fontFamily: "New-Times",
            }}
            className=" ps-3 pe-0 ps-lg-5 pe-lg-3 py-5"
          >
            <h4>INKMARK TERMS AND CONDITIONS</h4>

            <p>
              These terms and conditions, together with the various supplemental
              policies referred to below (together, the <strong>T&Cs</strong> )
              dictate your usage of the Inkmark <strong>platform</strong>{" "}
              accessible from our website{" "}
              <a href="https://inkmark.io/" target="_blank">
                inkmark.io
              </a>{" "}
              (the Platform). The Platform is provided by Inkmark Operations
              Ltd, of 67 Shirley way, Croydon, London, England (hereinafter
              referred to as ‘we’, ‘our(s)’, or ‘Inkmark’).
            </p>
            <p>
              The supplemental policies forming part of these T&Cs are the
              Privacy Policy, the Acceptable Use Policy and the Cookies Policy,
              copies of which are available <Privacy /> .
            </p>
            <p>
              Continued use of the Platform through any media constitutes
              acceptance of all these T&Cs. We reserve the right to update these
              T&Cs at any time and from time to time and you are obliged to stay
              abreast of the changes which occur by reviewing them before use.
              If a change is made, a notice will be placed at the top of the
              T&Cs for a period of 30 days to inform you of this change.
            </p>
            <p>
              {" "}
              We reserve the right to terminate or suspend your use of the
              Platform and/or restrict any of its features if we believe that
              you are not complying with the T&Cs. We may do so without prior
              notice.
            </p>
            <h4>THE PLATFORM</h4>
            <p>
              The Platform made available to you, through the website, app or
              otherwise, is provided on a limited licence basis for users that
              have subscribed and paid for access, to provide secure online file
              protection, management, encryption and storage.
            </p>
            <p>
              All media files and documents uploaded to the Platform will be
              saved to our Inkmark blockchain environment, and be accessible
              through our Platform tools and related services.
            </p>
            <h4>USE OF SITE AND SERVICES</h4>
            <p>
              To use the Platform, each user is required to create an account,
              with a username and password. Users are expected to maintain the
              password that they associate with the account and prevent its use
              by others. Inkmark will not be responsible for any unauthorised
              use of any User’s account by third parties.
            </p>
            <p>
              Each user will be provided access to an account in their name. The
              access is provided on the basis of a single user licence. We
              reserve the right to disconnect, suspend or terminate any User’s
              account if we believe a single user licence is being shared
              between multiple Users.
            </p>
            <h4>RESTRICTIONS OF USE</h4>
            <p>By making use of the Platform, Users agree:</p>
            <ol className={style.custom_list}>
              <li>
                to use the services provided only in compliance with all
                applicable laws and these T&Cs;
              </li>
              <li>
                {" "}
                not to provide or suffer to permit third -party use of the
                Platform, related services or features;
              </li>
              <li>
                not to deconstruct, reconstruct, modify or reverse engineer the
                Platform or any feature thereof, or attempt to do the same (or
                allow or encourage any one else to do so);
              </li>
              <li>
                not to modify or remove any trademark, copyright, title, or
                restricted right notice or label from the Platform or any
                constituent part thereof whatsoever, or in any other way violate
                our intellectual property rights in any way.
              </li>
            </ol>
            <h4>TRIAL LICENCE OR FREE ACCOUNT</h4>
            <p>
              If, in our discretion, a User is granted a licence for a period of
              time without payment, either as a trial or proof of value, or
              otherwise (No Fee Licence), it is an express condition of the
              access and continued use of that No Fee Licence that the relevant
              User consents to the sharing of their contact details with us and
              third parties for the purposes of receiving periodic marketing
              material (from us and such third parties). A User may withdraw
              that consent in accordance with the Privacy Policy, but will
              thereafter no longer have access to their account.
            </p>
            <h4>PAYMENTS AND FEES, REFUNDS</h4>
            <p>
              For continued use of the Inkmark Platform, we will charge a per
              User licence fee based on either monthly or yearly subscriptions.
              Users are responsible for all fees as specified and the fee shall
              be paid in Pounds Sterling (GBP) unless another currency is
              specified. Any fee that is paid is non-refundable unless the
              service to which the payment was taken for was not provided. Fees
              are deemed inclusive of taxes, duties or levies.
            </p>
            <p>
              {" "}
              Users are responsible for providing Inkmark with a written notice
              disputing any fee payment within 30 days of being charged,
              otherwise it will be deemed to be without recourse after expiry of
              that 30 day period.
            </p>
            <p>
              If a User cancels a subscription without notice, or fails to make
              a payment of fees when due, without limiting our rights to
              terminate, suspend or restrict access to the Platform (or features
              of it) a cancellation fee may become due and payable.
            </p>
            <p>
              All payments will be made through Stripe or via a credit/debit
              card, or the Google/Apple pay function. Users are responsible for
              reading and complying with the terms of any payment provider that
              they choose and Inkmark will not be held liable for any
              transaction chargeback or refusal that Users might encounter.
              After signing up, Inkmark will create a Stripe account on your
              behalf, using your email address for the purpose of processing
              your credit card payments for subscription services, which are
              processed by Stripe.
            </p>
            <p>
              You acknowledge that Stripe or your other chosen payment method
              will be your default payment gateway(s) for all subsequent
              payments and charges, and that it is your sole responsibility as
              the licensed User to activate and maintain these accounts. If you
              do not wish to keep the payment account active, it is your
              responsibility to deactivate it. Stripe is a Third-Party Service,
              as defined in another Section of these Terms of Service. Users are
              separately bound to any terms that their chosen payment gateway
              creates in their own capacity.
            </p>
            <h4>CONFIDENTIALITY</h4>
            <p>
              Any data entered into the Inkmark platform by a User is treated as
              private and confidential. We do not have access to documents that
              a User uploads to the Platform.
            </p>
            <h4>INTELLECTUAL PROPERTY</h4>
            <p>
              {" "}
              Intellectual Property includes any patent, service or product
              under patent application, trademark, copyright, unpatented
              invention, know-how, domain name, technology, process, method, and
              all other proprietary rights, all derivates, improvements,
              development enhancements, and modifications or extensions.
            </p>
            <p>
              Inkmark retains all rights unreservedly in and to any Intellectual
              Property rights comprising the Service or the Platform. The User
              will retain proprietary rights over any material or data that they
              upload, or enter onto, the site or otherwise provide through the
              Platform. This includes audio and video files, recordings of any
              events, or photographs.
            </p>
            <p>
              Users grant Inkmark the right to use the User information to the
              extent needed by Inkmark to provide the services to the User.
              Users acknowledge that Inkmark does not have any control over or
              responsibility for any content provided by, uploaded or entered
              onto, the site or Platform, by any User.
            </p>
            <h4>WARRANTIES</h4>
            <p>
              Inkmark and the User both warrant that they have the power to
              accept and be bound by these T&Cs. Inkmark warrants that it will
              provide Services in a professional manner and will use
              commercially reasonable efforts to ensure that the same are free
              from viruses, worms, malware, trap doors, Trojan horses or
              software locks, and materially in accordance with any service
              description set out in these T&Cs.{" "}
              <strong>
                Your attention is drawn to the limits of liability set out
                below.
              </strong>
            </p>
            <p>
              The Platform and related services are provided ‘as is’ and without
              any representation or warranty of any kind.
            </p>
            <p>
              User’s use of the platform and services is at their own risk and
              Inkmark makes no other representation or warranty, implied or
              expressed by operation of law or otherwise, without limitation,
              any implied or statutory warranty of title, non-infringement,
              merchantability, or fitness for any particular purpose. Inkmark
              does not warrant that the platform or any service will be provided
              error-free, virus free, secure, or uninterrupted.
            </p>
            <p>
              Users represent and warrant that they will only use the platform
              and any service within it will not be in contravention of any law,
              regulation or rule. Users will not wilfully or knowingly use the
              platform in a way that could overburden, impair, interfere with
              Inkmark’s operation of, or disable the platform. Users agree they
              will comply with all regulations and laws in relation to
              electronic communications including laws within all regions where
              the User’s recipients (attendees) are located.
            </p>
            <p>
              User submits that they have the consent or authority necessary to
              store, use, process or transfer any material that they are using
              within any event.
            </p>
            <p>
              User agrees to defend and hold harmless Inkmark against any claim
              brought or made against Inkmark by a third party alleging the User
              information or their use of the service of platform was in
              violation of any third-party intellectual property rights. Users
              are responsible for the payment of any loss, damage, or cost,
              inclusive of any legal fees or costs, that might be awarded
              against Inkmark by any third party in relation to any User’s
              breach of IP rights against any third party.
            </p>
            <p>
              Inkmark warrants that it has the right to provide the Services to
              a User without infringing the intellectual property rights of a
              third party.
            </p>
            <h4>Limitation of Liability</h4>
            <p>
              Save as set out in the next sentence, a party will not be liable
              to the other party for any loss of profit, loss of use, loss of
              opportunity, including as a result of negligence, and whether
              directly or indirectly arising, or for any special, incidental,
              consequential, or punitive damages which arise from or relate to a
              failure to adhere to these terms. Each User will hold Inkmark
              harmless from and against any claims by that User, or any
              affiliate or associate of that user, relating to the storage of
              content by a User that infringes these T&Cs.
            </p>
            <p>
              In any event, our liability to a User will always be limited,
              except for fraud, death or personal injury, to the total amount of
              fees received from that User in the 12 months prior to such
              liability arising.
            </p>
            <h4>Third Party Services (and Third-Party Applications)</h4>
            <p>
              The Platform makes use of various third-party applications,
              including the blockchain infrastructure on which User documents
              are stored, and various payment processing solutions, such as
              Strype (Third Party Applications).
            </p>
            <p>
              {" "}
              Inkmark does not maintain control over any Third-Party Application
              and will not be held responsible for their content, use or
              operation. Third Party Applications could be modified in the
              future and Inkmark will not have any obligation to provide notice
              of any of those modifications. Such modifications may include a
              loss of Platform functionality.
            </p>
            <p>
              By providing access to Third Party Applications Inkmark gives no
              warranty or representation, nor any endorsement, either implied or
              expressed, with respect to the quality, accuracy, authenticity,
              fitness for purpose, specifications, functionality or legality of
              any information or service provided by, or through, any such Third
              Party Application.
            </p>
            <p>
              Inkmark might update the Platform to enhance or change features or
              services of the Platform. Inkmark reserves the right to adjust the
              fees in relation to any service on the platform into the future
              provided that reasonable notice will be provided to Users. Inkmark
              reserves the right to suspend or discontinue any feature or
              functionality of the Platform provided that the functionality of
              the platform does not decrease in material terms.
            </p>
            <h4>TERMINATION</h4>
            <p>
              User access can be terminated by us without notice for any
              material breach by a User, which includes any breach of these
              T&Cs, including a failure to pay fees. Users can terminate their
              accounts at any time they choose by giving written notice to us.
              Upon termination of any account, User’s rights to access and use
              the services or platform will immediately cease. Fees are not
              refundable on termination.
            </p>
            <p>
              All provisions in these terms survive the termination of any User
              account, including but not limited to in relation to any
              provisions that pertain to indemnity, IP ownership, warranty
              disclaimers and limitations of liability. Neither party may
              assign, delegate, or transfer any right or responsibility under or
              in respect of these T&Cs whether in law or otherwise without the
              prior written consent of the other.
            </p>
            <h4>GOVERNING LAW</h4>
            <p>
              These T&Cs are governed by the laws of England and Wales. Any
              dispute arising from or related to these T&Cs, including
              non-contractual disputes, will be governed by English law and
              subject to the exclusive jurisdiction of the English Courts.
            </p>
            <h4>FORCE MAJEURE</h4>
            <p>
              Either party can be excused for the failure to perform an
              obligation under the T&Cs where the failure results from an event
              or circumstances (or the consequences of such event or
              circumstances) that are beyond the reasonable control of the party
              seeking to be excused (including, by way of illustration only) as
              a result of electrical storm, fire, earthquake, flood, or any
              delays or failure in relation to a service provided through third
              parties. A failure to pay through lack of funds or compliance
              issues is not force majeure. It is expressly acknowledged that a
              failure of a User’s network (including interruptions, outages, or
              downtime) which it relies on to access the Platform is not our
              responsibility.
            </p>
            <h4>SEVERABILITY</h4>
            <p>
              If any provision of these T&Cs is found to be void or
              unenforceable, they will be severed from the rest of the terms and
              the remaining terms will continue to apply.
            </p>
            <h4>NOTICES</h4>
            <p>
              Inkmark can give notice to their general customer base through a
              general notice stated on the platform, and through notices sent by
              emails to Users. Users must issue all formal correspondence under
              these T&Cs to mail@inkmark.io
            </p>
            <h4>ENTIRE AGREEMENT</h4>
            <p>
              These T&Cs represent the entire agreement between each User and
              Inkmark and will apply to access to and use of the Platform or any
              related service. This does not restrict Inkmark’s right to modify
              these T&Cs at any time in accordance with the terms hereof.
            </p>
          </div>
        </div>
      </Popup>
    </>
  );
}
