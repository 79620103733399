import { useState } from "react"
import { useHistory } from "react-router-dom"
import { transfer } from "../../Services/Shared"
import Avatar from "./avatar"

interface Props {
    close?: Function,
    name: string,
    id: string,
    user: string,
}
export default function OwnershipTransfer({ close, name, id, user }: Props) {
    const [transferred, setTransferred] = useState(false)
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState<string[]>([])

    const history = useHistory()
    return <>
        {
            transferred ?
                <>
                    <img src="/icons/transfer.svg" alt="Transfer successful" className="img-fluid mx-auto" />
                    <p className="text-center">
                        <span className="fw-bold">Ownership successfully transferred to</span>
                        <br />
                        <Avatar name={name} image={user} />
                        <br />
                        <span className="text-break flex-fill">
                            {name}
                        </span>
                    </p>
                    <button onClick={() => {
                        history.goBack()
                    }} className="btn btn-outline-primary mt-auto mb-3 shadow-none">Go Back</button>
                </>
                : <div>
                    <h5>
                        Confirm transfer of ownership
                        {
                            close && <button className="btn color-red float-end py-0 shadow-none" onClick={() => close()}>
                                <i className="far fa-times-circle "></i>
                            </button>
                        }
                    </h5>
                    <p style={{
                        borderBottom: "1px solid #dddddd"
                    }}>The ownership of this file will be transferred.<br /> You will no longer have admin access to it</p>
                    <p style={{
                        borderBottom: "1px solid #dddddd"
                    }}>Are you sure you want to transfer ownership to
                        <br />
                        <span className="me-1">
                            <Avatar name={name} image={user} />
                        </span>
                        <span className="text-break flex-fill">
                            {name}
                        </span>
                    </p>
                    <p >
                        Please enter your password to confirm
                    </p>
                    <form className="w-100">
                        <label htmlFor="password" className="form-label text-muted">Confirm Password</label>
                        <input type="password" className="form-control shadow-none" id="password"
                            onChange={(e) => {
                                setErrors([])
                                setPassword(e.target.value)
                            }} required />
                        <ul>
                            {
                                errors.map(error => <li className="text-danger">{error}</li>)
                            }
                        </ul>
                        <button className="btn btn-primary my-2 shadow-none" type="button" onClick={async () => {
                            try {
                                if (!!password) {
                                    await transfer(id, user, password)
                                    setTransferred(true)
                                } else {
                                    setErrors(["Password is required"])
                                }
                            }
                            catch (error) {
                                setErrors(error as string[])
                            }
                        }}>
                            Transfer Ownership
                        </button>
                    </form>
                </div>
        }

    </>
}