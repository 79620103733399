export const userMessages = /* GraphQL */ `
  query userMessages($id: ID!, $token: String) {
    getUser(id: $id) {
      messages(
        sortDirection: DESC
        filter: { read: { eq: false } }
        nextToken: $token
      ) {
        items {
          id
          comment {
            content
            user {
              name
            }
            file {
              name
              id
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const onMessageCreate = /* GraphQL */ `
  subscription OnMessageCreate($userId: ID!) {
    onMessageCreate(userId: $userId) {
      comment {
        id
        content
        user {
          name
        }
        file {
          name
          id
        }
      }
      id
      read
    }
  }
`;

export const onNotificationCreate = /* GraphQL */ `
  subscription OnNotificationCreate($userId: ID!) {
    onNotificationCreate(userId: $userId) {
      id
      activity {
        id
        parameters
        type
        createdAt
        user {
          name
          id
        }
        file {
          name
          id
        }
      }
    }
  }
`;

export const userNotifications = /* GraphQL */ `
  query userNotifications($id: ID!, $token: String) {
    getUser(id: $id) {
      notifications(
        sortDirection: DESC
        filter: { read: { eq: false } }
        nextToken: $token
      ) {
        items {
          id
          activity {
            id
            parameters
            type
            createdAt
            user {
              name
              id
            }
            file {
              name
              id
            }
          }
        }
        nextToken
      }
    }
  }
`;
