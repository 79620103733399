import style from "./homeSlide.module.css";
export interface Props {
  title: string;
  firstImage: string;
  secondImage: string;
}

export default function HomeSlide({ title, firstImage, secondImage }: Props) {
  return (
    <>
      <h5 className={`mb-0 ${style.title}`} >{title}</h5>
      <div className="row justify-content-between position-relative">
        <div className="col-3 pe-0">
          <div
            className="bg-white"
            style={{
              width: "100%",
              borderRadius: "50%",
              paddingTop: "100%",
              backgroundImage: `url("${firstImage}")`,
              backgroundSize: "contain",
            }}
          ></div>
        </div>

        <div className="col-6 row px-0">
          <img
            src="/images/home center image.svg"
            className="img-fluid px-0"
            alt="arrow"
          />
        </div>

        <div className="col-3 ps-0">
          <div
            className="bg-white"
            style={{
              width: "100%",
              borderRadius: "50%",
              paddingTop: "100%",
              marginTop: "30%",
              backgroundImage: `url("${secondImage}")`,
              backgroundSize: "contain",
              
            }}
          ></div>
        </div>

        <div className={`${style.file} d-inline-block `}>
          <img
            src="/icons/file.svg"
            alt="file.svg"
            className="img-fluid"           
          />
        </div>
      </div>
    </>
  );
}
