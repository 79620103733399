export const rootFolders = /* GraphQL */ `
  query folders($id: ID!, $date: String!, $token: String) {
    folders: fileByUser(
      sortDirection: DESC
      userId: $id
      filter: { parent: { eq: "0" }, activeDate: { gt: $date } }
      nextToken: $token
    ) {
      nextToken
      items {
        id
        name
        size
        modified
        parent
        type
        sharedCount
        activeDate
        shared(limit: 3) {
          items {
            user {
              name
              id
            }
          }
        }
        timelockShared(limit: 3) {
          items {
            email
            name
            id
            view
          }
        }
        uploader {
          name
        }
      }
    }
  }
`;

export const folder = /* GraphQL */ `
  query ChildrenFiles($id: ID!, $root: Int!, $userId: ID!, $date: String!) {
    files: getFile(id: $id) {
      id
      name
      parentDet {
        name
        id
        parentDet {
          name
          id
          parentDet {
            name
            id
          }
        }
      }
      activeDate
      children(
        filter: {
          root: { eq: $root }
          userId: { eq: $userId }
          activeDate: { gt: $date }
        }
        sortDirection: DESC
      ) {
        nextToken
        items {
          id
          name
          activeDate
          size
          modified
          type
          sharedCount
          timelockShared(limit: 3) {
            items {
              name
              id
              email
              view
            }
          }
          uploader {
            name
          }
        }
      }
      timelockShared {
        items {
          name
          id
          email
          view
        }
      }
    }
  }
`;

export const nextFolder = /* GraphQL */ `
  query ChildrenFiles(
    $id: ID!
    $root: Int!
    $userId: ID!
    $date: String!
    $token: String
  ) {
    files: getFile(id: $id) {
      id
      children(
        filter: {
          root: { eq: $root }
          userId: { eq: $userId }
          activeDate: { gt: $date }
        }
        sortDirection: DESC
        nextToken: $token
      ) {
        nextToken
        items {
          id
          name
          size
          modified
          type
          sharedCount
          uploader {
            name
          }
          timelockShared(limit: 3) {
            items {
              name
              id
              email
              view
            }
          }
        }
      }
    }
  }
`;

export const file = /* GraphQL */ `
  query getFile($id: ID!) {
    files: getFile(id: $id) {
      id
      name
      parentDet {
        name
        id
        parentDet {
          name
          id
          parentDet {
            name
            id
          }
        }
      }
      modified
      created
      parent
      size
      type
      userId
      activeDate
      shared {
        items {
          user {
            name
            id
          }
          view
        }
      }
      timelockShared {
        items {
          name
          email
          id
          relationship
          view
        }
      }
      versions(sortDirection: DESC, limit: 1) {
        items {
          id
        }
      }
    }
  }
`;
