import { API } from "aws-amplify";
import {
  completePayment,
  createStripeSubscription,
  updateCard,
} from "../graphql/mutations";
import { getInvoices } from "../graphql/queries";

export async function createSubscription(planId: string) {
  try {
    let result = await (API.graphql({
      query: createStripeSubscription,
      variables: {
        planId: planId,
      },
    }) as Promise<{
      data: any;
    }>);

    return {
      clientSecret: result.data.createStripeSubscription.clientSecret,
      subscriptionId: result.data.createStripeSubscription.subscriptionId,
      updated: result.data.createStripeSubscription.updated,
      price: result.data.createStripeSubscription.price,
      size: result.data.createStripeSubscription.size,
    };
  } catch (e) {
    throw e;
  }
}

export async function deleteSubscription() {
  try {
    await (API.graphql({
      query: createStripeSubscription,
    }) as Promise<{
      data: any;
    }>);
  } catch (e) {
    throw e;
  }
}

export async function updateCardDetails(id: string) {
  try {
    await (API.graphql({
      query: updateCard,
      variables: {
        id,
      },
    }) as Promise<{
      data: any;
    }>);
  } catch (e) {
    throw e;
  }
}

export async function getInvoiceList(
  pageNo: string,
  type: "before" | "after" | "first"
) {
  try {
    const result = await (API.graphql({
      query: getInvoices,
      variables: {
        pageNo,
        type,
      },
    }) as Promise<{
      data: any;
    }>);
    return result.data.getInvoices;
  } catch (e) {
    throw e;
  }
}

export async function completePaymentFunc() {
  try {
    const result = await (API.graphql({
      query: completePayment,
    }) as Promise<{
      data: any;
    }>);
    return result.data.completePayment;
  } catch (e) {
    throw e;
  }
}
