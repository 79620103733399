import TeamMemberCard from "./components/teammembercard";
import Layout from "./Layout";
import style from "./about.module.css";

export default function About() {
  return (
    <Layout>
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(243, 243, 243, 0.43) 0%, #F4F4F4 115.93%)",
        }}
        className="layout-section py-4 text-center"
      >
        <h1 className={style.banner}>
          Our vision: to make it easy for everyone to secure their digital
          treasure
        </h1>
        <p>
          How do you know your most important files are safe and secure? Can you
          really trust Internet storage solutions? How can you demonstrate
          ownership? These are the challenges we set out to resolve when we
          created Inkmark – because we believe demonstrating creative or
          intellectual property rights should be easier for everyone.
        </p>
        <img
          src="/images/about.png"
          alt="about"
          className={style.about_image}
        />
      </div>
      <section className="layout-section my-5">
        <article className="text-center text-md-start">
          <h1 className={style.banner}>Our Story</h1>
          <p>
            "As a musician, I understand the importance of creative rights and
            ownership. I also understand that the copyrighting process can be
            unnecessarily complex and expensive. That’s why I was driven to
            create Inkmark: as a better way to secure your digital treasure.
          </p>
          <p>
            Whether you need to store legal documents, share documents on a
            time- sensitive basis, protect your creative work or IP, Inkmark
            offers you a better way to store these files. Our encrypted cloud
            storage uses Blockchain to lock in the date and ownership of files
            in a way that can’t be edited or disputed. We aim to be the go-to
            solution for anyone needing to securely store and work with
            important digital content."
            <p
              // style={{ marginRight: "-210px" }}
              className={` ${style.quote} layout-section d-flex fst-italic justify-content-center justify-content-lg-end`}
            >
              Courtney Larmond
            </p>
          </p>
          {/* <p>Courtney Larmond</p> */}
        </article>
        <article className="text-center text-md-start mt-5">
          <h1 className={style.banner}>Our Team</h1>
          <div className={`row ${style.team}`}>
            <div className="col-12 col-md-6 py-2">
              <div className="me-0 me-md-5 mb-3">
                <TeamMemberCard
                  name="Courtney Larmond – Founder & CEO"
                  color="blue"
                  description={
                    <div>
                      <p>
                        A session musician who’s played across Europe with
                        Gospel choirs and solo artists, Courtney’s first great
                        passion is music. With more than 30 years of experience
                        working and dabbling in the music industry.
                      </p>

                      <p>
                        Courtney also has more than 15 years of experience
                        working in IT. With Inkmark, Courtney brings both these
                        passions together to solve one of the problems that
                        plagues both industries: the need for truly secure
                        digital storage.
                      </p>
                    </div>
                  }
                  // src="/images/pro.png"
                  src="/images/courtney.png"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 py-2">
              <div className="me-0 me-md-5 mb-3">
                <TeamMemberCard
                  name="Andene Rodney"
                  color="blue"
                  description={
                    <div>
                      <p>
                        "Andene Rodney is a qualified HR Consultant, Trainer and
                        Coach. She has worked for many years designing and
                        delivering bespoke and accredited courses.
                      </p>
                      <p>
                        {/* {" "} */}A Panel Member for the Judicial
                        Appointments Commission where she participates in
                        recruitment exercises for Judges being appointed to
                        Courts and Tribunals. An Employer Member sitting in
                        Employment Tribunals for England & Wales. A Qualified
                        Teacher and Lay Panel Member with the British
                        Association for Counselling and Psychotherapy.
                      </p>
                      <p>
                        Andene holds a degree in Business Studies, a Post
                        Graduate degree in Personnel and Development and a
                        Masters degree in Human Resources. She has provided
                        consultancy services and training to business start-ups
                        and small businesses. She is a Fellow of the Chartered
                        Institute of Personnel and Development and the Institute
                        of Training & Occupational Learning."
                      </p>
                    </div>
                  }
                  src="/images/andene.jpeg"
                />
              </div>
            </div>
          </div>
        </article>
      </section>
    </Layout>
  );
}
