export default function getBreadCrumb(details: any) {
  const list = [];
  while (details) {
    list.push({
      id: details.id,
      name: details.name,
    });
    details = details.parentDet;
  }
  list.reverse();
  return list;
}
