export const homePage = /* GraphQL */ `
  query HomePage($user: ID!) {
    files: searchFiles(
      filter: {
        or: [{ type: { ne: "folder" }, root: { eq: 0 }, userId: { eq: $user } }]
      }
      limit: 5
      sort: { direction: desc, field: modified }
    ) {
      items {
        id
        name
        lastUploadType
        type
        size
        modified
        parent
        sharedCount
        shared(limit: 3) {
          items {
            user {
              name
              id
            }
          }
        }
        uploader {
          name
        }
      }
    }
    favorites: userFavorites(userId: $user, sortDirection: DESC, limit: 4) {
      items {
        file {
          id
          name
          type
          size
          modified
          parent
          shared(limit: 3) {
            items {
              user {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const allFilesByType = /* GraphQL */ `
  query FileByType($type: ID!, $id: ID!, $token: String) {
    files: fileByUser(
      sortDirection: DESC
      userId: $id
      nextToken: $token
      filter: { type: { eq: $type } }
    ) {
      nextToken
      items {
        id
        name
        size
        modified
        parent
        type
        sharedCount
        uploader {
          name
        }
      }
    }
  }
`;

export const fileCounts = /* GraphQL */ `
  query FileCounts($id: ID!) {
    getUser(id: $id) {
      imageCount
      imageSize
      mediaCount
      mediaSize
      otherSize
      otherCount
      documentCount
      documentSize
      plan {
        size
      }
      messages(sortDirection: DESC, filter: { read: { eq: false } }) {
        items {
          id
          comment {
            content
            user {
              name
              id
            }
            file {
              name
              id
            }
          }
        }
        nextToken
      }
      notifications(sortDirection: DESC, filter: { read: { eq: false } }) {
        items {
          id
          activityId
          userId
          read
          updatedAt
          createdAt
          __typename
          activity {
            user {
              id
              name
            }
            file {
              id
              name
            }
            id
            parameters
            fileId
            userId
            type
            updatedAt
            createdAt
            notify
            groups
            __typename
          }
        }
        nextToken
      }
    }
  }
`;

/*export const fileByUser = /* GraphQL 
  query FileByUser($userId: ID!) {
    fileByUser(
      userId: $userId
      filter: { parent: { attributeExists: false } }
      sortDirection: ASC
    ) {
      items {
        id
        name
        size
        
        modified
        type
      }
    }
  }
`;
*/

export const rootFolders = /* GraphQL */ `
  query folders($parent: ID!, $id: ID!, $date: String!, $token: String) {
    folders: fileByUser(
      sortDirection: DESC
      userId: $id
      nextToken: $token
      filter: {
        or: [
          { parent: { eq: $parent }, activeDate: { attributeExists: false } }
          {
            parent: { eq: $parent }
            activeDate: { attributeExists: true, le: $date }
          }
          { parent: { eq: $parent }, activeDate: { eq: null } }
        ]
      }
    ) {
      nextToken
      items {
        id
        name
        size
        modified
        parent
        type
        sharedCount
        shared(limit: 3) {
          items {
            user {
              name
              id
            }
          }
        }
        uploader {
          name
        }
        favorites(filter: { userId: { eq: $id } }) {
          items {
            id
          }
        }
      }
    }
    details: getFile(id: $id) {
      parentDet {
        id
        name
      }
    }
  }
`;

export const folder = /* GraphQL */ `
  query ChildrenFiles($id: ID!, $root: Int!, $userId: ID!, $date: String!) {
    files: getFile(id: $id) {
      id
      name
      parentDet {
        name
        id
        parentDet {
          name
          id
          parentDet {
            name
            id
          }
        }
      }
      children(
        filter: {
          or: [
            {
              root: { eq: $root }
              userId: { eq: $userId }
              activeDate: { attributeExists: false }
            }
            {
              root: { eq: $root }
              userId: { eq: $userId }
              activeDate: { attributeExists: true, le: $date }
            }
            {
              root: { eq: $root }
              userId: { eq: $userId }
              activeDate: { eq: null }
            }
          ]
        }
        sortDirection: DESC
        limit: 30
      ) {
        nextToken
        items {
          id
          name
          size
          modified
          type
          sharedCount
          shared(limit: 3) {
            items {
              user {
                name
                id
              }
            }
          }
          uploader {
            name
          }
          favorites(filter: { userId: { eq: $userId } }) {
            items {
              id
            }
          }
        }
      }
      comments(sortDirection: DESC) {
        items {
          createdAt
          content
          id
          userId
        }
      }
      shared {
        items {
          user {
            name
            id
          }
        }
      }
      activities(sortDirection: DESC, limit: 1) {
        items {
          id
          parameters
          type
          createdAt
          user {
            name
            id
          }
        }
        nextToken
      }
    }
  }
`;

export const nextFolder = /* GraphQL */ `
  query ChildrenFiles(
    $id: ID!
    $root: Int!
    $userId: ID!
    $date: String!
    $token: String
  ) {
    files: getFile(id: $id) {
      id
      children(
        filter: {
          or: [
            {
              root: { eq: $root }
              userId: { eq: $userId }
              activeDate: { attributeExists: false }
            }
            {
              root: { eq: $root }
              userId: { eq: $userId }
              activeDate: { attributeExists: true, le: $date }
            }
            {
              root: { eq: $root }
              userId: { eq: $userId }
              activeDate: { eq: null }
            }
          ]
        }
        sortDirection: DESC
        nextToken: $token
      ) {
        nextToken
        items {
          id
          name
          size
          modified
          type
          sharedCount
          shared(limit: 3) {
            items {
              user {
                name
                id
              }
            }
          }
          uploader {
            name
          }
          favorites(filter: { userId: { eq: $id } }) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const subfolders = /* GraphQL */ `
  query ChildrenFiles(
    $id: ID!
    $fileId: ID!
    $root: Int!
    $date: String!
    $token: String
  ) {
    files: getFile(id: $id) {
      id
      name
      parent
      children(
        filter: {
          or: [
            {
              folderOrFile: { eq: "folder" }
              id: { ne: $fileId }
              root: { eq: $root }
              activeDate: { attributeExists: false }
            }
            {
              folderOrFile: { eq: "folder" }
              id: { ne: $fileId }
              root: { eq: $root }
              activeDate: { attributeExists: true, le: $date }
            }
            {
              folderOrFile: { eq: "folder" }
              id: { ne: $fileId }
              root: { eq: $root }
              activeDate: { eq: null }
            }
          ]
        }
        sortDirection: DESC
        nextToken: $token
      ) {
        items {
          id
          name
        }
        nextToken
      }
    }
  }
`;

export const subfoldersList = /* GraphQL */ `
  query ChildrenFiles($id: ID!, $filter: ModelFileFilterInput, $token: String) {
    files: getFile(id: $id) {
      id
      name
      parent
      children(filter: $filter, sortDirection: DESC, nextToken: $token) {
        items {
          id
          name
        }
        nextToken
      }
    }
  }
`;

export const rootfolders = /* GraphQL */ `
  query ChildrenFiles(
    $fileId: ID
    $parent: ID!
    $id: ID!
    $date: String!
    $token: String
  ) {
    folders: fileByUser(
      nextToken: $token
      sortDirection: DESC
      userId: $id
      filter: {
        or: [
          {
            parent: { eq: $parent }
            type: { eq: "folder" }
            id: { ne: $fileId }
            activeDate: { attributeExists: false }
          }
          {
            parent: { eq: $parent }
            type: { eq: "folder" }
            id: { ne: $fileId }
            activeDate: { attributeExists: true, le: $date }
          }
          {
            parent: { eq: $parent }
            type: { eq: "folder" }
            id: { ne: $fileId }
            activeDate: { eq: null }
          }
        ]
      }
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const rootfoldersList = /* GraphQL */ `
  query ChildrenFiles($token: String, $filter: ModelFileFilterInput, $id: ID!) {
    folders: fileByUser(
      nextToken: $token
      userId: $id
      sortDirection: DESC
      filter: $filter
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const file = /* GraphQL */ `
  query getFile($id: ID!) {
    files: getFile(id: $id) {
      id
      name
      parentDet {
        name
        id
        parentDet {
          name
          id
          parentDet {
            name
            id
          }
        }
      }
      modified
      created
      parent
      size
      type
      userId
      shared {
        items {
          user {
            name
            id
          }
        }
      }
      comments(sortDirection: DESC) {
        items {
          createdAt
          content
          id
          userId
          user {
            name
          }
        }
      }
      activities(sortDirection: DESC) {
        nextToken
        items {
          id
          parameters
          type
          createdAt
          user {
            name
            id
          }
        }
      }
      versions(sortDirection: DESC, limit: 1) {
        items {
          id
        }
      }
    }
  }
`;

export const rootFavorites = /* GraphQL */ `
  query userFavorites($id: ID!, $token: String) {
    favorites: userFavorites(
      sortDirection: DESC
      userId: $id
      filter: { parent: { eq: "0" } }
      nextToken: $token
    ) {
      nextToken
      items {
        file {
          modified
          name
          id
          type
          size
          sharedCount
          uploader {
            name
          }
          user {
            name
            id
          }
          shared(limit: 3, filter: { userId: { ne: $id } }) {
            items {
              user {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`;
export const userFavorites = /* GraphQL */ `
  query userFavorites($id: ID!, $parent: ID!, $favID: ID!) {
    favorites: userFavorites(
      sortDirection: DESC
      userId: $id
      filter: { parent: { eq: $parent } }
    ) {
      nextToken
      items {
        file {
          uploader {
            name
          }
          modified
          name
          id
          type
          size
          user {
            name
            id
          }
          shared(limit: 3, filter: { userId: { ne: $id } }) {
            items {
              user {
                name
                id
              }
            }
          }
        }
      }
    }
    comments: fileComments(fileId: $parent, sortDirection: DESC) {
      items {
        createdAt
        content
        id
        userId
        user {
          name
        }
      }
    }
    file: getFile(id: $parent) {
      name
      userId
    }
    shared: getShared(id: $id) {
      view
    }
    info: getFavorite(id: $favID) {
      fileId
      file {
        name
      }
      parentDet {
        fileId
        file {
          name
        }
        parentDet {
          fileId
          file {
            name
          }
          parentDet {
            fileId
            file {
              name
            }
          }
        }
      }
    }
  }
`;

export const userNextFavorites = /* GraphQL */ `
  query userFavorites($id: ID!, $parent: ID!, $token: String) {
    favorites: userFavorites(
      sortDirection: DESC
      userId: $id
      nextToken: $token
      filter: { parent: { eq: $parent } }
    ) {
      nextToken
      items {
        file {
          modified
          name
          id
          type
          size
          user {
            name
            id
          }
          shared(limit: 3, filter: { userId: { ne: $id } }) {
            items {
              user {
                name
                id
              }
            }
          }
          uploader {
            name
          }
        }
      }
    }
    file: getFile(id: $parent) {
      userId
    }
  }
`;

export const userFavorite = /* GraphQL */ `
  query userFavorite($id: ID!) {
    files: getFavorite(id: $id) {
      parentDet {
        fileId
        file {
          name
        }
        parentDet {
          fileId
          file {
            name
          }
          parentDet {
            fileId
            file {
              name
            }
          }
        }
      }
      file {
        id
        name
        modified
        created
        parent
        size
        type
        userId
        comments(sortDirection: DESC) {
          items {
            createdAt
            content
            id
            userId
            user {
              name
            }
          }
        }
        versions(sortDirection: DESC, limit: 1) {
          items {
            id
          }
        }
      }
    }
    shared: getShared(id: $id) {
      view
    }
  }
`;

export const fileShared = /* GraphQL */ `
  query fileShared($id: ID!, $token: String) {
    fileShared(sortDirection: DESC, fileId: $id, nextToken: $token) {
      items {
        user {
          name
          id
        }
        view
      }
      nextToken
    }
  }
`;

export const fileSharedRefresh = /* GraphQL */ `
  query fileShared($id: ID!) {
    fileShared(sortDirection: DESC, fileId: $id, limit: 3) {
      items {
        user {
          name
          id
        }
      }
    }
  }
`;

export const userShared = /* GraphQL */ `
  query userShared($id: ID!, $parent: ID!, $file: ID!) {
    files: userShared(
      userId: $id
      sortDirection: DESC
      filter: { parent: { eq: $parent }, archived: { ne: true } }
    ) {
      nextToken
      items {
        file {
          modified
          name
          id
          type
          size
          sharedCount
          user {
            name
            id
          }
          uploader {
            name
          }
          shared(limit: 4, filter: { userId: { ne: $id } }) {
            items {
              user {
                name
                id
              }
            }
          }
          favorites(filter: { userId: { eq: $id } }) {
            items {
              id
            }
          }
        }
      }
    }
    comments: fileComments(fileId: $parent, sortDirection: DESC) {
      items {
        createdAt
        content
        id
        user {
          name
        }
        userId
      }
    }
    shared: getShared(id: $file) {
      view
      file {
        name
      }
      parentDet {
        fileId
        file {
          name
        }
        parentDet {
          fileId
          file {
            name
          }
          parentDet {
            fileId
            file {
              name
            }
          }
        }
      }
    }
  }
`;

export const userSharedNext = /* GraphQL */ `
  query userShared($id: ID!, $parent: ID!, $token: String) {
    files: userShared(
      userId: $id
      sortDirection: DESC
      nextToken: $token
      filter: { parent: { eq: $parent }, archived: { ne: true } }
    ) {
      nextToken
      items {
        file {
          modified
          name
          id
          type
          size
          sharedCount
          user {
            name
            id
          }
          shared(limit: 4, filter: { userId: { ne: $id } }) {
            items {
              user {
                name
                id
              }
            }
          }
          favorites(filter: { userId: { eq: $id } }) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const rootShared = /* GraphQL */ `
  query userShared($id: ID!, $date: String!, $token: String) {
    userShared(
      userId: $id
      sortDirection: DESC
      filter: {
        or: [
          {
            parent: { eq: 0 }
            archived: { ne: true }
            activeDate: { attributeExists: false }
          }
          {
            parent: { eq: 0 }
            archived: { ne: true }
            activeDate: { attributeExists: true, le: $date }
          }
          {
            parent: { eq: 0 }
            archived: { ne: true }
            activeDate: { eq: null }
          }
        ]
      }
      nextToken: $token
    ) {
      nextToken
      items {
        file {
          modified
          name
          id
          type
          size
          user {
            name
            id
          }
          sharedCount
          shared(limit: 3, filter: { userId: { ne: $id } }) {
            items {
              user {
                name
                id
              }
            }
          }
          favorites(filter: { userId: { eq: $id } }) {
            items {
              id
            }
          }
          uploader {
            name
          }
        }
      }
    }
  }
`;

export const userFileShared = /* GraphQL */ `
  query userFavorite($id: ID!) {
    files: getShared(id: $id) {
      view
      parentDet {
        fileId
        file {
          name
        }
        parentDet {
          fileId
          file {
            name
          }
          parentDet {
            fileId
            file {
              name
            }
          }
        }
      }
      file {
        id
        name
        modified
        created
        parent
        size
        type
        userId
        comments(sortDirection: DESC) {
          items {
            createdAt
            content
            id
            userId
            user {
              name
            }
          }
        }
        activities(sortDirection: DESC) {
          items {
            id
            parameters
            type
            createdAt
            user {
              name
              id
            }
          }
        }
        versions(sortDirection: DESC, limit: 1) {
          items {
            id
          }
        }
      }
    }
  }
`;
