import {
  Email,
  Login as LoginModel,
  RegisterUser,
  ResetPassword as ResetPasswordModel,
  CompletePassword as CompletePasswordModel,
  PostConfirm as PostConfirmModel,
} from "../Models/Authentication";
import { API, Auth } from "aws-amplify";
import { signUp, updateUser, verifyRecaptcha } from "../graphql/mutations";
import { useAuth } from "../Context/UserContext";

export async function Register(model: RegisterUser) {
  try {
    await API.graphql({
      query: signUp,
      variables: {
        email: model.Email,
        password: model.Password,
        fullName: model.FullName,
        PriceId: model.PriceId,
      },
      authMode: "AWS_IAM",
    });

    return {
      success: true,
    };
  } catch (error: any) {
    // console.log("Error");

    return {
      success: false,
      type: "Email",
      message: error.errors[0].message,
    };
  }
}

export async function VerifyRecaptcha(recaptchaToken: String) {
  try {
    let result;
    await Auth.currentAuthenticatedUser()
      .then(async () => {
        result = await API.graphql({
          query: verifyRecaptcha,
          variables: {
            recaptchaToken: recaptchaToken,
          },
        });
      })
      .catch(async () => {
        result = await API.graphql({
          query: verifyRecaptcha,
          variables: {
            recaptchaToken: recaptchaToken,
          },
          authMode: "AWS_IAM",
        });
      });
    return result;
  } catch (error) {
    // console.log("error", error);
  }
}
export async function ResendEmail(email: Email) {}

export async function Login(model: LoginModel) {
  try {
    let result = await Auth.signIn(model.Email, model.Password);

    if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
      return {
        success: false,
        type: "NewPassword",
        message: "Password needs to be replaced",
      };
    }
    if (
      result.attributes["custom:priceId"] &&
      result.attributes["custom:priceId"] !== "0"
    ) {
      return {
        success: true,
        pay: result.attributes["custom:priceId"],
      };
    }
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      success: false,
      type: "Email",
      message: error.message,
    };
  }
}

export async function PostConfirm(model: PostConfirmModel) {
  try {
    const temp = await Auth.confirmSignUp(model.userName, model.codeParameter);
    console.log(temp);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      success: false,
      type: "Email",
      message: error.message,
    };
  }
}

export async function CompletePassword(model: CompletePasswordModel) {
  try {
    let result = await Auth.signIn(model.email, model.OldPassword);
    if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
      await Auth.completeNewPassword(result, model.NewPassword, {
        name: model.NewFullName,
        "custom:storageName": "My Content",
      });
      await (API.graphql({
        query: updateUser,
        variables: {
          input: {
            name: model.NewFullName,
            storageName: "My Content",
            id: result.username,
          },
        },
      }) as Promise<{
        data: any;
      }>);
      await Auth.signIn(model.email, model.NewPassword);
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        type: "NewPassword",
        message: "An unknown error has occurred",
      };
    }
  } catch (error: any) {
    return {
      success: false,
      type: "NewPassword",
      message: error.message,
    };
  }
}

export async function SendResetCode(email: Email) {
  try {
    await Auth.forgotPassword(email.email);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      success: false,
      type: "Email",
      message: error.message,
    };
  }
}

export async function ResetPassword(model: ResetPasswordModel) {
  try {
    await Auth.forgotPasswordSubmit(
      model.Email,
      model.Token,
      model.NewPassword
    );
    return {
      success: true,
    };
  } catch (error: any) {
    if (error.code === "ExpiredCodeException") {
      return {
        success: false,
        type: "Token",
        message: error.message,
      };
    } else if (error.code === "InvalidPasswordException") {
      return {
        success: false,
        type: "NewPassword",
        message: error.message,
      };
    } else if (error.code === "CodeMismatchException") {
      return {
        success: false,
        type: "Token",
        message: error.message,
      };
    } else {
      return {
        success: false,
        type: "Email",
        message: error.message,
      };
    }
  }
}

// const handleLogout = () => {
//   console.log("calling")
//   console.log(logout)
//   // logout();
// };

export async function Logout() {
  try {
    await Auth.signOut();
  } catch {
    alert("Can't sign out");
  }
}
