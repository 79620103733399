import Share from "./Share";

interface Props {
    fileId: string,
    refresh: Function
};

export default function Sharing({ fileId, refresh }: Props) {

    return <>
        <div className="px-4 flex-fill overflow-lg-auto pt-3 d-flex flex-column">
            <Share removeOverflow={true} fileId={fileId} refresh={refresh} />
        </div>
    </>

}