import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FAQList from "./components/FAQGroupQuestions";
import FaqCard from "./components/faqcard";
import { Group, groups } from "./components/faq/groups";
import { FAQData, FAQDataType } from "./components/faq/data";
import style from "./FAQ.module.css";

export default function FAQLists() {
  const { group, question } = useParams<{
    question?: string;
    group?: string;
  }>();
  const [showList, setShowList] = useState(true);
  const [faqGroupItems, setFaqGroupItems] = useState<FAQDataType[]>([]);
  const [faq, setFaq] = useState<FAQDataType>();
  useEffect(() => {
    let groupItems;
    setFaqGroupItems([]);
    setFaq(undefined);
    if (group) {
      groupItems = FAQData.filter(
        (data) => data.groups.indexOf(parseInt(group) as Group) >= 0
      );
      setFaqGroupItems(groupItems);
    }
    if (groupItems) {
      setFaq(groupItems[parseInt(question ?? "0")]);
    }
  }, [group, question]);

  useEffect(() => {
    if (question) {
      setShowList(false);
    } else {
      setShowList(true);
    }
  }, [question]);

  return (
    <section className="layout-section py-4">
      <div className="d-none d-md-flex row justify-content-center">
        {groups.map((x) => (
          <div
            style={{
              width: "20%",
            }}
          >
            <FaqCard
              header={x.header}
              id={x.group}
              active={parseInt(group ?? "") === x.group}
              single={true}
            />
          </div>
        ))}
      </div>
      <div className={`card card-radius ${style.single_border} mt-lg-3`}>
        <div className="row">
          <div
            className={`col-12 col-md-4 d-md-block ${
              showList ? "d-block" : "d-none"
            }`}
          >
            <FAQList
              items={faqGroupItems.map((x, i) => ({
                id: i,
                value: x.title,
              }))}
              group={group}
            />
          </div>
          <div
            className={`col-12 col-md-8 px-4 d-md-block ${
              showList ? "d-none" : "d-block"
            } ${style.single_border_left}`}
          >
            <div
              className="color-dark-grey"
              style={{
                borderBottom: "1px solid var(--light-blue)",
              }}
            >
              <nav className="my-lg-3" aria-label="breadcrumb">
                <ol className={`breadcrumb ${style.navSingle}`}>
                  <li className="breadcrumb-item-arrow" aria-current="page">
                    <Link
                      to="/faq"
                      className="text-decoration-none color-dark-grey"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item-arrow" aria-current="page">
                    <Link
                      to={`/faq/${group}`}
                      className="text-decoration-none color-dark-grey"
                    >
                      {group &&
                        groups.find((x) => parseInt(group ?? "") === x.group)
                          ?.header}
                    </Link>
                  </li>
                  <li className="breadcrumb-item-arrow" aria-current="page">
                    {faq?.title}
                  </li>
                </ol>
              </nav>
            </div>
            <h4 className="my-2">{faq?.title}</h4>
            <div className={style["faq-item"]}>{faq?.body}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
