export const fileActivities = /* GraphQL */ `
  query fileActivities($id: ID!, $token: String) {
    fileActivities(
      fileId: $id
      sortDirection: DESC
      nextToken: $token
    ) {
      nextToken
      items {
        id
        parameters
        type
        createdAt
        user {
          name
          id
        }
      }
    }
  }
`;

export const nextFileActivities = /* GraphQL */ `
  query nextFileActivities($id: ID!, $token: String) {
    files: getFile(id: $id) {
      id
      activities(sortDirection: DESC, nextToken: $token) {
        items {
          id
          parameters
          type
          createdAt
          user {
            name
            id
          }
        }
        nextToken
      }
    }
  }
`;
