import { useEffect } from "react";
import { useState } from "react";
import FolderSVG from "../../Icons/FolderSVG";
import MoveSVG from "../../Icons/MoveSVG";
import NewFolderSVG from "../../Icons/NewFolderSVG";
import { FileData, Create as CreateModel } from "../../Models/Document";
import {
  Create,
  moveFile,
  moveFileBatch,
  subFolders,
} from "../../Services/Document";
import TopMenu from "../topMenu";
import style from "./Move.module.css";
import { v4 as uuid } from "uuid";
import Loading from "./Loading";
import InfiniteScroll from "react-infinite-scroller";

interface Props {
  close: Function;
  fileId?: string;
  onmove: Function;
  root: number;
  ids?: string[];
}
export default function Move({ close, fileId, onmove, root, ids }: Props) {
  const [loading, setLoading] = useState(true);
  const [currentFolder, setCurrentFolder] = useState<{
    id: string;
    name: string;
    parent: string;
  }>();
  const [selected, setSelected] = useState<string>(root.toString());
  const [folders, setFolders] = useState<FileData[]>([]);
  const [token, setToken] = useState<string>();
  const [createFolder, setCreateFolder] = useState(false);
  const [folderCreating, setFolderCreating] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    subFolders(fileId, ids, root, root.toString())
      .then((value) => {
        if (value !== undefined) {
          setFolders(value.files);
          setCurrentFolder(value.parent);
          setToken(value.token);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fileId, root]);

  const getFolders = (id?: string) => {
    setLoading(true);
    if (id) {
      setFolders([]);
      setCurrentFolder(undefined);
      subFolders(fileId, ids, root, id)
        .then((value) => {
          if (value !== undefined) {
            setFolders(value.files);
            setCurrentFolder(value.parent);
            setSelected(id);
            setToken(value.token);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loadMore = () => {
    setLoadingMore(true);
    if (!!token && !loadingMore)
      subFolders(fileId, ids, root, root.toString(), token)
        .then((value) => {
          if (value !== undefined) {
            setFolders(folders.concat(value.files));
            setCurrentFolder(value.parent);
            setToken(value.token);
          }
        })
        .finally(() => {
          setLoadingMore(false);
        });
  };

  const move = () => {
    setLoading(true);
    if (fileId)
      moveFile(fileId, selected).then(() => {
        // console.log("this is file id", fileId, selected);
        onmove(fileId, selected);
        close();
        setLoading(false);
      });
    else if (ids) {
      moveFileBatch(ids, selected).then(() => {
        onmove(fileId, selected);
        close();
        setLoading(false);
      });
    }
  };

  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        type: "folder",
        parent: currentFolder?.id ?? "",
        size: 0,
        id: uuid(),
        uploadUrl: undefined,
      };
      let value = await Create(document);
      if (value !== false) {
        setFolders([value, ...folders]);
        setCreateFolder(false);
        setFolderCreating(false);
        setSelected(value.id);
      }
    }
  };
  return (
    <>
      <section className={`card border-0 d-none d-lg-block ${style.card}`}>
        <div
          className={`card-header d-flex justify-content-between align-items-center py-3 border-0 ${style["card-header"]}`}
        >
          <button
            className="btn shadow-none px-0"
            onClick={() => getFolders(currentFolder?.parent)}
          >
            {currentFolder?.parent && (
              <i className="far fa-arrow-alt-circle-left pe-1"></i>
            )}
            {currentFolder?.name}
          </button>
          <button
            className="btn text-danger shadow-none"
            onClick={() => close()}
          >
            <i className="far fa-times-circle"></i>
          </button>
        </div>
        <div
          className="card-body"
          style={{
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <InfiniteScroll
              loader={
                <li className="text-center">
                  <Loading />
                </li>
              }
              useWindow={false}
              loadMore={loadMore}
              initialLoad={true}
              hasMore={!!token}
              element="ul"
              className="list-unstyled mb-0"
            >
              {createFolder && (
                <li>
                  <button className="btn shadow-none ps-0 text-black d-flex w-100">
                    <span className="d-inline-block svgIcon me-2 ">
                      <FolderSVG color="#fcc62d" />
                    </span>
                    <input
                      type="text"
                      className="form-control shadow-none mt-2"
                      onKeyDown={handleFolderKeyEvent}
                      autoFocus
                      disabled={folderCreating}
                    />
                  </button>
                </li>
              )}
              {folders.map((x) => (
                <li key={x.id}>
                  <button
                    className={`btn shadow-none ps-0 ${
                      selected === x.id ? "color-blue" : "text-black"
                    }`}
                    onClick={() => {
                      setSelected(x.id);
                    }}
                    onDoubleClick={() => getFolders(x.id)}
                  >
                    <span className="d-inline-block svgIcon me-2 ">
                      <FolderSVG
                        color={selected === x.id ? "#3A77F3" : "#fcc62d"}
                      />
                    </span>
                    {x.name}
                  </button>
                </li>
              ))}
            </InfiniteScroll>
          )}
        </div>
        <div
          className={`card-footer d-flex justify-content-between py-3 border-0 ${style["card-footer"]}`}
        >
          <button
            className="btn btn-outline-primary shadow-none"
            onClick={() => setCreateFolder(true)}
            disabled={loading}
          >
            <span className="d-inline-block svgIcon me-1">
              <NewFolderSVG color="#3A77F3" />
            </span>
            New Folder
          </button>
          <button
            className={`btn shadow-none ${
              selected ? "btn-outline-primary" : style.unselected
            }`}
            onClick={() => {
              move();
            }}
            disabled={loading}
          >
            <span className="d-inline-block svgIcon me-1">
              <MoveSVG color={selected ? "#3A77F3" : "white"} />
            </span>
            Move here
          </button>
        </div>
      </section>

      <section className="bg-white h-100 d-block d-lg-none">
        <div className={`${style["topMenu-border"]} py-2`}>
          <TopMenu />
        </div>
        <section
          className="px-3 my-3"
          style={{
            paddingBottom: "44px",
          }}
        >
          <button
            className="btn shadow-none fw-bold px-0"
            onClick={() => getFolders(currentFolder?.parent)}
          >
            {currentFolder?.parent && (
              <i className="far fa-arrow-alt-circle-left pe-1"></i>
            )}
            {currentFolder?.name}
          </button>

          <p className="fw-bold">Select Destination Folder</p>
          <div>
            {loading ? (
              <Loading />
            ) : (
              <InfiniteScroll
                loader={
                  <li className="text-center">
                    <Loading />
                  </li>
                }
                useWindow={false}
                loadMore={loadMore}
                initialLoad={true}
                hasMore={!!token}
                element="ul"
                className="list-unstyled mb-0"
              >
                {folders.map((x) => (
                  <li key={x.id} className="d-flex justify-content-between">
                    <button
                      className={`btn shadow-none ps-0 ${
                        selected === x.id ? "color-blue" : "text-black"
                      }`}
                      onClick={() => {
                        setSelected(x.id);
                      }}
                    >
                      <span className="d-inline-block svgIcon me-2 ">
                        <FolderSVG
                          color={selected === x.id ? "#3A77F3" : "#fcc62d"}
                        />
                      </span>
                      {x.name}
                    </button>
                    <button
                      className="btn shadow-none px-0"
                      onClick={() => getFolders(x.id)}
                    >
                      <i className="far fa-arrow-alt-circle-right pe-1"></i>
                    </button>
                  </li>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </section>
        <div className="row w-100 bg-white mx-0 py-2 position-fixed bottom-0">
          <div className="col-6">
            <button
              className="btn btn-outline-primary w-100 shadow-none"
              onClick={() => close()}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button
              className={`btn w-100 shadow-none ${
                selected ? "btn-primary" : "btn-primary-disabled"
              }`}
              onClick={() => {
                move();
              }}
              disabled={loading}
            >
              Move
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
