import { useOverlay } from "../../../Context/OverlayContext";
import { createSubscription } from "../../../Services/stripe";
import PriceCard from "../PriceCard";
import { useAlert } from "react-alert";
import { useState } from "react";
import Toggle from "../../../landing-page/components/toggle";
import { CancelPremium } from "../../../Services/Profile";
interface Props {
  id: string;
  onSubscribe: Function;
  onReload: Function;
  onCancel: Function;
}
export default function Plans({ id, onSubscribe, onCancel, onReload }: Props) {
  const { setLoading } = useOverlay();
  const [duration, setDuration] = useState<"monthly" | "yearly">("monthly");
  const alert = useAlert();

  return (
    <div className="row mx-5 mx-xl-0">
      <div className="d-flex justify-content-center my-3">
        <Toggle
          itemA="Monthly"
          itemB="Yearly"
          onSelected={(value: "monthly" | "yearly") => setDuration(value)}
        />
      </div>
      {duration === "monthly" ? (
        <>
          <div className="col-12 col-xl-4 mb-3 mb-xl-0">
            <PriceCard
              images="/icons/lite.svg"
              type="Lite"
              defaultId={"0"}
              current={id}
              duration="monthly"
              size="5 GB"
              click={() => {
                setLoading(true);

                createSubscription("0")
                  .then((result) => {
                    onSubscribe(result);
                    CancelPremium("");
                  })
                  .catch(() => {
                    alert.error("An Error occurred", { timeout: 5000 });
                  })

                  .finally(() => {
                    onReload();
                    setLoading(false);
                  });
              }}
              subscribed={id === "0"}
            />
          </div>
          <div className="col-12 col-xl-4 mb-3 mb-xl-0">
            <PriceCard
              images="/icons/pro.svg"
              type="Pro"
              defaultId={"1"}
              current={id}
              price={7.99}
              info={7.99 * 12}
              size="100 GB"
              duration="monthly"
              click={() => {
                setLoading(true);
                createSubscription("1")
                  .then((result) => {
                    onSubscribe(result);
                  })
                  .catch(() => {
                    alert.error("An Error occurred", { timeout: 5000 });
                  })
                  .finally(() => {
                    onReload();
                    setLoading(false);
                  });
              }}
              subscribed={id === "1"}
            />
          </div>
          <div className="col-12 col-xl-4 mb-3 mb-xl-0">
            <PriceCard
              images="/icons/proplus.svg"
              type="Premium"
              defaultId={"2"}
              current={id}
              price={9.99}
              info={9.99 * 12}
              duration="monthly"
              size="250 GB"
              click={() => {
                setLoading(true);
                createSubscription("2")
                  .then((result) => {
                    onSubscribe(result);
                  })
                  .catch((e) => {
                    alert.error("An Error occurred", { timeout: 5000 });
                  })
                  .finally(() => {
                    onReload();
                    setLoading(false);
                  });
              }}
              subscribed={id === "2"}
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-12 col-xl-4 mb-3 mb-xl-0">
            <PriceCard
              images="/icons/lite.svg"
              type="Lite"
              defaultId={"0"}
              current={id}
              duration="yearly"
              size="5 GB"
              click={() => {
                setLoading(true);

                createSubscription("0")
                  .then((result) => {
                    onSubscribe(result);
                    CancelPremium("");
                  })
                  .catch(() => {
                    alert.error("An Error occurred", { timeout: 5000 });
                  })
                  .finally(() => {
                    onReload();
                    setLoading(false);
                  });
              }}
              subscribed={id === "0"}
            />
          </div>
          <div className="col-12 col-xl-4 mb-3 mb-xl-0">
            <PriceCard
              images="/icons/pro.svg"
              type="Pro"
              defaultId={"3"}
              current={id}
              price={6.99}
              info={12}
              size="100 GB"
              duration="yearly"
              click={() => {
                onReload();
                setLoading(true);
                createSubscription("3")
                  .then((result) => {
                    onSubscribe(result);
                  })
                  .catch(() => {
                    alert.error("An Error occurred", { timeout: 5000 });
                  })
                  .finally(() => {
                    onReload();
                    setLoading(false);
                  });
              }}
              subscribed={id === "3"}
            />
          </div>
          <div className="col-12 col-xl-4 mb-3 mb-xl-0">
            <PriceCard
              images="/icons/proplus.svg"
              type="Premium"
              defaultId={"4"}
              current={id}
              price={8.99}
              info={10}
              duration="yearly"
              size="250 GB"
              click={() => {
                setLoading(true);
                createSubscription("4")
                  .then((result) => {
                    onSubscribe(result);
                  })
                  .catch((e) => {
                    alert.error("An Error occurred", { timeout: 5000 });
                  })
                  .finally(() => {
                    onReload();
                    setLoading(false);
                  });
              }}
              subscribed={id === "4"}
            />
          </div>
        </>
      )}
    </div>
  );
}
