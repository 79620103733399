import { useParams } from "react-router-dom";
import Layout from "../layout";
import File from "./Components/File";
import style from "../styles/files.module.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Comments from "../Components/Comments";
import Activity from "../Components/Activity";
import { useEffect, useRef, useState } from "react";
import {
  Comment as CommentModel,
  SharedData,
  Activity as ActivityModel,
} from "../../Models/Document";
import { getFile } from "../../Services/Shared";
import { commentListener } from "../../Services/Comments";
import FetchActivity from "../../Services/Activity";
import getBreadCrumb from "../Favorites/BreadCrumb";
import { Preview } from "../../Services/Document";

export default function SharedFile() {
  const { id } = useParams<{ id: string }>();
  const [file, setFile] = useState<SharedData>();
  const [breadcrumbItems, setBreadCrumbItems] = useState<any[]>([]);
  const [previewFile, setPreviewFile] = useState("");
  const [comments, setComments] = useState<CommentModel[]>([]);
  const commentSubscriber = useRef<any>();
  const [activities, setActivities] = useState<{
    items: ActivityModel[];
    token: string;
  }>({
    items: [],
    token: "",
  });
  const [viewActivity, setViewActivity] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const refresh = () => {
    getFile(id).then((value) => {
      if (value) {
        setFile(value.file as any);
        setComments(value.comments.reverse());
        setViewActivity(!(value.view as boolean));
        setBreadCrumbItems(getBreadCrumb(value.parent));
        if (value.view) {
          FetchActivity(id).then((value) => {
            if (value) {
              setActivities({
                items: value.activities,
                token: value.token,
              });
              setLoadingActivity(false);
            }
          });
        } else {
          setLoadingActivity(false);
        }
      }
    });
    Preview(id).then((value) => {
      if (value?.success && value.data) {
        setPreviewFile(value.data);
      }
    });
  };
  useEffect(() => {
    getFile(id).then((value) => {
      if (value) {
        setFile(value.file as any);
        setComments(value.comments.reverse());
        setViewActivity(!(value.view as boolean));
        setBreadCrumbItems(getBreadCrumb(value.parent));
        if (value.view) {
          FetchActivity(id).then((value) => {
            if (value) {
              setActivities({
                items: value.activities,
                token: value.token,
              });
              setLoadingActivity(false);
            }
          });
        } else {
          setLoadingActivity(false);
        }
      }
    });
    commentListener(id, (value: any) => {
      setComments((comments) => [...comments, value]);
    }).then((value) => {
      commentSubscriber.current = value;
    });

    Preview(id).then((value) => {
      if (value?.success && value.data) {
        setPreviewFile(value.data);
      }
    });
    return () => {
      if (commentSubscriber.current) {
        commentSubscriber.current.unsubscribe();
      }
    };
  }, [id]);

  const nextActivities = () => {
    if (activities.token) {
      FetchActivity(id, activities.token).then((values) => {
        if (values) {
          setActivities((activities) => ({
            items: activities.items.concat(values.activities),
            token: values.token,
          }));
        }
      });
    }
  };
  return (
    <Layout>
      <div className="d-lg-none h-100">
        <Tabs
          selectedTabClassName={style.tabSelected}
          className="d-flex flex-column h-100"
          selectedTabPanelClassName="d-flex  flex-fill flex-column"
        >
          <TabList className={style.tabs}>
            <Tab>Home</Tab>
            <Tab>Comments</Tab>
            <Tab>Activity</Tab>
          </TabList>
          <TabPanel>
            <File
              comments={comments}
              file={file}
              id={id}
              activities={{
                value: activities.items,
                disabled: viewActivity,
                loading: loadingActivity,
                loadMore: nextActivities,
                hasMore: !!activities.token,
              }}
              breadcrumbs={breadcrumbItems}
              refresh={refresh}
              previewFile={previewFile}
            />
          </TabPanel>
          <TabPanel>
            <Comments comments={comments} fileId={id} />
          </TabPanel>
          <TabPanel>
            <Activity
              activities={activities.items}
              loading={loadingActivity}
              disabled={viewActivity}
              loadMore={nextActivities}
              hasMore={!!activities.token}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="d-none d-lg-block h-100">
        <File
          comments={comments}
          file={file}
          id={id}
          activities={{
            value: activities.items,
            disabled: viewActivity,
            loading: loadingActivity,
            loadMore: nextActivities,
            hasMore: !!activities.token,
          }}
          refresh={refresh}
          breadcrumbs={breadcrumbItems}
          previewFile={previewFile}
        />
      </div>
    </Layout>
  );
}
