import { useState } from "react";
import { useForm } from "react-hook-form";
import Popup from "../../Components/popup";
import { useAuth } from "../../../Context/UserContext";
import { member } from "../../../Models/Document";
import {
  allowActivityView,
  invite,
  preventActivityView,
  remove,
} from "../../../Services/Time-Lock";
import Avatar from "../../Components/avatar";
import { useOverlay } from "../../../Context/OverlayContext";
import { useAlert } from "react-alert";

interface Props {
  members: member[];
  fileId: string;
  refresh: Function;
  folderChildrenIds?: [] | [];
}

export default function Sharing({
  members,
  fileId,
  refresh,
  folderChildrenIds,
}: Props) {
  const [open, setOpen] = useState<string>();
  const { register, handleSubmit } = useForm<{
    email: string;
    relationship: string;
    name: string;
  }>();
  const { setLoading } = useOverlay();

  const alert = useAlert();
  const submit = async (data: any) => {
    setLoading(true);
    await invite(data.email, fileId, data.relationship, data.name);

    // refresh(() => setLoading(false));
    setLoading(false);
    refresh();
  };

  const { user } = useAuth();

  const removeShared = (data: any, folderChildrenIds: any) => {
    if (folderChildrenIds === undefined) {
      setOpen(undefined);
      setLoading(true);
      remove(data.id);
    }
    if (folderChildrenIds) {
      setOpen(undefined);
      setLoading(true);
      remove(data.id);
      const emailToCheck = data.email;
      const emailPattern = new RegExp(`${emailToCheck}$`);

      folderChildrenIds.map((x: any) =>
        x.timelockShared.items.map((y: any) => {
          const containsEmail = emailPattern.test(y.id);

          if (containsEmail) {
            remove(y.id);
          } else {
            // console.log("The string does not contain the email address.");
          }
        })
      );
    }

    setLoading(false);
    refresh();
  };

  return (
    <>
      <h5>Manage Sharing</h5>
      Incase of inactivity share folder with
      <br />
      <form onSubmit={handleSubmit(submit)}>
        <input
          type="text"
          className="form-control shadow-none"
          placeholder="Enter full name"
          {...register("name")}
        />
        <input
          type="text"
          list="relationship"
          className="form-control shadow-none my-2"
          placeholder="Enter relationship with you"
          {...register("relationship")}
        />
        <input
          type="email"
          className="form-control shadow-none"
          placeholder="Enter email address"
          {...register("email")}
        />
        <datalist id="relationship">
          <option value="parent" />
          <option value="child" />
          <option value="sibling" />
          <option value="spouse" />
        </datalist>
        <button className="btn btn-primary mt-2 shadow-none">
          Send Invite
        </button>
      </form>
      <p
        style={{
          borderBottom: "1px solid #dddddd",
        }}
        className="mb-0 mt-2 font-weight-semibold"
      >
        Shared with
      </p>
      <ul className={`list-unstyled`}>
        <li className="d-flex align-items-top my-3">
          <Avatar name={user?.name ?? ""} image={user?.id ?? ""} />
          <div className="text-break flex-fill font-weight-medium ms-1">
            {user?.name} (You)
          </div>
          <span
            className="me-3 fw-normal"
            style={{
              fontSize: "14px",
            }}
          >
            Owner
          </span>
        </li>
        {members.map((x) => (
          <li className="d-flex align-items-center my-3" key={x.id}>
            <span className="me-1">
              <Avatar name={x.name ?? x.email} image={x.id} />
            </span>
            <div className="text-break flex-fill">{x.name ?? x.email}</div>
            <Popup
              offsetX={-45}
              position="center center"
              trigger={
                <button
                  className="btn shadow-none py-0 d-flex align-items-center fw-normal"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {x.view ? "Can view activity" : "Cannot view activity"}
                  {open === x.id ? (
                    <i className="fas fa-angle-down color-orange ps-2"></i>
                  ) : (
                    <i className="fas fa-angle-up color-orange ps-2"></i>
                  )}
                </button>
              }
              onOpen={() => setOpen(x.id)}
              onClose={() => setOpen(undefined)}
              nested
            >
              <ul
                className="list-unstyled bg-white card-radius mb-0 p-2"
                style={{
                  filter: "drop-shadow(0px 2px 6px rgb(0,0,0,0.16))",
                }}
              >
                <li>
                  <button
                    className="btn shadow-none "
                    onClick={() => {
                      setOpen(undefined);
                      setLoading(true);
                      allowActivityView(x.id)
                        .then(async () => {
                          alert.success("Successful request", {
                            timeout: 5000,
                          });
                          await refresh();
                        })
                        .catch(() => {
                          alert.error("Request Failed", { timeout: 5000 });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                  >
                    Can view activity
                  </button>
                </li>
                <li>
                  <button
                    className="btn shadow-none"
                    onClick={() => {
                      setOpen(undefined);
                      setLoading(true);
                      preventActivityView(x.id)
                        .then(async () => {
                          alert.success("Successful request", {
                            timeout: 5000,
                          });
                          await refresh();
                        })
                        .catch(() => {
                          alert.error("Request Failed", { timeout: 5000 });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                  >
                    Cannot view activity
                  </button>
                </li>
                <li>
                  <button
                    className="btn shadow-none"
                    onClick={() => removeShared(x, folderChildrenIds)}
                  >
                    Remove
                  </button>
                </li>
              </ul>
            </Popup>
          </li>
        ))}
      </ul>
    </>
  );
}
