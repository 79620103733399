import style from "../styles/files.module.css";
import FileTable from "../Components/TableRows/Table";
import DropFiles from "../Components/Dropfiles";
import MobileUpload from "../Components/MobileUpload";
import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  batchLockService,
  DownloadFiles,
  GetFolder,
  parentListener,
  Preview,
  renameFileService,
} from "../../Services/Document";
import { FileRow } from "../Components/TableRows";
import { useHistory } from "react-router-dom";
import { FileData, Create as CreateModel } from "../../Models/Document";
import { Create } from "../../Services/Document";
import { v4 as uuid } from "uuid";
import moment from "moment";
import DesktopUpload from "../Components/DesktopUpload";
import { useOverlay } from "../../Context/OverlayContext";
import Popup from "../Components/popup";
import Previewer from "../Components/Preview";
import { FileWithChecked } from "../Home/home";
import { batchArchive } from "../../Services/Archive";
import { useAlert } from "react-alert";
import Move from "../Components/Move";
import Layout from "../Home/Layout";
import CloseButton from "../Components/closeButton";
export default function Files() {
  const [createFolder, setCreateFolder] = useState(false);
  const parentSubscriber = useRef<any>();
  const history = useHistory();
  const [folderCreating, setFolderCreating] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const { setLoading } = useOverlay();
  const [folder, setFolder] = useState<{
    files: FileWithChecked[];
    token: string;
    loading: boolean;
  }>({
    files: [],
    token: "",
    loading: true,
  });

  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState("");
  const [openMove, setOpenMove] = useState(false);

  const alert = useAlert();
  const handleChecked = (fileId: string, checked: boolean) => {
    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((x) =>
        x.id === fileId
          ? {
              ...x,
              checked: checked,
            }
          : x
      ),
    }));
  };

  const previewImage = (file: FileData) => {
    setLoading(true);

    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(file.name);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setLoading(true);
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        parent: "0",
        type: "folder",
        size: 0,
        id: uuid(),
        uploadUrl: undefined,
      };
      const out = await Create(document);

      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
      setLoading(false);
    }
  };

  const createFile = async (id: string, file: File, name: string) => {
    let value: CreateModel = {
      id: id,
      name: name ?? file.name,
      parent: "0",
      type: file.type,
      size: file.size,
      uploadUrl: "file",
    };
    await Create(value);
  };

  const clickFile = async (file: FileData) => {
    if (file.type !== "folder") {
      history.push("/files/file/" + file.id);
    } else {
      history.push("/files/folder/" + file.id);
    }
  };

  const rename = async (file: FileData, name: string) => {
    await renameFileService(file.id, name);

    setFolder((folder) => ({
      ...folder,
      files: folder.files.map((f) =>
        f.id === file.id ? { ...f, name: name } : f
      ),
    }));
  };

  const refresh = () => {
    GetFolder("0", 0)
      .then((files) => {
        if (!!files) {
          setFolder((folder) => ({
            loading: false,
            files: files.files,
            token: files.token,
          }));
        }
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  };
  useEffect(() => {
    refresh();
    parentListener("0", (value: any) => {
      if (!value.activeDate) {
        setFolder((folder) => ({
          ...folder,
          files: [value].concat(folder.files.filter((x) => x.id !== value.id)),
        }));
      } else if (
        value.activeDate &&
        moment(value.activeDate, "YYYY-MM-DD").isBefore(moment())
      )
        setFolder((folder) => ({
          ...folder,
          files: [value].concat(folder.files.filter((x) => x.id !== value.id)),
        }));
    }).then((value) => {
      parentSubscriber.current = value;
    });

    return () => {
      if (parentSubscriber.current) {
        parentSubscriber.current.unsubscribe();
      }
    };
  }, []);

  const move = (fileId: string, selected: string) => {
    if (selected !== "0") {
      setFolder((folder) => ({
        ...folder,
        files: folder.files.filter((f) => f.id !== fileId),
      }));
    } else {
      return;
    }
  };

  const onLock = async (file: FileData) => {
    if (file.type !== "folder") {
      history.push("/time-lock/file/" + file.id);
    } else {
      history.push("/time-lock/folder/" + file.id);
    }
  };
  return (
    <Layout>
      <MobileUpload
        callback={createFile}
        parent="0"
        url="files"
        uploadUrl="file"
      />
      <section className="h-100 d-flex flex-column">
        <DropFiles
          callback={createFile}
          parent="0"
          url="files"
          uploadUrl="file"
        />
        <div className="mt-4 d-flex justify-content-between flex-column-reverse flex-lg-row  align-items-lg-start">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3">
              <li
                className="breadcrumb-item active text-black font-weight-semibold"
                aria-current="page"
              >
                My Files
              </li>
            </ol>
          </nav>
          <DesktopUpload
            callback={createFile}
            parent="0"
            onCreateFolder={() => setCreateFolder(true)}
            url="files"
            uploadUrl="file"
            disableMulti={
              folder.files.filter((x) => x.checked).map((x) => x.id).length <= 0
            }
          />
        </div>
        <div className={`mb-lg-5 mb-0 ${style.table}`}>
          <FileTable
            border={folder.files.length !== 0}
            hasMore={!!folder.token}
            next={() => {
              if (!!folder.token)
                GetFolder("0", 0, folder.token).then((files) => {
                  if (!!files) {
                    setFolder((folder) => ({
                      ...folder,
                      files: folder.files.concat(files.files),
                      loading: false,
                      token: files.token,
                    }));
                  }
                });
            }}
            loading={loadingFiles}
            items={[
              {
                display: (
                  <>
                    {folder.files.filter((file) => file.checked).length !==
                    folder.files.length ? (
                      <>
                        <i className="fas fa-check me-1"></i>
                        Select All
                      </>
                    ) : (
                      <>
                        <i className="far fa-window-close me-1"></i>
                        Deselect All
                      </>
                    )}
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((file) => file.checked).length ==
                    folder.files.length
                  ) {
                    for (let file of folder.files) {
                      handleChecked(file.id, false);
                    }
                  } else {
                    for (let file of folder.files) {
                      handleChecked(file.id, true);
                    }
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-archive text-start me-1"></i>
                    Archive
                  </>
                ),
                action: () => {
                  setLoading(true);
                  batchArchive(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    refresh();
                    setLoading(false);
                  });
                },
              },
              {
                display: (
                  <>
                    <img
                      src="/icons/Move.png"
                      alt="move"
                      className="img-fluid"
                    />{" "}
                    Move to
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                      .length > 0
                  )
                    setOpenMove(true);
                  else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-lock me-1"></i>
                    Lock File
                  </>
                ),
                action: () => {
                  if (
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                      .length > 0
                  ) {
                    setLoading(true);
                    batchLockService(
                      folder.files.filter((x) => x.checked).map((x) => x.id)
                    ).finally(() => {
                      refresh();
                      setLoading(false);
                    });
                  } else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-download me-1"></i>
                    Download
                  </>
                ),
                action: async () => {
                  setLoading(true);
                  await DownloadFiles(
                    folder.files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    setLoading(false);
                  });
                },
              },
            ]}
          >
            {createFolder && (
              <tr>
                <td colSpan={6}>
                  <div
                    className={`d-flex w-100 ${
                      folderCreating && style.disabled
                    }`}
                  >
                    <img
                      src={`/logos/folder.png`}
                      alt={`folder logo`}
                      className="img-fluid"
                    />
                    <input
                      type="text"
                      className="form-control ms-2 shadow-none d-inline flex-fill"
                      onClick={(e: any) => e.stopPropagation()}
                      onKeyDown={handleFolderKeyEvent}
                      autoFocus
                      disabled={folderCreating}
                    />
                  </div>
                </td>
              </tr>
            )}

            {folder.files.map((file, i) => (
              <FileRow
                onClickChecked={(data: boolean) => handleChecked(file.id, data)}
                fileData={file}
                onClick={() => clickFile(file)}
                key={file.id}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                onRename={(value: string) => rename(file, value)}
                onMove={move}
                onLock={() => onLock(file)}
                onArchive={(id: string) =>
                  setFolder((folder) => ({
                    ...folder,
                    files: folder.files.filter((f) => f.id !== id),
                  }))
                }
                onFavorite={(fileId: any) => {
                  setFolder((folder) => ({
                    ...folder,
                    files: folder.files.map((f) =>
                      f.id === fileId ? { ...f, favorites: true } : f
                    ),
                  }));
                }}
                onUnfavorite={(fileId: any) => {
                  setFolder((folder) => ({
                    ...folder,
                    files: folder.files.map((f) =>
                      f.id === fileId ? { ...f, favorites: false } : f
                    ),
                  }));
                }}
              />
            ))}
          </FileTable>
        </div>
        <Popup
          open={preview}
          closeOnDocumentClick
          className="modalPopup"
          onClose={() => setPreview(false)}
        >
          <div
            style={{
              height: "90vh",
              width: "80vw",
            }}
          >
            <CloseButton preview={preview} setPreview={setPreview} />
            <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
          </div>
        </Popup>
        <Popup
          open={openMove}
          closeOnDocumentClick={false}
          onClose={() => setOpenMove(false)}
          closeOnEscape={false}
          className="menuPopup"
        >
          {(close: Function) => (
            <Move
              close={() => {
                close();
              }}
              ids={folder.files.filter((x) => x.checked).map((x) => x.id)}
              onmove={() => {
                refresh();
              }}
              root={0}
            />
          )}
        </Popup>
      </section>
    </Layout>
  );
}
