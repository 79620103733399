import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useOverlay } from "../../../Context/OverlayContext";
import CustomError from "../../../Helper/CustomErrorMessage";
import style from "../Setting.module.css";
import Popup from "../../Components/popup";
const getCreditCard = (
  cardType:
    | "visa"
    | "mastercard"
    | "american-express"
    | "discover"
    | "diners"
    | "jcb"
    | "unknown"
    | string
) => {
  switch (cardType) {
    case "visa":
      return "fa fa-cc-visa";
    case "mastercard":
      return "fa fa-cc-mastercard";
    case "american-express":
      return "fa fa-cc-amex";
    case "discover":
      return "fa fa-cc-discover";
    case "diners":
      return "fa fa-cc-diners";
    case "jcb":
      return "fa fa-cc-jcb";
    default:
      return "fas fa-credit-card";
  }
};

interface Props {
  message: string;
  details: Function;
  cancel: Function;
  clientSecret?: string;
  onSuccess: Function;
}
export default function Payment({
  message,
  cancel,
  details,
  clientSecret = "",
  onSuccess,
}: Props) {
  const [card, setCard] = useState("unknown");
  const [cardNumber, setCardNumber] = useState(false);
  const [cardCvc, setCardCvc] = useState(false);
  const [cardExpiry, setCardExpiry] = useState(false);
  const [preview, setPreview] = useState(false);
  const [cardNumberError, setCardNumberError] = useState<string>();
  const [cardExpiryError, setCardExpiryError] = useState<string>();
  const [cardCvcError, setCardCvcError] = useState<string>();
  const [name, setName] = useState("");
  const { setLoading } = useOverlay();
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    stripe
      ?.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements?.getElement(CardNumberElement) ?? { token: "" },
          billing_details: {
            name: name,
          },
        },
      })
      .then((e: any) => {
        if (e.error) alert.error(e.error.message, { timeout: 5000 });
        else onSuccess();
      })
      .catch((e) => {
        alert.error("An Error has occurred while processing this request", {
          timeout: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const processPayment = () => {
    setPreview(false);
    handleSubmit();
  };

  const isCardValid = () => {
    if (card && cardNumber && cardCvc && cardExpiry) {
      // details();

      setPreview(true);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="col-12 col-xl-6 mb-3">
          <label className="form-label" htmlFor="name">
            Name on Card
          </label>
          <input
            className="form-control shadow-none"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            required
          />
        </div>
        <div className="col-12 col-xl-6 mb-3">
          <label className="form-label" htmlFor="number">
            Card Number
          </label>
          <div className="d-flex align-items-center">
            <CardNumberElement
              className={`form-control ${style.stripeInput}`}
              id="number"
              onChange={(e) => {
                setCard(e.brand);
                setCardNumber(e.complete);
                setCardNumberError(e.error?.message);
              }}
            />
            <i className={`${getCreditCard(card)} ${style.stripeIcons}`}></i>
          </div>
          <CustomError messageSingle={cardNumberError} />
        </div>
        <div className="col-6 col-xl-3 mb-3">
          <label className="form-label" htmlFor="expiry">
            Expiry Date
          </label>
          <CardExpiryElement
            className={`form-control ${style.stripeInput}`}
            onChange={(e) => {
              setCardExpiry(e.complete);
              setCardExpiryError(e.error?.message);
            }}
          />
          <CustomError messageSingle={cardExpiryError} />
        </div>
        <div className="col-6 col-xl-3 mb-3">
          <label className="form-label" htmlFor="cvc">
            CVC
          </label>
          <CardCvcElement
            className={`form-control ${style.stripeInput}`}
            id="cvc"
            onChange={(e) => {
              setCardCvc(e.complete);
              setCardCvcError(e.error?.message);
            }}
          />
          <CustomError messageSingle={cardCvcError} />
        </div>
      </div>
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => isCardValid()}
      >
        Save
      </button>
      <button
        className="btn bg-dark-grey ms-2 text-white"
        onClick={() => cancel(0)}
        type="button"
      >
        Cancel
      </button>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => {
          setPreview(false);
        }}
      >
        <div
          className="bg-white p-1  d-flex "
          style={{
            width: "300px",
            height: "200px",
            filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
            borderRadius: "10px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div>
            <p className="mt-3 text-sm">{details()}</p>
            <div className="container mt-2">
              <button
                className="btn btn-primary btn-sm ms-2 me-2 mb-2"
                type="submit"
                onClick={processPayment}
              >
                Confirm
              </button>
              <button
                className="btn btn-danger  btn-sm mb-2  text-white"
                onClick={() => cancel(0)}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </form>
  );
}
