import { useEffect, useState } from "react";
import { useOverlay } from "../../Context/OverlayContext";
import { getInvoiceList } from "../../Services/stripe";
import style from "./Setting.module.css";

export default function Invoices() {
  const { setLoading } = useOverlay();
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [invoices, setInvoices] = useState<
    {
      created: string;
      id: string;
      status: string;
      total: number;
      url: string;
      name: string;
    }[]
  >([]);
  useEffect(() => {
    setLoading(true);
    getInvoiceList("0", "first")
      .then((result) => {
        setInvoices(result.invoices);
        setHasMore(result.hasMore);
      })
      .finally(() => setLoading(false));
  }, []);

  const getNextPage = () => {
    setLoading(true);
    getInvoiceList(invoices[invoices.length - 1].id, "after")
      .then((result) => {
        setInvoices(result.invoices);
        setHasMore(result.hasMore);
        setCurrentPage(currentPage + 1);
      })
      .finally(() => setLoading(false));
  };
  const getPrevPage = () => {
    setLoading(true);
    getInvoiceList(invoices[0].id, "before")
      .then((result) => {
        setInvoices(result.invoices);
        setHasMore(result.hasMore);
        setCurrentPage(currentPage - 1);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <div className={style.invoices}>
        <table className="table table-bordered d-none d-xl-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Reference</th>
              <th>Date</th>
              <th className="text-nowrap">Amount</th>
              <th className="text-nowrap">Status</th>
              <th className="text-nowrap">Download</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.name}</td>
                <td className="text-wrap">{invoice.id}</td>
                <td className="text-nowrap">
                  {new Date(invoice.created).toLocaleDateString()}
                </td>
                <td className="text-center">{invoice.total}</td>
                <td className="text-center">
                  {invoice.status === "paid" ? (
                    <i className="fas fa-check-circle text-warning"></i>
                  ) : (
                    <i className="fas fa-ban text-danger"></i>
                  )}
                </td>
                <td className="text-center">
                  <a href={invoice.url}>
                    <img src="/icons/invoice.png" alt="download" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-block d-xl-none">
          {invoices.map((invoice) => (
            <p>
              <span className={`color-orange ${style.date}`}>
                {new Date(invoice.created).toLocaleDateString()}
              </span>
              <br />
              <span className={style.amount}>$ {invoice.total.toString()}</span>
              <br />
              <span>{invoice.id}</span>
              <br />
              <span>{invoice.name}</span>
              <br />
              <span>
                {invoice.status === "paid" ? (
                  <i className="fas fa-check-circle text-success"></i>
                ) : (
                  <i className="fas fa-ban text-danger"></i>
                )}
                <a href={invoice.url} className="ms-2">
                  <img src="/icons/invoice.png" alt="download" />
                </a>
              </span>
              <hr />
            </p>
          ))}
        </div>
      </div>
      <div>
        <button
          className="btn btn-primary"
          onClick={() => getPrevPage()}
          disabled={currentPage <= 0}
        >
          Prev
        </button>
        <button
          className="btn btn-primary ms-3"
          onClick={() => getNextPage()}
          disabled={!hasMore}
        >
          Next
        </button>
      </div>
    </>
  );
}
