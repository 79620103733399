export const search = /* GraphQL */ `
  query search($name: String, $nextToken: String) {
    files: searchFilesAndSharedFiles(name: $name, nextToken: $nextToken) {
      nextToken
      items {
        ... on File {
          id
          name
          type
          size
          modified
          parent
          root
          activeDate
          sharedCount
          shared(limit: 3) {
            items {
              user {
                name
                id
              }
            }
          }
          uploader {
            name
          }
        }
        ... on Shared {
          file {
            id
            name
            type
            size
            modified
            parent
            root
            activeDate
            sharedCount
            shared(limit: 3) {
              items {
                user {
                  name
                  id
                }
              }
            }
            uploader {
              name
            }
          }
        }
      }
    }
  }
`;
