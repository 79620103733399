import { useState } from "react";
import PriceCard from "./components/pricecard";
import Toggle from "./components/toggle";
import style from "./pricing.module.css";

export default function Pricing() {
  const [duration, setDuration] = useState<"monthly" | "yearly">("monthly");
  return (
    <div className="layout-section text-center py-4">
      <h2 className={`text-center ${style.h2}`}>Affordable pricing</h2>
      <p className="text-center">
        Your digital treasure is priceless to you. Luckily, using Inkmark is
        highly affordable.
      </p>

      <Toggle
        itemA="Monthly"
        itemB="Yearly"
        onSelected={(value: "monthly" | "yearly") => setDuration(value)}
      />
      {duration === "monthly" ? (
        <div className={`row justify-content-between ${style.prices} mt-4`}>
          <div className="col-12 col-md-3 ">
            <PriceCard
              images="/icons/lite.svg"
              type="Lite"
              duration="monthly"
              storage={5}
              url={0}
            />
          </div>
          <div className="col-12 col-md-3 py-3 py-md-0">
            <div>
              <PriceCard
                images="/icons/pro.svg"
                type="Pro"
                price={7.99}
                info={7.99 * 12}
                duration="monthly"
                storage={100}
                url={1}
              />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <PriceCard
              images="/icons/proplus.svg"
              type="Pro+"
              price={9.99}
              info={9.99 * 12}
              duration="monthly"
              storage={250}
              url={2}
            />
          </div>
        </div>
      ) : (
        <div className={`row justify-content-between ${style.prices} mt-4`}>
          <div className="col-12 col-md-3 ">
            <PriceCard
              images="/icons/lite.svg"
              type="Lite"
              duration="monthly"
              storage={5}
              url={0}
            />
          </div>
          <div className="col-12 col-md-3 py-3 py-md-0">
            <div>
              <PriceCard
                images="/icons/pro.svg"
                type="Pro"
                price={6.99}
                info={12}
                yearlySum={6.99 * 12}
                duration="yearly"
                url={3}
                storage={100}
              />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <PriceCard
              images="/icons/proplus.svg"
              type="Pro+"
              price={8.99}
              info={10}
              yearlySum={8.99 * 12}
              duration="yearly"
              storage={250}
              url={4}
            />
          </div>
        </div>
      )}
    </div>
  );
}
