export default function CustomError({ messages, messageSingle }: any) {
    if (messageSingle) {
        return <small className="alert alert-danger py-1 mb-1 d-block">
            {messageSingle}
        </small>
    }
    else if (messages) {
        return <div className="alert alert-danger py-1 mb-1">
            {
                Object.entries(messages).map(([type, message] :any) => (
                    <small key={type} className="text-small">{message}</small>
                ))
            }
        </div>
    }
    else{
       return <div></div>
    }

}