import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "./layout";
import style from "./layout.module.css";
import { CompletePassword as CompletePasswordService } from "../Services/Authenticate";
import { ErrorMessage } from "@hookform/error-message";
import CustomError from "../Helper/CustomErrorMessage";
import { CompletePassword as Model } from "../Models/Authentication";

export default function CompletePassword() {
    const history = useHistory();
    const { email,password } = useParams<{ email: string, password:string }>();    
    const { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm<Model>({
        defaultValues: {
            NewFullName: "Name"
        },
    });

    const handleLogin = async (data: Model) => {
        data.email = email
        data.OldPassword = password
        var result = await CompletePasswordService(data)
        if (result.success) {
            history.push("/platform")
        }
        else {
            let resultType = result.type as any
            setError(resultType, { message: result.message })
        }
    }

    return (
        <Layout display={true}>
            <div className={`d-flex flex-column justify-content-between justify-content-lg-center align-items-center h-100 ${style["max-vh-100"]}`}>
                <p className={`text-end ${style.contentWidth}  ${style["fs-18"]} color-dark-grey d-none d-lg-block`}>
                    <small>Use a different account?</small>
                    <Link className="btn btn-outline-primary ms-3 shadow-none px-3 py-1" to="/login">Login</Link>
                </p>
                <div
                    className={`justify-content-center d-flex flex-column ${style.form}`}
                >
                    <form className="text-center text-lg-start " onSubmit={handleSubmit(handleLogin)} autoComplete="off">
                        <h1 className={`${style.loginHeader} mb-3`}>New Password is required</h1>
                        <ErrorMessage
                            errors={errors}
                            name="NewFullName"
                            render={({ messages, message }) => <CustomError messages={messages} messageSingle={message} />}
                        />

                        <input
                            type="text"
                            className="form-control shadow-none mb-3"
                            placeholder="Full name"
                            required
                            {...register("NewFullName")}
                            autoComplete="new-password"
                        />
                        <ErrorMessage
                            errors={errors}
                            name="NewPassword"
                            render={({ messages, message }) => <CustomError messages={messages} messageSingle={message} />}
                        />
                        <input
                            type="password"
                            className="form-control mb-3 shadow-none"
                            placeholder="Password"
                            required
                            {...register("NewPassword")}
                        />
                        <button
                            className="btn  btn-primary btn-radius-10 shadow-none w-100"
                            disabled={isSubmitting}
                            type="submit"
                        >Sign in</button>
                    </form>

                    <p className={`text-end ${style.contentWidth} mt-2 ${style["fs-18"]} color-dark-grey d-lg-none w-100 d-flex justify-content-between`}>
                        <small>Use a different account?</small>
                        <Link className="btn btn-outline-primary ms-3 btn-radius-10 shadow-none px-3 py-1" to="/login">Login</Link>
                    </p>
                </div>
                <p className={`text-end ${style.contentWidth} mt-2 ${style["fs-18"]} color-dark-grey d-none d-lg-block`}>
                    <small>New to Inkmark?</small>
                    <Link className="btn btn-outline-primary ms-3 btn-radius-10 shadow-none px-3 py-1" to="/registration">Create Account</Link>
                </p>
                <div className="d-block d-lg-none w-100 mt-5">
                    <p className={`mb-1 text-center ${style["fs-18"]} color-dark-grey`}>New to Inkmark?</p>
                    <Link className="btn btn-outline-primary btn-radius-10 w-100" to="/registration">
                        Create Account
                    </Link>
                </div>
            </div>
        </Layout>
    );
}
