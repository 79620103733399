import { useOverlay } from "../../Context/OverlayContext";
import style from "./../layout.module.css";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useAlert } from "react-alert";
import CustomError from "../../Helper/CustomErrorMessage";
import { updateCardDetails } from "../../Services/stripe";
import { Link } from "react-router-dom";

const getCreditCard = (
  cardType:
    | "visa"
    | "mastercard"
    | "american-express"
    | "discover"
    | "diners"
    | "jcb"
    | "unknown"
    | string
) => {
  switch (cardType) {
    case "visa":
      return "fa fa-cc-visa";
    case "mastercard":
      return "fa fa-cc-mastercard";
    case "american-express":
      return "fa fa-cc-amex";
    case "discover":
      return "fa fa-cc-discover";
    case "diners":
      return "fa fa-cc-diners";
    case "jcb":
      return "fa fa-cc-jcb";
    default:
      return "fas fa-credit-card";
  }
};

interface Props {
  onComplete: Function;
}
export default function PaymentForm({ onComplete }: Props) {
  const elements = useElements();
  const { setLoading } = useOverlay();
  const stripe = useStripe();
  const [card, setCard] = useState("unknown");
  const [cardNumberError, setCardNumberError] = useState<string>();
  const [cardExpiryError, setCardExpiryError] = useState<string>();
  const [cardCvcError, setCardCvcError] = useState<string>();
  const [name, setName] = useState("");

  const alert = useAlert();

  return (
    <form
      className={`text-start p-lg-4 ${style.form}`}
      onSubmit={async (e) => {
        e.preventDefault();
        setLoading(true);
        await stripe
          ?.createPaymentMethod({
            type: "card",
            card: elements?.getElement(CardNumberElement) ?? { token: "" },
            billing_details: {
              name: name,
            },
          })
          .then(async (e) => {
            if (e.error) alert.error(e.error.message);
            else {
              await updateCardDetails(e.paymentMethod.id);
              alert.success("Successfully created credit card", {
                timeout: 5000,
              });
              onComplete();
            }
          })
          .catch((e) => {
            alert.error(
              "An Error has occurred while processing this request, Please confirm you have a functional card"
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    >
      <h4 className="mb-4">Add credit information to start billing</h4>
      <div className="row justify-content-between">
        <div className="col-12 mb-4">
          <label className="form-label" htmlFor="name">
            Name on Card
          </label>
          <input
            className="form-control shadow-none"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            required
          />
        </div>
        <div className="col-12 mb-4">
          <label className="form-label" htmlFor="number">
            Card Number'''
          </label>
          <div className="d-flex align-items-center">
            <CardNumberElement
              className={`form-control ${style.stripeInput}`}
              id="number"
              onChange={(e) => {
                setCard(e.brand);
                setCardNumberError(e.error?.message);
              }}
            />
            <i className={`${getCreditCard(card)} ${style.stripeIcons}`}></i>
          </div>
          <CustomError messageSingle={cardNumberError} />
        </div>
        <div className="col-6 mb-4 col-lg-5">
          <label className="form-label" htmlFor="expiry">
            Expiry Date
          </label>
          <CardExpiryElement
            className={`form-control ${style.stripeInput}`}
            onChange={(e) => {
              setCardExpiryError(e.error?.message);
            }}
          />
          <CustomError messageSingle={cardExpiryError} />
        </div>
        <div className="col-6 mb-4 col-lg-5">
          <label className="form-label" htmlFor="cvc">
            CVC
          </label>
          <CardCvcElement
            className={`form-control ${style.stripeInput}`}
            id="cvc"
            onChange={(e) => {
              setCardCvcError(e.error?.message);
            }}
          />
          <CustomError messageSingle={cardCvcError} />
        </div>
      </div>
      <button className="btn  btn-primary shadow-none px-4" type="submit">
        Subscribe
      </button>
      {/* <button className="btn  bg-dark-grey text-white shadow-none px-4 ms-2">
        Cancel
      </button> */}
      <button className="btn  bg-dark-grey text-white shadow-none px-4 ms-2">
        <Link
          className="text-white"
          style={{ textDecoration: "none" }}
          to="/platform"
        >
          Skip
        </Link>
      </button>
      <br />
      <img src="/icons/stripe.png" alt="stripe" className="mt-3" />
    </form>
  );
}
