import { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import Popup from "./popup";
import { useAuth } from "../../Context/UserContext";
import { useFile } from "../../Context/FileContext";
import { Logout } from "../../Services/Authenticate";
import ButtonBadge from "./ButtonBadge";
import Messages from "./Messages";
import Notifications from "./Notifications";
import { useHistory } from "react-router-dom";
import style from "./profile.module.css";

export default function Profile() {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const {
    user,
    image,
    messages,
    clearMessages,
    notifications,
    hasMoreNotifications,
    hasMoreMessages,
    clearNotifications,
    clearUserContext,
  } = useAuth();
  const navigate = useHistory();
  const { clearFileContext } = useFile();

  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (innerRef.current && !innerRef.current?.contains(event.target)) {
      if (showNotifications) setShowNotifications(false);
      if (showMessages) setShowMessages(false);
    }
  };

  const handleLogout = () => {
    clearUserContext && clearUserContext();
    clearFileContext && clearFileContext();
    Logout();
  };

  return (
    <div className="position-relative" ref={innerRef}>
      <div className="d-flex border-x border-y p-2 rounded">
        <ButtonBadge
          length={notifications?.length}
          hasValue={
            (notifications?.length ?? 0) > 0 || (hasMoreNotifications ?? false)
          }
          onClick={() => {
            setShowNotifications(!showNotifications);
            setShowMessages(false);
          }}
          classList="me-4"
        >
          <i className="fas fa-bell color-blue fa-lg"></i>
        </ButtonBadge>
        <ButtonBadge
          length={messages?.length}
          hasValue={(messages?.length ?? 0) > 0 || (hasMoreMessages ?? false)}
          onClick={() => {
            setShowNotifications(false);
            setShowMessages(!showMessages);
          }}
          classList="me-4"
        >
          <img src="/icons/Chat Text.svg" alt="Chat" />
        </ButtonBadge>

        <Popup
          trigger={
            <button className="btn btn-transparent px-1 py-0 shadow-none w-100">
              <div className="d-flex align-items-center">
                <Avatar
                  src={image}
                  size="36px"
                  name={user?.name}
                  round={true}
                />
                <span
                  className="d-inline-block text-truncate"
                  style={{
                    width: "100px",
                  }}
                >
                  {user?.name}
                </span>
                <i className="fas fa-caret-down ms-2"></i>
              </div>
            </button>
          }
          position="bottom right"
          nested
        >
          <div
            className="card bg-white p-0"
            style={{
              filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
              borderRadius: "10px",
            }}
          >
            <div className="card-body px-0">
              <ul className={`list-unstyled mb-0`}>
                <li>
                  <button
                    className="btn shadow-none"
                    onClick={() => {
                      navigate.push("/settings");
                    }}
                  >
                    <i className="fas fa-cog text-start"></i> Settings
                  </button>
                </li>
                <li>
                  <Popup
                    className="modalPopup"
                    trigger={
                      <button className="btn shadow-none">
                        <img
                          src="/icons/logout-black.svg"
                          alt="Logout"
                          className="me-1"
                        />
                        Logout
                      </button>
                    }
                    modal
                    closeOnDocumentClick={false}
                  >
                    {(close: Function) => (
                      <div
                        className="bg-white d-flex flex-column rounded text-center p-0 pt-3"
                        style={{
                          filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                        }}
                      >
                        <p className={`pb-3 mx-5 fw-bold ${style.logoutStyle}`}>
                          Are you sure you want to logout?
                        </p>
                        <div className="row border-top mx-0">
                          <div className="col-6 border-end">
                            <button
                              className={`btn px-2 py-3 w-100 shadow-none fw-bold color-dark-grey ${style.logoutStyle}`}
                              onClick={() => close()}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              className={`btn px-2 py-3 flex-fill color-blue shadow-none fw-bold ${style.logoutStyle}`}
                              onClick={() => {
                                handleLogout();
                              }}
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
              </ul>
            </div>
          </div>
        </Popup>
      </div>

      {showNotifications && (
        <div
          className="card position-absolute w-100 bg-white"
          style={{
            filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
            borderRadius: "10px",
            zIndex: 9,
            border: 0,
          }}
        >
          <div className="card-body p-0">
            <div className="d-flex justify-content-between align-items-center p-3 fw-bold">
              Notifications
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  clearNotifications && clearNotifications();
                }}
              >
                Clear All
              </button>
            </div>
            <div
              style={{
                maxHeight: "50vh",
                width: "100%",
                padding: "0px 20px",
              }}
              className="overflow-auto"
            >
              <Notifications />
            </div>
          </div>
        </div>
      )}

      {showMessages && (
        <div
          className="card position-absolute w-100 bg-white"
          style={{
            filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
            borderRadius: "10px",
            zIndex: 9,
            border: 0,
          }}
        >
          <div className="card-body p-0">
            <div className="d-flex justify-content-between align-items-center p-3 fw-bold">
              Messages
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  clearMessages && clearMessages();
                }}
              >
                Clear All
              </button>
            </div>
            <div
              style={{
                maxHeight: "50vh",
                width: "100%",
                padding: "0px 20px",
              }}
              className="overflow-auto"
            >
              <Messages />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
