import { ReactNode, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import style from "../Row.module.css";
import Loading from "../Loading";
import Popup from "../popup";

interface Props {
  border: boolean;
  favorites?: boolean;
  children: React.ReactNode;
  next: () => any;
  hasMore: boolean;
  loading?: boolean;
  items?: {
    display: ReactNode;
    action: Function;
  }[];
  isTimeLock?: boolean;
}

export default function Files({
  border,
  favorites,
  children,
  next,
  hasMore,
  loading,
  items,
  isTimeLock,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);


  return (
    <>
      <div
        className={`rounded  ${
          border && style.border
        } px-2 pb-2 overflow-lg-auto h-100 d-none d-lg-block`}
        id="scrollableDiv"
        ref={containerRef}
      >
        <table className="table">
          <thead
            className="color-dark-grey sticky-top bg-white"
            style={{
              zIndex: 1,
              fontSize: "12px",
            }}
          >
            <tr className="d-none d-lg-table-row ">
              <th>
                <Popup
                  trigger={
                    <button
                      className="btn btn-transparent color-dark-grey shadow-none p-0"
                      type="button"
                    >
                      <i className="fas fa-ellipsis-v"></i>
                    </button>
                  }
                  position="left center"
                  nested
                >
                  {(close: Function) => (
                    <div
                      className="card bg-white p-0"
                      style={{
                        filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="card-body px-0">
                        <ul className={`list-unstyled mb-0 ${style.menu}`}>
                          {items?.map((item, i) => (
                            <li key={i}>
                              <button
                                className="btn shadow-none px-3 "
                                onClick={() => {
                                  item.action();
                                  close();
                                }}
                              >
                                {item.display}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </Popup>
              </th>
              {favorites && (
                <th
                  className="fw-normal border-bottom d-none d-lg-table-cell"
                  style={{
                    width: "20px",
                  }}
                ></th>
              )}
              <th className="border-bottom pt-3 fw-normal">Name</th>
              {isTimeLock && (
                <th className="border-bottom pt-3 fw-normal">Release Date</th>
              )}
              <th className="border-bottom fw-normal">Last Edit</th>
              <th className="border-bottom fw-normal">Size</th>
              <th className="border-bottom fw-normal">Members</th>
              <th
                className="border-bottom fw-normal"
                style={{
                  width: "50px",
                }}
              ></th>
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td colSpan={favorites ? 6 : 5} align="center">
                {" "}
                <Loading />
              </td>
            </tr>
          ) : (
            <InfiniteScroll
              loader={
                <tr>
                  <td className="text-center" colSpan={favorites ? 6 : 5}>
                    <Loading />
                  </td>
                </tr>
              }
              useWindow={false}
              element="tbody"
              getScrollParent={() => containerRef.current}
              loadMore={next}
              initialLoad={true}
              hasMore={hasMore}
            >
              {children}
            </InfiniteScroll>
          )}
        </table>
      </div>
      <div
        className={`rounded  ${
          border && style.border
        } px-2 pb-2 overflow-lg-auto h-100 d-lg-none d-block`}
      >
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <InfiniteScroll
            loader={
              <div className="text-center">
                <Loading />
              </div>
            }
            loadMore={next}
            initialLoad={true}
            hasMore={hasMore}
            useWindow={true}
          >
            <table className="table">
              <tbody>{children}</tbody>
            </table>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}
