/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onFileByUser = /* GraphQL */ `
  subscription OnFileByUser($userId: ID!) {
    onFileByUser(userId: $userId) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onFileByUserByParent = /* GraphQL */ `
  subscription OnFileByUserByParent($userId: ID!, $parent: ID) {
    onFileByUserByParent(userId: $userId, parent: $parent) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onCreateByFile = /* GraphQL */ `
  subscription OnCreateByFile($parent: ID!) {
    onCreateByFile(parent: $parent) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onFileCommentCreate = /* GraphQL */ `
  subscription OnFileCommentCreate($fileId: ID!) {
    onFileCommentCreate(fileId: $fileId) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onFavoriteFile = /* GraphQL */ `
  subscription OnFavoriteFile($userId: ID!, $parent: ID) {
    onFavoriteFile(userId: $userId, parent: $parent) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onFavoriteFileByUser = /* GraphQL */ `
  subscription OnFavoriteFileByUser($userId: ID!) {
    onFavoriteFileByUser(userId: $userId) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onFavoriteByFile = /* GraphQL */ `
  subscription OnFavoriteByFile($parent: ID!) {
    onFavoriteByFile(parent: $parent) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onMessageCreate = /* GraphQL */ `
  subscription OnMessageCreate($userId: ID!) {
    onMessageCreate(userId: $userId) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onNotificationCreate = /* GraphQL */ `
  subscription OnNotificationCreate($userId: ID!) {
    onNotificationCreate(userId: $userId) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onSharedCreate = /* GraphQL */ `
  subscription OnSharedCreate($uploaderId: ID!, $parent: ID!) {
    onSharedCreate(uploaderId: $uploaderId, parent: $parent) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onCreateFileCronJob = /* GraphQL */ `
  subscription OnCreateFileCronJob(
    $filter: ModelSubscriptionFileCronJobFilterInput
  ) {
    onCreateFileCronJob(filter: $filter) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFileCronJob = /* GraphQL */ `
  subscription OnUpdateFileCronJob(
    $filter: ModelSubscriptionFileCronJobFilterInput
  ) {
    onUpdateFileCronJob(filter: $filter) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFileCronJob = /* GraphQL */ `
  subscription OnDeleteFileCronJob(
    $filter: ModelSubscriptionFileCronJobFilterInput
  ) {
    onDeleteFileCronJob(filter: $filter) {
      id
      email
      callTime
      cronSyntax
      percentage
      planId
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile(
    $filter: ModelSubscriptionFileFilterInput
    $userId: String
  ) {
    onCreateFile(filter: $filter, userId: $userId) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile(
    $filter: ModelSubscriptionFileFilterInput
    $userId: String
  ) {
    onUpdateFile(filter: $filter, userId: $userId) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile(
    $filter: ModelSubscriptionFileFilterInput
    $userId: String
  ) {
    onDeleteFile(filter: $filter, userId: $userId) {
      id
      name
      type
      size
      userId
      uploaderId
      uploader {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      parent
      parentDet {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      modified
      created
      children {
        nextToken
        __typename
      }
      root
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      sharedCount
      lastUploadType
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      activeDate
      folderOrFile
      groups
      __typename
    }
  }
`;
export const onCreateVersion = /* GraphQL */ `
  subscription OnCreateVersion($filter: ModelSubscriptionVersionFilterInput) {
    onCreateVersion(filter: $filter) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const onUpdateVersion = /* GraphQL */ `
  subscription OnUpdateVersion($filter: ModelSubscriptionVersionFilterInput) {
    onUpdateVersion(filter: $filter) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const onDeleteVersion = /* GraphQL */ `
  subscription OnDeleteVersion($filter: ModelSubscriptionVersionFilterInput) {
    onDeleteVersion(filter: $filter) {
      id
      fileId
      modified
      created
      hash
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      name
      __typename
    }
  }
`;
export const onCreateShared = /* GraphQL */ `
  subscription OnCreateShared(
    $filter: ModelSubscriptionSharedFilterInput
    $fileOwner: String
    $userId: String
  ) {
    onCreateShared(filter: $filter, fileOwner: $fileOwner, userId: $userId) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const onUpdateShared = /* GraphQL */ `
  subscription OnUpdateShared(
    $filter: ModelSubscriptionSharedFilterInput
    $fileOwner: String
    $userId: String
  ) {
    onUpdateShared(filter: $filter, fileOwner: $fileOwner, userId: $userId) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const onDeleteShared = /* GraphQL */ `
  subscription OnDeleteShared(
    $filter: ModelSubscriptionSharedFilterInput
    $fileOwner: String
    $userId: String
  ) {
    onDeleteShared(filter: $filter, fileOwner: $fileOwner, userId: $userId) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      created
      email
      view
      archived
      count
      relationship
      name
      activeDate
      fileOwner
      groups
      parentShared
      parentDet {
        id
        fileId
        userId
        modified
        created
        email
        view
        archived
        count
        relationship
        name
        activeDate
        fileOwner
        groups
        parentShared
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const onCreateTimelockShared = /* GraphQL */ `
  subscription OnCreateTimelockShared(
    $filter: ModelSubscriptionTimelockSharedFilterInput
    $fileOwner: String
  ) {
    onCreateTimelockShared(filter: $filter, fileOwner: $fileOwner) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const onUpdateTimelockShared = /* GraphQL */ `
  subscription OnUpdateTimelockShared(
    $filter: ModelSubscriptionTimelockSharedFilterInput
    $fileOwner: String
  ) {
    onUpdateTimelockShared(filter: $filter, fileOwner: $fileOwner) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const onDeleteTimelockShared = /* GraphQL */ `
  subscription OnDeleteTimelockShared(
    $filter: ModelSubscriptionTimelockSharedFilterInput
    $fileOwner: String
  ) {
    onDeleteTimelockShared(filter: $filter, fileOwner: $fileOwner) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      fileOwner
      schedulerId
      parentId
      modified
      created
      email
      view
      name
      relationship
      __typename
    }
  }
`;
export const onCreateFavorite = /* GraphQL */ `
  subscription OnCreateFavorite(
    $filter: ModelSubscriptionFavoriteFilterInput
    $userId: String
  ) {
    onCreateFavorite(filter: $filter, userId: $userId) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const onUpdateFavorite = /* GraphQL */ `
  subscription OnUpdateFavorite(
    $filter: ModelSubscriptionFavoriteFilterInput
    $userId: String
  ) {
    onUpdateFavorite(filter: $filter, userId: $userId) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const onDeleteFavorite = /* GraphQL */ `
  subscription OnDeleteFavorite(
    $filter: ModelSubscriptionFavoriteFilterInput
    $userId: String
  ) {
    onDeleteFavorite(filter: $filter, userId: $userId) {
      id
      fileId
      userId
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      modified
      parentFav
      created
      archived
      parentDet {
        id
        fileId
        userId
        modified
        parentFav
        created
        archived
        parent
        __typename
      }
      parent
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onCreateUser(filter: $filter, id: $id) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onUpdateUser(filter: $filter, id: $id) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onDeleteUser(filter: $filter, id: $id) {
      id
      name
      email
      timezone
      storageName
      lastTrackedActivity
      files {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      favorites {
        nextToken
        __typename
      }
      versions {
        nextToken
        __typename
      }
      shared {
        nextToken
        __typename
      }
      timelockShared {
        nextToken
        __typename
      }
      imageCount
      imageSize
      mediaCount
      mediaSize
      documentCount
      documentSize
      otherCount
      otherSize
      plan {
        id
        name
        size
        stripeId
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      planId
      notifyAbout
      emailNotifications
      messages {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      stripeId
      subscriptionId
      subscriptionStatus
      dueDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan($filter: ModelSubscriptionPlanFilterInput) {
    onCreatePlan(filter: $filter) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan($filter: ModelSubscriptionPlanFilterInput) {
    onUpdatePlan(filter: $filter) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan($filter: ModelSubscriptionPlanFilterInput) {
    onDeletePlan(filter: $filter) {
      id
      name
      size
      user {
        nextToken
        __typename
      }
      stripeId
      stripePriceId
      cronJobId {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCancellation = /* GraphQL */ `
  subscription OnCreateCancellation(
    $filter: ModelSubscriptionCancellationFilterInput
  ) {
    onCreateCancellation(filter: $filter) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCancellation = /* GraphQL */ `
  subscription OnUpdateCancellation(
    $filter: ModelSubscriptionCancellationFilterInput
  ) {
    onUpdateCancellation(filter: $filter) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCancellation = /* GraphQL */ `
  subscription OnDeleteCancellation(
    $filter: ModelSubscriptionCancellationFilterInput
  ) {
    onDeleteCancellation(filter: $filter) {
      id
      reason
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity($filter: ModelSubscriptionActivityFilterInput) {
    onCreateActivity(filter: $filter) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity($filter: ModelSubscriptionActivityFilterInput) {
    onUpdateActivity(filter: $filter) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity($filter: ModelSubscriptionActivityFilterInput) {
    onDeleteActivity(filter: $filter) {
      id
      parameters
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      type
      updatedAt
      createdAt
      notifications {
        nextToken
        __typename
      }
      notify
      groups
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      activityId
      activity {
        id
        parameters
        fileId
        userId
        type
        updatedAt
        createdAt
        notify
        groups
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $userId: String
  ) {
    onCreateComment(filter: $filter, userId: $userId) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $userId: String
  ) {
    onUpdateComment(filter: $filter, userId: $userId) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment(
    $filter: ModelSubscriptionCommentFilterInput
    $userId: String
  ) {
    onDeleteComment(filter: $filter, userId: $userId) {
      id
      content
      file {
        id
        name
        type
        size
        userId
        uploaderId
        parent
        modified
        created
        root
        sharedCount
        lastUploadType
        activeDate
        folderOrFile
        groups
        __typename
      }
      fileId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      userId
      updatedAt
      createdAt
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      commentId
      comment {
        id
        content
        fileId
        userId
        updatedAt
        createdAt
        __typename
      }
      userId
      user {
        id
        name
        email
        timezone
        storageName
        lastTrackedActivity
        imageCount
        imageSize
        mediaCount
        mediaSize
        documentCount
        documentSize
        otherCount
        otherSize
        planId
        notifyAbout
        emailNotifications
        stripeId
        subscriptionId
        subscriptionStatus
        dueDate
        createdAt
        updatedAt
        __typename
      }
      read
      updatedAt
      createdAt
      __typename
    }
  }
`;
