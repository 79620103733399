import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loading from "../Platform/Components/Loading";
import Layout from "./layout";
import { PostConfirm as PostConfirmService } from "../Services/Authenticate";
import style from "./layout.module.css";
import { Login as LoginService } from "../Services/Authenticate";

export default function PostConfirms() {
  const location = useLocation();
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get("client_id") ?? "";
    const userName = queryParams.get("user_name") ?? "";
    const codeParameter = queryParams.get("confirmation_code") ?? "";
    PostConfirmService({ clientId, userName, codeParameter }).then((res) => {
      if (res.success) {
        const ptcm = localStorage.getItem("ptcm");
        if (ptcm) {
          const data = JSON.parse(atob(ptcm));
          LoginService(data).then((result) => {
            localStorage.removeItem("ptcm");
            if (result.success) {
              if (
                !!result.pay
              ) {
                history.push("/payment")
              }
              else {
                history.push("/platform");
              }
            } else {
              setConfirmed(true);
            }
          });
        }
        else {
          setConfirmed(true);
        }
      } else {
        localStorage.removeItem("ptcm");
        setError(true);
      }
    });
  }, []);
  return (
    <Layout>
      <div className={`d-flex justify-content-center align-items-center ${style.cover}`}>
        {!confirmed && !error ? <Loading /> : <></>}
        {confirmed ? (
          <div className={`text-center ${style.form2}`}>
            Your account has been confirmed, return to{" "}
            <Link to="/login" className="d-inline">
              login
            </Link>{" "}
            page
          </div>
        ) : (
          <></>
        )}
        {error ? (
          <div className={`text-center ${style.form2}`}>
            There was an error verifying your account
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
}
