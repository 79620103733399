import * as React from "react";

const NewFolderSVG = ({
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (

    <svg viewBox="0 0 16.154 14.615" width="100%" height="100%">
        <g id="Folder_Add_ou-lc" data-name="Folder Add ou-lc">
            <path id="Path_22011"
                data-name="Path 22011"
                d="M18.846,15.769a.769.769,0,1,0-1.538,0v1.538H15.769a.769.769,0,1,0,0,1.538h1.538v1.538a.769.769,0,1,0,1.538,0V18.846h1.538a.769.769,0,1,0,0-1.538H18.846Z"
                transform="translate(-5 -6.538)"
                fill={color} />
            <path id="Path_22012"
                data-name="Path 22012"
                d="M7.968,4a3.639,3.639,0,0,1,.857.064,2.308,2.308,0,0,1,.667.276,3.641,3.641,0,0,1,.651.561l.637.638h3.4c.405,0,.755,0,1.043.024a2.35,2.35,0,0,1,.9.228A2.307,2.307,0,0,1,17.133,6.8a2.35,2.35,0,0,1,.228.9c.024.288.024.638.024,1.043v1.568a.955.955,0,0,1-.042.405.384.384,0,0,1-.168.168.956.956,0,0,1-.405.042H15.077a4.615,4.615,0,0,0-4.615,4.615v.154a.956.956,0,0,1-.042.405.384.384,0,0,1-.168.168.956.956,0,0,1-.405.042H5.2c-.405,0-.755,0-1.043-.024a2.349,2.349,0,0,1-.9-.228,2.308,2.308,0,0,1-1.009-1.008,2.35,2.35,0,0,1-.228-.9C2,13.862,2,13.512,2,13.107V7.2c0-.405,0-.755.024-1.043a2.35,2.35,0,0,1,.228-.9A2.308,2.308,0,0,1,3.26,4.252a2.35,2.35,0,0,1,.9-.228C4.446,4,4.8,4,5.2,4Z"
                transform="translate(-2 -4)"
                fill={color} />
        </g>
    </svg>    
);

export default NewFolderSVG