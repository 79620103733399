import Popup from "../../Platform/Components/popup";
import style from "./navmenu.module.css";
import { useState } from "react";
import CloseButton from "../../Platform/Components/closeButton";

export default function Privacy() {
  const [preview, setPreview] = useState(false);
  return (
    <>
      <button
        className="color-black btn btn-none p-0 text-small "
        style={{
          fontSize: "14px",
        }}
        onClick={() => setPreview(true)}
      >
        Privacy policy
      </button>
      <Popup
        className="modalPopup"
        open={preview}
        closeOnDocumentClick
        onClose={() => {
          setPreview(false);
        }}
      >
        <div
          className="bg-white overflow-auto"
          style={{
            borderRadius: "40px",
            padding: "10px",
            height: "90vh",
            maxHeight: "90vh",
            width: "75vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <div
            style={{
              overflow: "auto",
              height: "100%",
              fontFamily: "New-Times",
            }}
            className=" ps-3 pe-0 ps-lg-5 pe-lg-3 py-5"
          >
            <h4>Inkmark Privacy Policy</h4>
            <h4>Effective: September 2023</h4>
            <p>
              We appreciate you using Inkmark! We describe here how we collect,
              use, and handle your personal data when you use our websites,
              software, and services (“Services”). We respect your privacy and
              are committed to protecting your personal data. This policy
              explains how we look after that data when you visit our website
              and tells you about your rights and how the law protects you.
            </p>
            <p>
              We collect and use the following information to provide, improve,
              protect, and promote our Services. We also use cookies, the use of
              which is dealt with in a separate policy also available on our
              website.
            </p>
            <p>
              <u>
                <em>
                  <strong>Important information.</strong>
                </em>
              </u>
              This policy aims to give you information on how we collect and
              process personal data through our website. The website is not
              intended for children and we do not knowingly collect data
              relating to children. It is important that you read this policy
              together with any other privacy notice or fair use or processing
              policy we may provide on specific occasions when we are collecting
              or processing personal data, so that you are fully aware how it is
              being used. This policy supplements other notices and is not
              intended to override them.
            </p>
            <p>
              <em>
                <strong>Account information.</strong>
              </em>
               We collect, and associate with your account, the information you
              provide to us when you do things such as sign up for your account,
              upgrade to a paid plan, and set up two-factor authentication (like
              your name, email address, phone number, payment info, and physical
              address).
            </p>
            <p>
              <em>
                <strong>Your Data.</strong>
              </em>
               Our Services are designed as a simple and personalised way for
              you to store your files, documents, photos, comments, messages,
              and so on (“Your Data”), collaborate with others, and work across
              multiple devices. To make that possible, we store, process, and
              transmit Your Data as well as information related to it. This
              related information includes your profile information that makes
              it easier to collaborate and share Your Data with others, as well
              as things like the size of the file, the time it was uploaded,
              collaborators, and usage activity.
            </p>
            <p>
              <em>
                <strong>Contacts.</strong>
              </em>
              You may choose to give us access to your contacts to make it easy
              for you, and your Inkmark Business team (collectively, “Inkmark
              Business Team”), if you’re a Inkmark Business Team user, to do
              things like share and collaborate on Your Data, send messages, and
              invite others to use the Services. If you do, we’ll store those
              contacts on our servers.
            </p>
            <p>
              {" "}
              <em>
                <strong>Usage information.</strong>
              </em>
               We collect information related to how you use the Services,
              including actions you take in your account (like sharing, editing,
              viewing, creating and moving files or folders). We use this
              information to provide, improve, and promote our Services, and
              protect Inkmark users.
            </p>
            <p>
              <em>
                <strong>Device information.</strong>
              </em>
               We also collect information from and about the devices you use to
              access the Services. This includes things like IP addresses, the
              type of browser and device you use, the web page you visited
              before coming to our sites, and identifiers associated with your
              devices. Your devices (depending on their settings) may also
              transmit location information to the Services. For example, we use
              device information to detect abuse and identify and troubleshoot
              bugs.
            </p>
            <p>
              {" "}
              <em>
                <strong>Cookies and other technologies.</strong>
              </em>{" "}
               We use technologies like cookies and pixel tags to provide,
              improve, protect, and promote our Services. For example, cookies
              help us with things like remembering your username for your next
              visit, understanding how you are interacting with our Services,
              and improving them based on that information. You can set your
              browser to not accept cookies, but this may limit your ability to
              use the Services. We may also use third-party service providers
              that set cookies and similar technologies to promote Inkmark
              services. You can learn more about how cookies and similar
              technologies work, as well as how to opt out of the use of them
              for advertising purposes.
            </p>
            <p>
              {" "}
              <em>
                <strong>Marketing. </strong>
              </em>{" "}
              We give users the option to use some of our Services on a trial
              free of charge. These free Services are made possible by the fact
              that some users upgrade to one of our paid Services. If you
              register for our Services, we will, from time to time, send you
              information about upgrades when permissible. Users who receive
              these marketing materials can opt out at any time. If you don’t
              want to receive a particular type of marketing material from us,
              click the ‘unsubscribe’ link in the corresponding emails, or
              update your preferences in the Notifications section of your
              personal account.
            </p>
            <p>
              {" "}
              We sometimes contact people who don’t have a Inkmark account. For
              recipients in the EU, we or a third party will obtain consent
              before reaching out. If you receive an email and no longer wish to
              be contacted by Inkmark, you can unsubscribe and remove yourself
              from our contact list via the message itself.
            </p>
            <p>
              <em>
                <strong>Bases for processing your data. </strong>
              </em>{" "}
               We collect and use the personal data described above in order to
              provide you with the Services in a reliable and secure manner. We
              also collect and use personal data for our legitimate business
              needs. To the extent we process your personal data for other
              purposes, we ask for your consent in advance or require that our
              partners obtain such consent.
            </p>
            <p>
              We may share information as discussed below, but we won’t sell it
              to advertisers or other third parties.
            </p>
            <p>
              {" "}
              <em>
                <strong>Others working for and with Inkmark. </strong>
              </em>{" "}
               Inkmark uses certain trusted third parties (for example,
              providers of customer support and IT services) for the business
              purposes of helping us provide, improve, protect, and promote our
              Services. These third parties will access your information to
              perform tasks on our behalf, and we’ll remain responsible for
              their handling of your information per our instructions.
            </p>
            <p>
              <em>
                <strong>Other Companies. </strong>
              </em>{" "}
               Inkmark shares infrastructure, systems, and technology with other
              companies, including third parties, to provide, improve, protect,
              and promote Inkmark Company Services. We process your information
              across the Inkmark Companies for these purposes, as permitted by
              applicable law and in accordance with their terms and policies.
            </p>
            <p>
              {" "}
              <em>
                <strong>Other users. </strong>
              </em>
              Our Services are designed to help you collaborate with others. If
              you register your Inkmark account with an email address on a
              domain owned by your employer or organisation or collaborate with
              other Inkmark users.
            </p>
            <p>
              Certain features let you make additional information available to
              others.
            </p>
            <p>
              {" "}
              <em>
                <strong>Law & Order and the Public Interest.</strong>
              </em>{" "}
               We may disclose your information to third parties if we determine
              that such disclosure is reasonably necessary to: (a) comply with
              any applicable law, regulation, legal process, or appropriate
              government request; (b) protect any person from death or serious
              bodily injury; (c) prevent fraud or abuse of Inkmark or our users;
              d) protect Inkmark’s rights, property, safety, or interest; or (e)
              perform a task carried out in the public interest. Stewardship of
              your data is critical to us and a responsibility that we take
              seriously. We believe that your data should receive the same legal
              protections regardless of whether it’s stored on our Services or
              on your home computer’s hard drive. When receiving, scrutinising,
              and responding to government requests (including national security
              requests) for your data, we intend to:
            </p>
            <ul>
              <li>Be transparent</li>
              <li>Fight blanket requests</li>
              <li>Protect all users, and</li>
              <li>Provide trusted services.</li>
            </ul>
            <p>
              <em>
                <strong>Security.</strong>
              </em>
               We have a team dedicated to keeping your information secure and
              testing for vulnerabilities. We continue to work on features to
              keep your information safe in addition to things like two-factor
              authentication, encryption of files at rest, and alerts when new
              devices and apps are linked to your account. We deploy automated
              technologies to detect abusive behaviour and content that may harm
              our Services, you, or other users.
            </p>
            <p>
              <em>
                <strong>User Controls.</strong>
              </em>
               You can access, amend, and download, and delete your personal
              information by logging into your Inkmark account and going to
              your account settings page.
            </p>
            <p>
              <em>
                <strong>Retention.</strong>
              </em>
              When you sign up for an account with us, we’ll retain information
              you store on our Services for as long as your account exists or as
              long as we need it to provide you the Services. If you delete your
              account, we’ll initiate deletion of this information after 30
              days. (1) there might be some latency in deleting this information
              from our servers and back-up storage; and (2) we may retain this
              information if necessary to comply with our legal obligations,
              resolve disputes, or enforce our agreements.
            </p>
            <p>
              {" "}
              <em>
                <strong>Around the world.</strong>
              </em>
               To provide you with the Services, we may store, process, and
              transmit data in the United Kingdom and locations around the
              world—including those outside your country. Data may also be
              stored locally on the devices you use to access the Services and
              you should be aware of the risks associated with accessing our
              Services from a device that is not your own.
            </p>
            <p>
              <em>
                <strong>Data Transfers.</strong>
              </em>
               When transferring data from the United Kingdom, the European
              Economic Area, the European Union, and Switzerland, Inkmark relies
              upon a variety of legal mechanisms, such as contracts with our
              customers and affiliates, Standard Contractual Clauses, and the
              European Commission's adequacy decisions about certain countries,
              as applicable.
            </p>
            <p>
              You have control over your personal data and how it’s collected,
              used, and shared. For example, you can:
            </p>
            <ul>
              <li>
                Delete Your Data in your Inkmark account. You can learn more
                about how to delete files saved on Inkmark.
              </li>
              <li>
                Change or correct personal data. You can manage your account and
                the content contained in it, as well as edit some of your
                personal data, through your account settings page.
              </li>
              <li>
                Access and take your data elsewhere. You can access your
                personal data from your Inkmark account, and you can download a
                copy of your Data in a machine-readable format.
              </li>
              <li>
                Object to the processing of your personal data. Depending on the
                processing activity, you can request that we stop or limit
                processing of your personal data.
              </li>
            </ul>
            <p>
              If you would like to submit a data access request or object to the
              processing of your personal data, please email us at 
              <a href="mailto:info@Inkmark.com?subject=Privacy%20query">
                privacy@Inkmark.io
              </a>{" "}
              To request that your personal data be deleted, please email us at 
              <a href="mailto:info@Inkmark.com?subject=Deletion%20query">
                info@Inkmark.com
              </a>{" "}
              If we’re involved in a reorganization, merger, acquisition, or
              sale of our assets, your data may be transferred as part of that
              deal. We’ll notify you (for example, via a message to the email
              address associated with your account) of any such deal and outline
              your choices in that event.
            </p>
            <p>
              We may revise this Privacy Policy from time to time and will post
              the most current version on our website. If a revision
              meaningfully reduces your rights, we will notify you.
            </p>
            <p>
              Have questions or concerns about Inkmark, our Services, and
              privacy? Contact our team at{" "}
              <a href="mailto:info@Inkmark.com">info@Inkmark.com</a>. If they
              can’t answer your question, you have the right to contact your
              local data protection supervisory authority.
            </p>
            <p>
              {" "}
              The table below summaries the type of personal information that we
              may receive and how we may use it:
            </p>

            <div className="table-responsive">
              <table className=" table table-sm ">
                <thead className="">
                  <tr>
                    <th scope="col">Category of Personal Information</th>
                    <th scope="col">Source</th>
                    <th scope="col">How Used</th>
                    <th scope="col">Business disclosure</th>
                  </tr>
                </thead>
                <tbody className={style.custom_table}>
                  <tr>
                    <td>
                      <p>Contact information such as</p>
                      <p>name, email, address, phone</p>
                      <p> number</p>
                    </td>
                    <td>you</td>
                    <td>
                      <p>to provide the Services</p>
                      <p>communicate with you</p>

                      <p>
                        <p>improve Services,ensure security</p>
                        <p>prevent fraud, comply with law</p>
                      </p>
                    </td>
                    <td>
                      <p>service providers</p>
                      <p>communicate with you</p>
                      <p>law enforcement</p>
                      <p>with your consent</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>Log in information</p>
                    </td>
                    <td>you</td>
                    <td>
                      <p>provide the Services,security and </p>

                      <p>fraud prevention </p>
                      <p>compliance & law</p>
                    </td>
                    <td>
                      <p>service providers</p>
                      <p>law enforcement </p>
                      <p>with your consent</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>Financial eg billing</p>
                      <p> bank details</p>
                    </td>
                    <td>
                      <p>you</p>
                      <p>
                        {" "}
                        3<sup>rd</sup> pty
                      </p>
                    </td>
                    <td>
                      <p>provide the Services</p>
                      <p>prevent unlawful access</p>

                      <p>comply with law</p>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>
                      <p>User generated content</p>
                      <p>including feedback</p>
                    </td>
                    <td>
                      <p>you</p>
                      <p>others that</p>
                      <p> refer to you</p>
                    </td>
                    <td>
                      <p>provide the Services</p>
                      <p>communicate with you</p>
                    </td>
                    <td>
                      <p>service providers</p>
                      <p>law enforcement</p>

                      <p>with your consent</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>
                        <p>Device & online identifier</p>
                        <p>info,browser type, operating</p>
                      </p>
                      <p>
                        <p> system geolocation from IP</p>
                        <p>Service usage information</p>
                        <p>(when and how accessed)</p>
                      </p>
                    </td>
                    <td>
                      <p>you,third party</p>

                      <p>analytics</p>
                      <p>providers</p>
                    </td>
                    <td>
                      <p>personalisation </p>
                      <p>security prevention</p>
                    </td>
                    <td>
                      <p>service providers</p>
                      <p>law enforcement</p>

                      <p>with your consent</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}
