import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DropFiles from "../../Components/Dropfiles";
import FileTable from "../../Components/TableRows/Table";
import style from "../timeLock.module.css";
import Sharing from "./Sharing";
import Inactive from "./Inactive";
import {
  FileData,
  member,
  Create as CreateModel,
} from "../../../Models/Document";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import {
  batchUnlockService,
  Create,
  GetNextFolder,
} from "../../../Services/Time-Lock";
import { TimelockRow } from "../../Components/TableRows";
import DesktopUpload from "../../Components/DesktopUpload";
import { DownloadFiles, Preview } from "../../../Services/Document";
import Popup from "../../Components/popup";
import { useOverlay } from "../../../Context/OverlayContext";
import Previewer from "../../Components/Preview";
import { FileWithChecked } from "../../Home/home";
import { useAlert } from "react-alert";
import CloseButton from "../../Components/closeButton";
import { IoArrowBackCircle } from "react-icons/io5";

interface props {
  files: FileWithChecked[];
  id: string;
  members: member[];
  folderChildrenIds?: [];
  date: Date;
  handleDateChange: Function;
  refresh: Function;
  createFile: Function;
  token: string;
  onNext: (files: FileWithChecked[], token: string) => any;
  rename: Function;
  loadingFiles: boolean;
  breadcrumbs: any[];
  name: string;
  onClickChecked: Function;
  onUnlock: Function;
  disabled?: boolean;
}

export default function Folder({
  files,
  id,
  date,
  handleDateChange,
  onUnlock,
  members,
  folderChildrenIds,
  refresh,
  createFile,
  token,
  onNext,
  rename,
  loadingFiles,
  breadcrumbs,
  name,
  onClickChecked,
  disabled,
}: props) {
  const [createFolder, setCreateFolder] = useState(false);
  const [folderCreating, setFolderCreating] = useState(false);
  const history = useHistory();
  const [previewFile, setPreviewFile] = useState("");
  const [preview, setPreview] = useState(false);
  const [firstId, setFirstId] = useState(null);
  const [prevId, setPrevId] = useState("/time-lock");
  const [fileType, setFileType] = useState("");
  const { setLoading } = useOverlay();
  const alert = useAlert();

  const previewImage = (file: FileData) => {
    const fileType = () => {
      let filesplit = file.name.split(".");
      return filesplit[filesplit.length - 1];
    };
    setLoading(true);
    Preview(file.id)
      .then((value) => {
        if (value?.success && value.data) {
          setPreviewFile(value.data);
          setPreview(true);
          setFileType(fileType());
        }
      })
      .finally(() => setLoading(false));
  };

  const handleKeyDown = (event: any, file: FileData) => {
    event.stopPropagation();
    switch (event.code) {
      case "ArrowUp":
        event.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        event.target.nextElementSibling?.focus();
        break;
      case "Space":
        previewImage(file);
        break;
      case "Enter":
        clickFile(file);
        break;
      default:
        break;
    }
  };

  const handleFolderKeyEvent = async (e: any) => {
    if (e.keyCode === 27) {
      setCreateFolder && setCreateFolder(false);
    } else if (e.keyCode === 13) {
      setFolderCreating(true);
      let document: CreateModel = {
        name: e.target.value,
        type: "folder",
        parent: id,
        size: 0,
        id: uuid(),
        uploadUrl: undefined,
      };
      await Create(document);
      setCreateFolder && setCreateFolder(false);
      setFolderCreating(false);
    }
  };

  const clickFile = async (file: FileData) => {
    if (file.type === "folder") {
      history.push("/time-lock/folder/" + file.id);
    } else {
      history.push("/time-lock/file/" + file.id);
    }
  };

  breadcrumbs.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });

  useEffect(() => {
    breadcrumbs.map((x, index) => {
      if (breadcrumbs.length === 1) {
        setPrevId(x.id);
      }
      if (breadcrumbs.length === 2) {
        setPrevId(x.id);
      }
      if (breadcrumbs.length === 3) {
        setPrevId(x.id);
      }
    });

    if (breadcrumbs.length === 0) {
      setPrevId("/time-lock");
    }
  }, [breadcrumbs]);

  return (
    <section className="h-100 d-flex">
      <div className="d-flex flex-column h-100 flex-fill pb-lg-3">
        <DropFiles
          callback={createFile}
          parent={id}
          locked={true}
          url="time-lock"
          uploadUrl="time-lock"
        />
        <div className="mt-4 d-flex justify-content-between flex-column-reverse flex-lg-row align-items-lg-start">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3">
              <li className="breadcrumb-item">
                <Link to={`${prevId}`}>
                  <IoArrowBackCircle
                    style={{ width: "30px", height: "30px" }}
                  />
                </Link>
                <Link
                  className="text-decoration-none color-dark-grey"
                  to="/time-lock"
                >
                  TimeLock
                </Link>
              </li>
              {breadcrumbs.length === 3 &&
                !breadcrumbs.some((item) => item.id === firstId) && (
                  <li className="breadcrumb-item">
                    {" "}
                    <i className="fas fa-ellipsis-h"></i>{" "}
                  </li>
                )}
              {breadcrumbs.map((x) => (
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none color-dark-grey"
                    to={"/time-lock/folder/" + x.id}
                  >
                    {x.name}
                  </Link>
                </li>
              ))}
              <li className="breadcrumb-item">{name}</li>
            </ol>
          </nav>
          <DesktopUpload
            callback={createFile}
            parent={id}
            onCreateFolder={() => setCreateFolder(true)}
            locked={true}
            url="time-lock"
            uploadUrl="time-lock"
            disableMulti={
              files.filter((x) => x.checked).map((x) => x.id).length <= 0
            }
          />
        </div>
        <div className={`mb-lg-3 mb-0 ${style.table}`}>
          <FileTable
            border={files.length !== 0}
            hasMore={!!token}
            next={() => {
              if (!!token)
                GetNextFolder(id, token).then((values) => {
                  if (values) onNext(values.files, values.token);
                });
            }}
            loading={loadingFiles}
            isTimeLock
            items={[
              {
                display: (
                  <>
                    {files.filter((file) => file.checked).length !==
                    files.length ? (
                      <>
                        <i className="fas fa-check me-1"></i>
                        Select All
                      </>
                    ) : (
                      <>
                        <i className="far fa-window-close me-1"></i>
                        Deselect All
                      </>
                    )}
                  </>
                ),
                action: () => {
                  if (
                    files.filter((file) => file.checked).length == files.length
                  ) {
                    for (let file of files) {
                      onClickChecked(file.id, false);
                    }
                  } else {
                    for (let file of files) {
                      onClickChecked(file.id, true);
                    }
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-lock-open me-2"></i>
                    <span className="me-4">Lock File</span>
                  </>
                ),
                action: () => {
                  if (
                    files.filter((x) => x.checked).map((x) => x.id).length > 0
                  ) {
                    setLoading(true);
                    batchUnlockService(
                      files.filter((x) => x.checked).map((x) => x.id)
                    ).finally(() => {
                      refresh();
                      setLoading(false);
                    });
                  } else {
                    alert.info("No file has been selected", { timeout: 5000 });
                  }
                },
              },
              {
                display: (
                  <>
                    <i className="fas fa-file-download me-1"></i>
                    Download
                  </>
                ),
                action: async () => {
                  setLoading(true);
                  await DownloadFiles(
                    files.filter((x) => x.checked).map((x) => x.id)
                  ).finally(() => {
                    setLoading(false);
                  });
                },
              },
            ]}
          >
            {createFolder && (
              <tr>
                <td colSpan={7}>
                  <div
                    className={`d-flex w-100 ${
                      folderCreating && style.disabled
                    }`}
                  >
                    <img
                      src={`/logos/folder.png`}
                      alt={`folder logo`}
                      className="img-fluid"
                    />
                    <input
                      type="text"
                      className="form-control ms-2 shadow-none d-inline flex-fill"
                      onClick={(e: any) => e.stopPropagation()}
                      onKeyDown={handleFolderKeyEvent}
                      autoFocus
                      disabled={folderCreating}
                    />
                  </div>
                </td>
              </tr>
            )}

            {files.map((file, i) => (
              <TimelockRow
                onUnlock={onUnlock}
                fileData={file}
                onClick={() => clickFile(file)}
                key={file.id}
                onRename={(value: string) => {
                  rename(file.id, value);
                }}
                tabIndex={i}
                handleKeyDown={(e) => handleKeyDown(e, file)}
                previewImage={() => previewImage(file)}
                onClickChecked={(val: boolean) => onClickChecked(file.id, val)}
              />
            ))}
          </FileTable>
        </div>
      </div>

      <div
        className="ms-3 mb-lg-3 d-none d-lg-block"
        style={{
          minWidth: "450px",
          width: "35%",
        }}
      >
        <section className="h-100 d-flex flex-column">
          <div className={`${style.selectedCard} p-3 overflow-auto bg-white`}>
            <Sharing
              fileId={id}
              members={members}
              refresh={refresh}
              folderChildrenIds={folderChildrenIds}
            />
          </div>
          <div className={`${style.selectedCard} p-3 mt-3 bg-white`}>
            <Inactive
              date={date}
              handleChange={handleDateChange}
              disabled={disabled}
            />
          </div>
        </section>
      </div>
      <Popup
        open={preview}
        closeOnDocumentClick
        className="modalPopup"
        onClose={() => setPreview(false)}
      >
        <div
          style={{
            height: "90vh",
            width: "80vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <Previewer src={previewFile} fileName={fileType} autoPlay={true} />
        </div>
      </Popup>
    </section>
  );
}
