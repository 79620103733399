import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import Popup from "../popup";
import { FileData } from "../../../Models/Document";
import style from "../Row.module.css";
import Avatar from "../avatar";
import { FileWithChecked } from "../../Home/home";
import { ChangeDate } from "../../../Services/Time-Lock";
import { useOverlay } from "../../../Context/OverlayContext";
import AsyncModifiedDate from "./AsyncModifiedDate";
import DoubleClick from "./DoubleClick";

interface Props {
  fileData: FileWithChecked;
  onClick: Function;
  onRename: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
  onClickChecked: Function;
  onUnlock: Function;
}
const splitName = (name: string) => {
  let pos = name.lastIndexOf(".");
  if (pos === -1) {
    return {
      name: name,
      extension: "",
    };
  } else {
    let nameOnly = name.substring(0, pos);
    let extensionOnly = name.substring(pos);
    return {
      name: nameOnly,
      extension: extensionOnly,
    };
  }
};
const FileRow: React.FC<Props> = ({
  fileData,
  onClick,
  onRename,
  handleKeyDown,
  tabIndex,
  previewImage,
  onClickChecked,
  onUnlock,
}) => {
  const [rename, setRename] = useState(false);
  const [action, setAction] = useState(false);
  const [file, setFile] = useState<FileWithChecked>(fileData);
  const { setLoading } = useOverlay();
  const timeoutRef = useRef<any>();
  const [lastClickTime, setLastClickTime] = useState(0);
  const [singleClickTimeout, setSingleClickTimeout] = useState<number | null>(
    null
  );

  useEffect(() => {
    setFile(fileData);
  }, [fileData]);

  const renameClick = async (event: any) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      setAction(true);
      await onRename(
        event.currentTarget.value + splitName(file.name).extension
      );
      setRename(false);
      setAction(false);
    } else if (event.key === "Escape") {
      setRename(false);
    }
  };

  const unlock = async () => {
    setLoading(true);
    await ChangeDate(new Date(), fileData.id);
    onUnlock(fileData.id);
    setLoading(false);
  };

  const isVideoFile = (file: any) => {
    return file.type === "media";
  };
  return (
    <tr
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td className="align-middle">
        <input
          type="checkbox"
          onChange={(e) => onClickChecked(e.currentTarget.checked)}
          checked={file.checked}
          className="form-check-input"
        />
      </td>
      <td
        onClick={() => {
          DoubleClick({
            previewImage,
            rename,
            timeoutRef,
            onClick,
            file,
            lastClickTime,
            setLastClickTime,
            singleClickTimeout,
            setSingleClickTimeout,
          });
        }}
        className="align-middle "
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        <button
          className="d-flex btn align-items-center shadow-none px-0"
          // onClick={() => !rename && onClick(file.id)}
        >
          <span style={{ width: "32px" }}>
            <img
              src={`/logos/${file.type}.png`}
              alt={`${file.type} logo`}
              className="img-fluid"
            />
          </span>
          {rename ? (
            <>
              <input
                type="text"
                className="form-control ms-2 shadow-none d-none d-lg-inline"
                defaultValue={splitName(file.name).name}
                onClick={(e: any) => e.stopPropagation()}
                onKeyDown={renameClick}
                autoFocus
                disabled={action}
              />
              <span className="text-nowrap d-none d-lg-inline">
                {splitName(file.name).extension}
              </span>
            </>
          ) : (
            <span className="ms-2 d-none d-lg-inline">{file.name}</span>
          )}
        </button>
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        {rename ? (
          <div className="d-flex align-items-center">
            <input
              type="text"
              className="form-control shadow-none"
              defaultValue={splitName(file.name).name}
              onKeyPress={renameClick}
              autoFocus
              disabled={action}
            />
            <span className="text-nowrap">
              {splitName(file.name).extension}
            </span>
          </div>
        ) : (
          <>
            <span className={`text-center ${style.file}`}>{file.name}</span>
            <br />
            <span className={style.file2}>
              <span>
                <AsyncModifiedDate modified={file.modified} />
              </span>

              {/* {file.modified.toLocaleDateString()} */}
              <span className="color-dark-grey"> by {file.modifierName}</span>
            </span>
            {file.size}
            <br />
            <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
              {file.users &&
                file.users.map((user, i) => (
                  <Avatar name={user.name} image={user.id} key={user.id} />
                ))}
              {file.sharedCount
                ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
                : ""}
            </span>
          </>
        )}
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        {/* {file.activeDate?.toLocaleDateString()} */}
        <AsyncModifiedDate modified={file.activeDate} />
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          {/* {file.modified.toLocaleDateString()} */}
          <AsyncModifiedDate modified={file.modified} />
          <small className="color-dark-grey color-dark-grey">
            by {file.modifierName}
          </small>
        </div>
      </td>
      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="text-end">
        <Popup
          trigger={
            <button
              className="btn btn-transparent color-dark-grey shadow-none"
              type="button"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
          }
          position="left center"
          nested
        >
          {(close: Function) => (
            <div
              className="card bg-white p-0"
              style={{
                filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                borderRadius: "10px",
              }}
            >
              <div className="card-body px-0">
                <ul className={`list-unstyled mb-0 ${style.menu}`}>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        setRename(true);
                      }}
                    >
                      <img
                        src="/icons/Rename.png"
                        alt="move"
                        className="img-fluid"
                      />{" "}
                      Rename
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        previewImage();
                      }}
                    >
                      <i className="fas fa-play"></i> Preview
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn shadow-none"
                      onClick={() => {
                        close();
                        unlock();
                      }}
                    >
                      <i className="fas fa-lock-open" /> Unlock File
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popup>
      </td>
    </tr>
  );
};

export default FileRow;
