import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { DateTime } from "luxon";
import { Auth } from "aws-amplify";

interface AsyncModifiedTimeProps {
  modified: any; // Define the prop type for localTime
}

const changeTimeZone = async (localTime: any, userTimezone: string) => {
  const date = new Date(localTime);
  const rfc2822Date = date.toUTCString();

  const localDateTime = DateTime.fromRFC2822(rfc2822Date);
  const targetDateTime = localDateTime.setZone(userTimezone);

  const newDateTime = targetDateTime.toFormat("MM/dd/yyyy,h:mm:ss a");
  if (newDateTime === "Invalid DateTime") {
    return localTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return newDateTime.split(",")[1];
};

const AsyncModifiedTime: React.FC<AsyncModifiedTimeProps> = ({ modified }) => {
  const [modifiedDate, setModifiedDate] = useState("");
  const sharedValue = useContext(UserContext);
  const userTimezone = sharedValue.user?.locale || "";

  useEffect(() => {
    changeTimeZone(modified, userTimezone)
      .then((result) => {
        setModifiedDate(result);
      })
      .catch((error) => {
        console.error("Error fetching modified date:", error);
        setModifiedDate("");
      });
  }, [modified]);

  return <small>{modifiedDate}</small>;
};

export default AsyncModifiedTime;
