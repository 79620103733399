// import { Link } from "react-router-dom";
import { useAuth } from "../../Context/UserContext";
import GetFileSize from "../../Helper/FileSizeExtractor";
import Pie from "./PieChart";
import Progress from "./Progress";
import { Link, NavLink, useHistory } from "react-router-dom";

export default function StorageSummary() {
  const { summary } = useAuth();

  return (
    <div className="d-flex flex-column align-items-center">
      <div
        style={{
          height: "220px",
          width: "100%",
        }}
      >
        <Pie summary={summary} />
      </div>
      <NavLink to="/settings/subscription">
        <p
          className="mb-2 mt-4 font-weight-semibold"
          style={{
            fontSize: "24px",
          }}
        >
          Upgrade Plan
        </p>
      </NavLink>
      <Link to="/platform/image" className="w-100 btn shadow-none">
        <div className="d-flex mb-2">
          <img
            src="/logos/image.svg"
            alt="amount of images"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
          <div className="flex-fill ms-3">
            <h5 className="text-start">Images</h5>
            <Progress
              now={((summary?.image.size ?? 0) / (summary?.total ?? 1)) * 100}
              color="#3A77F3"
            />
            <div className="mb-0 d-flex justify-content-between">
              {summary?.image.count} File(s){" "}
              <span>{GetFileSize(summary?.image.size ?? 0, "image")}</span>{" "}
            </div>
          </div>
        </div>
      </Link>

      <Link to="/platform/document" className="w-100 btn shadow-none">
        <div className="d-flex  mb-2">
          <img
            src="/logos/document.svg"
            alt="amount of document files"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
          <div className="flex-fill ms-3">
            <h5 className="text-start">Documents</h5>
            <Progress
              now={
                ((summary?.document.size ?? 0) / (summary?.total ?? 1)) * 100
              }
              color="#FDA758"
            />
            <div className="mb-0 d-flex justify-content-between">
              {summary?.document.count} File(s){" "}
              <span>
                {GetFileSize(summary?.document.size ?? 0, "document")}
              </span>
            </div>
          </div>
        </div>
      </Link>

      <Link to="/platform/media" className="w-100 btn shadow-none">
        <div className="d-flex mb-2">
          <img
            src="/logos/media.svg"
            alt="amount of media typed files"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
          <div className="flex-fill ms-3">
            <h5 className="text-start">Media</h5>
            <Progress
              now={((summary?.media.size ?? 0) / (summary?.total ?? 1)) * 100}
              color="#AC9CFF"
            />
            <div className="mb-0 d-flex justify-content-between">
              {summary?.media.count} File(s){" "}
              <span>{GetFileSize(summary?.media.size ?? 0, "media")}</span>
            </div>
          </div>
        </div>
      </Link>

      <Link to="/platform/other" className="w-100 btn shadow-none">
        <div className="d-flex">
          <img
            src="/logos/other.svg"
            alt="amount of documents of unknown types"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
          <div className="flex-fill ms-3">
            <h5 className="text-start">Others</h5>
            <Progress
              now={((summary?.other.size ?? 0) / (summary?.total ?? 1)) * 100}
              color="#FCC62D"
            />
            <div className="mb-0 d-flex justify-content-between">
              {summary?.other.count} File(s){" "}
              <span>{GetFileSize(summary?.other.size ?? 0, "other")}</span>{" "}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
