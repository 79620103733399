import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "./layout";
import style from "./layout.module.css";
import { SendResetCode, ResetPassword as ResetService } from "../Services/Authenticate";
import { ResetPassword as Model } from "../Models/Authentication";
import { ErrorMessage } from "@hookform/error-message";
import CustomError from "../Helper/CustomErrorMessage";

export default function ResetPassword() {
    const { register, handleSubmit, formState: { errors, isSubmitting, isSubmitSuccessful }, setError } = useForm<Model>();
    const { email, code } = useParams<{ email: string, code: string }>();
    const history = useHistory()
    const submit = async (data: Model) => {
        if (email && code) {
            let result = await ResetService({
                Email: email,
                NewPassword: data.NewPassword,
                Token: code
            })
            if (result.success) {
                history.push({
                    pathname: "/login",
                    state: {
                        reset: true
                    }
                })
            } else {
                let resultType = result.type as any
                setError(resultType, { message: result.message })
            }
        }
        else {
            var model = {
                email: data.Email
            }
            let result = await SendResetCode(model)
            if (!result.success) {
                let resultType = result.type as any
                setError(resultType, { message: result.message })
            }
        }
    }

    return (
        <Layout display={true}>
            <div className={`d-flex flex-column justify-content-between justify-content-lg-center align-items-center h-100 ${style["max-vh-100"]}`}>
                <p className={`text-end ${style.contentWidth}  ${style["fs-18"]} color-dark-grey d-none d-lg-block`}>
                    <small>Already have an account?</small>
                    <Link className="btn btn-outline-primary ms-3 shadow-none px-3 py-1" to="/login">Login</Link>
                </p>
                <div
                    className={`justify-content-center d-flex flex-column ${style.form}`}
                >
                    <form className="text-center text-lg-start " onSubmit={handleSubmit(submit)}>
                        <h1 className={`${style.loginHeader} mb-3`}>Reset Password</h1>
                        {
                            (email && code) ?
                                <>

                                    <ErrorMessage
                                        errors={errors}
                                        name="NewPassword"
                                        render={({ messages, message }) => <CustomError messages={messages} messageSingle={message} />}
                                    />
                                    <input
                                        type="password"
                                        className="form-control mb-3 shadow-none"
                                        placeholder="New Password"
                                        required
                                        {...register("NewPassword")}
                                    />
                                </> :
                                <>
                                    {
                                        isSubmitSuccessful ?
                                            <div className="text-small alert alert-warning py-1 mb-1" role="alert">
                                                An email containing the code has been sent.
                                            </div> : <></>
                                    }
                                    <ErrorMessage
                                        errors={errors}
                                        name="Email"
                                        render={({ messages, message }) => <CustomError messages={messages} messageSingle={message} />}
                                    />
                                    <input
                                        type="email"
                                        className="form-control mb-3 shadow-none"
                                        placeholder="Email"
                                        required
                                        {...register("Email")}
                                    />
                                </>
                        }
                        <button
                            className="btn  btn-primary btn-radius-10 shadow-none w-100"
                            disabled={isSubmitting}
                            type="submit"
                        >
                            Reset Password
                        </button>
                    </form>

                    <p className={`text-end ${style.contentWidth} mt-2 ${style["fs-18"]} color-dark-grey d-lg-none w-100 d-flex justify-content-between`}>
                        <small>Already have an account?</small>
                        <Link className="btn btn-outline-primary ms-3 btn-radius-10 shadow-none px-3 py-1" to="/login">Login</Link>
                    </p>
                </div>
                <p className={`text-end ${style.contentWidth} mt-2 ${style["fs-18"]} color-dark-grey d-none d-lg-block`}>
                    <small>New to Inkmark?</small>
                    <Link className="btn btn-outline-primary ms-3 btn-radius-10 shadow-none px-3 py-1" to="/registration">Create Account</Link>
                </p>
                <div className="d-block d-lg-none w-100 mt-5">
                    <p className={`mb-1 text-center ${style["fs-18"]} color-dark-grey`}>New to Inkmark?</p>
                    <Link className="btn btn-outline-primary btn-radius-10 w-100" to="/registration">
                        Create Account
                    </Link>
                </div>
            </div>
        </Layout>
    );
}
