import { useState } from "react";
import Toggle from "../landing-page/components/toggle";
import Layout from "./layoutTwo";
// import Layout from "./layout-two";
import style from "./layout.module.css";
import PriceCard from "./pricecard";

export default function Pricing() {
  const [duration, setDuration] = useState<"monthly" | "yearly">("monthly");

  return (
    <Layout>
      <div
        className={`text-center d-flex flex-column align-items-center h-100 ${style.pricing} pt-4`}
      >
        <h1 className={style.header}>Choose the right plan for you</h1>
        <p className={`mb-2 mb-lg-4 ${style.info}`}>
          The most affordable cloud storage plans to suit every need
        </p>
        <Toggle
          itemA="Monthly"
          itemB="Yearly"
          onSelected={(value: "monthly" | "yearly") => setDuration(value)}
        />

        {duration === "monthly" ? (
          <div className="row w-100 my-2 my-lg-4 px-5 px-lg-5 g-3">
            <div className="col-12 col-lg-4">
              <PriceCard
                images="/icons/lite.svg"
                type="Lite"
                duration="monthly"
                storage={5}
                url={0}
              />
            </div>
            <div className="col-12 col-lg-4">
              <PriceCard
                images="/icons/pro.svg"
                type="Pro"
                price={7.99}
                info={7.99 * 12}
                duration="monthly"
                storage={100}
                url={1}
              />
            </div>
            <div className="col-12 col-lg-4">
              <PriceCard
                images="/icons/proplus.svg"
                type="Pro+"
                price={9.99}
                info={9.99 * 12}
                duration="monthly"
                storage={250}
                url={2}
              />
            </div>
          </div>
        ) : (
          <div className="row w-100 my-2 my-lg-4 px-5 px-lg-5 g-3">
            <div className="col-12 col-lg-4">
              <PriceCard
                images="/icons/lite.svg"
                type="Lite"
                duration="monthly"
                storage={5}
                url={0}
              />
            </div>
            <div className="col-12 col-lg-4">
              <PriceCard
                images="/icons/pro.svg"
                type="Pro"
                price={6.99}
                info={12}
                duration="yearly"
                url={3}
                storage={100}
              />
            </div>
            <div className="col-12 col-lg-4">
              <PriceCard
                images="/icons/proplus.svg"
                type="Pro+"
                price={8.99}
                info={10}
                duration="yearly"
                storage={250}
                url={4}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
