export const onFileByUser = /* GraphQL */ `
  subscription OnFileByUser($userId: ID!) {
    onFileByUser(userId: $userId) {
      id
      name
      size
      modified
      parent
      type
      lastUploadType
      root
    }
  }
`;

export const onFileCommentCreate = /* GraphQL */ `
  subscription onFileCommentCreate($fileId: ID!) {
    onFileCommentCreate(fileId: $fileId) {
      createdAt
      content
      id
      userId
      user{
        name
      }
    }
  }
`;

export const onFileByUserByParent = /* GraphQL */ `
  subscription onFileByUserByParent($userId: ID!, $parent: ID) {
    onFileByUserByParent(userId: $userId, parent: $parent) {
      id
      name
      size
      modified
      type
      activeDate
      uploaderId
      uploader {
        name
      }
    }
  }
`;

export const onFavoriteFile = /* GraphQL */ `
  subscription OnFavoriteFile($userId: ID!, $parent: ID) {
    onFavoriteFile(userId: $userId, parent: $parent) {
      id
      name
      type
      size
      userId
      parent
      modified
      created
      root
      uploader {
        name
      }
    }
  }
`;

export const onFavoriteByFile = /* GraphQL */ `
  subscription OnFavoriteFile($parent: ID!) {
    onFavoriteByFile(parent: $parent) {
      id
      name
      type
      size
      userId
      parent
      modified
      created
      root
      uploader{
        name
      }
    }
  }
`;

export const onFavoriteFileByUser = /* GraphQL */ `
  subscription OnFavoriteFileByUser($userId: ID!) {
    onFavoriteFileByUser(userId: $userId) {
      id
      name
      type
      size
      userId
      parent
      modified
      created
      root
    }
  }
`;
