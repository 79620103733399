import Popup from "../../Platform/Components/popup";
import { useState } from "react";
import CloseButton from "../../Platform/Components/closeButton";

export default function Cookies() {
  const [preview, setPreview] = useState(false);
  return (
    <>
      <button
        className="color-black btn btn-none p-0 text-small "
        style={{
          fontSize: "14px",
        }}
        onClick={() => setPreview(true)}
      >
        Cookies policy
      </button>
      <Popup
        className="modalPopup"
        open={preview}
        closeOnDocumentClick
        onClose={() => {
          setPreview(false);
        }}
      >
        <div
          className="bg-white overflow-auto"
          style={{
            borderRadius: "40px",
            padding: "10px",
            height: "90vh",
            maxHeight: "90vh",
            width: "75vw",
          }}
        >
          <CloseButton preview={preview} setPreview={setPreview} />
          <div
            style={{
              overflow: "auto",
              height: "100%",
              fontFamily: "New-Times",
            }}
            className=" ps-3 pe-0 ps-lg-5 pe-lg-3 py-5"
          >
            <h4>Inkmark Acceptable Use Policy</h4>
            <p>
              Inkmark is used by millions of people, and we're proud of the
              trust placed in us. In exchange, we trust you to use our services
              responsibly.
            </p>
            <p>
              You agree not to misuse the Inkmark services ("Services") or help
              anyone else to do so. By using the Services, you accept the terms
              of this policy. If you do not agree to its terms, you must not use
              our Services. We reserve the right to amend this policy from time
              to time and at any time.
            </p>
            <p>
              There are other terms that apply to you. This policy must be read
              in conjunction with the Terms & Conditions and privacy policy.
            </p>
            <h4>Prohibited Uses</h4>
            <p>
              You must not do any of the following in connection with the
              Services, or knowingly attempt to do so, or wilfully permit anyone
              else to do so:
            </p>
            <ul>
              <li>
                probe, scan, or test the vulnerability of any system or network,
                unless done in compliance with our Bug Bounty Program;
              </li>
              <li>
                {" "}
                breach or otherwise circumvent any security or authentication
                measures;
              </li>
              <li>
                access, tamper with, or use non-public areas or parts of the
                Services, or shared areas of the Services you haven't been
                invited to;
              </li>
              <li>
                interfere with or disrupt any user, host, or network, for
                example by sending a virus, overloading, flooding, spamming, or
                mail-bombing any part of the Services;
              </li>
              <li>
                access, search, or create accounts for the Services by any means
                other than our publicly supported interfaces (for example,
                "scraping" or creating accounts in bulk);
              </li>
              <li>
                send unsolicited communications, promotions or advertisements,
                or spam;
              </li>
              <li>
                send, knowingly receive, upload, use or re-use altered,
                deceptive or false source-identifying information, including
                "spoofing" or "phishing";
              </li>
              <li>
                promote or advertise products or services other than your own
                without appropriate authorisation;
              </li>
              <li>
                abuse referrals or promotions to get more storage space than
                deserved or to sell storage space received from referrals or
                promotions;
              </li>
              <li>circumvent storage space limits;</li>
              <li>
                sell the Services unless specifically authorised to do so;
              </li>
              <li>
                publish, share, or store materials that constitute child
                sexually exploitative material (including material which may not
                be illegal child sexual abuse material, but which nonetheless
                sexually exploits or promotes the sexual exploitation of
                minors), unlawful pornography, or are otherwise indecent;
              </li>
              <li>
                publish, share, or store content that contains or promotes
                extreme acts of violence or terrorist activity, including terror
                propaganda;
              </li>
              <li>
                advocate bigotry or hatred against any person or group of people
                based on their race, religion, ethnicity, sex, gender identity,
                sexual orientation, disability, or impairment;
              </li>
              <li>
                harass or abuse Inkmark personnel or representatives or agents
                performing services on behalf of Inkmark;
              </li>
              <li>
                violate the law in any way, including storing, publishing or
                sharing material that’s fraudulent, defamatory, or misleading,
                or that violates the intellectual property rights of others;
              </li>
              <li>violate the privacy or infringe the rights of others;</li>
              <li>
                use the Services to back up, or use as infrastructure for your
                own cloud services;
              </li>
              <li>
                use the storage space provided by the Services as the basis for
                cryptographic proof-of-space or proof-of-storage, or any similar
                proof system.
              </li>
            </ul>
            <p>
              We reserve the right to take appropriate action in response to
              violations of this policy, which could include removing or
              disabling access to content, suspending a user’s access to the
              Services, or terminating an account.
            </p>
            <p>
              When we consider that a breach of this acceptable use policy as
              occurred, we may take whatever action we deem necessary or
              appropriate. Failure to comply with this policy will be regarded
              as a material breach of our Terms & Conditions and terms of use
              and may result in us taking any of the following actions:
            </p>
            <ul>
              <li>
                immediate, temporary or permanent withdrawal of your right to
                use the Services or access our site;
              </li>
              <li>issue a warning to you;</li>
              <li>notify law enforcement or other agencies;</li>
              <li>
                instigate legal procedings against you (including recovery of
                legal fees and other expenses on an indemnity basis)
              </li>
            </ul>
            <p>
              We exclude our liability for all action we may take in response to
              breaches of this acceptable use policy. The actions we may take
              are not limited to those described above.
            </p>
            <p>
              If you are a consumer, please note that the terms of this policy,
              the Terms and Conditions generally, its subject matter and its
              formation and governed by English law. You and we both agree that
              the courts of England and Wales will have exclusive jurisdiction
              except that we may also bring procedings in either Scotland or
              Northern Ireland if you are resident there. If you are a business,
              the terms of this policy, the Terms and Conditions generally, its
              subject matter and its formation (including any non-contractual
              dispute or claims) are governed by English law. We both agree to
              the exclusive jurisdiction of the English courts.
            </p>
          </div>
        </div>
      </Popup>
    </>
  );
}
