import { useState } from "react";
import { useOverlay } from "../../../Context/OverlayContext";
import { CancelPremium } from "../../../Services/Profile";
import style from "../Setting.module.css";
const reasons = [
  "I needed it for a project which is now over",
  "Inkmark is too expensive for me",
  "I don't use it often",
  "There are not many quality resources",
  "I needed it for a project which is now over",
  "I have decided to use another platform",
];
interface Props {
  keep: Function;
  onSuccess: Function;
  onReload: Function;
}
export default function Cancel({ keep, onSuccess, onReload }: Props) {
  const [cancelReason, setCancelReason] = useState(0);
  const { setLoading } = useOverlay();
  function cancel(reason: string) {
    setLoading(true);
    CancelPremium(reason)
      .then((result) => {
        if (result.success) {
          onReload();
          onSuccess();
        }
      })
      .finally(() => setLoading(false));
  }
  return (
    <div className={style.subscription}>
      <h4>We are sorry you decided to leave the premium family</h4>
      <p className={style["text-subscription-2"]}>
        You can still enjoy all the perks of being Premium until your current
        plan expires. After that, you will no longer have access to additional
        storage. That doesn't feel right, does it?
      </p>
      <hr />
      <h4>We'd like to know why you're leaving:</h4>
      {reasons.map((x, i) => (
        <div className="form-check" key={i}>
          <input
            className="form-check-input"
            type="radio"
            name="reason"
            id={"reason" + i}
            checked={cancelReason === i}
            onChange={() => setCancelReason(i)}
          />
          <label className="form-check-label" htmlFor="reason1">
            {x}
          </label>
        </div>
      ))}
      <div className={`d-flex my-3 ${style.profileFormButtons}`}>
        <button className="btn btn-primary" onClick={() => keep()}>
          Keep Premium
        </button>
        <button
          className="btn bg-dark-grey color-white ms-2 shadow-none"
          onClick={() => cancel(reasons[cancelReason])}
        >
          Cancel Premium
        </button>
      </div>
    </div>
  );
}
