interface props {
  now: number;
  color: string;
}
export default function Progress({ now, color }: props) {
  return (
    <div>
      <div
        className="progress"
        style={{
          borderRadius: "1.5rem",
          height:"10px"
        }}
      >
        <div
          className="progress-bar "
          style={{
            width: now + "%",
            borderRadius: "1.5rem",
            backgroundColor: color
          }}
          role="progressbar"
          aria-valuenow={now}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    </div>
  );
}
