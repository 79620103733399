import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { SearchData } from "../../../Models/Document";
import style from "../Row.module.css";
import Avatar from "../avatar";
import Popup from "../popup";

interface Props {
  fileData: SearchData;
  onClick: Function;
  onMove: Function;
  handleKeyDown: KeyboardEventHandler<HTMLTableRowElement>;
  tabIndex: number;
  previewImage: Function;
}

const SearchRow: React.FC<Props> = ({
  fileData,
  onClick,
  onMove,
  handleKeyDown,
  tabIndex,
  previewImage,
}) => {
  const [file, setFile] = useState<SearchData>(fileData);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    setFile(fileData);
  }, [fileData]);

  const isVideoFile = (file: any) => {
    return file.type === "media";
  };

  return (
    <tr
      className={`${style.fileRow} position-relative`}
      style={{
        fontSize: "14px",
      }}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      onLoad={(event) => {
        if (tabIndex === 0) {
          event.currentTarget.focus();
        }
      }}
    >
      <td
        className="align-middle"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            if (isVideoFile(file)) {
              previewImage(file);
            }
          }, 5000);
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutRef.current);
        }}
      >
        <button
          className="d-flex btn align-items-center shadow-none px-0"
          onClick={() => onClick(file.id)}
        >
          <span style={{ width: "32px" }}>
            <img
              src={`/logos/${file.type}.png`}
              alt={`${file.type} logo`}
              className="img-fluid"
            />
          </span>
          <span className="ms-2 d-none d-lg-inline">{file.name}</span>
        </button>
      </td>
      <td className="d-table-cell d-lg-none align-middle">
        <>
          <span className="text-center">{file.name}</span>
          <br />
          {file.modified.toLocaleDateString()}
          <span className="color-dark-grey"> by {file.modifierName}</span>
          <br />
          {file.size}
          <br />
          <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
            {file.users &&
              file.users.map((user, i) => (
                <Avatar name={user.name} image={user.id} key={user.id} />
              ))}
            {file.sharedCount
              ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
              : ""}
          </span>
        </>
      </td>
      <td className="align-middle d-none d-lg-table-cell">
        <div className="d-flex flex-column">
          {file.modified.toLocaleDateString()}
          <small className="color-dark-grey color-dark-grey">
            by {file.modifierName}
          </small>
        </div>
      </td>
      <td className="align-middle d-none d-lg-table-cell text-nowrap">
        {file.size}
      </td>
      <td className="color-dark-grey align-middle d-none d-lg-table-cell images">
        <span className={`${style.imgOverlap} ${style.horizontalAvatars}`}>
          {file.users &&
            file.users.map((user, i) => (
              <Avatar name={user.name} image={user.id} key={user.id} />
            ))}
          {file.sharedCount
            ? file.sharedCount > 3 && "+" + (file.sharedCount - 3)
            : ""}
        </span>
      </td>
      <td className="text-end">
        <Popup
          trigger={
            <button
              className="btn btn-transparent color-dark-grey shadow-none"
              type="button"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
          }
          position="left center"
          nested
        >
          {(close: Function) => (
            <div
              className="card bg-white p-0"
              style={{
                filter: "drop-shadow(0px 4px 6px rgb(0,0,0,0.16))",
                borderRadius: "10px",
              }}
            >
              <div className="card-body px-0">
                <ul className={`list-unstyled mb-0 ${style.menu}`}>
                  <li>
                    <button
                      className="btn shadow-none me-5"
                      onClick={() => {
                        close();
                        previewImage();
                      }}
                    >
                      <i className="fas fa-play"></i> Preview
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popup>
      </td>
    </tr>
  );
};

export default SearchRow;
