import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Popup from "../../Components/popup";
import { useFile } from "../../../Context/FileContext";
import {
  Comment as CommentModel,
  FileData,
  member,
  Activity as ActivityModel,
} from "../../../Models/Document";
import { DownloadFile, Preview, Replace } from "../../../Services/Document";
import { archive } from "../../../Services/Archive";
import Comment from "../../Components/Comment";
import Move from "../../Components/Move";
import Share from "../../Components/Share";
import Avatar from "../../Components/avatar";
import Activity from "../../Components/Activity";
import ReplaceDropFiles from "../../Components/ReplaceDropFiles";
import { useOverlay } from "../../../Context/OverlayContext";
import Previewer from "../../Components/Preview";
import CloseButton from "../../Components/closeButton";
import UploadSVG from "../../../Icons/Upload";
import { IoArrowBackCircle } from "react-icons/io5";

interface props {
  comments: CommentModel[];
  file?: FileData;
  id: string;
  members: member[];
  refresh: Function;
  activities: ActivityModel[];
  loadMoreActivities: Function;
  hasMoreActivities: boolean;
  breadcrumbs: any[];
  previewFile: string;
}

export default function File({
  comments,
  file,
  id,
  members,
  refresh,
  activities,
  loadMoreActivities,
  hasMoreActivities,
  breadcrumbs,
  previewFile = "",
}: props) {
  const [move, setMove] = useState(false);
  const [preview, setPreview] = useState(false);
  const { addFiles } = useFile();
  const history = useHistory();
  const [prevId, setPrevId] = useState("/files");
  const [firstId, setFirstId] = useState(null);
  const { setLoading } = useOverlay();
  const onDownload = async () => {
    if (file) {
      await DownloadFile(file.lastVersion);
    }
  };

  const openView = () => {
    setPreview(true);
  };
  const uploadFile = async (upload: File) => {
    addFiles([
      {
        id: file?.id,
        file: upload,
        uploadUrl: "replace",
        save: async () => {
          let value = {
            id: file?.id,
            size: upload.size,
            type: upload.type,
            name: upload.name,
          };
          await Replace(value as any);
          refresh();
        },
      },
    ]);
    setTimeout(() => refresh(), 10000);
  };
  breadcrumbs.map((x, index) => {
    // Check if it's the first breadcrumb and set the id
    if (index === 0 && firstId === null) {
      setFirstId(x.id);
    }
  });
  useEffect(() => {
    breadcrumbs.map((x, index) => {
      if (breadcrumbs.length === 1) {
        setPrevId(`/files/folder/${x.id}`);
      }
      if (breadcrumbs.length === 2) {
        setPrevId(`/files/folder/${x.id}`);
      }
      if (breadcrumbs.length === 3) {
        setPrevId(`/files/folder/${x.id}`);
      }
    });

    if (breadcrumbs.length === 0) {
      setPrevId("/files");
    }
  }, [breadcrumbs]);

  return (
    <>
      <section className="flex-fill overflow-auto px-2 py-3 d-flex d-lg-none flex-column justify-content-between">
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`${prevId}`}>
                  <IoArrowBackCircle
                    style={{ width: "30px", height: "30px" }}
                  />
                </Link>
                <Link
                  className="text-decoration-none color-dark-grey"
                  to="/files"
                >
                  My files
                </Link>
              </li>
              {breadcrumbs.length === 3 &&
                !breadcrumbs.some((item) => item.id === firstId) && (
                  <li className="breadcrumb-item">
                    {" "}
                    <i className="fas fa-ellipsis-h"></i>{" "}
                  </li>
                )}
              {breadcrumbs.map((x) => (
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none color-dark-grey"
                    to={"/files/folder/" + x.id}
                  >
                    {x.name}
                  </Link>
                </li>
              ))}
              <li className="breadcrumb-item">{file?.name}</li>
            </ol>
          </nav>
          <div className="card card-radius mb-2">
            <div className="card-body d-flex align-items-center flex-column">
              <div
                onDoubleClick={() => {
                  openView();
                }}
                style={{
                  width: "100%",
                }}
              >
                <Previewer
                  src={previewFile}
                  fileName={file?.name ?? ""}
                  autoPlay={false}
                />
              </div>
              <p className="mb-0 mt-3">{file?.name}</p>
              <p className="text-muted mb-0">{file?.size}</p>
            </div>
            <button
              className="w-100 btn-primary btn card-button-footer"
              style={{
                border: "1px solid #DCE4F5",
              }}
              onClick={() => {
                onDownload();
              }}
            >
              Download
            </button>
          </div>
          <label
            className="btn  w-100"
            style={{
              backgroundColor: "#F2F6FF",
              color: "#3A77F3",
              border: "1px solid #3A77F3",
            }}
          >
            Take photo
            <input
              type="file"
              id="imageFile"
              capture="user"
              accept="image/*"
              className="d-none"
              onChange={(event) => {
                if (event.currentTarget.files)
                  uploadFile(event.currentTarget.files[0]);
              }}
            />
          </label>
          <p className="text-center mb-2">OR</p>
          <label
            className="btn w-100 mb-3"
            style={{
              backgroundColor: "#F2F6FF",
              color: "#3A77F3",
              border: "1px solid #3A77F3",
            }}
          >
            Upload file from library
            <input
              type="file"
              id="file"
              className="d-none"
              onChange={(event) => {
                if (event.currentTarget.files)
                  uploadFile(event.currentTarget.files[0]);
              }}
            />
          </label>
        </div>

        <div>
          <button
            className="btn w-100 mb-3 shadow-none"
            style={{
              border: "1px solid #FDA758",
              backgroundColor: "#FFF6EF",
              color: "#FDA758",
            }}
            onClick={() => setMove(true)}
          >
            Move File
          </button>
          <button
            className="btn w-100 shadow-none"
            style={{
              border: "1px solid #AC9CFF",
              color: "#AC9CFF",
              backgroundColor: "#F5F6FC",
            }}
            onClick={() => {
              setLoading(true);
              archive(id)
                .then(() => {
                  history.push("/archived");
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            Archive File
          </button>
        </div>
      </section>

      <section className="h-100 d-none d-lg-flex flex-column">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-3">
            <li className="breadcrumb-item">
              <Link to={`${prevId}`}>
                <IoArrowBackCircle style={{ width: "30px", height: "30px" }} />
              </Link>
              <Link
                className="text-decoration-none color-dark-grey"
                to="/files"
              >
                My files
              </Link>
            </li>
            {breadcrumbs.length === 3 && (
              <li className="breadcrumb-item">
                {" "}
                <i className="fas fa-ellipsis-h"></i>{" "}
              </li>
            )}
            {breadcrumbs.map((x) => (
              <li className="breadcrumb-item">
                <Link
                  className="text-decoration-none color-dark-grey"
                  to={"/files/folder/" + x.id}
                >
                  {x.name}
                </Link>
              </li>
            ))}
            <li className="breadcrumb-item">{file?.name}</li>
          </ol>
        </nav>
        <div className="row flex-fill overflow-auto">
          <div className="col-4 d-flex justify-content-between flex-column overflow-auto h-100">
            <div>
              <div className="card card-radius mb-2">
                <div
                  className="card-body d-flex align-items-center flex-column"
                  onDoubleClick={() => {
                    openView();
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Previewer
                      src={previewFile}
                      fileName={file?.name ?? ""}
                      autoPlay={false}
                    />
                  </div>
                  <div>
                    <Popup
                      open={preview}
                      closeOnDocumentClick
                      className="modalPopup"
                      onClose={() => setPreview(false)}
                    >
                      <div
                        style={{
                          height: "90vh",
                          width: "80vw",
                        }}
                      >
                        <CloseButton
                          preview={preview}
                          setPreview={setPreview}
                        />
                        <Previewer
                          src={previewFile}
                          fileName={file?.name ?? ""}
                          autoPlay={true}
                        />
                      </div>
                    </Popup>
                  </div>
                  <p className="mb-0 fw-bold text-center mt-3">{file?.name}</p>
                  <p
                    className="text-muted mb-0"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {file?.size}
                  </p>
                </div>
                <button
                  className="w-100 btn-primary btn card-button-footer"
                  style={{
                    border: "1px solid #DCE4F5",
                  }}
                  onClick={() => {
                    onDownload();
                  }}
                >
                  Download
                </button>
              </div>
              {/* <ReplaceDropFiles
                callback={(file: File) => {
                  uploadFile(file);
                }}
              />
              <p className="text-center my-2">OR</p> */}
              <label
                className="btn w-100 mb-3 fw-bold"
                style={{
                  backgroundColor: "#F2F6FF",
                  color: "#3A77F3",
                  border: "1px solid #3A77F3",
                }}
              >
                <div>
                  <span className="svgIcon d-inline-block">
                    <UploadSVG color="#ACB8D1" />
                  </span>
                  <br />
                  Upload file
                  <input
                    type="file"
                    id="file"
                    className="d-none"
                    onChange={(event) => {
                      if (event.currentTarget.files)
                        uploadFile(event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </label>

              <div className="card card-radius mb-3 border">
                <div className="card-body d-flex align-items-center flex-column fw-bold">
                  Shared with
                  <div className="justify-content-center d-flex flex-wrap">
                    {members.map((user, i) => (
                      <Avatar name={user.name} image={user.id} key={user.id} />
                    ))}
                  </div>
                </div>
                <Popup
                  className="modalPopup"
                  trigger={
                    <button
                      className="w-100 color-dark-grey btn  card-button-footer shadow-none fw-bold"
                      style={{
                        backgroundColor: "#ECECF2",
                      }}
                    >
                      Manage Sharing
                    </button>
                  }
                  modal
                  closeOnDocumentClick={false}
                >
                  {(close: Function) => (
                    <div
                      className="bg-white p-3 d-flex flex-column"
                      style={{
                        width: "40vw",
                        height: "380px",
                        filter: "drop-shadow(0px 3px 6px rgb(0,0,0,0.16))",
                      }}
                    >
                      <Share
                        close={() => close()}
                        fileId={id}
                        refresh={refresh}
                      />
                    </div>
                  )}
                </Popup>
              </div>
            </div>

            <div>
              <button
                className="btn w-100 mb-3 shadow-none"
                style={{
                  border: "1px solid #FDA758",
                  backgroundColor: "#FFF6EF",
                  color: "#FDA758",
                }}
                onClick={() => setMove(true)}
              >
                Move File
              </button>
              <button
                className="btn w-100 shadow-none"
                style={{
                  border: "1px solid #AC9CFF",
                  color: "#AC9CFF",
                  backgroundColor: "#F5F6FC",
                }}
                onClick={() => {
                  setLoading(true);
                  archive(id).then(() => {
                    history.push("/archived");
                    setLoading(false);
                  });
                }}
              >
                Archive File
              </button>
            </div>
          </div>
          <div className="col-4 h-100">
            <Activity
              activities={activities}
              loadMore={loadMoreActivities}
              hasMore={hasMoreActivities}
            />
          </div>
          <div className="col-4 h-100">
            <Comment
              fileId={id}
              comments={comments}
              next={function () {
                throw new Error("Function not implemented.");
              }}
              hasMore={false}
            />
          </div>
        </div>
      </section>
      <Popup
        open={move}
        closeOnDocumentClick={false}
        onClose={() => setMove(false)}
        closeOnEscape={false}
        className="menuPopup"
      >
        {(close: Function) => (
          <Move
            close={() => {
              close();
            }}
            fileId={id}
            onmove={() => {}}
            root={0}
          />
        )}
      </Popup>
    </>
  );
}
