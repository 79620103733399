import { API, Auth, Storage } from "aws-amplify";
import {
  cancleNotification,
  clearActivityNotifications,
  clearNotifications,
  deleteStripeSubscription,
  updateMessage,
  updateNotification,
  updateUser,
} from "../graphql/mutations";
import {
  getNotificationSettings,
  getSubscription,
  getSubscriptionSettings,
} from "../graphql/profileQueries";
import {
  onMessageCreate,
  onNotificationCreate,
  userMessages,
  userNotifications,
} from "../graphql/userQueries";
import { PasswordChange, Profile as ProfileModel } from "../Models/Profile";
import { getUser } from "../graphql/queries";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export async function Profile() {
  try {
    var user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: getUser,
      variables: { id: user.username },
    }) as Promise<{
      data: any;
    }>);

    return {
      success: true,
      data: {
        UserName: value.data.getUser.name,
        Email: value.data.getUser.email,
        StorageName: value.data.getUser.storageName ?? "My Content",
        TimeZone: value.data.getUser.timezone ?? timeZone,
        Date: new Date(value.data.getUser.updatedAt),
      },
    };
  } catch (error) {
    console.error(error);
  }
}

export async function ProfileChange(data: ProfileModel) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    // let email = user.attributes.email;
    const value = await (API.graphql({
      query: getUser,
      variables: { id: user.username },
    }) as Promise<{
      data: any;
    }>);
    let email = value.data.getUser.email;
    await Auth.updateUserAttributes(user, {
      email: data.Email,
      name: data.UserName,
      "custom:storageName": data.StorageName,
      locale: data.TimeZone,
    });

    await (API.graphql({
      query: updateUser,
      variables: {
        input: {
          name: data.UserName,
          storageName: data.StorageName,
          email: data.Email,
          timezone: data.TimeZone,
          id: user.username,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return {
      success: true,
      emailChanged: data.Email !== email,
    };
  } catch (error: any) {
    return {
      success: false,
      type: "Email",
      message: error.message,
    };
  }
}

export async function EmailChange(value: { Code: string }) {
  try {
    await Auth.verifyCurrentUserAttributeSubmit("email", value.Code);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      success: false,
      type: "Code",
      message: error.message,
    };
  }
}

export async function ChangePassword(data: PasswordChange) {
  try {
    var user = await Auth.currentAuthenticatedUser();
    if (data.OldPassword === data.NewPassword) {
      return {
        success: false,
        type: "OldPassword",
        message: "New password must be different from old password",
      };
    }
    await Auth.changePassword(user, data.OldPassword, data.NewPassword);
    await (API.graphql({
      query: updateUser,
      variables: {
        input: {
          id: user.username,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return {
      success: true,
    };
  } catch (error: any) {
    if (error.code === "NotAuthorizedException") {
      return {
        success: false,
        type: "OldPassword",
        message: error.message,
      };
    } else {
      return {
        success: false,
        type: "NewPassword",
        message: error.message,
      };
    }
  }
}
export async function GetPicture() {
  try {
    var user = await Auth.currentAuthenticatedUser();
    const result = await Storage.get(user.username, {
      download: true,
      cacheControl: "no-cache",
    });

    if (result && result.Body) {
      var value = await new Response(result.Body as Blob).text();
      return {
        success: true,
        data: value,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function UploadPicture(file: string) {
  try {
    var user = await Auth.currentAuthenticatedUser();
    await Storage.put(user.username, file);
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function GetNotifications() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: getNotificationSettings,
      variables: { id: user.username },
    }) as Promise<{
      data: any;
    }>);
    return value.data.getUser;
  } catch (e) {
    return undefined;
  }
}

export async function SaveNotifications(email: boolean, notify: boolean) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    await (API.graphql({
      query: updateUser,
      variables: {
        input: {
          id: user.username,
          notifyAbout: notify,
          emailNotifications: email,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return {
      success: true,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
}

export async function GetSubscription() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: getSubscriptionSettings,
      variables: { id: user.username },
    }) as Promise<{
      data: any;
    }>);

    return {
      plan: value.data.getUser.plan,
      subscription: value.data.getSubscription,
      card: value.data.getPaymentMethod,
    };
  } catch (e) {
    return undefined;
  }
}

export async function getSubscriptionStatus() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: getSubscription,
      variables: { id: user.username },
    }) as Promise<{
      data: any;
    }>);

    return {
      success: true,
      data: value.data.getSubscription.status,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function SaveSubscriptions(index: string) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    let result = await (API.graphql({
      query: updateUser,
      variables: {
        input: {
          id: user.username,
          planId: index,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return {
      success: true,
      data: result.data.updateUser.plan,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
}

export async function CancelPremium(reason: string) {
  try {
    let result = await (API.graphql({
      query: deleteStripeSubscription,
      variables: {
        reason: reason,
      },
    }) as Promise<{
      data: any;
    }>);
    return {
      success: true,
      data: result.data.deleteStripeSubscription,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
}

export async function RemoveMessage(id: string) {
  try {
    await (API.graphql({
      query: updateMessage,
      variables: {
        input: {
          id: id,
          read: true,
        },
      },
    }) as Promise<{
      data: any;
    }>);
    return {
      success: true,
    };
  } catch (error) {
    return undefined;
  }
}
// export async function RemoveNotification(id: string) {
//   try {
//     await (API.graphql({
//       query: updateNotification,
//       variables: {
//         input: {
//           id: id,
//           read: true,
//         },
//       },
//     }) as Promise<{
//       data: any;
//     }>);
//     return {
//       success: true,
//     };
//   } catch (error) {
//     console.log("error", error);
//     return undefined;
//   }
// }

export async function RemoveNotification(id: string, user: string) {
  try {
    await (API.graphql({
      query: cancleNotification,
      variables: {
        userId: user,
        activityId: id,
      },
    }) as Promise<{
      data: any;
    }>);

    return {
      success: true,
    };
  } catch (error) {
    // console.log("error", error);
    return undefined;
  }
}

export async function messageListener(callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  return (
    API.graphql({
      query: onMessageCreate,
      variables: { userId: user.username },
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onMessageCreate;
      let data = {
        userName: temp.comment.user.name,
        fileName: temp.comment.file.name,
        fileId: temp.comment.file.id,
        content: temp.comment.content,
        id: temp.id,
      };
      callBack(data);
    },
  });
}
export async function notificationListener(callBack: Function) {
  let user = await Auth.currentAuthenticatedUser();
  return (
    API.graphql({
      query: onNotificationCreate,
      variables: { userId: user.username },
    }) as any
  ).subscribe({
    next: (value: any) => {
      let temp = value.value.data.onNotificationCreate;

      let data = {
        createdAt: new Date(temp.activity.createdAt),
        parameters: temp.activity.parameters ?? [],
        userId: temp.activity.user.id,
        userName: temp.activity.user.name,
        type: temp.activity.type,
        id: temp.id,
        fileName: temp.activity.file.name,
        fileId: temp.activity.file.id,
      };
      callBack(data);
    },
  });
}
export async function fetchNewMessages(token: String) {
  try {
    let userId = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userMessages,
      variables: { id: userId.username, token: token },
    }) as Promise<{
      data: any;
    }>);

    let user = value.data.getUser;
    const notifications = user.messages.items.map((x: any) => ({
      userName: x.comment.user.name,
      fileName: x.comment.file.name,
      fileId: x.comment.file.id,
      content: x.comment.content,
      id: x.id,
    }));
    return {
      notifications,
      messageToken: user.messages.nextToken,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function fetchNewNotifications(token: String) {
  try {
    let userId = await Auth.currentAuthenticatedUser();
    const value = await (API.graphql({
      query: userNotifications,
      variables: { id: userId.username, token: token },
    }) as Promise<{
      data: any;
    }>);

    let user = value.data.getUser;
    const notifications = user.notifications.items.map((x: any) => ({
      createdAt: new Date(x.activity.createdAt),
      parameters: x.activity.parameters ?? [],
      userId: x.activity.user.id,
      userName: x.activity.user.name,
      type: x.activity.type,
      id: x.id,
      fileName: x.activity.file.name,
      fileId: x.activity.file.id,
    }));
    return {
      notifications,
      messageToken: user.notifications.nextToken,
    };
  } catch (error: any) {
    return undefined;
  }
}

export async function clearAllMessages() {
  try {
    await API.graphql({
      query: clearNotifications,
      variables: {
        id: "0",
      },
    });
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function clearAllNotifications() {
  try {
    await API.graphql({
      query: clearActivityNotifications,
      variables: {
        id: "0",
      },
    });
    return true;
  } catch (error: any) {
    return false;
  }
}
